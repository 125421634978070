// Note: EditDB for CheckList/Form
// Date: 01/24/2024
// Author: Guruprasad Venkatraman

import { DataStore } from 'aws-amplify';
import {
  ResponseType,
  Response,
  DatabaseResponse,
  mapModelItems,
  executeSingleQuery,
} from '../AmplifyDB';
import { ProgressStatus } from '../../API';
import DepartmentItem from '../model/DepartmentItem';
import FormItem from '../model/FormItem';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Form, FormGroup, FormOption, User } from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import { findUsersForModelItems } from './ModelDB';
import { getElectricalDose, getForm } from '../../graphql/queries';
import {
  confirmAllItemsHaveID,
  findItemByID,
  generateID,
  globals,
} from '../../ui/_global/common/Utils';

export type CheckListDB = {
  name: string;
  items: FormGroup[];
  departmentID: string;
  activeID?: string | null | undefined;
  overrideID?: string | null | undefined;
  version?: string | null | undefined;
  status?: ProgressStatus | keyof typeof ProgressStatus;
  modifiedBy?: string;
  createdBy: string;
};

/**
 * This function will check if the CheckList is a draft version and delete it
 * @param CheckListItem The CheckList to check
 * @returns Success if ready to create a new CheckList or Failure if there is a draft version
 */

export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Form[];
    if (isActive)
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.activeID.eq(id), f.status.eq('DRAFT')])
      );
    else
      results = await DataStore.query(Form, (f) =>
        f.and((f) => [f.status.eq('DRAFT'), f.id.eq(id)])
      );
    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbCheckList = results[0];
    if (dbCheckList.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Form, dbCheckList.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The dbCheckList did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbCheckList,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchChecklists = async (
  dep: DepartmentItem,
  useDatabase: boolean = false,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    const checklistList = await DataStore.query(Form, (m) =>
      m.and((m) => [
        m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
        m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
      ])
    );
    let checklists: FormItem[] = [];
    // let promises: Promise<User | null>[] = [];
    for (let i = 0; i < checklistList.length; i++) {
      let checklist = new FormItem(checklistList[i]);
      // promises.push(checklist.findUser());
      /* Take out the active version if there is one */
      mapModelItems(checklist, checklists, checklist.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(checklists);
    checklists.sort((a, b) => a.getName().localeCompare(b.getName()));

    return {
      type: ResponseType.Success,
      data: checklists,
    };
  } catch (error) {
    console.error('Error fetching checklists:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Create a new dbCheckList in the database and choose the version
 * @param dbCheckList CheckListDB JSON format
 * @returns The successful CheckListItem or the error
 */
export const createCheckList = async (
  checkList: CheckListDB | FormItem,
  previousItem?: FormItem
): Promise<Response> => {
  try {
    let json: CheckListDB;
    if (checkList instanceof FormItem) {
      json = {
        name: checkList.name,
        status: checkList.status,
        activeID: checkList.activeID,
        overrideID: checkList.overrideID,
        departmentID: checkList.departmentID,
        version: checkList.version != null ? checkList.version : 'v1.0.0',
        items: checkList.items != null ? checkList.items : [],
        createdBy: checkList.model.createdBy ? checkList.model.createdBy : '',
        modifiedBy: checkList.modifiedBy ? checkList.modifiedBy.id : undefined,
      };
    } else json = checkList;

    /* Confirm that all items have an ID */
    json.items = json.items.map((item: FormGroup, index: number) => {
      let newItem: FormGroup = {
        ...item,
        id: item.id ? item.id : generateID(),
        options: item.options
          ? item.options.map(
              (option: FormOption, index: number) =>
                ({
                  ...option,
                  id: option.id ? option.id : generateID(),
                  index: option.index == null ? index : option.index,
                }) as FormOption
            )
          : [],
        index: item.index == null ? index : item.index,
      };
      return newItem;
    });

    if (globals.debug) console.log('CHECKLIST json.items', json.items);

    /* 
			1. Creating a DRAFT the first time
			2. Creating a DRAFT from an ACTIVE version
			3. Updating a DRAFT from a DRAFT version
			4. Creating a ARCHIVE from an ACTIVE version
		*/
    let c: Form;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status?.includes('DRAFT')
    ) {
      let dbCheck = await DataStore.query(Form, previousItem.uid);
      if (dbCheck == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Checklist does not exist could not update',
        };
      }

      c = await DataStore.save(
        Form.copyOf(dbCheck, (updated) => {
          updated.name = json.name;
          updated.items = json.items;
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      c = await DataStore.save(
        new Form({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          items: json.items,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    let checkListItem = new FormItem(c);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    console.error('Error in checkList function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the checkList to the database
 *    1. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
 *    2. Update the ACTIVE checkList with the new information
 *    3. Delete the DRAFT checkList
 * @param draftCheckListItem The checkList to publish
 */

export const publishCheckList = async (
  draftCheckListItem: FormItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the checkList is configured correctly as a draft version */
    if (draftCheckListItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The checkList is not a draft version',
      };
    }

    let dbForm = await DataStore.query(Form, draftCheckListItem.uid);
    if (dbForm == null) {
      return {
        type: ResponseType.Failure,
        data: 'The checkList does not exist',
      };
    }

    let activeCheckList: Form;

    /* Use case 1: Creating the FIRST active version */
    if (draftCheckListItem.activeID == null) {
      /* Update the draft checkList to be active */
      activeCheckList = await DataStore.save(
        Form.copyOf(dbForm, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active checkList item */
      let id: string = draftCheckListItem.activeID;
      let curCheckList = await DataStore.query(Form, id);

      /* Base Case 3 -- check if the active checkList exists */
      if (curCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active checkList does not exist',
        };
      }

      let archive = new FormItem(curCheckList);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curCheckList.id;

      // 2. Create a new ARCHEIVED checkList based on the current ACTIVE checkList
      let archiveResult: Response = await createCheckList(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as FormItem;

      // 2. Update the ACTIVE checkList with the new information
      activeCheckList = await DataStore.save(
        Form.copyOf(curCheckList, (updated) => {
          updated.name = draftCheckListItem.name;
          updated.items = draftCheckListItem.items;

          updated.createdBy = draftCheckListItem.model.createdBy;
          updated.modifiedBy = draftCheckListItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftCheckListItem.version;
        })
      );

      // 3. Delete the DRAFT checkList
      let draftCheckList = await DataStore.delete(Form, draftCheckListItem.uid);
      if (draftCheckList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft checkList does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeCheckList.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      // 4. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeCheckList.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let checkListItem = new FormItem(activeCheckList);
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteCheckList = async (
  checkListItem: FormItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = checkListItem.uid;
    if (isSoft) {
      let checkList = await DataStore.query(Form, id);
      if (checkList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList does not exist',
        };
      }

      let result = await DataStore.save(
        Form.copyOf(checkList, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList did not update correctly',
        };
      }
    } else {
      let checkList = await DataStore.delete(Form, id);
      if (checkList == null) {
        return {
          type: ResponseType.Failure,
          data: 'The checkList does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: checkListItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isCheckListDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const getCheckListDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Form, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new FormItem(modelUpdates[i]);
      let activeItem = db.checklists.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem = activeItem;
      }
      updates.push({
        model: model,
        title: 'Checklist ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.CHECKLIST,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertChecklistChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'CheckList ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as FormItem),
      changeType: DraftChangeType.CHECKLIST,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: FormItem): string {
  if (draftItem.activeItem == null)
    return `Created CheckList: ${draftItem.name}`;
  return `Updated CheckList: ${draftItem.name}`;
}

export const removeCurrentCheckListDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftCheckList = await DataStore.query(Form, (f) =>
      f.and((f) => [f.status.eq('DRAFT'), f.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftCheckList.length; i++) {
      let checkList: Form = draftCheckList[i];
      await DataStore.delete(checkList);
      updates.push({
        model: checkList,
        message: `Removed Checklist: ${checkList.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getChecklistByID = async (
  db: DatabaseResponse,
  id: string
): Promise<FormItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbMed = findItemByID(id, db.checklists);
      if (dbMed != null) return resolve(dbMed as FormItem);
      else {
        executeSingleQuery(getForm, { id: id }, 1500)
          .then((form) => {
            if (form == null) {
              resolve(null);
            } else {
              let formItem = new FormItem(form);
              resolve(formItem);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
