import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { DatabaseResponse, ResponseType } from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getDepartmentsFromState,
  getFormattedDate,
  getFormattedDateTime,
  globals,
} from '../../../_global/common/Utils';
import { FaCaretDown, FaCaretUp, FaChevronRight } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import ContactItem from '../../../../data/model/ContactItem';
import SearchBar from '../../../components/Search/SearchBar';
import { WeightObject } from '../../../../models';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import {
  deleteWeightObject,
  fetchWeightObjects,
} from '../../../../data/functions/WeightObjectDB';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListWeightObjects = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const [objects, setWeightObjects] = useState<WeightObject[]>(
    database.weightObjects ? database.weightObjects : []
  );

  const scrollPosition = useRef(0);
  const [sort, setSort] = useState('name_asc');
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<WeightObject[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const reloadDatabase = async () => {
    const response = await fetchWeightObjects(department);
    if (response.type === ResponseType.Success) {
      setWeightObjects(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          weightObjects: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  // const reloadDatabase = async () => {
  //     const response = await loadDatabase(department);
  //     if (response.type === ResponseType.Success) {
  //         const database = response.data;
  //         setDatabase(database);
  //         const protocols: ProtocolItem[] = database.protocols;
  //         handleFilterChange(protocols);
  //     }
  // }

  // useEffect(() => {
  //     reloadDatabase();
  // }
  // , []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : objects)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: WeightObject, b: WeightObject) => {
      return a.name.localeCompare(b.name);
    });

    /* Sort the list based on the sort value */
    filteredList = filteredList.sort((a: WeightObject, b: WeightObject) => {
      if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort.includes('modified')) {
        if (!a.updatedAt || !b.updatedAt) return 0;
        const aDate = new Date(a.updatedAt);
        const bDate = new Date(b.updatedAt);
        if (sort === 'modified_desc') return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'weight_asc') return a.weight - b.weight;
      else if (sort === 'weight_desc') return b.weight - a.weight;
      else return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(objects);
  }, [searchQuery, filters, categoriesFilter, objects, sort]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (obj: WeightObject) => {
    const state = {
      selectedProtocol: null,
      value: obj,
      subValue: null,
      type: 'WeightObject',
      editType: 'edit',
      editMode: false,
      page: 'listWeightObjectPage',
    };
    navigate(`/database/edit/subtractable-weights`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'WeightObject',
      editType: 'new',
      editMode: false,
      page: 'listWeightObjectPage',
    };
    navigate(`/database/new/subtractable-weights`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {params.value.name}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },

    {
      field: 'modified_date',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Modified Date</div>
      ),
      filterable: false,
    },
    {
      field: 'modified_by',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Modified By</div>,
      filterable: false,
    },
    {
      field: 'department_subs',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Department Subs</div>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span>{params.value.count + '/' + params.value.total}</span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.count - v2.count,
      filterable: false,
    },
    {
      field: 'weight',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Weight</div>,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>
            {params.value.lbs} lbs
            {' (' + params.value.kg + ' kg)'}
          </span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.lbs - v2.lbs,
      filterable: false,
    },
  ];

  const rows = list.map((item: WeightObject) => {
    let id = item?.modifiedBy;
    if (id == null) id = item?.createdBy;
    let username = 'Hinckley Medical';
    if (id) {
      let user = database.users.find((user) => user.id === id);
      if (user) username = user.firstName + ' ' + user.lastName;
    }

    return {
      id: item.id,
      name: {
        name: item.name,
      },
      modified_date: getFormattedDate(
        item.updatedAt ? item.updatedAt : new Date(),
        true
      ),
      modified_by: username,
      department_subs: {
        count: allSubDeps && item.pairedDepIDs?.length,
        total: allSubDeps && allSubDeps.length + 1,
      },
      weight: {
        lbs: item.weight.toFixed(1),
        kg: (item.weight / 2.205).toFixed(1),
      },
    };
  });

  const handleSelectionChange = (newSelectionModel: any) => {
    const selectedItems = newSelectionModel
      .map((id: string) => list.find((item) => item.id === id))
      .filter((item: WeightObject | undefined) => item !== undefined);
    setSelectedItems(selectedItems);
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteWeightObject(currentMedication);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted Subtracted Weight: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: WeightObject[]) => {
          const updatedItems = prevItems.filter(
            (item: WeightObject) => item.id !== currentMedication.id
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete Subtracted Weight');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete Subtracted Weight');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteWeightObject(parmMedication);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(
            `Successfully deleted ${successCount} Subtracted Weights.`
          );
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(
            `Failed to delete ${failureCount} Subtracted Weights.`
          );
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete Subtracted Weight.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Weights?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} weights?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Subtractable Weights: ' + list.length + ' items'
            : 'Subtractable Weights: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isCreateButton={true}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Weights...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[50, 100, 200]}
          checkboxSelection
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.id === params.id))
          }
          onRowSelectionModelChange={handleSelectionChange}
          rowSelectionModel={selectedItems.map((item: WeightObject) => item.id)}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListWeightObjects;
