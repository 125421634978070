import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import { globals, toTitleCase } from '../../../_global/common/Utils';
import { EquipmentOption, FormGroup, FormOption } from '../../../../models';
import EquipmentComparisonView from '../comparisonView/EquipmentComparisonView';
import FormItem from '../../../../data/model/FormItem';
import ChecklistComparisonView from '../comparisonView/ChecklistComparisonView';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { useNavigate } from 'react-router-dom';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

const isGroupChanged = (
  currentGroup: FormGroup,
  archiveGroup: FormGroup | undefined
) => {
  if (archiveGroup == null) return true;
  if (currentGroup.isDouble !== archiveGroup?.isDouble) return true;
  if (currentGroup.title !== archiveGroup?.title) return true;
  if (currentGroup.isUrgent !== archiveGroup?.isUrgent) return true;
  for (let i = 0; i < currentGroup.options.length; i++) {
    let currentOption = currentGroup.options[i];
    let archiveOption = archiveGroup.options[i];
    if (archiveOption == null) return true;
    if (currentOption == null) return true;
    if (currentOption.firstItem !== archiveOption.firstItem) return true;
    if (currentOption.secItem !== archiveOption.secItem) return true;
    // return false;
  }
  return false;
};

export const compareFormItems = (
  currentItem: FormItem,
  archiveItem: FormItem
) => {
  const changes: any[] = [];

  // Define the specific fields to check in the main FormItem
  const fieldsToCompare: (keyof FormItem)[] = ['name'];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  // Compare non-array fields
  fieldsToCompare.forEach((key) => {
    const currentValue = currentItem[key];
    const archiveValue = archiveItem ? archiveItem[key] : '';

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  // Compare options array
  const currentGroups = currentItem.items || [];
  const archiveGroups = archiveItem?.items || [];

  const groupChanges = currentGroups.reduce(
    (acc: any[], currentGroup, index) => {
      const archiveGroup = archiveGroups.find(
        (group) => group.id === currentGroup.id
      );
      let optionChanged = isGroupChanged(currentGroup, archiveGroup);

      const optionChanges: any = { index, currentValue: {}, archiveValue: {} };
      if (optionChanged) {
        optionChanges.currentValue.title = currentGroup.title;
        optionChanges.currentValue.isUrgent = currentGroup.isUrgent
          ? 'Urgent'
          : 'Normal';
        optionChanges.currentValue.isDouble = currentGroup.isDouble
          ? 'Double'
          : 'Single';

        let groupChanges = [];
        for (
          let i = 0;
          i <
          Math.max(
            currentGroup.options.length,
            archiveGroup?.options.length || 0
          );
          i++
        ) {
          const currentOption = currentGroup.options[i];
          const archiveOption = archiveGroup?.options[i];
          groupChanges.push({
            key: 'item',
            currentValue: {
              isDouble: currentGroup.isDouble,
              firstItem: currentOption?.firstItem || '',
              secItem: currentOption?.secItem || '',
            },
            archiveValue: {
              isDouble: archiveGroup?.isDouble,
              firstItem: archiveOption?.firstItem || '',
              secItem: archiveOption?.secItem || '',
            },
          });
        }
        optionChanges.currentValue.options = groupChanges;

        if (archiveGroup) {
          optionChanges.archiveValue.title = archiveGroup?.title || '';
          optionChanges.archiveValue.isUrgent = archiveGroup?.isUrgent
            ? 'Urgent'
            : 'Normal';
          optionChanges.archiveValue.isDouble = archiveGroup?.isDouble
            ? 'Double'
            : 'Single';
          optionChanges.archiveValue.options = groupChanges;
        } else {
          optionChanges.archiveValue = null;
        }
      }

      // // Define the specific fields to check in options
      // const fieldsToCompareInGroups: (keyof FormGroup)[] = [
      //   'options',
      //   'title',
      //   'isUrgent',
      //   'isDouble',
      // ];

      // const optionChanges: any = { index, currentValue: {}, archiveValue: {} };

      // fieldsToCompareInGroups.forEach((field) => {
      //   let currentValue = currentGroup[field];
      //   let archiveValue = archiveGroup ? archiveGroup[field] : '';

      //   if (field === 'isUrgent') {
      //     currentValue = currentValue ? 'Urgent' : 'Normal';
      //     archiveValue = archiveValue ? 'Urgent' : 'Normal';
      //     // optionChanges.currentValue[field] = String(currentValue);
      //     // optionChanges.archiveValue[field] = String(archiveValue);
      //   }

      //   if (field === 'isDouble') {
      //     currentValue = currentValue ? 'Double' : 'Single';
      //     archiveValue = archiveValue ? 'Double' : 'Single';
      //     // optionChanges.currentValue[field] = String(currentValue);
      //     // optionChanges.archiveValue[field] = String(archiveValue);
      //   }

      //   if (optionChanged && field !== 'options') {
      //     optionChanges.currentValue[field] = currentValue;
      //     optionChanges.archiveValue[field] = archiveValue;
      //   }

      //   if (field === 'options') {
      //     const isChanged = isGroupChanged(currentGroup, archiveGroup);
      //     if (isChanged) {
      //       optionChanged = true;
      // let groupChanges = [];
      // for (
      //   let i = 0;
      //   i <
      //   Math.max(
      //     currentGroup.options.length,
      //     archiveGroup?.options.length || 0
      //   );
      //   i++
      // ) {
      //   const currentOption = currentGroup.options[i];
      //   const archiveOption = archiveGroup?.options[i];
      //   groupChanges.push({
      //     key: 'item',
      //     currentValue: {
      //       isDouble: currentGroup.isDouble,
      //       firstItem: currentOption.firstItem,
      //       secItem: currentOption.secItem,
      //     },
      //     archiveValue: {
      //       isDouble: archiveGroup?.isDouble,
      //       firstItem: archiveOption?.firstItem,
      //       secItem: archiveOption?.secItem,
      //     },
      //   });
      // }
      // optionChanges.currentValue[field] = groupChanges;
      // optionChanges.archiveValue[field] = groupChanges;

      //       // optionChanges.currentValue[field] = currentGroup.options.map(
      //       //   (option) => {
      //       //     return {
      //       //       isDouble: currentGroup.isDouble,
      //       //       firstItem: option.firstItem,
      //       //       secItem: option.secItem,
      //       //     };
      //       //   }
      //       // );
      //       // optionChanges.archiveValue[field] = archiveGroup?.options.map(
      //       //   (option) => {
      //       //     return {
      //       //       isDouble: archiveGroup?.isDouble || false,
      //       //       firstItem: option?.firstItem || '',
      //       //       secItem: option?.secItem || '',
      //       //     };
      //       // }
      //     }
      //     // const groupItemChanges = currentGroup.options.reduce(
      //     //   (inner_acc: any[], currentOption: FormOption, index: number) => {
      //     //     const archiveOption = archiveGroup?.options[index];
      //     //     // if (
      //     //     //   compareValues(
      //     //     //     currentOption.firstItem,
      //     //     //     archiveOption?.firstItem || ''
      //     //     //   ) ||
      //     //     //   compareValues(
      //     //     //     currentOption.secItem,
      //     //     //     archiveOption?.secItem || ''
      //     //     //   )
      //     //     // ) {
      //     //     inner_acc.push({
      //     //       key: 'item',
      //     //       currentValue: {
      //     //         isDouble: currentGroup.isDouble,
      //     //         firstItem: currentOption.firstItem,
      //     //         secItem: currentOption.secItem,
      //     //       },
      //     //       archiveValue: {
      //     //         isDouble: archiveGroup?.isDouble,
      //     //         firstItem: archiveOption?.firstItem || '',
      //     //         secItem: archiveOption?.secItem || '',
      //     //       },
      //     //     });
      //     //     // }
      //     //     return inner_acc;
      //     //   },
      //     //   []
      //     // );
      //     // if (groupItemChanges.length > 0) {
      //     //   optionChanges.currentValue.options = groupItemChanges;
      //     //   optionChanges.archiveValue.options = groupItemChanges;
      //     //   optionChanged = true;
      //     // }
      //   } else if (compareValues(currentValue, archiveValue)) {
      //     optionChanged = true;
      //     optionChanges.currentValue[field] = String(currentValue);
      //     optionChanges.archiveValue[field] = String(archiveValue);
      //   }
      // });

      // Only add to the changes if any field has changed
      if (optionChanged) {
        acc.push(optionChanges);
      } else {
        acc.push({
          index,
          currentValue: null,
          archiveValue: null,
        });
      }
      // }
      return acc;
    },
    []
  );

  if (groupChanges.length > 0) {
    changes.push({
      key: 'groups',
      changes: groupChanges,
    });
  }

  return changes;
};

const ChecklistComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  onChangesCount,
}) => {
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as FormItem,
      previousItem: currentDraft?.previousItem as FormItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareFormItems(
      changeItem as FormItem,
      previousItem as FormItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    let checklist =
      mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: null,
      value: checklist,
      subValue: null,
      type: 'Checklist',
      editType: 'edit',
      editMode: false,
      page: 'reviewPage',
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = checklist.name.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/review/view/${cleanedPath}`, { state });
  };

  return (
    <div className="doseChangesWrapper">
      <table className="changesTable">
        {previousItem ? (
          <>
            <thead>
              <tr>
                <th className="changeHeader beforeChange">
                  <div className="headerContent">
                    Before
                    <button
                      className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                      onClick={() => handleViewClick('before')}
                    >
                      View
                    </button>
                  </div>{' '}
                </th>
                <th className="changeHeader afterChange">
                  <div className="headerContent">
                    After
                    <button
                      className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                      onClick={() => handleViewClick('after')}
                    >
                      View
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <ChecklistComparisonView
              focusItem={changeItem as FormItem}
              changes={changes}
            />
          </>
        ) : (
          <>
            <thead>
              <tr>
                <th className="changeHeader afterChange">
                  <div className="headerContent">
                    New {toTitleCase(currentDraft.changeType)}
                    <button
                      className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                      onClick={() => handleViewClick('after')}
                    >
                      View
                    </button>
                  </div>
                </th>
              </tr>
            </thead>
            <ChecklistComparisonView
              focusItem={changeItem as FormItem}
              changes={changes}
            />
          </>
        )}
      </table>
    </div>
  );
};

export default ChecklistComparison;
