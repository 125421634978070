import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion, Button, Form, InputGroup } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { FaPencilAlt } from 'react-icons/fa';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { AccordionTab } from 'primereact/accordion';
import { IoArrowBack, IoClose, IoSearch } from 'react-icons/io5';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  getFormattedDateTime,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight, FaLock } from 'react-icons/fa6';
import FormItem from '../../../../data/model/FormItem';
import ContactItem from '../../../../data/model/ContactItem';
import SearchBar from '../../../components/Search/SearchBar';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { Contact, ProgressStatus } from '../../../../models';
import {
  deleteContact,
  fetchContacts,
  subscribeContactFromDepartment,
} from '../../../../data/functions/ContactDB';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import { number } from 'yup';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HMCheckbox from '../../../components/general/HMCheckbox';
import HMSwitch from '../../../components/general/HMSwitch';
import AddContactSideout from '../../../components/SideOut/AddContactSideout';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListContacts = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const reducerState = useSelector((state: any) => state.department);
  const { state } = location;

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user = useSelector((state: any) => state?.user);
  const department: DepartmentItem = database.department;
  const [contacts, setContacts] = useState<ContactItem[]>(database.contacts);
  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const [unsubscribeList, setUnsubscribeList] = useState<ContactItem[]>([]);

  const [list, setList] = useState<any[]>([]);
  const [selectedItems, setSelectedItems] = useState<ContactItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const adminAccess = useMemo(
    () => hasAdminUserAccess(department, reducerState, user),
    [department, user]
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);
  const dispatch = useDispatch();

  const reloadDatabase = async () => {
    const resp = await fetchContacts(database.department);
    if (resp.type === ResponseType.Success) {
      setContacts(resp.data);
      handleFilterChange(resp.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          contacts: resp.data,
        })
      );
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
    }
  };

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : contacts)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = filteredList.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return item.departmentID === filter.id;
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: ContactItem, b: ContactItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(contacts);
  }, [searchQuery, filters, categoriesFilter, contacts]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (check: ContactItem) => {
    const state = {
      selectedProtocol: null,
      value: check,
      subValue: null,
      type: 'Contact',
      editType: 'edit',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/edit/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Contacts',
      editType: 'new',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/new/contact`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleSave = () => {
    handleSubscribeContacts(unsubscribeList, false)
      .then((completed) => {
        if (completed) {
          setSnackbarMessage(
            'Successfully updated ' + department.name + ' contacts'
          );
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            'Failed to update ' + department.name + ' contacts'
          );
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .finally(() => {
        setUnsubscribeList([]);
        reloadDatabase();
      });
  };

  const handleChangeContacts = (newContacts: ContactItem[]) => {
    const addContacts = newContacts.filter(
      (item) => !contacts.find((contact) => contact.uid === item.uid)
    );
    const removeContacts = contacts.filter(
      (item) => !newContacts.find((contact) => contact.uid === item.uid)
    );
    Promise.all([
      handleSubscribeContacts(addContacts, true),
      handleSubscribeContacts(removeContacts, false),
    ])
      .then((completed: boolean[]) => {
        if (completed.every((completed) => completed)) {
          setSnackbarMessage(
            'Successfully updated ' + department.name + ' contacts'
          );
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage(
            'Failed to update ' + department.name + ' contacts'
          );
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        }
      })
      .finally(() => {
        setUnsubscribeList([]);
        reloadDatabase();
      });
  };

  const handleSubscribeContacts = (
    contacts: ContactItem[],
    isSubscribe: boolean
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      let promises: Promise<Response>[] = [];
      for (const contact of contacts) {
        promises.push(
          subscribeContactFromDepartment(contact, department, isSubscribe)
        );
      }
      Promise.all(promises).then((responses) => {
        if (
          responses.every((response) => response.type === ResponseType.Success)
        ) {
          return resolve(true);
        } else {
          return reject(false);
        }
      });
    });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const columns: GridColDef[] = [
    {
      field: 'input',
      flex: 0.25,
      disableColumnMenu: true,
      headerAlign: 'center',
      renderHeader: () => (
        <div style={{ marginRight: '24px' }}>
          <HMCheckbox
            checked={mainCheckbox}
            onChange={(
              event: React.ChangeEvent<HTMLInputElement>,
              checked: boolean
            ) => {
              setMainCheckbox(checked);
              if (checked) {
                setSelectedItems([
                  ...contacts.filter(
                    (item) => department.id === item.departmentID
                  ),
                ]);
              } else {
                setSelectedItems([]);
              }
            }}
          />
        </div>
      ),
      renderCell: (params: any) => {
        let item = list.find((item) => item.uid === params.value.uid);
        if (!item) return <div></div>;
        const adminAccess = hasAdminUserAccess(department, reducerState, user);
        const isOwner = item.departmentID === department.id ?? false;

        const isChecked = selectedItems.find(
          (selectedItem) => selectedItem.uid === item.uid
        );

        let isSwitchChecked = item.status !== ProgressStatus.DEACTIVATED;
        if (unsubscribeList.find((newItem) => newItem.uid === item.uid))
          isSwitchChecked = !isSwitchChecked;

        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
              alignItems: 'center',
              height: '100%',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {adminAccess && (
              <>
                {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <HMCheckbox
                      checked={isChecked != null}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        handleSelectionChange(item);
                      }}
                    />
                    <div
                      style={{
                        marginLeft: '2px',
                        border: '1px solid #E0E0E0',
                        height: '50%',
                        width: '1px',
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      flexDirection: 'row',
                      marginLeft: '-12px',
                    }}
                  >
                    <HMSwitch
                      checked={isSwitchChecked}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        let find = unsubscribeList.find(
                          (newItem) => newItem.uid === item.uid
                        );
                        if (find)
                          setUnsubscribeList(
                            unsubscribeList.filter(
                              (newItem) => newItem.uid !== item.uid
                            )
                          );
                        else setUnsubscribeList([...unsubscribeList, item]);
                      }}
                    />
                    <div
                      style={{
                        marginLeft: '1px',
                        border: '1px solid #E0E0E0',
                        height: '50%',
                        width: '1px',
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        );
      },
      sortable: false,
      filterable: false,
      hideSortIcons: true,
      filterOperators: [],
    },
    {
      field: 'name',
      flex: 2,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {!(params.value.isOwner && params.value.status !== 'DEACTIVATED') && (
            <div style={{ marginRight: '8px' }}>
              <FaLock className="table-icon" style={{}} color="#A3A3A3" />
            </div>
          )}
          {params.value.name}
        </div>
      ),
      sortable: true,
      sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
      filterable: false,
    },
    {
      field: 'title',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Title</div>,
      filterable: false,
    },

    {
      field: 'number',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Number</div>,
      filterable: false,
    },

    {
      field: 'modified_date',
      flex: 1,
      renderHeader: () => (
        <div style={{ fontWeight: 'bold' }}>Modified Date</div>
      ),
      filterable: false,
    },
    {
      field: 'owner',
      flex: 1,
      renderHeader: () => <div style={{ fontWeight: 'bold' }}>Owner</div>,
      renderCell: (params) => {
        if (department) {
          const departmentOwner = findDepartmentOwner(
            department,
            reducerState,
            params.value
          );
          return (
            <div
              style={{
                display: 'flex',
                // justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {departmentOwner && (
                <OwnerImage
                  owner={departmentOwner}
                  // isLocked={!adminAccess}
                  size={32}
                />
              )}
            </div>
          );
        } else {
          return <div></div>;
        }
      },
      sortable: true,
      sortComparator: (v1, v2) => {
        const owner1 = findDepartmentOwner(department, reducerState, v1);
        const owner2 = findDepartmentOwner(department, reducerState, v2);
        return owner1?.name.localeCompare(owner2?.name || '') || 0;
      },
      filterable: false,
    },
    {
      field: 'note',
      flex: 1,
      renderHeader: () => (
        <div
          style={{
            fontWeight: 'bold',
          }}
        >
          Note
        </div>
      ),
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <span>{params.value.note}</span>
          <span>
            <FaChevronRight
              className="icon-normal "
              style={{ margin: '4px' }}
            />
          </span>
        </div>
      ),
      filterable: false,
    },
  ];

  const rows = list.map((item: ContactItem) => {
    return {
      id: item.uid,
      input: {
        uid: item.uid,
        status: item.status,
      },
      name: {
        name: item.name,
        status: item.status,
        isOwner: item.departmentID === department.id ?? false,
      },
      title: item.title,
      number: item.number,
      modified_date: getFormattedDate(
        item.dbContact.updatedAt ? item.dbContact.updatedAt : new Date(),
        true
      ),
      owner: item,
      note: {
        note: item.note,
      },
    };
  });

  const handleSelectionChange = (contact: ContactItem) => {
    if (selectedItems.find((item) => item.uid === contact.uid)) {
      setSelectedItems((prevItems: ContactItem[]) => {
        const updatedItems = prevItems.filter(
          (item: ContactItem) => item.uid !== contact.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, contact];
      let available = list.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };
  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];

      // Delete the medication
      let response = await deleteContact(currentMedication.dbContact);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted contact: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: ContactItem[]) => {
          const updatedItems = prevItems.filter(
            (item: ContactItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete contact');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete contact');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          let response = await deleteContact(parmMedication.dbContact);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} contacts.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} contacts.`);
          setSnackbarSeverity('error');
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete contact');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getRowClassName = useCallback(
    (params: any) => {
      const rowItem = list.find((item) => item.uid === params.id);
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );
      // If the row is selected, give it the selected class.
      if (isSelected) {
        return 'selectedRow';
      }
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactiveRow';
      }

      // Default return for other cases
      return 'white';
    },
    [department, list, selectedItems, user, reducerState]
  );
  const isSaveActive = useMemo(() => {
    if (unsubscribeList.length > 0) return true;
    return false;
  }, [unsubscribeList]);

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Contacts?'}
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} contacts?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <AddContactSideout
        isVisible={isAddContactModalOpen}
        handleClose={() => setIsAddContactModalOpen(false)}
        handleAddContacts={(newContacts: ContactItem[]) => {
          // setContacts(newContacts);
          setIsAddContactModalOpen(false);
          handleChangeContacts(newContacts);
        }}
        contacts={contacts}
        department={department}
      />

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Contacts: ' + list.length + ' items'
            : 'Contacts: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={isSaveActive}
        isSaveActive={true}
        handleSave={handleSave}
        handleCancelEdit={() => {
          setUnsubscribeList([]);
        }}
        isCreateButton={adminLevel > 2 && adminAccess}
        isCreateActive={true}
        isAddButton={adminAccess && department.parentDep != null}
        handleAdd={() => setIsAddContactModalOpen(true)}
        handleCreate={handleCreateItem}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Contacts...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>
      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[50, 100, 200]}
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.uid === params.id))
          }
          getRowClassName={getRowClassName}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .selectedRow': {
              backgroundColor: '#E0EADD !important',
            },

            '& .inactiveRow': {
              backgroundColor: '#f2f2f2',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListContacts;
