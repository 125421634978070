import React, { useMemo, useState } from 'react';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import DraftChangeItem, {
  DraftChangeType,
} from '../../../../data/model/DraftChangeItem';
import FullViewDialog from '../fullView/FullViewDialog';
import ProtocolAccordionSecondLayer from '../../protocol/details/ProtocolAccordionSecondLayer';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { toTitleCase } from '../../../_global/common/Utils';
import ProtocolDataTableComparison from './ProtocolDataTableComparison';
import CategoryItem from '../../../../data/model/CategoryItem';
import HMSwitch from '../../../components/general/HMSwitch';

interface FolderComparisonProps {
  changeType: DraftChangeType;
  currentDraft: DraftChangeItem;
  onChangesCount?: (count: number) => void;
}

const compareSubIndexItems = (
  currentItems: ProtocolItem[],
  previousItems: ProtocolItem[]
): number => {
  let changeCount = 0;

  for (let i = 0; i < currentItems.length; i++) {
    const currentItem = currentItems[i];
    const previousItem = previousItems[i];
    if (currentItem.index !== previousItem.index) {
      changeCount++;
    }
  }
  changeCount = 1;
  return changeCount;
};

// export function findBeforeIndexAndName(
//   item: ProtocolItem,
//   previousItems: (ProtocolItem)[]
// ): string {
//   const index = previousItems.findIndex(
//     (prevItem) => prevItem.index === item.index
//   );
//   return `${index + 1}: ${item.name}`;
// }

// export function findAfterIndexAndName(
//   item: ProtocolItem,
//   changeItems: (ProtocolItem)[]
// ): string {
//   const index = changeItems.findIndex(
//     (changeItem) => changeItem.index === item.index
//   );
//   return `${index + 1}: ${item.name}`;
// }

export const compareProtocolIndexItems = (folder: CategoryItem): number => {
  let changedProtocols = folder.protocols.sort((a, b) => a.index - b.index);
  let previousProtocols = folder.protocols.map((protocol) => {
    return protocol.activeItem ? protocol.activeItem : protocol;
  }) as ProtocolItem[];
  return compareSubIndexItems(changedProtocols, previousProtocols);
};

const ProtocolIndexComparison: React.FC<FolderComparisonProps> = ({
  onChangesCount,
  currentDraft,
  changeType,
}) => {
  const [showAllItems, setShowAllItems] = useState(true);
  const folder = currentDraft.changeItem as CategoryItem;

  const changeProtocols = useMemo(() => {
    return (folder.protocols as ProtocolItem[]).sort(
      (a, b) => a.index - b.index
    );
  }, [folder]);
  const previousProtocols = useMemo(() => {
    return folder.protocols.map((protocol) => {
      return protocol.activeItem ? protocol.activeItem : protocol;
    }) as ProtocolItem[];
  }, [folder]);

  const changes = useMemo(() => {
    const count = compareSubIndexItems(
      changeProtocols as ProtocolItem[],
      previousProtocols as ProtocolItem[]
    );
    if (onChangesCount) {
      onChangesCount(count);
    }

    return count;
  }, [folder, onChangesCount, changeProtocols, previousProtocols]);

  const headerTitle =
    changeType === DraftChangeType.MEDICATION_INDEX
      ? 'Medication'
      : changeType === DraftChangeType.INFUSION_INDEX
        ? 'Infusion'
        : 'Electrical';

  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousProtocols.length > 0 ? (
            <>
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">Before</div>{' '}
                  </th>
                  <th
                    className="changeHeader afterChange"
                    style={{
                      padding: '0px 20px',
                    }}
                  >
                    <div className="headerContent">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        After
                        <HMSwitch
                          checked={showAllItems}
                          onChange={() => setShowAllItems(!showAllItems)}
                          tooltipPosition="left"
                          tooltipText={'Only show protocols that have changed'}
                        />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {previousProtocols.length >= 1 && (
                      <div>
                        <ProtocolDataTableComparison
                          protocolData={previousProtocols}
                          keepBefore={true}
                          previousItems={previousProtocols}
                          changeItems={changeProtocols}
                          showAllItems={showAllItems}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    {changeProtocols.length >= 1 && (
                      <ProtocolDataTableComparison
                        protocolData={changeProtocols}
                        previousItems={previousProtocols}
                        changeItems={changeProtocols}
                        showAllItems={showAllItems}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {changeProtocols.length >= 1 && (
                    <ProtocolDataTableComparison
                      protocolData={changeProtocols}
                      previousItems={previousProtocols}
                      changeItems={changeProtocols}
                      showAllItems={showAllItems}
                    />
                  )}
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default ProtocolIndexComparison;
