import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  DatabaseResponse,
  ResponseType,
  getDepartments,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import {
  copyVitalsFromDeptoDep,
  resetContactIndexes,
} from '../../../../../data/AmplifyActions';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { globals } from '../../../../_global/common/Utils';
import { useDispatch } from 'react-redux';
import { fetchContacts } from '../../../../../data/functions/ContactDB';
import ContactItem from '../../../../../data/model/ContactItem';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ContactIndexes = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const { state } = location;
  const db: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);

  const [info, setInfo] = useState<any>({
    department: null,
    contacts: [],
  });

  const loadDepartmentData = async () => {
    const response = await getDepartments();
    if (response.type === ResponseType.Success) {
      setDepartments(response.data);
      setList(response.data);
    }
  };

  const loadContactsData = async (
    department: DepartmentItem,
    isFrom: boolean
  ): Promise<ContactItem[] | null> => {
    const response = await fetchContacts(department, false);
    if (response.type === ResponseType.Success) {
      console.log('Contacts', response.data);
      let contacts = response.data;
      return contacts;
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  // useEffect(() => {
  //     if(info.departmentFrom !== null) loadVitalsData(info.departmentFrom, true);
  //     if(info.departmentTo !== null)
  // }
  // , [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/copy`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    if (globals.debug) console.log('Department selected', department);
    let contacts = await loadContactsData(department, isFrom);
    if (contacts)
      setInfo({ ...info, department: department, contacts: contacts });
    else setInfo({ ...info, department: department, contacts: [] });
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter((item) => item.id !== info.department?.id);
    if (globals.debug) console.log('List', l);
    setList(l);
  }, [info]);

  const handleSubmit = async () => {
    if (info.department === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.contacts.length === 0) {
      alert(
        'Error: trying to copy an EMPTY list of contacts\nFrom: ' +
          info.department.name
      );
      return;
    }
    let result = await resetContactIndexes(info.department, info.contacts);
    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      if (globals.debug)
        console.log('Reset', results, 'contacts from', info.department.name);
      loadDatabase(db, dispatch);
      let isConfirmed = window.confirm(
        `Reset ${results} contacts from ${info.department.name}`
      );
      setInfo({ ...info, department: null, contacts: [] });
    } else {
      if (globals.debug)
        console.log(
          'Reset contacts from ' + info.department.name + ' failed: ',
          result.data
        );
    }
  };

  const isSaveValid = useMemo(() => {
    return info.department !== null && info.contacts.length > 0;
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Copy Actions'}
        name={'Reset Contact Indexes'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        // isSaveButton={true}
        // isSaveActive={isSaveValid}
        customText="Reset"
        isCustomActive={isSaveValid}
        isCustomButton={true}
        handleCustom={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="formContainer">
        <Row>
          <Col sm={6}>
            {' '}
            {/* Left column */}
            <label
              className="ketamine-general-label"
              style={{ marginTop: '20px' }}
            >
              Department: <span className="required-field">*</span>
            </label>
            <p className="sidebarText" style={{ marginLeft: '10px' }}>
              Select the department you want to reset contact indexes.
            </p>
            <SearchableDropdown<DepartmentItem>
              id="searchDropdown"
              options={list}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.id}
              onChange={(option: DepartmentItem) => {
                handleDepartmentSelected(option, false);
              }}
              onClear={() => {
                setInfo({ ...info, department: null, contacts: [] });
              }}
              placeholder="Search department..."
            />
          </Col>
          <Col sm={6}>
            {/* Right column */}
            <label style={{ marginTop: 20 }} className="ketamine-general-label">
              Department Contacts: {info.contacts.length} items
            </label>
            <hr style={{ margin: 10 }} />
            {info.contacts.map((item: ContactItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Name: {item.name}</h6>
                  <h6>Number: {item.number}</h6>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ContactIndexes;
