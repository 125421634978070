import React, { useMemo } from 'react';
import {
  findBeforeValue,
  findCurrentValue,
} from './reviewComparsion/MedicationComparison';
import HighlightChanges from './HighlightChanges';

interface HighlightChangesProps {
  changes: any[];
  titles: string[];
  keys: string[];
  debug?: boolean;
  isNewItem?: boolean;
}

const HighlightChangesRow: React.FC<HighlightChangesProps> = (props) => {
  const { titles, keys, debug, isNewItem } = props;
  if (keys.length !== titles.length) {
    throw new Error('Keys and titles length mismatch');
  }
  return (
    <tr>
      {isNewItem !== true && (
        <td>
          <div className="rows-container">
            {keys.map((key, index) => (
              <div className="rows-container-item">
                <label htmlFor="Name" className="ketamine-general-label">
                  {titles[index]}:
                </label>
                <div
                  style={{
                    border: '1px solid #e0e0e0',
                    color: '#000',
                    fontWeight: '500',
                    fontSize: '15px',
                    padding: '4px',
                    borderRadius: '5px',
                    minHeight: '30px',
                    backgroundColor: 'white',
                  }}
                >
                  <HighlightChanges
                    beforeText={findBeforeValue(props.changes, key)}
                    afterText={findCurrentValue(props.changes, key)}
                    onlyPrevious={true}
                    debug={debug}
                  />
                </div>
              </div>
            ))}
          </div>
        </td>
      )}
      <td>
        <div className="rows-container">
          {keys.map((key, index) => (
            <div className="rows-container-item">
              <label htmlFor="Name" className="ketamine-general-label">
                {titles[index]}:
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(props.changes, key)}
                  afterText={findCurrentValue(props.changes, key)}
                  onlyCurrent={true}
                  debug={debug}
                />
              </div>
            </div>
          ))}
        </div>
      </td>
    </tr>
  );
  // (
  //   <tr>
  //     <td>
  //       <div className="two-row-container">
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key1)}
  //               afterText={findCurrentValue(props.changes, key1)}
  //               onlyPrevious={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title2}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key2)}
  //               afterText={findCurrentValue(props.changes, key2)}
  //               onlyPrevious={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </td>
  //     <td>
  //       <div className="two-row-container">
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key1)}
  //               afterText={findCurrentValue(props.changes, key1)}
  //               onlyCurrent={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title2}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key2)}
  //               afterText={findCurrentValue(props.changes, key2)}
  //               onlyCurrent={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </td>
  //   </tr>
  // ) : (
  //   <tr>
  //     <td>
  //       <div className="two-row-container">
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key1)}
  //               afterText={findCurrentValue(props.changes, key1)}
  //               onlyPrevious={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </td>
  //     <td>
  //       <div className="two-row-container">
  //         <div className="two-row-container-item">
  //           <label htmlFor="Name" className="ketamine-general-label">
  //             {title}:
  //           </label>
  //           <div
  //             style={{
  //               border: '1px solid #e0e0e0',
  //               color: '#000',
  //               fontWeight: '500',
  //               fontSize: '15px',
  //               padding: '4px',
  //               borderRadius: '5px',
  //               minHeight: '30px',
  //               backgroundColor: 'white',
  //             }}
  //           >
  //             <HighlightChanges
  //               beforeText={findBeforeValue(props.changes, key1)}
  //               afterText={findCurrentValue(props.changes, key1)}
  //               onlyCurrent={true}
  //               debug={debug}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </td>
  //   </tr>
  // );
};

export default HighlightChangesRow;
