import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ProtocolHeader from '../../../pages/protocol/ProtocolHeader';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';

import { globals, toTitleCase } from '../../../_global/common/Utils';
import { FormQuestion, FormType } from '../../../../models';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as Yup from 'yup';
import QuestionBox from './QuestionBox';
import { useCallback, useEffect, useState } from 'react';
import { MdRateReview } from 'react-icons/md';

const QUESTION_TYPES = Object.values(FormType).filter(
  (type) => type !== FormType.LONG_ANSWER
);
const NotificationQuestionPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [questionTypeIndex, setQuestionTypeIndex] = useState(0);
  const isViewPageState = location.state?.isViewPageState;
  const [curIndex, setCurIndex] = useState(0);
  const database = useSelector((state: any) => state.protocol.departmentItem);
  const department: DepartmentItem = database.department;

  const formik = useFormik({
    initialValues: {
      questions: [] as FormQuestion[],
    },
    validationSchema: Yup.object({
      questions: Yup.array().of(
        Yup.object({
          question: Yup.string().required('Question is required'),
          type: Yup.string()
            .oneOf(Object.values(FormType) as string[], 'Invalid question type')
            .notRequired(),
          answers: Yup.array().when('type', {
            is: (type: FormType) => {
              return [FormType.SINGLE_SELECT, FormType.MULTI_SELECT].includes(
                type
              );
            },
            then: (schema) =>
              schema
                .required('Answers are required')
                .min(2, 'Minimum 2 answers are required')
                .max(5, 'Maximum 5 answers are allowed')
                .test(
                  'unique',
                  'Answers must be unique and not empty',
                  (answers: string[] | undefined) => {
                    if (!answers) return false;
                    const set = new Set(answers.filter(Boolean));
                    return set.size === answers.length;
                  }
                ),
            otherwise: (schema) =>
              Yup.mixed().when('type', {
                is: FormType.DROPDOWN,
                then: () =>
                  schema
                    .required('Answers are required')
                    .of(Yup.string().required('Answer is required'))
                    .test(
                      'unique',
                      'Answers must be unique and not empty',
                      (answers: string[] | undefined) => {
                        if (!answers) return false;
                        const set = new Set(answers.filter(Boolean));
                        return set.size === answers.length;
                      }
                    ),
                otherwise: () => schema.notRequired(),
              }),
          }),
          correctAnswer: Yup.array()
            .of(Yup.string().trim().required('Correct answer is required'))
            .test(
              'non-empty',
              'Correct answer is required',
              (value) => Array.isArray(value) && value.length > 0
            ),
        })
      ),
    }),
    onSubmit: () => {
      const questions: FormQuestion[] = [...formik.values.questions];

      navigate('/create/notification', {
        state: {
          sendFormQuestions: questions,
          previousFormQuestions: location.state?.previousFormQuestions,
          saveFormikValues: location.state?.saveFormikValues,
        },
      });
    },
  });

  const { values, setFieldValue } = formik;

  const renumberQuestions = useCallback(
    (questions: FormQuestion[]): FormQuestion[] => {
      return questions.map((question, index) => ({
        ...question,
        index,
      }));
    },
    []
  );

  useEffect(() => {
    console.log('Initial Questions:', values.questions);
  }, [values.questions]);

  const handleBack = () => {
    let backUrl = location.state.isViewPageState
      ? '/view/notification'
      : '/create/notification';
    navigate(backUrl, {
      state: {
        sendFormQuestions:
          formik.isValid && formik.dirty ? values.questions : [],
        previousFormQuestions:
          formik.isValid && formik.dirty
            ? location.state?.previousFormQuestions
            : [],
        ...location.state.isViewPageState,
        saveFormikValues: location.state?.saveFormikValues,
      },
    });
  };

  useEffect(() => {
    if (location.state?.previousFormQuestions) {
      console.log(
        'Setting the value of previous questions',
        location.state.previousFormQuestions
      );
      setFieldValue('questions', location.state.previousFormQuestions);
      if (location.state.questionIndex) {
        setCurIndex(location.state.questionIndex);
      }
    }
  }, [location.state?.previousFormQuestions]);

  const handleAddQuestion = (type: FormType) => {
    const lastIndex =
      values.questions.length > 0
        ? values.questions[values.questions.length - 1].index
        : -1; // Start from -1, so the first index will be 0

    const newQuestion: FormQuestion = {
      index: lastIndex + 1,
      type,
      question: '',
      description: '',
      answers: [],
      correctAnswer: [],
    };
    const updatedQuestions = [...values.questions, newQuestion];

    setFieldValue('questions', renumberQuestions(updatedQuestions));
    setCurIndex(lastIndex + 1);
  };

  const onCopyQuestion = (index: number) => {
    const questionToCopy = values.questions[index];
    const lastIndex =
      values.questions.length > 0
        ? values.questions[values.questions.length - 1].index
        : -1; // Start from -1, so the first index will be 0

    const newQuestion: FormQuestion = {
      ...questionToCopy,
      index: lastIndex + 1,
    };
    const updatedQuestions = [...values.questions];
    updatedQuestions.splice(index + 1, 0, newQuestion);
    setFieldValue('questions', renumberQuestions(updatedQuestions));
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedQuestions = Array.from(values.questions);
    const [removed] = reorderedQuestions.splice(result.source.index, 1);
    reorderedQuestions.splice(result.destination.index, 0, removed);
    for (let i = 0; i < reorderedQuestions.length; i++) {
      reorderedQuestions[i] = {
        ...reorderedQuestions[i],
        index: i,
      };
    }
    console.log('Reordered Questions', reorderedQuestions);
    setFieldValue('questions', reorderedQuestions);
    console.log('After reorder questions', values.questions);
  };

  const renderQuestionBox = (question: FormQuestion, index: number) => {
    const isSelected = curIndex === index;

    return (
      <QuestionBox
        question={question}
        index={index}
        isSelected={isSelected}
        onSelect={() => setCurIndex(index)}
        onCopy={() => onCopyQuestion(index)}
        onRemove={() => {
          const updatedQuestions = [...values.questions];
          updatedQuestions.splice(index, 1);
          setFieldValue('questions', renumberQuestions(updatedQuestions));
        }}
        onInputChange={(updates) => {
          const updatedQuestions = [...values.questions];
          updatedQuestions[index] = {
            ...updatedQuestions[index],
            ...updates,
          };
          setFieldValue('questions', updatedQuestions);
        }}
        onTypeChange={(newType) => {
          const updatedQuestions = [...values.questions];
          updatedQuestions[index] = {
            ...updatedQuestions[index],
            type: newType,
          };
          setFieldValue('questions', renumberQuestions(updatedQuestions));
        }}
        formik={formik}
        isDisabled={isViewPageState ? true : false}
      />
    );
  };
  return (
    <div className="screen-container">
      <ProtocolHeader
        name={'Notification Questions'}
        page={
          location.state.isViewPageState
            ? 'Notication View Page'
            : 'Notification Create Page'
        }
        description={
          isViewPageState
            ? 'View the questions for your training notification.'
            : 'Create a new notification question for your protocol. You can drag and drop questions to reorder them.'
        }
        isBackButton={true}
        type={'protocol'}
        isSendButton={isViewPageState ? false : true}
        isSendActive={formik.isValid && formik.dirty}
        handleCancel={handleBack}
        handleCreate={() => formik.submitForm()}
      />

      <FormikProvider value={formik}>
        <div className="notification-question-container">
          <div className="ketamineContent" style={{ justifyContent: 'center' }}>
            <div
              className={`KetamineGeneral left-container ${isViewPageState ? 'disabled' : ''}`}
            >
              <h5
                className="notification-header-text"
                style={{
                  backgroundColor: '#00534C',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '6px',
                  marginBottom: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  textDecoration: 'none',
                  borderBottom: 'none',
                  fontSize: '16px',
                }}
              >
                Questions
              </h5>
              {values.questions.length === 0 && (
                <div
                  className="no-data-container"
                  style={{ marginTop: '15vh' }}
                >
                  <MdRateReview size={120} className="light-grey-icon" />
                  <h4 className="light-grey-icon">No questions added...</h4>
                </div>
              )}
              <DragDropContext
                onDragEnd={isViewPageState ? () => {} : onDragEnd} // Disable drag-and-drop functionality
              >
                <Droppable
                  droppableId="questions"
                  isDropDisabled={isViewPageState ? true : false} // Disable drag-and-drop functionality
                >
                  {(provided) => (
                    <div
                      className={`questions-container ${isViewPageState ? 'disabled' : ''}`}
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {values.questions.map((question, index) => (
                        <Draggable
                          key={question.index}
                          draggableId={question.index.toString()}
                          index={index}
                          isDragDisabled={isViewPageState} // Disable drag-and-drop functionality
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              {renderQuestionBox(question, index)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div
              className={`KetamineGeneral right-container ${isViewPageState ? 'disabled' : ''}`}
            >
              <h5
                className="ketmine-header-text"
                style={{
                  backgroundColor: '#00534C',
                  color: 'white',
                  padding: '10px',
                  borderRadius: '6px',
                  marginBottom: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  textDecoration: 'none',
                  fontSize: '16px',
                }}
              >
                Question Type
              </h5>
              <div className="question-type-container">
                {QUESTION_TYPES.map((type: any, index) => (
                  <div
                    key={type}
                    className={`question-type-button ${questionTypeIndex === index ? 'active' : ''} ${isViewPageState ? 'disabled-button' : ''}`}
                    onClick={() => {
                      if (!isViewPageState) {
                        setQuestionTypeIndex(index);
                        setCurIndex(index);
                        handleAddQuestion(type);
                      }
                    }}
                    style={isViewPageState ? { cursor: 'not-allowed' } : {}}
                  >
                    {toTitleCase(type.replace('_', ' '))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </FormikProvider>
    </div>
  );
};

export default NotificationQuestionPage;
