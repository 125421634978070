import React, { useMemo, useState } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoChevronDown } from 'react-icons/io5';
import { MdClear } from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import HMCheckbox from '../general/HMCheckbox';
import SearchBar from '../Search/SearchBar';

interface Props<T> {
  title: string;
  options: T[];
  selectedItems?: T[];
  labelField: (option: T) => string;
  keyField: (option: T) => string;
  onSelected: (selectedItems: T[]) => void;
  style?: React.CSSProperties;
  buttonColor?: string;
  textColor?: string;
  searchable?: boolean;
  buttonStyle?: React.CSSProperties;
}

const MultiSelectDropdown = <T,>({
  title,
  options,
  selectedItems = [],
  labelField,
  keyField,
  onSelected,
  style,
  buttonColor = '#00534C',
  textColor = 'white',
  searchable = false,
  buttonStyle,
}: Props<T>) => {
  const [selected, setSelected] = useState<T[]>(selectedItems);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionToggle = (option: T) => {
    const isSelected = selected.some((item) => item === option);
    const newItems = isSelected
      ? selected.filter((item) => item !== option)
      : [...selected, option];

    setSelected(newItems);
    onSelected(newItems);
  };

  const clearFilter = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSelected([]);
    onSelected([]);
  };

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        labelField(option).toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [labelField, options, searchQuery]
  );

  return (
    <div style={style}>
      <Button
        aria-controls={open ? 'multi-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        sx={{ textTransform: 'none', ...buttonStyle }}
        // endIcon={
        //   <>
        //     {selectedItems.length > 0 && (
        //       <MdClear onClick={clearFilter} style={{ marginRight: '4px' }} />
        //     )}
        //     <IoChevronDown />
        //   </>
        // }
        style={{
          background: buttonColor,
          color: textColor,
          fontFamily: 'Titillium Web, sans-serif',
          fontWeight: '500',
        }}
      >
        <span style={{ fontSize: '15px' }}>
          {selectedItems.length > 0
            ? `${selectedItems.length} ${title}`
            : `  ${title}`}
        </span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginLeft: '4px',
            marginTop: '2px',
          }}
        >
          <MdClear
            onClick={clearFilter}
            style={{
              marginRight: '4px',
              color: selectedItems.length > 0 ? 'red' : 'transparent',
            }}
          />

          <IoChevronDown />
        </div>
      </Button>

      <Menu
        id="multi-select-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {searchable && (
          <SearchBar
            value={searchQuery}
            autoFocus={false}
            onChange={(searchQuery: string) => setSearchQuery(searchQuery)}
            containerStyle={{
              width: 'calc(100% - 2rem)',
              marginLeft: '1rem',
              alignSelf: 'center',
            }}
          />
        )}

        {filteredOptions.map((option) => {
          const isSelected = selected.some((item) => item === option);
          return (
            <li
              key={keyField(option)}
              className="hoverable"
              onClick={() => handleOptionToggle(option)}
              style={{
                display: 'flex',
                fontSize: '14px',
                fontWeight: '500',
                fontFamily: 'Titillium Web, sans-serif',
                borderRadius: 0,
                // padding: '4px 8px',
                // margin: '0px 10px',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
                minHeight: '30px',
                minWidth: '100px',
              }}
              // onClick={() => handleOptionToggle(option)}
              // dense
            >
              <HMCheckbox
                checked={isSelected}
                // size="small"
                // style={{ padding: '4px 8px' }}
              />
              <div
                className="no-select"
                style={{
                  fontSize: '15px',
                  fontWeight: '500',
                  fontFamily: 'Titillium Web, sans-serif',
                  padding: '4px 8px',
                }}
              >
                {labelField(option)}
              </div>
            </li>
          );
        })}

        {searchable && filteredOptions.length === 0 && (
          <MenuItem disabled dense>
            No results found
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MultiSelectDropdown;
