import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import DepartmentItem from '../../../data/model/DepartmentItem';
import React from 'react';
import { IoClose } from 'react-icons/io5';

type ShareAccountData = {
  departmentCode: string;
  user: {
    id: string;
    firstName: string;
    lastName: string;
  };
  expiration: number;
};

interface CreateAccountInvitationModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: () => void;
  department: DepartmentItem;
  shareAccountData: ShareAccountData;
}

const CreateAccountInvitationModal: React.FC<
  CreateAccountInvitationModalProps
> = (props: CreateAccountInvitationModalProps) => {
  const { isVisible, handleClose, handleSubmit, department, shareAccountData } =
    props;

  const handleButton = () => {
    if (!isVisible) return;
    handleSubmit();
  };

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          // width: '50vw',
          // maxWidth: '800px',
          // flex: 1,
          // maxWidth
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '500px',
        }}
        onHide={() => {
          handleClose();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}
        >
          <h4 className="keychain-title">
            <span
              style={{
                marginLeft: '20px',
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              Create User Account
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={handleClose}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <form
            className="keychain-form"
            onSubmit={(e) => {
              e.preventDefault();
              handleButton();
            }}
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // alignItems: 'center',
              background: 'transparent',
              border: 'none',
            }}
          >
            <div
              className="input-container"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
                marginBottom: '10px',
              }}
            >
              {department.logoVerifiedUrl && (
                <img
                  className="department-logo-public-regualr"
                  style={{
                    height: '4rem',
                    width: '4rem',
                    marginRight: '10px',
                  }}
                  src={department.logoVerifiedUrl}
                  alt="Agency Logo"
                />
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  // width: '100%',
                }}
              >
                <label
                  className='"ketamine-general-label'
                  htmlFor="password"
                  style={{
                    fontWeight: '600',
                    color: 'black',
                    fontSize: '1rem',
                  }}
                >
                  {department.name}
                </label>
              </div>
            </div>

            <p
              className="departmentItemText"
              style={{
                fontSize: '0.9rem',
                color: 'black',
                marginTop: '10px',
                // maxWidth: '50%',
              }}
            >
              You have been invited by{' '}
              {shareAccountData.user.firstName +
                ' ' +
                shareAccountData.user.lastName}{' '}
              to create a new user account with {department.name}. Press the
              button below to get started. Thanks for joining the OneDose
              community!
            </p>

            <Button
              data-testid="set-Bttn"
              className="primary-button btn-rightMargin"
              style={{
                marginTop: '3rem',
              }}
              onClick={handleButton}
            >
              Create Account
            </Button>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default CreateAccountInvitationModal;
