import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import MedicationProtocolEditPage from '../protocol/edit/Medication/MedicationProtocolEditPage';
import ElectricalProtocolCreatePage from '../protocol/edit/Electrical/ElectricalProtocolCreatePage';
import InfusionProtocolEditPage from '../protocol/edit/Infusion/InfusionProtocolEditPage';
import CreateEditAmbulance from '../protocol/edit/Ambulance/AmbulanceCreateEditPage';
import VitalProtocolEditPage from '../protocol/edit/Vital/VitalProtocolEditPage';
import EquipmentNewPage from '../protocol/edit/Equipment/EquipmentNewPage';
import CheckListEditPage from '../protocol/edit/CheckList/CheckListEditPage';
import ElectricalProtocolEditPage from '../protocol/edit/Electrical/ElectricalProtocolEditPage';
import ProtocolDetails from '../protocol/details/ProtocolDetails';

/* 10-27-23 praveen : Created the common component protocol route navigation */
const ProtocolNavigator = () => {
  const location = useLocation();
  const protocolPage = useMemo(() => {
    if (location.state.type === 'Medication')
      return (
        <MedicationProtocolEditPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (
      location.state.type === 'MedicationDose' ||
      location.state.type === 'InfusionDose' ||
      location.state.type === 'ElectricalShock'
    )
      return <ProtocolDetails />;
    else if (location.state.type === 'Infusion')
      return (
        <InfusionProtocolEditPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (location.state.type === 'Equipment')
      return (
        <EquipmentNewPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (location.state.type === 'Vital')
      return (
        <VitalProtocolEditPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (location.state.type === 'Electrical')
      return (
        <ElectricalProtocolEditPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (location.state.type === 'Checklist')
      return (
        <CheckListEditPage
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
    else if (location.state.type === 'Ambulance')
      return (
        <CreateEditAmbulance
          stateData={{ ...location.state, isFromReview: true }}
        />
      );
  }, [location]);

  return <>{protocolPage}</>;
};

export default ProtocolNavigator;
