import {
  DatabaseResponse,
  Response,
  ResponseType,
  timeoutPromise,
} from '../../../../../../data/AmplifyDB';
import * as XLSX from 'xlsx';
import CategoryItem from '../../../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../../../data/model/ProtocolItem';
import Fuse from 'fuse.js';
import MedicationItem, {
  cloneMedication,
} from '../../../../../../data/model/MedicationItem';
import { globals, toTitleCase } from '../../../../../_global/common/Utils';
import { Medication } from '../../../../../../models';
import { DataStore } from 'aws-amplify';
import { fetchItemFromAPI } from '../../../../../../data/GraphQL_API';
import { getAllDepartments } from '../../../../../../data/functions/DepartmentDB';
import DepartmentItem from '../../../../../../data/model/DepartmentItem';
import { getHashedPin, getSalt } from '../../../../../_global/common/Encrypt';
import { SoftwareType } from '../../../../../../API';

export type DepartmentInput = {
  name: string;
  uniqueCode: string;
  pin: string;
  hashedPin: string;
  saltedPin: string;
  isPublic: boolean;
  isPublicSignup: boolean;
  isOneWeightEnabled: boolean;
  infusionCalculation: boolean;
  softwarePlan: SoftwareType;
  uniquePublicURL: string;
};

export type MetaData = {
  columnName: string;
  isPublic?: boolean;
  isPublicSignup?: boolean;
  isOneWeightEnabled?: boolean;
  infusionCalculation?: boolean;
  softwarePlan?: SoftwareType;
};

export const processDepartmentsExcelFile = async (
  file: File,
  allDepartments: DepartmentItem[],
  metaData: MetaData
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (
        file.type !==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ) {
        return resolve({
          type: ResponseType.Failure,
          data: 'Only Excel Workbook files are allowed',
        });
      } else if (metaData.columnName == null || metaData.columnName === '') {
        return resolve({
          type: ResponseType.Failure,
          data: 'Column name is required',
        });
      }
      const response = await timeoutPromise(
        readExcelFile(file, metaData.columnName, allDepartments, metaData),
        10000
      ).catch((error) => {
        console.error('Error reading excel file:', error);
        reject(error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      });
      console.log('Response:', response);
      if (response.type === ResponseType.Success) {
        console.log('Success reading excel file:', response.data);
        resolve(response);
      } else {
        console.log('Error reading excel file:', response.data);
        resolve(response);
      }
    } catch (error) {
      console.error('Error processing excel file:', error);
      reject(error);
    }
  });
};

async function readExcelFile(
  file: File,
  columnName: string,
  departments: DepartmentItem[],
  metaData?: MetaData
): Promise<Response> {
  return new Promise(async (resolve, reject) => {
    try {
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data, { type: 'buffer' });
      let newDepartments: DepartmentInput[] = [];
      for (let i = 0; i < workbook.SheetNames.length; i++) {
        const sheetName = workbook.SheetNames[i];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet);

        const getCode = (departments: DepartmentItem[]): number => {
          let code = Math.floor(100000 + Math.random() * 900000);
          let found = departments.find(
            (dep) => dep.departmentCode === code.toString()
          );
          if (found) return getCode(departments);
          return code;
        };

        const getPublicURL = (
          name: string,
          location: string,
          departments: DepartmentItem[],
          attempt: number = 0
        ): string => {
          if (attempt > 2) {
            throw new Error('Failed to generate unique public URL');
          }
          let _name =
            attempt > 1
              ? name + location + attempt.toString()
              : attempt === 1
                ? name + location
                : name;
          let url = _name.replace(/[^A-Za-z0-9]/g, '');
          let found = departments.find((dep) => dep.uniquePublicURL === url);
          if (found)
            return getPublicURL(name, location, departments, attempt + 1);
          return url;
        };

        //Output all the column names in the excel file
        console.log('Column names:', json);
        let rowCount = 0;
        json.forEach((row: any) => {
          rowCount++;
          console.log('Row:', row);
          console.log('Column name:', columnName);
          console.log('Row[columnName]:', row[columnName]);
          /* Extract the current file page */
          if (row[columnName] != null) {
            try {
              const salt = getSalt();
              const code = getCode(departments);
              const _pin = '' + Math.floor(1000 + Math.random() * 9000);
              const newDepartment = {
                name: toTitleCase(row[columnName]),
                uniqueCode: code.toString(),
                pin: _pin,
                hashedPin: getHashedPin(_pin, salt),
                saltedPin: salt,
                isPublic: metaData?.isPublic ?? false,
                isPublicSignup: metaData?.isPublicSignup ?? false,
                isOneWeightEnabled: metaData?.isOneWeightEnabled ?? false,
                infusionCalculation: metaData?.infusionCalculation ?? false,
                softwarePlan:
                  metaData?.softwarePlan ?? SoftwareType.PROFESSIONAL,
                uniquePublicURL: '',
              };
              newDepartments.push(newDepartment);
            } catch (error) {
              console.log('Error parsing file page:', error);
            }
          } else if (rowCount === 0 && row[columnName] == null) {
            console.log('No value found for column:', columnName);
            return reject(
              'Invalid column name provided: ' +
                columnName +
                '. Does not match any column in the excel file.'
            );
          }
        });

        resolve({
          type: ResponseType.Success,
          data: newDepartments,
        });
      }
    } catch (error) {
      console.error('Error reading excel file===:', error);
      reject(error);
    }
  });
}

const preprocessText = (text: string) => {
  return text.toLowerCase().trim();
};
