import React, { useEffect, useState } from 'react';
import './css/Login.scss'; // Importing CSS
import image from '../../../assets/images/hinckleymedical_logo_symbol.png'; // Importing image
import { InputText } from 'primereact/inputtext';
import { CgPassword } from 'react-icons/cg';
import { IoPersonSharp } from 'react-icons/io5';
import { RiLockPasswordFill } from 'react-icons/ri';
import { Auth, DataStore } from 'aws-amplify';
import { useDispatch } from 'react-redux';
import { handleLogOut } from '../../../store/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { FaBuilding, FaBuildingShield } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import {
  createUserGraphQL,
  validateDepartmentCode,
} from '../../../data/GraphQL_API';
import { ResponseType } from '../../../data/AmplifyDB';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { UserType } from '../../../API';
import SelectDepartmentsSignUpModal from '../../components/Modal/SelectDepartmentsSignUpModal';
import { globals } from '../../_global/common/Utils';
import CreateAccountInvitationModal from './CreateAccountInvitationModal';

interface LoginScreenProps {}

interface UserConfirmState {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  departmentID: string;
  deptCode: string;
  deptPin: string;
  userCode: string;
  pairedDepIDs: string[];
  timestamp: number;
}

const NEW_USER = 4;
const CREATE_NEW_USER = 5;
const CONFIRM_NEW_USER = 6;

const USER_CONFIRM_KEY = 'user_confirm_state';
const SEVEN_DAYS_MS = 1000 * 60 * 60 * 24 * 7;

const CreateAccount: React.FC<LoginScreenProps> = () => {
  const location = useLocation();
  const [status, setStatus] = useState(NEW_USER);
  const [state, setState] = useState<any>({
    username: '',
    password: '',
    confirmPassword: '',
    userCode: '',
    deptCode: '',
    deptPin: '',
    firstName: '',
    lastName: '',
    email: '',
    verificationCode: '',
    error: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState<{
    title: string;
    primaryBtnName?: string;
    secondaryBtnName?: string;
    message: string;
    handleClose?: () => void;
    handleSubmit?: () => void;
  }>({
    title: '',
    message: '',
  });

  const [
    isCreateAccountInvitationModalVisible,
    setIsCreateAccountInvitationModalVisible,
  ] = useState(false);

  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentItem>();
  const [pairedDepartments, setPairedDepartments] = useState<DepartmentItem[]>(
    []
  );
  const [loadingText, setLoadingText] = useState<string | null>(null);
  const [childDepartments, setChildDepartments] = useState<
    DepartmentItem[] | undefined
  >();

  const [selectDepartmentsModal, setSelectDepartmentsModal] = useState(false);

  useEffect(() => {
    document.title = `Create Account | OneDose`;
    /* Clear anything in local storage and redux store */
    dispatch<any>(handleLogOut());
  }, []);

  /* Gagan: Adding this useEffect to handle navigation from ShareCreateAccount */
  useEffect(() => {
    const handleShareAccountNavigation = async () => {
      const navigationState = location.state;

      // Check if we have department data from ShareCreateAccount
      if (navigationState?.shareAccountData != null) {
        setLoadingText('Validating Invitation...');
        let resp = await validateDepartmentCode(
          navigationState.shareAccountData.departmentCode,
          undefined,
          undefined,
          true
        )
          .catch((error) => {
            console.error('Error validating department code:', error);
            return {
              type: ResponseType.Failure,
              data: 'Error validating department code',
            };
          })
          .finally(() => {
            setLoadingText(null);
          });
        if (resp.type === ResponseType.Success) {
          setSelectedDepartment(resp.data);
          setIsCreateAccountInvitationModalVisible(true);
        }
        // setIsCreateAccountInvitationModalVisible(true);

        // Show modal asking if they want to create an account
        // setModalState({
        //   title: `Create New Account Invitation`,
        //   message: `Would you like to create a new account using the link shared?`,
        //   primaryBtnName: 'Cancel',
        //   secondaryBtnName: 'Create',
        //   handleClose: () => {
        // // Close the modal and stay on login page
        // setModalState({
        //   title: '',
        //   message: '',
        // });
        // navigate('/login');
        // window.location.reload();
        //   },
        //   handleSubmit: () => {
        //     // Clearing previously pending New User Account creation confirmation
        //     // And hence giving priotity to shared link new user account creation
        //     clearUserConfirmState();

        //     // Set department code from navigation
        //     setState({
        //       ...state,
        //       username: '',
        //       email: '',
        //       firstName: '',
        //       lastName: '',
        //       userCode: '',
        //       deptCode: navigationState.departmentCode,
        //       deptPin: '',
        //       password: '',
        //       confirmPassword: '',
        //       verificationCode: '',
        //       error: '',
        //     });

        //     handleNewUser();

        //     // Clear modal
        //     setModalState({
        //       title: '',
        //       message: '',
        //     });
        //   },
        // });
      }
    };

    handleShareAccountNavigation();
  }, [location.state]); // Only run when location state changes

  /* useEffect to check for saved state
   * This helps to look for any new user creation
   * that was not verified in the last 7 days
   */
  useEffect(() => {
    const getConfirmedUser = async () => {
      // Get the saved state
      const savedState = getUsernameAtConfirmUserState();
      if (savedState) {
        if (Date.now() - savedState.timestamp < SEVEN_DAYS_MS) {
          // State is less than 7 days old
          setState((prev: any) => ({
            ...prev,
            username: savedState.username,
            email: savedState.email,
            firstName: savedState.firstName,
            lastName: savedState.lastName,
            userCode: savedState.userCode,
            deptCode: savedState.deptCode,
            deptPin: savedState.deptPin,
          }));

          // Throw a pop-up asking if they want to continue with the verification of the new user creation
          // If they click yes, then the state is set to CONFIRM_NEW_USER.
          // If they click no, then the state is set to NORMAL_LOGIN with clearing the saved state.
          setModalState({
            title: `Hello ${savedState.firstName},`,
            message: `We detected that you were in the process of creating a new account. Would you like to continue with the account creation process?`,
            primaryBtnName: 'Cancel',
            secondaryBtnName: 'Continue',
            handleSubmit: async () => {
              setLoadingText('Loading last login...');
              let resp = await validateDepartmentCode(
                savedState.deptCode,
                savedState.deptPin,
                (
                  department: DepartmentItem | null,
                  departments: DepartmentItem[],
                  isComplete: boolean
                ) => {
                  if (isComplete && department) {
                    const sortedDeps = department.isTopEnabled
                      ? [department, ...departments].sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )
                      : [...departments].sort((a, b) =>
                          a.name.localeCompare(b.name)
                        );

                    setChildDepartments(sortedDeps);

                    // Set paired departments after we have child departments
                    const pairedDeps = savedState.pairedDepIDs
                      .map((id) => sortedDeps.find((dep) => dep.id === id))
                      .filter(
                        (dep): dep is DepartmentItem => dep !== undefined
                      );

                    setPairedDepartments(pairedDeps);
                  } else if (childDepartments) {
                    setChildDepartments((prev) =>
                      [...(prev || []), ...departments].sort((a, b) =>
                        a.name.localeCompare(b.name)
                      )
                    );
                  }
                }
              );

              if (resp.type === ResponseType.Success) {
                setSelectedDepartment(resp.data);
                setStatus(CONFIRM_NEW_USER);
              } else {
                console.log('Error getting department: ', resp.data);
                setModalState({
                  title: 'Error getting department',
                  handleSubmit: () => {
                    clearUserConfirmState();
                    // setStatus(NORMAL_LOGIN); // Navigate to login page
                    navigate('/login');
                  },
                  message:
                    'An unexpected error occurred. Please try again later.',
                });
              }

              setLoadingText(null);
            },
            handleClose: () => {
              clearUserConfirmState();
              navigate('/login');
            },
          });
        }
      }
    };

    if (status === NEW_USER) getConfirmedUser();
  }, [status]);

  /**
   * Save the user confirm state to local storage
   */
  const saveUsernameAtConfirmUserState = (data: UserConfirmState): void => {
    try {
      localStorage.setItem(USER_CONFIRM_KEY, JSON.stringify(data));
    } catch (error) {
      console.error('Error saving user confirm state:', error);
    }
  };

  /**
   * Get the user confirm state from local storage
   */
  const getUsernameAtConfirmUserState = (): UserConfirmState | null => {
    try {
      const data = localStorage.getItem(USER_CONFIRM_KEY);
      return data ? JSON.parse(data) : null;
    } catch (error) {
      console.error('Error getting user confirm state:', error);
      return null;
    }
  };

  /**
   * Clear the user confirm state from local storage
   */
  const clearUserConfirmState = (): void => {
    try {
      localStorage.removeItem(USER_CONFIRM_KEY);
    } catch (error) {
      console.error('Error clearing user confirm state:', error);
    }
  };

  /**
   * Handle the main submit button press
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setState({ ...state, error: '' });

    if (status === NEW_USER) handleNewUser();
    else if (status === CREATE_NEW_USER) handleCreateNewUser();
    else if (status === CONFIRM_NEW_USER) handleConfirmNewUser();
  };

  /**
   * Handle a New User workflow
   */
  const handleNewUser = async () => {
    setState({
      ...state,
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      userCode: '',
      firstName: '',
      lastName: '',
      error: '',
    });
    setLoadingText('Validating code...');

    // Checking if navigation is coming from ShareCreateAccount by looking at location state
    const isFromShare = location.state?.shareAccountData;

    let departmentCode = isFromShare
      ? location.state.shareAccountData.departmentCode
      : state.deptCode;

    // Skip validation if coming from ShareCreateAccount
    // Normal flow validation
    if (departmentCode == null || departmentCode.length !== 6) {
      setState({
        ...state,
        error:
          departmentCode.length < 6
            ? 'Department code must be 6 digits'
            : 'Department code exceeds 6 digits',
      });
      setLoadingText(null);
      return;
    }

    if (!isFromShare) {
      if (state.deptPin == null || state.deptPin.length !== 4) {
        setState({
          ...state,
          error:
            state.deptPin.length < 4
              ? 'PIN must be  4 digits'
              : 'PIN exceeds 4 digits',
        });
        setLoadingText(null);
        return;
      }
    }

    setChildDepartments(undefined);
    // Validating department code...

    try {
      // Only pass PIN for normal flow
      const departmentPin = isFromShare ? undefined : state.deptPin;

      setState({
        ...state,
        deptCode: departmentCode,
        deptPin: departmentPin,
      });

      let results = await validateDepartmentCode(
        departmentCode,
        departmentPin,
        (
          department: DepartmentItem | null,
          departments: DepartmentItem[],
          isComplete: boolean
        ) => {
          if (isComplete && department) {
            setChildDepartments(
              department.isTopEnabled
                ? [
                    department,
                    ...departments.sort((a, b) => a.name.localeCompare(b.name)),
                  ]
                : [...departments].sort((a, b) => a.name.localeCompare(b.name))
            );
          } else if (childDepartments) {
            setChildDepartments((prev) =>
              [...(prev || []), ...departments].sort((a, b) =>
                a.name.localeCompare(b.name)
              )
            );
          }
        }
      );
      if (results.type === ResponseType.Success) {
        let department = results.data as DepartmentItem;
        setSelectedDepartment(department);
        setStatus(CREATE_NEW_USER);
        resetFormAndLoading();
        setLoadingText(null);
      } else {
        setLoadingText(null);
        console.log('Error validating code: ', results.data);
        setState({ ...state, error: results.data });
      }
    } catch (error) {
      console.error('Error in handleNewUser:', error);
      setState({ ...state, error: 'An unexpected error occurred' });
    }
    setLoadingText(null);
  };

  const handleUserInfo = async () => {
    setLoadingText('Logging out last login...');
    try {
      await dispatch<any>(handleLogOut);
      await Auth.signOut();
      let promises = [
        DataStore.clear(),
        setTimeout(() => {
          return new Promise((resolve, reject) => resolve('timeout'));
        }, 1000),
      ];
      Promise.race(promises).then((result: any) => {
        if (result === 'timeout') {
          if (globals.debug) console.log('DataStore clear timeout');
        } else {
          if (globals.debug) console.log('DataStore clear success');
        }
      });
      localStorage.clear();
      if (globals.debug) console.log('Successfully logged out');
    } catch (error) {
      if (globals.debug) console.log('error signing out: ', error);
    }
    setLoadingText(null);
  };

  /**
   * Handle a create new user workflow
   */
  async function handleCreateNewUser() {
    // If user is already signed in, sign them out
    // This is added for the "share-create-account" flow
    // - If an user is signed in one tab and
    // - they used direct share URL to create a new account in another tab,
    // - then then have to be signed them out
    try {
      const _user = await Auth.currentAuthenticatedUser();
      if (_user) {
        await handleUserInfo();
      }
    } catch (error) {
      if (globals.debug) console.log('error signing out: ', error);
    }

    if (valdateNewUserForm() && selectedDepartment) {
      try {
        setLoadingText('Creating new user...');

        let _username = state.username.replace(/\s/g, '');

        await Auth.signUp({
          username: _username,
          password: state.password,
          attributes: {
            email: state.email,
            'custom:type': '3',
          },
        });

        // Save state for email confirmation
        await saveUsernameAtConfirmUserState({
          username: state.username,
          email: state.email,
          firstName: state.firstName,
          lastName: state.lastName,
          deptCode: state.deptCode
            ? state.deptCode
            : location.state.departmentCode, // If state.deptCode is not set, it will be set to the location.state.departmentCode
          deptPin: state.deptPin,
          userCode: state.userCode,
          departmentID: selectedDepartment.id,
          pairedDepIDs: [...pairedDepartments].map((dep) => dep.id),
          timestamp: Date.now(),
        });
        setLoadingText(null);
        setStatus(CONFIRM_NEW_USER);
        setState({
          ...state,
          password: '',
          error: '',
        });
        setModalState({
          title: 'User created',
          message:
            'Please check your email for the confirmation code from onedose@hinckleymed.com.\nEmail: ' +
            state.email,
        });
      } catch (error: any) {
        setLoadingText(null);
        console.log('Error signing in', error);
        if (error.code) {
          switch (error.code) {
            case 'UsernameExistsException':
              setState({ ...state, error: 'Username already exists' });
              break;
            default:
              setModalState({
                title: 'Error signing in',
                message:
                  'An unexpected error occurred. Please try again later.',
              });
              break;
          }
        } else {
          setModalState({
            title: 'Error signing in',
            message: 'An unexpected error occurred. Please try again later.',
          });
        }
      }
    }
  }

  /**
   * Handle a confirm new user workflow
   */
  async function handleConfirmNewUser() {
    if (state.verificationCode.length > 0) {
      try {
        setLoadingText('Confirming new user...');
        let _username = state.username.replace(/\s/g, '');
        await Auth.confirmSignUp(_username, state.verificationCode);
        if (selectedDepartment) {
          let dep = selectedDepartment;
          if (pairedDepartments.length === 1) {
            dep = pairedDepartments[0];
          }
          let response = await createUserGraphQL(
            UserType.USER,
            _username,
            state.firstName,
            state.lastName,
            state.userCode,
            dep,
            pairedDepartments
          );
          if (response.type === ResponseType.Success) {
            await clearUserConfirmState(); // Clear the saved state
          } else {
            console.log('Error creating user: ', response.data);
            setModalState({
              title: 'Error creating user',
              message: 'An unexpected error occurred. Please try again later.',
            });
            setLoadingText(null);
            return;
          }
        }
        setLoadingText(null);
        setModalState({
          title: 'Congrats ' + state.firstName + '!',
          message: 'You successfully created a OneDose account.',
          handleSubmit: async () => {
            // const user = await Auth.signIn(state.username, state.password);
            // console.log('user', user);
            navigate('/login');
          },
        });
      } catch (error: any) {
        setLoadingText(null);
        console.log('Error signing in', error);
        if (error.code) {
          switch (error.code) {
            case 'CodeMismatchException':
              setState({ ...state, error: 'Code is incorrect' });
              break;
            default:
              setModalState({
                title: 'Error signing in',
                message:
                  'An unexpected error occurred. Please try again later.',
              });
              break;
          }
        } else {
          setModalState({
            title: 'Error signing in',
            message: 'An unexpected error occurred. Please try again later.',
          });
        }
      }
    } else {
      setState({ ...state, error: 'Code is required' });
    }
  }

  function resetFormAndLoading() {
    setLoadingText(null);
    setState({
      ...state,
      username: '',
      password: '',
      verificationCode: '',
      userCode: '',
      error: '',
    });
  }

  async function resendCode() {
    try {
      let _username = state.username.replace(/\s/g, '');
      await Auth.resendSignUp(_username);
      setModalState({
        title: 'Code resent',
        message:
          'Please check your email from onedose@hinckleymed.com for the confirmation code.\nYour Email: ' +
          state.email +
          '\nNOTE: Make sure to check the spam folder.',
      });
    } catch (err: any) {
      console.log('Error resending code: ', err);
      if (err.code === 'LimitExceededException')
        setState({
          ...state,
          error:
            'You have exceeded the number of attempts to resend the code. Please try again later.',
        });
      else if (err.code === 'InvalidParameterException') {
        setState({
          ...state,
          error: 'User already confirmed. Please login.',
        });
        navigate('/login');
        // setStatus(NORMAL_LOGIN); // Navigate to login page
      } else setState({ ...state, error: 'Error resending code' });
    }
  }

  function valdateNewUserForm() {
    if (!validateNewPasswordForm()) return false;

    if (
      selectedDepartment &&
      childDepartments &&
      childDepartments.length > 1 &&
      pairedDepartments.length === 0
    ) {
      setState({ ...state, error: 'Subscribing to a department is required' });
      return false;
    }

    if (state.firstName.length < 2) {
      setState({ ...state, error: 'First name is required' });
      return false;
    }

    if (state.firstName[0] !== state.firstName[0].toUpperCase())
      setState({
        ...state,
        firstName: state.firstName[0].toUpperCase() + state.firstName.slice(1),
      });

    if (state.lastName.length < 2) {
      setState({ ...state, error: 'Last name is required' });
      return false;
    }
    if (state.lastName[0] !== state.lastName[0].toUpperCase())
      setState({
        ...state,
        lastName: state.lastName[0].toUpperCase() + state.lastName.slice(1),
      });

    if (state.email.length === 0) {
      setState({ ...state, error: 'Email is required' });
      return false;
    }

    //Validate email
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(state.email)) {
      setState({ ...state, error: 'Email is invalid' });
      return false;
    }

    if (state.userCode.length !== 4) {
      setState({ ...state, error: '4-Digit pin is required' });
      return false;
    }
    return true;
  }

  /**
   * Check if the new password is valid with these requirements:
   * 1. Password is > 6 characters long
   * 2. Password contains a number
   * 3. Password contains a special character
   * @returns true if the new password is valid, false otherwise
   */
  function validateNewPasswordForm() {
    if (state.username.length === 0) {
      setState({ ...state, error: 'Username is required' });
      return false;
    }

    /* 1-5-23 Hazlett: Tested with my email as the username and it worked fine. - NOT sure what happended earlier with the people and syncing */
    // //Check if the username has a space or period
    // if(username.includes(' ') || username.includes('.')){
    //   setError("Username cannot contain spaces or periods")
    //   return false;
    // }

    if (state.password.length === 0) {
      setState({ ...state, error: 'Password is required' });
      return false;
    } else if (state.confirmPassword.length === 0) {
      setState({ ...state, error: 'Confirm password is required' });
      return false;
    } else if (state.password !== state.confirmPassword) {
      setState({ ...state, error: 'Passwords do not match' });
      return false;
    } else if (!validatePassword(state.password)) {
      setState({
        ...state,
        error:
          'Password does not meet requirements. It must be ≥6 characters long, contain a number and special character.',
      });
      return false;
    }

    return true;
  }
  /**
   * Validates the password to ensure it meets the following criteria:
   *  1. At least 6 characters long
   *  2. Contains at least one number
   *  3. Contains at least one special character
   */
  const validatePassword = (new_password: string): boolean => {
    const minLength = /.{6,}/;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return (
      minLength.test(new_password) &&
      hasNumber.test(new_password) &&
      hasSpecialChar.test(new_password)
    );
  };

  const resetModal = () => {
    setModalState({
      title: '',
      primaryBtnName: '',
      secondaryBtnName: '',
      message: '',
      handleClose: undefined,
      handleSubmit: undefined,
    });
  };

  return (
    <div className="login-container">
      {/* This is like a loading spinner that is used to display a loading message to the user */}
      {loadingText && <Loading message={loadingText} />}

      {selectedDepartment && location.state.shareAccountData && (
        <CreateAccountInvitationModal
          isVisible={isCreateAccountInvitationModalVisible}
          handleClose={() => {
            setIsCreateAccountInvitationModalVisible(false);
            // Close the modal and stay on login page
            setModalState({
              title: '',
              message: '',
            });
            navigate('/login');
            window.location.reload();
          }}
          handleSubmit={() => {
            setLoadingText(
              'Collecting ' + selectedDepartment.name + ' data...'
            );
            setIsCreateAccountInvitationModalVisible(false);
            // Clearing previously pending New User Account creation confirmation
            // And hence giving priotity to shared link new user account creation
            clearUserConfirmState();

            // Set department code from navigation
            setState({
              ...state,
              username: '',
              email: '',
              firstName: '',
              lastName: '',
              userCode: '',
              deptCode: location.state.shareAccountData?.departmentCode,
              deptPin: '',
              password: '',
              confirmPassword: '',
              verificationCode: '',
              error: '',
            });

            handleNewUser();

            // Clear modal
            setModalState({
              title: '',
              message: '',
            });
          }}
          department={selectedDepartment as DepartmentItem}
          shareAccountData={location.state.shareAccountData}
        />
      )}

      {/* This is a pop-up modal that is used to display messages to the user */}
      <ConfirmModal
        isVisible={modalState.title !== ''}
        title={modalState.title}
        handleClose={() => {
          modalState.handleClose?.();
          resetModal();
        }}
        handleSubmit={() => {
          modalState.handleSubmit?.();
          resetModal();
        }}
        isDeleteBtn={false}
        isSingleBtn={!modalState.primaryBtnName || !modalState.secondaryBtnName}
        primaryBtnName={modalState.primaryBtnName ?? ''}
        secondaryBtnName={modalState.secondaryBtnName ?? 'Okay'}
        primaryDescription={modalState.message}
      />

      {/* This is the main form that houses the different login status */}
      <form className="login-form" onSubmit={handleSubmit}>
        {/* This is the logo and title of the company */}
        <div className="company-logo-container ">
          <img className="login-logo" src={image} alt="Hinckley Medical Logo" />
          <h6 className="title-company">{'Hinckley Medical'}</h6>
        </div>

        {/* New User Form */}
        {status === NEW_USER && (
          <>
            <div className="login-form-container">
              {/* This is the error message that is displayed if there is an error */}
              {state.error && <div className="login-error">{state.error}</div>}

              {/* DEPARTMENT CODE */}
              <label htmlFor="deptCode" className={`login-title`}>
                Department Code
              </label>

              <div className="login-input-container">
                <FaBuildingShield size={20} />
                <InputText
                  type="text"
                  className="login-input"
                  id="deptCode"
                  name="deptCode"
                  placeholder="Department Code"
                  autoFocus={true}
                  required={true}
                  value={state.deptCode}
                  onChange={(e: any) => {
                    setState({ ...state, deptCode: e.target.value });
                  }}
                />
              </div>

              {/* PIN */}
              <label htmlFor="pin" className={`login-title`}>
                PIN
              </label>

              <div className="login-input-container">
                <CgPassword size={20} />
                <InputText
                  type="password"
                  className="login-input"
                  id="pin"
                  name="pin"
                  placeholder="PIN"
                  required={true}
                  value={state.deptPin}
                  onChange={(e: any) => {
                    setState({ ...state, deptPin: e.target.value });
                  }}
                />
              </div>

              {/* This is the description that is displayed to the user */}
              <div className="login-description">
                Please contact your department administrator to get the code.
              </div>

              {/* Continue Button */}
              <button className="login-button" type="submit">
                Continue
              </button>
            </div>

            {/* Cancel Button */}
            <button
              type="button"
              className="signup-button"
              onClick={() => {
                navigate('/login');
              }}
            >
              Cancel
            </button>
          </>
        )}

        {/* Create New User Form */}
        {status === CREATE_NEW_USER && (
          <>
            {/* This is the title of the form */}
            <div className="create-user-title">
              <h3>Create New User</h3>
            </div>

            {/* This is the form that is used to create a new user */}
            <div className="login-form-container">
              {state.error && <div className="login-error">{state.error}</div>}

              {/* This is the label for the department name */}
              <label htmlFor="email" className={`login-department-name`}>
                {selectedDepartment?.name}
              </label>

              {/* This is the loading spinner that is displayed if the child departments are not loaded */}
              {childDepartments == null ? (
                <Loading text={'Loading Agencies...'} />
              ) : childDepartments.length > 1 ? (
                <>
                  {/* Subscribed Agencies */}
                  <label htmlFor="departments" className={`signup-title`}>
                    Subscribed Agencies
                  </label>

                  {/* This is the input container for selecting the Subscribed Agencies */}
                  <div className="login-input-container department-select-container">
                    <FaBuilding
                      size={20}
                      style={{
                        position: 'absolute',
                        left: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        zIndex: 1,
                      }}
                    />
                    <div
                      className="department-select"
                      onClick={() => {
                        setState({ ...state, error: '' });
                        setSelectDepartmentsModal(true);
                      }}
                      role="button"
                      tabIndex={0}
                    >
                      {pairedDepartments.length === 0 ? (
                        <span>Select Departments...</span>
                      ) : (
                        <div
                          className="department-tags-container"
                          style={{
                            maxHeight: '80px',
                            overflowY: 'auto',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}
                        >
                          {pairedDepartments.map((department, index) => (
                            <span key={index} className="department-tag">
                              {department.name}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* This is the description that is displayed to the user */}
                  <div className="login-description">
                    Choose which departments under {selectedDepartment?.name}{' '}
                    you would also like to subscribe to.
                  </div>
                </>
              ) : (
                <></>
              )}

              {/* This is the pop-upmodal that is used to select the departments */}
              <SelectDepartmentsSignUpModal
                isVisible={selectDepartmentsModal}
                title={selectedDepartment?.name || 'Hinckley Medical'}
                description={`${selectedDepartment?.name} has the following agencies underneath it. Select the agencies you would like to subscribe to. NOTE: Some agencies may not be public.`}
                buttonText={'Continue'}
                department={selectedDepartment as DepartmentItem}
                availableDepartments={childDepartments}
                values={pairedDepartments}
                includeOwner={selectedDepartment?.isTopEnabled}
                handleClose={() => setSelectDepartmentsModal(false)}
                handleSubmit={(deps) => {
                  // Add warning modal for too many departments
                  if (deps.length > 3) {
                    setModalState({
                      title: 'Warning',
                      primaryBtnName: 'Cancel',
                      secondaryBtnName: 'Accept',
                      message:
                        'The more agencies you subscribe to the longer it will take to sync the data and the more space it will take on your device.',
                      handleClose: () => {
                        setSelectDepartmentsModal(true);
                      },
                      handleSubmit: () => {
                        setPairedDepartments(deps);
                        setSelectDepartmentsModal(false);
                      },
                    });
                  } else {
                    setPairedDepartments(deps);
                    setSelectDepartmentsModal(false);
                  }
                }}
              />

              {/* EMAIL */}
              <label htmlFor="email" className={`signup-title`}>
                Email
              </label>

              <div className="login-input-container">
                <MdEmail size={20} />
                <InputText
                  type="email-address"
                  className="login-input"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  autoFocus={true}
                  required={true}
                  value={state.email}
                  onChange={(e: any) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onBlur={() => {
                    if (state.email && state.email.length > 0) {
                      const emailRegex = /\S+@\S+\.\S+/;
                      if (!emailRegex.test(state.email)) {
                        setState({ ...state, error: 'Email is invalid' });
                      } else setState({ ...state, error: '' });
                    }
                  }}
                />
              </div>

              {/* USERNAME */}
              <label htmlFor="username" className={`signup-title`}>
                Username
              </label>

              <div className="login-input-container">
                <IoPersonSharp size={20} />
                <InputText
                  type="text"
                  className="login-input"
                  id="username"
                  name="username"
                  placeholder="Enter Username"
                  required={true}
                  value={state.username}
                  onChange={(e: any) => {
                    setState({ ...state, username: e.target.value });
                  }}
                  onBlur={() => {
                    if (state.username && state.username.length < 6) {
                      setState({ ...state, error: 'Username is invalid' });
                    } else setState({ ...state, error: '' });
                  }}
                />
              </div>

              {/* FIRST NAME */}
              <div className="password-fields-container">
                <div className="password-field">
                  <label htmlFor="first-name" className={`signup-title`}>
                    First Name
                  </label>

                  <div className="login-input-container">
                    <IoPersonSharp size={20} />
                    <InputText
                      type="text"
                      className="login-input"
                      id="first-name"
                      name="first-name"
                      placeholder="Enter First Name"
                      required={true}
                      value={state.firstName}
                      onChange={(e: any) => {
                        setState({ ...state, firstName: e.target.value });
                      }}
                    />
                  </div>
                </div>

                {/* LAST NAME */}
                <div className="password-field">
                  <label htmlFor="last-name" className={`signup-title`}>
                    Last Name
                  </label>

                  <div className="login-input-container">
                    <IoPersonSharp size={20} />
                    <InputText
                      type="text"
                      className="login-input"
                      id="last-name"
                      name="last-name"
                      placeholder="Enter Last Name"
                      required={true}
                      value={state.lastName}
                      onChange={(e: any) => {
                        setState({ ...state, lastName: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* 4-Digit PIN */}
              <label htmlFor="userCode" className={`signup-title`}>
                4-Digit PIN
              </label>

              <div className="login-input-container">
                <CgPassword size={20} />
                <InputText
                  type="password"
                  className="login-input"
                  id="userCode"
                  name="userCode"
                  placeholder="Enter 4-Digit PIN"
                  required={true}
                  value={state.userCode}
                  onChange={(e: any) => {
                    if (e.target.value.length <= 4)
                      setState({ ...state, userCode: e.target.value });
                    else if (e.target.value.length > 4) {
                      setState({
                        ...state,
                        userCode: e.target.value.substring(0, 4),
                      });
                    }
                  }}
                />
              </div>

              {/* This is the description that is displayed to the user */}
              <div className="login-description">
                Create a 4-digit PIN to use when verifying yourself in the
                future.
              </div>

              {/* Password Fields Container */}
              <div className="password-fields-container">
                {/* PASSWORD */}
                <div className="password-field">
                  <label htmlFor="password" className={`signup-title`}>
                    Password
                  </label>

                  <div className="login-input-container">
                    <RiLockPasswordFill size={20} />
                    <InputText
                      autoComplete="new-password"
                      type="password"
                      className="login-input"
                      id="password"
                      name="password"
                      placeholder="Enter Password"
                      required={true}
                      value={state.password}
                      onChange={(e: any) => {
                        setState({ ...state, password: e.target.value });
                      }}
                    />
                  </div>
                </div>

                {/* CONFIRM PASSWORD */}
                <div className="password-field">
                  <label htmlFor="confirm-password" className={`signup-title`}>
                    Confirm Password
                  </label>

                  <div className="login-input-container">
                    <RiLockPasswordFill size={20} />
                    <InputText
                      autoComplete="new-password"
                      type="password"
                      className="login-input"
                      id="confirm-password"
                      name="confirm-password"
                      placeholder="Confirm Password"
                      required={true}
                      value={state.confirmPassword}
                      onChange={(e: any) => {
                        setState({ ...state, confirmPassword: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Continue Button */}
              <button className="login-button" type="submit">
                Create Account
              </button>
            </div>

            {/* Cancel Button */}
            <button
              type="button"
              className="signup-button"
              onClick={() => {
                navigate('/login');
              }}
            >
              Cancel
            </button>
          </>
        )}

        {/* Confirm New User Form */}
        {status === CONFIRM_NEW_USER && (
          <>
            <div className="login-form-container">
              {/* This is the error message that is displayed to the user */}
              {state.error && <div className="login-error">{state.error}</div>}

              <label htmlFor="email" className={`login-title`}>
                Email
              </label>

              <div className="login-input-container">
                <IoPersonSharp size={20} />
                <InputText
                  autoComplete="email"
                  type="email"
                  className="login-input"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  autoFocus={true}
                  required={true}
                  value={state.email}
                  disabled={true}
                  onChange={(e: any) => {
                    setState({ ...state, email: e.target.value });
                  }}
                />
              </div>

              {/* USERNAME */}
              <label htmlFor="username" className={`login-title`}>
                Username
              </label>

              <div className="login-input-container">
                <IoPersonSharp size={20} />
                <InputText
                  autoComplete="username"
                  type="text"
                  className="login-input"
                  id="username"
                  name="username"
                  placeholder="Enter Username"
                  autoFocus={true}
                  disabled={true}
                  required={true}
                  value={state.username}
                  onChange={(e: any) => {
                    setState({ ...state, username: e.target.value });
                  }}
                />
              </div>

              {/* PIN */}
              <label htmlFor="code" className={`login-title`}>
                Email Code
              </label>

              <div className="login-input-container">
                <CgPassword size={20} />
                <InputText
                  // type="password"
                  className="login-input"
                  id="code"
                  name="code"
                  placeholder="Enter PIN"
                  autoFocus={true}
                  required={true}
                  value={state.verificationCode}
                  onChange={(e: any) => {
                    setState({ ...state, verificationCode: e.target.value });
                  }}
                />
              </div>

              {/* This is the description that is displayed to the user */}
              <div className="login-description">
                Check your inbox for a code from onedose@hinckleymed.com.
              </div>
              <div className="login-description">
                Make sure to check the spam folder if you do not see it.
              </div>

              {/* Confirm Button */}
              <button className="login-button" type="submit">
                Confirm
              </button>
            </div>

            {/* Resend Code Button */}
            <button
              type="button"
              className="signup-button"
              onClick={() => resendCode()}
            >
              No Code? Resend
            </button>

            {/* Cancel Button */}
            <button
              type="button"
              className="signup-button"
              onClick={() => {
                navigate('/login');
              }}
            >
              Cancel
            </button>
          </>
        )}
      </form>

      {/* This is the background image that is used to display the background image */}
      <div className="login-background">
        {/* Background image will be set via CSS */}
      </div>
    </div>
  );
};

export default CreateAccount;
