import { DataStore } from 'aws-amplify';
import {
  ResponseType,
  Response,
  DatabaseResponse,
  executeQuery,
} from '../AmplifyDB';
import KeychainItem from '../model/KeychainItem';
import { Department, Keychain, User } from '../../models';
import { globals } from '../../ui/_global/common/Utils';
import { update } from 'lodash';
import { updateCategoryAccess } from './CategoryDB';
import { updateProtocolAccess } from './ProtocolDB';
import { updateDepartmentAccess } from './DepartmentDB';
import DepartmentItem from '../model/DepartmentItem';
import CategoryItem from '../model/CategoryItem';
import ProtocolItem from '../model/ProtocolItem';
import { keychainsByDepartmentID } from '../../graphql/queries';

export type KeychainDB = {
  name: string;
  hashedPin: string;
  saltPin: string;
  hashType: string;
  version: string | null | undefined;
  modifiedBy?: string;
  createdBy: string;
  metaData?: string;
  departmentID: string;
};

/**
 * Create a new keychain in the database
 * @param protocol KeychainDB JSON format
 * @returns The successful KeychainItem or the error
 */
export const createKeychain = async (
  keychain: KeychainDB | KeychainItem
): Promise<Response> => {
  try {
    let json: KeychainDB | KeychainItem;
    if (keychain instanceof KeychainItem) {
      json = {
        name: keychain.name,
        hashedPin: keychain.hash,
        saltPin: keychain.salt,
        hashType: keychain.type,
        version: keychain.version,
        departmentID: keychain.departmentID,
        modifiedBy: keychain.modifiedBy ? keychain.modifiedBy.id : undefined,
        createdBy: keychain.model.createdBy,
      };
    } else json = keychain;

    let kc = await DataStore.save(
      new Keychain({
        name: json.name,
        hashedPin: json.hashedPin,
        saltPin: json.saltPin,
        hashType: json.hashType,
        version: json.version ? json.version : 'v1.0.0',
        departmentID: json.departmentID,
        modifiedBy: json.modifiedBy,
        createdBy: json.createdBy,
        metaData: json.metaData,
      })
    );
    if (globals.debug) console.log('Created keychain:', kc);
    let userID = kc.modifiedBy ? kc.modifiedBy : kc.createdBy;
    let modifiedBy = await DataStore.query(User, userID);
    if (modifiedBy == null) {
      return {
        type: ResponseType.Failure,
        data: 'The modified by user does not exist',
      };
    }

    let keychainItem = new KeychainItem(kc);
    keychainItem.modifiedBy = modifiedBy;
    return {
      type: ResponseType.Success,
      data: keychainItem,
    };
  } catch (e) {
    console.error('Error in keychain function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Delete a keychain from the database
 * @param keychainItem The keychain to delete
 * @returns
 */
export const deleteKeychain = async (
  keychainItem: KeychainItem,
  database: DatabaseResponse
): Promise<Response> => {
  try {
    let depID: string = keychainItem.departmentID;
    let id: string = keychainItem.uid;

    /* Remove the keychain from the department */
    if (database.department.keychainID === keychainItem.uid) {
      let resp = await updateDepartmentAccess(
        database.department,
        false,
        undefined,
        true
      );
      if (resp.type === ResponseType.Failure) {
        return {
          type: ResponseType.Failure,
          data: 'Error removing keychain from department',
        };
      }
    }

    /* Checkk if any categories or folders are using the keychain */
    for (let category of database.categories) {
      if (category.keychainID === keychainItem.uid) {
        let resp = await updateCategoryAccess(category, false, undefined, true);
        if (resp.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: 'Error removing keychain from category',
          };
        }
      }
    }

    for (let protocol of database.protocols) {
      if (protocol.keychainID === keychainItem.uid) {
        let resp = await updateProtocolAccess(protocol, false, undefined, true);
        if (resp.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data:
              'Error removing keychain from protocol' +
              protocol.name +
              resp.data,
          };
        }
      }
    }

    let keychain = await DataStore.delete(Keychain, id);
    if (keychain == null) {
      return {
        type: ResponseType.Failure,
        data: 'The keychain does not exist',
      };
    }

    if (globals.debug) console.log('Hard Deleted keychain:', keychain);

    return {
      type: ResponseType.Success,
      data: keychainItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const updateKeychainAccess = async (
  items: (CategoryItem | ProtocolItem | DepartmentItem)[],
  keychain?: KeychainItem,
  subDeps?: DepartmentItem[]
): Promise<Response> => {
  try {
    let updates = [];
    for (let item of items) {
      if ((item as CategoryItem).subCategories) {
        let resp = await updateCategoryAccess(
          item as CategoryItem,
          keychain ? true : item.isPublic,
          keychain,
          false
        );
        if (resp.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: 'Error updating category access',
          };
        } else {
          item.isPublic = keychain ? true : item.isPublic;
          item.keychainID = keychain ? keychain.uid : null;
          updates.push(resp.data);
        }
      }
      if ((item as ProtocolItem).parent) {
        let resp = await updateProtocolAccess(
          item as ProtocolItem,
          keychain ? true : item.isPublic,
          keychain,
          false
        );
        if (resp.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: 'Error updating protocol access' + resp.data,
          };
        } else {
          item.isPublic = keychain ? true : item.isPublic;
          item.keychainID = keychain ? keychain.uid : null;
          updates.push(resp.data);
        }
      } else if ((item as DepartmentItem).TAG === 'DepartmentItem') {
        let resp = await updateDepartmentAccess(
          item as DepartmentItem,
          keychain ? true : item.isPublic,
          keychain,
          false
        );
        if (resp.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: 'Error updating department access',
          };
        } else {
          item.isPublic = keychain ? true : item.isPublic;
          item.keychainID = keychain ? keychain.uid : null;
          updates.push(resp.data);
        }
      }
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchKeychains = async (
  department: DepartmentItem,
  useDataStore: boolean = true
): Promise<Response> => {
  try {
    let depIDs = [department.id];
    if (department.parentDep) depIDs.push(department.parentDep.id);
    if (department.parentDep?.parentDep)
      depIDs.push(department.parentDep.parentDep.id);

    let keychainList: Keychain[];
    if (useDataStore) {
      keychainList = await DataStore.query(Keychain, (c) =>
        c.or((c) => depIDs.map((id) => c.departmentID.eq(id)))
      );
    } else {
      let keychainPromises: Promise<Keychain[]>[] = [];
      for (let id of depIDs) {
        keychainPromises.push(
          executeQuery(keychainsByDepartmentID, {
            departmentID: id,
            filter: {
              _deleted: { ne: true },
            },
          })
        );
      }
      let results = await Promise.all(keychainPromises);
      //Filter out undefined results and duplicates
      keychainList = results
        .flat()
        .filter((kc) => kc !== undefined)
        .filter(
          (kc, index, self) => index === self.findIndex((t) => t.id === kc.id)
        );
    }

    let keychains: KeychainItem[] = [];
    for (let i = 0; i < keychainList.length; i++) {
      let keychain = new KeychainItem(keychainList[i]);
      keychains.push(keychain);
    }

    keychains.sort((a: KeychainItem, b: KeychainItem) =>
      a.name.localeCompare(b.name)
    );

    // contactsList.sort((a: Contact, b: Contact) => a.fullName.localeCompare(b.fullName));
    return {
      type: ResponseType.Success,
      data: keychains,
    };
  } catch (error) {
    console.error('Error fetching keyechains:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};
