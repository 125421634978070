import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { IoDocumentOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ListDepartmentItems from '../../protocol/details/ListDepartmentItems';
import { MdFolderOff } from 'react-icons/md';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import ReactLoading from 'react-loading';
import './PublicProtocol.scss';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import {
  handleDepartmentCache,
  handleGetDepartment,
  handlePublicLoaded,
  handlePublicSelectedItem,
  handleShowSideBar,
} from '../../../../store/actions';
import SearchableList from '../../../components/Search/SearchableList';
import { getHashedPin } from '../../../_global/common/Encrypt';
import CategoryItem from '../../../../data/model/CategoryItem';
import { fetchDepartmentByName } from '../../../../data/GraphQL_API';
import KeychainItem from '../../../../data/model/KeychainItem';
import { Button } from 'react-bootstrap';
import { FaLock, FaLockOpen } from 'react-icons/fa6';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import UnlockKeychainModal from './UnlockKeychainModal';
import SplashScreen from '../../SplashScreen';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const PublicProtocol = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [unlockCategory, setIsUnlockCategory] = useState<KeychainItem | null>(
    null
  );
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const dbState = useSelector((state: any) => state.protocol);
  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.departmentItem
  );
  const [department, setDepartment] = useState<DepartmentItem | undefined>(
    database.department ? database.department : undefined
  );

  const [depInfo, setDepInfo] = useState({
    keychain: undefined as KeychainItem | undefined,
    name: '',
    logo: '',
  });

  const [lastUpdate, setLastUpdate] = useState<string>('');
  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentItem>();

  const [isLockModalVisible, setIsLockModalVisible] = useState(false);

  const searchableProtocols = useMemo(() => {
    if (database && database.protocols) {
      let prots: ProtocolItem[] = [];
      for (let i = 0; i < database.protocols.length; i++) {
        let protocol = database.protocols[i];
        if (protocol.parent.keychainID) {
          let keychain = database.keychains.find(
            (k) => k.uid === protocol.parent.keychainID
          );
          if (keychain && keychain.isUnlocked) prots.push(protocol);
        } else prots.push(protocol);
      }
      return prots;
    }
    return [];
  }, [database]);

  const locked = useMemo(() => {
    let lock = {
      total: {
        locked: 0,
        unlocked: 0,
      },

      protocol: {
        locked: 0,
        unlocked: 0,
      },
      category: {
        locked: 0,
        unlocked: 0,
      },
    };
    if (dbState.departmentItem && dbState.departmentItem.keychains) {
      for (let i = 0; i < dbState.departmentItem.keychains.length; i++) {
        if (dbState.departmentItem.keychains[i].isUnlocked === false) {
          lock.protocol.locked +=
            dbState.departmentItem.keychains[i].protocols.length;
          lock.category.locked +=
            dbState.departmentItem.keychains[i].categories.length;
        } else if (dbState.departmentItem.keychains[i].isUnlocked === true) {
          lock.protocol.unlocked +=
            dbState.departmentItem.keychains[i].protocols.length;
          lock.category.unlocked +=
            dbState.departmentItem.keychains[i].categories.length;
        }
      }
      lock.total.locked = lock.protocol.locked + lock.category.locked;
      lock.total.unlocked = lock.protocol.unlocked + lock.category.unlocked;
      return lock;
    }
    return null;
  }, [dbState.departmentItem]);

  useEffect(() => {
    const split = location.pathname.split('/');
    if ((split.length >= 3 || split.length <= 5) && split[1] === 'sites') {
      const departmentName = split[2];
      if (dbState.departmentItem && dbState.departmentItem.department) {
        setDbInfo(dbState.departmentItem);
      } else if (departmentName) {
        /* Otherwise, fetch the department from the database */
        fetchDepartment(departmentName);
      } else {
        if (globals.debug)
          console.log('No department for name found', departmentName);
        navigate('/404');
      }
    } else {
      navigate('/404');
    }
  }, [location.pathname, dbState, dbState.departmentItem]);

  useEffect(() => {
    if (dbState.departmentItem && dbState.departmentItem.length != 0)
      setDbInfo(dbState.departmentItem);
  }, [dbState.departmentItem]);

  const setDbInfo = (data: DatabaseResponse) => {
    setDatabase(data);
    setDepartment(data.department);
    findLastUpdate(data);
    document.title = `${data.department.name} | OneDose`;
    if (data.department.activeSubDep) {
      setDepInfo({
        keychain: undefined,
        name: data.department.activeSubDep.name,
        logo: data.department.activeSubDep.logoVerifiedUrl
          ? data.department.activeSubDep.logoVerifiedUrl
          : '',
      });
    } else {
      setDepInfo({
        keychain: undefined,
        name: data.department.name,
        logo: data.department.logoVerifiedUrl
          ? data.department.logoVerifiedUrl
          : '',
      });
    }
  };

  const fetchDepartment = async (departmentName: string) => {
    let result: Response = await fetchDepartmentByName(
      departmentName,
      dispatch,
      () => dispatch<any>(handlePublicLoaded())
    );
    if (result.type === ResponseType.Success) {
      if (result.data.db) {
        let db: DatabaseResponse = result.data.db;
        setDbInfo(db);
        dispatch<any>(handleGetDepartment(db));
        dispatch<any>(handleDepartmentCache(db));
      } else if (result.data.keychain) {
        if (globals.debug)
          console.log('Department is locked', result.data.keychain);

        let keychain: KeychainItem = result.data.keychain;
        let department: DepartmentItem = result.data.department;
        setDepartment(department);
        setDepInfo({
          keychain: keychain,
          name: department.name,
          logo: department.logoVerifiedUrl ? department.logoVerifiedUrl : '',
        });
      }
    } else {
      if (globals.debug) console.log('Error fetching department', result.data);
      navigate('/login');
    }
  };

  const findLastUpdate = (data: DatabaseResponse) => {
    let arr: (CategoryItem | ProtocolItem)[] = [
      ...data.categories,
      ...data.protocols,
    ];
    let lastUpdated = arr.reduce((acc, item) => {
      if (item.model.updatedAt && item.model.updatedAt > acc) {
        return item.model.updatedAt;
      }
      return acc;
    }, '');
    setLastUpdate(lastUpdated);
  };

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleProtocolClick = async (selectedProtocol: ProtocolItem) => {
    if (department) {
      await dispatch<any>(handlePublicSelectedItem(selectedProtocol));
      await dispatch<any>(handleShowSideBar({ sidebar: true }));
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        department?.id,
        department.salt + expiration.toLocaleString(),
        'SHA512'
      );
      //Remove the '/' if it is the last character in the pathname
      const clean =
        location.pathname[location.pathname.length - 1] === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname;
      navigate(
        `${clean}/protocols?signature=${signature}&expiration=${expiration}`,
        {
          state: { selectedItem: selectedProtocol, database: database },
        }
      );
    }
  };

  const PublicProtocolHeader = () => {
    return (
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{
            margin: '0px 10px',
            display: 'flex',
            flexDirection: 'row',
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              padding: '0.25rem',
              gap: '0.5rem',
              borderRadius: '0.5rem',
              flex: 1,
            }}
          >
            {depInfo.logo !== '' && (
              <img
                className="department-logo-public"
                src={depInfo.logo}
                alt="Agency Logo"
              />
            )}
            {department && (
              <div style={{ flexDirection: 'column' }}>
                <div
                  className="titleText headerText"
                  style={{
                    marginLeft: '6px',
                    marginRight: '6px',
                  }}
                >
                  {depInfo.name} Protocols
                </div>
                {lastUpdate !== '' && (
                  <h6
                    className={`headerTextLight`}
                    style={{
                      fontWeight: '400',
                      color: '#616161',
                      marginLeft: '8px',
                      marginRight: '6px',
                      marginTop: '-4px',
                    }}
                  >
                    Last Updated {getFormattedDate(lastUpdate, true)}
                  </h6>
                )}
                {locked != null && locked.total.locked > 0 && (
                  <h6
                    className={`headerTextLight`}
                    style={{
                      fontWeight: '400',
                      color: '#616161',
                      marginLeft: '8px',
                      marginRight: '6px',
                      marginTop: '4px',
                    }}
                  >
                    {locked.category.locked > 0
                      ? locked.category.locked +
                        ' folder' +
                        (locked.category.locked > 1 ? 's' : '')
                      : ''}{' '}
                    {locked.category.locked > 0 && locked.protocol.locked > 0
                      ? ' and '
                      : ''}{' '}
                    {locked.protocol.locked > 0
                      ? locked.protocol.locked +
                        ' protocol' +
                        (locked.protocol.locked > 1 ? 's' : '')
                      : ''}{' '}
                    {locked.total.locked > 0 ? ' are locked' : ''}
                  </h6>
                )}
              </div>
            )}

            {!department && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}
              >
                <div className="loadingText">Loading</div>
                <div
                  style={{
                    marginTop: '-26px',
                  }}
                >
                  <ReactLoading
                    className="load"
                    type="bubbles"
                    height={32}
                    width={32}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="headerTextLight" style={{}}>
            {((department && department.keychain) ||
              (locked && locked.total.unlocked > 0)) && (
              <Button
                data-testid="isAddButton"
                className="primary-button"
                style={{
                  width: 'auto',
                  flex: 1,
                  padding: '0.5rem 1rem',
                }}
                onClick={() => {
                  if (department && department.keychain) setIsOpen(true);
                  else setIsLockModalVisible(true);
                }}
              >
                <span>
                  <FaLock className="icon-normal" />
                </span>{' '}
                Lock Department
              </Button>
            )}
          </div>
        </div>
        <hr />
      </div>
    );
  };

  return depInfo.keychain == null || depInfo.keychain.isUnlocked ? (
    <div className="screen-container">
      <PublicProtocolHeader />
      {unlockCategory && department && (
        <UnlockKeychainModal
          isVisible={unlockCategory !== null}
          keychain={unlockCategory}
          handleClose={() => {
            setIsUnlockCategory(null);
          }}
          handleSubmit={(keychain: KeychainItem) => {
            let newKeychain = {
              ...keychain!,
              isUnlocked: true,
            };
            let keychains = [...database!.keychains];
            let index = keychains.findIndex((k) => k.uid === keychain.uid);
            keychains[index] = newKeychain as KeychainItem;
            let db: DatabaseResponse = {
              ...database!,
              keychains: keychains,
            };
            dispatch<any>(handleGetDepartment(db));
            setDatabase(db);
            setIsUnlockCategory(null);
            // setDepInfo({
            //   ...depInfo,
            //   keychain: newKeychain as KeychainItem,
            // });
          }}
          department={department}
          type="category"
          logo={depInfo.logo}
        />
      )}
      <ConfirmModal
        isVisible={isLockModalVisible}
        handleClose={() => setIsLockModalVisible(false)}
        handleSubmit={async () => {
          await dispatch<any>(handleShowSideBar({ sidebar: false }));
          localStorage.clear();

          let newKeychains: KeychainItem[] = [];
          for (let i = 0; i < database!.keychains.length; i++) {
            let keychain = database!.keychains[i];
            keychain.isUnlocked = false;
            newKeychains.push(keychain);
          }
          let dep = new DepartmentItem(database.department.model);
          dep.logoVerifiedUrl = database.department.logoVerifiedUrl;
          let db: DatabaseResponse = {
            ...database!,
            department: dep,
            keychains: newKeychains,
          };
          setDepartment(dep);
          await dispatch<any>(handleGetDepartment(db));
          setDepInfo({
            keychain: undefined,
            name: department!.name,
            logo: department!.logoVerifiedUrl
              ? department!.logoVerifiedUrl
              : '',
          });
          setIsLockModalVisible(false);
        }}
        title={'Lock ' + (locked && locked.total.unlocked) + ' Items?'}
        primaryDescription={
          'This keychain was used to unlock ' +
          (locked
            ? (locked.category.unlocked > 0
                ? locked.category.unlocked +
                  ' folder' +
                  (locked.category.unlocked > 1 ? 's' : '')
                : '') +
              (locked.total.unlocked > 0 ? ' and ' : '') +
              (locked.protocol.unlocked > 0
                ? locked.protocol.unlocked +
                  ' protocol' +
                  (locked.protocol.unlocked > 1 ? 's' : '')
                : '')
            : '') +
          '. Are you sure you want to lock them?'
        }
        secondaryDescription={
          'This will require users to unlock the protocols again.'
        }
        primaryBtnName={'Cancel'}
        secondaryBtnName={'Lock'}
      />
      <ConfirmModal
        isVisible={isOpen}
        handleClose={() => setIsOpen(false)}
        handleSubmit={async () => {
          if (department && department.keychain) {
            let newKeychain = {
              ...department.keychain!,
              isUnlocked: false,
            };

            localStorage.clear();
            setDepInfo({
              keychain: newKeychain as KeychainItem,
              name: department.name,
              logo: department.logoVerifiedUrl
                ? department.logoVerifiedUrl
                : '',
            });
            let dep = new DepartmentItem(database.department.model);
            dep.activeSubDep = null;

            let db: DatabaseResponse = {
              ...database!,
              department: dep,
              categories: [],
              protocols: [],
              keychains: [],
            };
            setDatabase(db);
            setDepartment(dep);
            await dispatch<any>(handleGetDepartment(db));
            await dispatch<any>(handleDepartmentCache(undefined));

            setIsOpen(false);
            setDepInfo({
              ...depInfo,
              keychain: newKeychain as KeychainItem,
              name: department.name,
              logo: department.logoVerifiedUrl
                ? department.logoVerifiedUrl
                : '',
            });
          }
        }}
        title={'Lock ' + department?.name + ' Protocol set?'}
        primaryDescription={
          'Are you sure you want to lock ' +
          department?.name +
          ' protocol set for this device?'
        }
        primaryBtnName={'Cancel'}
        secondaryBtnName={'Lock'}
      />
      <SearchableList<ProtocolItem>
        id="searchDropdown"
        options={searchableProtocols}
        labelField={(option) => option.name}
        valueField={(option) => option.name}
        iconField={(option) => {
          return <IoDocumentOutline size={16} />;
        }}
        onChange={(option: ProtocolItem) => {
          handleProtocolClick(option);
        }}
        // multiSelect={true}
        placeholder="Search protocols..."
        containerStyle={{ width: '60%' }}
        notFoundText="No protocols found..."
        itemClassName="search-item"
      />
      <div style={{ flex: 1, display: 'flex' }}>
        <>
          {database &&
          database.categories &&
          database.categories.length >= 1 ? (
            <ListDepartmentItems
              isPublic={true}
              value={searchQuery}
              data={database}
              handleViewProtocol={handleProtocolClick}
              handleUnlockKeychain={(keychain: KeychainItem) => {
                setIsUnlockCategory(keychain);
              }}
            />
          ) : (
            <SplashScreen />
            // <div className="no-data-container">
            //   <MdFolderOff size={220} className="light-grey-icon" />
            //   <h4 className="light-grey-icon">Loading Records...</h4>
            // </div>
          )}
        </>
      </div>
    </div>
  ) : (
    <div className="screen-container">
      {department && (
        <UnlockKeychainModal
          isVisible={true}
          keychain={depInfo.keychain}
          handleClose={() => {}}
          handleSubmit={(keychain: KeychainItem) => {
            let newKeychain = {
              ...keychain!,
              isUnlocked: true,
            };
            setDepInfo({
              ...depInfo,
              keychain: newKeychain as KeychainItem,
            });
            const split = location.pathname.split('/');
            const departmentName = split[2];

            fetchDepartment(departmentName);
          }}
          department={department}
          type="department"
          logo={depInfo.logo}
        />
      )}
    </div>
  );
};

export default PublicProtocol;
