import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { useDispatch } from 'react-redux';
import { User } from '../../../../models';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { handleGetDepartment } from '../../../../store/actions';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import { getFormattedDate, globals } from '../../../_global/common/Utils';
import { Button, Col, Row } from 'react-bootstrap';
import SearchableDropdown from '../../../components/SearchableDropdown';
import KeychainItem from '../../../../data/model/KeychainItem';
import { IoDocumentOutline } from 'react-icons/io5';
import CategoryItem from '../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { FaFolderOpen, FaLock } from 'react-icons/fa6';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { FaTimes } from 'react-icons/fa';
import {
  deleteKeychain,
  updateKeychainAccess,
} from '../../../../data/functions/KeychainDB';
import { updateDepartmentAccess } from '../../../../data/functions/DepartmentDB';
import { UserType } from '../../../../models';
import { fetchUser } from '../../../../data/functions/UserDB';

const KeychainEdit = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [list, setList] = useState<(CategoryItem | ProtocolItem)[]>([]);
  const [parmList, setParmList] = useState<(CategoryItem | ProtocolItem)[]>([]);
  const [isProtectingDepartment, setIsProtectingDepartment] = useState(false);

  const department: DepartmentItem = database.department;
  const [parmKeychain, setParmKeychain] = useState<KeychainItem>(state.value);
  const [isDelete, setIsDelete] = useState(false);
  const [modifiedBy, setModifiedBy] = useState<User>();

  const [parmIsProtectingDepartment, setParmIsProtectingDepartment] = useState(
    department.keychainID === parmKeychain.uid
  );

  const [message, setMessage] = useState({
    title: '',
    description: '',
    errorCode: '',
  });

  const searchableProtocols = useMemo(() => {
    return database.protocols
      .filter((p) => {
        return (
          list.findIndex((l) => l.uid === p.uid) === -1 && p.keychainID == null
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [list, database.protocols]);

  const searchableCategories = useMemo(() => {
    return database.categories
      .filter((c) => {
        return (
          list.findIndex((l) => l.uid === c.uid) === -1 && c.keychainID == null
        );
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [list, database.categories]);

  useEffect(() => {
    if (parmKeychain) {
      if (department.keychainID === parmKeychain.uid) {
        setParmIsProtectingDepartment(true);
        setIsProtectingDepartment(true);
      } else {
        let l: (CategoryItem | ProtocolItem)[] = [];
        database.categories.forEach((c) => {
          if (parmKeychain.uid === c.keychainID) l.push(c);
        });
        database.protocols.forEach((p) => {
          if (parmKeychain.uid === p.keychainID) l.push(p);
        });
        setList(l);
        setParmList(l);
      }
    }
  }, [parmKeychain]);

  // Update the keychain state when the parmKeychain state changes
  useEffect(() => {
    if (state.value) {
      setParmKeychain(state.value);
    }
  }, [state.value]);

  /**
   * Find all the sub-departments that this weight object is paired with and set the pairedDeps state
   */
  useEffect(() => {
    const findUser = async () => {
      let id = parmKeychain.model.modifiedBy
        ? parmKeychain.model.modifiedBy
        : parmKeychain.model.createdBy;
      let result = await fetchUser(id);
      if (result.type === ResponseType.Success) {
        setModifiedBy(result.data);
      }
    };
    if (parmKeychain) findUser();
  }, [parmKeychain]);

  const handleSubmit = async () => {
    let removes: (CategoryItem | ProtocolItem | DepartmentItem)[] = [];
    let adds: (CategoryItem | ProtocolItem | DepartmentItem)[] = [];
    if (parmIsProtectingDepartment && !isProtectingDepartment)
      removes.push(department);
    if (!parmIsProtectingDepartment && isProtectingDepartment)
      adds.push(department);

    list.forEach((l) => {
      if (parmList.findIndex((p) => p.uid === l.uid) === -1) adds.push(l);
    });
    parmList.forEach((l) => {
      if (list.findIndex((p) => p.uid === l.uid) === -1) removes.push(l);
    });
    try {
      let promises: Promise<Response>[] = [];
      promises.push(updateKeychainAccess(adds, parmKeychain));
      promises.push(updateKeychainAccess(removes, undefined));
      let [addResp, removeResp] = await Promise.all(promises);

      if (addResp.type === ResponseType.Success) {
        if (globals.debug) console.log('ADDED', addResp.data);
      } else if (addResp.type === ResponseType.Failure && globals.debug)
        console.error('ERROR UPDATING KEY', addResp.data);

      if (removeResp.type === ResponseType.Success) {
        if (globals.debug) console.log('REMOVED', removeResp.data);
      } else if (removeResp.type === ResponseType.Failure && globals.debug)
        console.error('ERROR UPDATING KEY', removeResp.data);

      setMessage({
        title: 'Keychain Updated',
        description:
          'Keychain has sucessfully been ' +
          (addResp.data.length
            ? 'added to ' + addResp.data.length + ' items'
            : '') +
          (addResp.data.length && removeResp.data.length ? ' and ' : '') +
          (removeResp.data.length
            ? 'removed from ' + removeResp.data.length + ' items'
            : ''),
        errorCode: '',
      });

      setParmIsProtectingDepartment(isProtectingDepartment);
      setParmList(list);
    } catch (e) {
      if (globals.debug) console.error('ERROR UPDATING KEYCHAIN ACCESS', e);
    }
  };

  const reloadDatabase = async (): Promise<DatabaseResponse> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return newDB;
    } else {
      if (globals.debug) console.error('ERROR LOADING DATABASE', resp.data);
      return database;
    }
  };

  const handleBack = () => {
    navigate(`/database/list-keychains`, {
      state: { department: department, data: database.keychains, database },
    });
  };

  const handleDelete = async () => {
    const result = await deleteKeychain(parmKeychain, database);
    if (result.type === ResponseType.Success) {
      reloadDatabase()
        .then((db) => {
          navigate(`/database/list-keychains`, {
            state: { department: department, database: db },
          });
        })
        .catch((err) => {
          if (globals.debug) console.error('Error reloading database:', err);
        });
    } else if (globals.debug) {
      if (globals.debug) console.log('KEYCHAIN', ' delete failed', result.data);
    }
  };

  const handleOptionClicked = (item: CategoryItem | ProtocolItem) => {
    let l = [...list];
    l.push(item);
    l.sort((a, b) => {
      if (
        ((a as ProtocolItem).parent && (b as ProtocolItem).parent) ||
        (!(a as ProtocolItem).parent && !(b as ProtocolItem).parent)
      )
        return a.name.localeCompare(b.name);
      else return (a as ProtocolItem).parent ? 1 : -1;
    });
    setList(l);
  };

  const isSaveValid = useMemo(() => {
    if (parmList.length !== list.length) return true;
    for (let i = 0; i < list.length; i++) {
      if (list[i].uid !== parmList[i].uid) return true;
    }
    if (isProtectingDepartment !== parmIsProtectingDepartment) return true;
    return false;
  }, [list, parmList, isProtectingDepartment, parmIsProtectingDepartment]);

  return (
    <div className="screen-container">
      {parmKeychain && (
        <ConfirmModal
          isVisible={isDelete}
          title="Delete Keychain?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDelete}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            'Are you sure you want delete keychain ' + parmKeychain.name + '?'
          }
          secondaryDescription={
            'It is beng used to protect ' +
            list.length +
            ' items. This action cannot be undone.'
          }
        />
      )}

      <ConfirmModal
        isVisible={message.title !== ''}
        title={message.title}
        handleClose={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        handleSubmit={() => {
          setMessage({ title: '', description: '', errorCode: '' });
        }}
        isSingleBtn={true}
        secondaryBtnName="Okay"
        primaryDescription={message.description}
        secondaryDescription={message.errorCode}
      />

      <ProtocolHeader
        name={'Keychain: ' + parmKeychain.name}
        type={'protocol'}
        page={'Keychains'}
        isSaveButton={true}
        isDeleteButton={true}
        handleDelete={() => {
          setIsDelete(true);
        }}
        description={
          'Keychains are used to lock the department, folders, and protocols from public access.'
        }
        isSaveActive={isSaveValid}
        isBackButton={true}
        isDotButton={false}
        handleCancel={handleBack}
        handleSave={handleSubmit}
        handleCancelEdit={handleBack}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Keychain Information</h5>
          {parmKeychain && (
            <div className="input-container roundBorder">
              <div
                style={{
                  display: 'flex',
                  marginTop: '5px',
                  flexDirection: 'column',
                }}
                className="ketamine-general-label"
              >
                <Row>
                  <Col sm={6}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Modified By:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {modifiedBy
                          ? modifiedBy.firstName + ' ' + modifiedBy.lastName
                          : 'Hinckley Medical'}
                      </div>
                    </div>
                    {parmKeychain.version && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Version:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {parmKeychain.version}
                        </div>
                      </div>
                    )}
                    {user.type === UserType.ADMIN && parmKeychain.uid && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>ID:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {parmKeychain.uid}
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col sm={6}>
                    {parmKeychain.model.updatedAt && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Last Updated:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {getFormattedDate(
                            parmKeychain.model.updatedAt,
                            false
                          )}
                        </div>
                      </div>
                    )}
                    {parmKeychain.model.createdAt && (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>Created At:</div>
                        <div
                          style={{ fontWeight: '500', paddingBottom: '10px' }}
                        >
                          {getFormattedDate(
                            parmKeychain.model.createdAt,
                            false
                          )}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Protect Your Protocols</h5>
          <span
            className="contentText greyText"
            style={{ fontSize: 'medium', marginLeft: '0px' }}
          >
            You can use this keychain to lock your department or folders and
            protocols. You can add items below to lock them.
          </span>
          {list.length === 0 && (
            <>
              {isProtectingDepartment ? (
                <div>
                  <div
                    className="departmentItemNoHover no-select"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignContent: 'center',
                      padding: '10px 10px',
                      cursor: 'pointer',
                    }}
                  >
                    <FaLock size="1.5rem" style={{ marginRight: '10px' }} />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        justifyItems: 'center',
                        alignItems: 'flex-start',
                        margin: 0,
                        padding: 0,
                      }}
                    >
                      <h6
                        className="departmentItemText"
                        style={{
                          fontSize: '1.0rem',
                          fontWeight: '700',
                          flex: 1,
                        }}
                      >
                        Protecting {department.name} Protocol Set
                      </h6>
                      <p
                        style={{
                          display: 'flex',
                          fontSize: '0.8rem',
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        The protocol set is protected by this keychain from
                        public access.
                      </p>
                    </div>
                    <Button
                      className="btn-cancel"
                      // variant="link"
                      onClick={() => {
                        setIsProtectingDepartment(false);
                      }}
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setIsProtectingDepartment(true);
                  }}
                  className={`${list.length === 0 ? 'hoverable' : ''}`}
                  style={{
                    flex: 1,
                    justifyContent: 'center',
                    padding: '20px 0px',
                    marginTop: '20px',
                    border: '1px solid #E0E0E0',
                    borderRadius: '6px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <FaLock size={36} style={{ color: 'gray' }} />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'flex-end',
                      marginBottom: '5px',
                    }}
                  >
                    <h6
                      style={{
                        fontSize: '1.25rem',
                        fontWeight: '500',
                        marginBottom: '0px',
                      }}
                    >
                      Protect Department Protocol Set
                    </h6>
                  </div>
                </div>
              )}
            </>
          )}
          {list.length === 0 && !isProtectingDepartment && (
            <>
              <hr
                style={{
                  margin: '1.5rem 25%',
                }}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '0.5rem',
                  marginBottom: '0.5rem',
                }}
              >
                <h6
                  style={{
                    fontSize: '1.0rem',
                    fontWeight: '700',
                    marginBottom: '0px',
                  }}
                >
                  Or
                </h6>
              </div>
              <hr
                style={{
                  margin: '1.5rem 25%',
                }}
              />
            </>
          )}
          {!isProtectingDepartment && (
            <>
              <Row
                style={{
                  flex: 1,
                }}
              >
                <h6
                  style={{
                    fontSize: '1.0rem',
                    fontWeight: '700',
                    marginTop: '16px',
                    marginBottom: '16px',
                  }}
                >
                  Protect specific folders and protocols: {list.length} items
                </h6>
                <Col
                  sm={6}
                  style={{
                    borderRight: '1px solid #E0E0E0',
                  }}
                >
                  <SearchableDropdown<CategoryItem>
                    id="searchDropdown"
                    options={searchableCategories}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    keyField={(option) => option.uid}
                    onChange={(option: CategoryItem) => {
                      handleOptionClicked(option);
                    }}
                    multiSelect={true}
                    placeholder="Search folders..."
                    containerStyle={{ width: '100%' }}
                    // notFoundText="No folder found..."
                    // itemClassName="search-item"
                  />
                </Col>
                <Col sm={6}>
                  <SearchableDropdown<ProtocolItem>
                    id="searchDropdown"
                    options={searchableProtocols}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    keyField={(option) => option.uid}
                    onChange={(option: ProtocolItem) => {
                      handleOptionClicked(option);
                    }}
                    multiSelect={true}
                    placeholder="Search protocols..."
                    containerStyle={{ width: '100%' }}
                    // notFoundText="No protocols found..."
                    // itemClassName="search-item"
                  />
                </Col>
              </Row>
              {list.length > 0 && (
                <div
                  className="list-container"
                  style={{
                    marginTop: '20px',
                  }}
                >
                  <ViewportList items={list}>
                    {(item: CategoryItem | ProtocolItem, index: any) => {
                      return (
                        <div
                          key={index}
                          className="hoverable"
                          style={{
                            cursor: 'pointer',
                            display: 'flex',
                            flexDirection: 'row',
                            padding: '10px 10px',
                            borderTopLeftRadius: index === 0 ? '6px' : '0px',
                            borderTopRightRadius: index === 0 ? '6px' : '0px',
                            borderBottomLeftRadius:
                              index === list.length - 1 ? '6px' : '0px',
                            borderBottomRightRadius:
                              index === list.length - 1 ? '6px' : '0px',
                            borderBottom:
                              index !== list.length - 1
                                ? '1px solid #E0E0E0'
                                : 'none',
                          }}
                        >
                          {(item as ProtocolItem).parent ? (
                            <IoDocumentOutline
                              size="1.5rem"
                              style={{ marginRight: '10px' }}
                            />
                          ) : (
                            <FaFolderOpen
                              size="1.5rem"
                              style={{ marginRight: '10px' }}
                            />
                          )}
                          <h6
                            className="departmentItemText"
                            style={{ fontSize: '1.0rem', flex: 1 }}
                          >
                            {item.name}
                            {item.status !== 'ACTIVE' && (
                              <Status status={item.status} />
                            )}
                          </h6>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              flex: 1,
                            }}
                          >
                            <FaTimes
                              size="1rem"
                              className="icon-cancel"
                              style={{ marginRight: '10px' }}
                              onClick={() => {
                                let l = [...list];
                                l.splice(index, 1);
                                setList(l);
                              }}
                            />
                          </div>
                        </div>
                      );
                    }}
                  </ViewportList>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KeychainEdit;
