import 'primeicons/primeicons.css';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AiOutlineFileText } from 'react-icons/ai';
import { FaChevronRight, FaFolderOpen } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { handleDataTableRowSelection } from '../../../../store/actions';
import {
  findDepartmentOwner,
  getDepartmentsFromState,
  getFormattedDate,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import CategoryItem from '../../../../data/model/CategoryItem';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { User } from '../../../../models';
import {
  DragDropContext,
  DragUpdate,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { ViewportList } from 'react-viewport-list';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import {
  findAfterIndexAndName,
  findBeforeIndexAndName,
} from '../../review/reviewComparsion/DoseIndexComparsion';
import HighlightChanges from '../HighlightChanges';
import { IoDocumentText } from 'react-icons/io5';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import { FaLock } from 'react-icons/fa6';
import { globals } from '../../../_global/common/Utils';

// Helper function to get the clientY position of a DOM element
const getClientYPosition = (draggableId: string) => {
  const draggableElement = document.querySelector(
    `[data-rbd-drag-handle-draggable-id='${draggableId}']`
  );
  if (draggableElement) {
    const { top, bottom } = draggableElement.getBoundingClientRect();
    return (top + bottom) / 2; // Get the vertical midpoint of the dragged element
  }
  return null;
};

// Custom auto-scroll function using requestAnimationFrame
const autoScroll = (event: DragUpdate) => {
  const scrollableContainer = document.querySelector('.protocolListContainer'); // Adjust selector as needed
  if (!scrollableContainer) return;

  const threshold = 100; // Distance from top or bottom to start scrolling
  const maxScrollSpeed = 500; // Maximum speed for scrolling
  const minScrollSpeed = 10; // Minimum speed for scrolling
  const { destination, draggableId } = event;

  // Early exit if there is no destination (i.e., dragging outside droppable area)
  if (!destination) return;

  // Get the clientY position of the dragged item
  const clientY = getClientYPosition(draggableId);
  if (clientY === null) return;

  // Get container's bounding rectangle
  const { top, bottom } = scrollableContainer.getBoundingClientRect();

  // Determine the speed based on proximity to edges
  let scrollSpeed = 0;
  if (clientY - top < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (clientY - top) / threshold),
      minScrollSpeed
    );
  } else if (bottom - clientY < threshold) {
    scrollSpeed = Math.max(
      maxScrollSpeed * (1 - (bottom - clientY) / threshold),
      minScrollSpeed
    );
  }

  // Scroll the container using requestAnimationFrame for smoother performance
  const scroll = () => {
    if (scrollSpeed !== 0) {
      scrollableContainer.scrollBy({
        top: scrollSpeed > 0 ? scrollSpeed : -scrollSpeed,
      });
      requestAnimationFrame(scroll);
    }
  };

  requestAnimationFrame(scroll);
};
interface ProtocolDataTableProps {
  protocolData: (CategoryItem | ProtocolItem)[];
  keepBefore?: boolean;
  previousItems?: ProtocolItem[] | null;
  changeItems?: ProtocolItem[] | null;
  showAllItems?: boolean;
}

/* 10-10-2023 Arul: Created the seperate datatable component for Edit Folder Page*/
const ProtocolDataTableComparison: React.FC<ProtocolDataTableProps> = ({
  protocolData,
  keepBefore,
  previousItems,
  changeItems,
  showAllItems,
}) => {
  const [folderData, setFolderData] = useState<any>([]);
  // const tableData = useSelector((state: any) => state?.protocol?.editDatatable);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [isHovered, setIsHovered] = useState<string | null>(null);
  const [isSelected, setIsSelected] = useState<boolean>(false);

  /* 10-20-2023 Arul: Mapping the table data in setstate*/
  useEffect(() => {
    if (protocolData?.length) {
      setFolderData(protocolData);
    } else {
      setFolderData([]);
    }
  }, [protocolData]);

  useEffect(() => {
    setIsSelected(showAllItems ?? false);
  }, [showAllItems]);

  const filterProtocolData = useMemo(() => {
    if (isSelected) {
      return folderData.filter((item: CategoryItem | ProtocolItem) => {
        const beforeText = findBeforeIndexAndName(
          item as ProtocolItem,
          previousItems as ProtocolItem[]
        );
        const afterText = findAfterIndexAndName(
          item as ProtocolItem,
          changeItems as ProtocolItem[]
        );
        return beforeText !== afterText;
      });
    }
    return folderData;
  }, [folderData, isSelected, previousItems, changeItems]);

  return (
    <div>
      <div
        className="editDataTableHeader"
        style={{
          display: 'flex',
          padding: '0px 20px',
          fontSize: '12px',
        }}
      >
        <span
          style={{
            flex: 1,
          }}
        ></span>
        <span style={{ textAlign: 'start', flex: 10 }}>Name</span>
      </div>
      <DragDropContext onDragUpdate={autoScroll} onDragEnd={() => {}}>
        <Droppable droppableId="droppable">
          {(provided: any) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="protocolListContainer"
              style={{ fontSize: '12px' }}
            >
              {filterProtocolData.map(
                (item: CategoryItem | ProtocolItem, index: any) => {
                  const beforeText = findBeforeIndexAndName(
                    item as ProtocolItem,
                    previousItems as ProtocolItem[]
                  );
                  const afterText = findAfterIndexAndName(
                    item as ProtocolItem,
                    changeItems as ProtocolItem[]
                  );

                  return (
                    <Draggable
                      key={item.uid}
                      draggableId={item.uid}
                      isDragDisabled={true}
                      index={index}
                    >
                      {(provided: any) => {
                        return (
                          <li
                            key={index}
                            onMouseEnter={() => setIsHovered(item.uid)}
                            onMouseLeave={() => setIsHovered(null)}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`cursorPointer row_unselected spanFormat ${index === folderData.length - 1 ? '' : 'underline'}`}
                            style={{
                              ...provided.draggableProps.style,
                              padding: '0px 20px',
                              minHeight: '30px',
                              backgroundColor:
                                isHovered === item.uid ? '#f3f7f2' : undefined,
                              display: 'flex',
                              //7-10-24 Hazlett: PSA Cannot do grid format and a draggable item hd to switch to flex
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                              }}
                            >
                              {item instanceof CategoryItem ? (
                                <FaFolderOpen
                                  className="table-icon"
                                  style={{
                                    marginBottom: '4px',
                                  }}
                                  color={'#636363'}
                                />
                              ) : (
                                <IoDocumentText
                                  className="table-icon"
                                  style={{ margin: '0 4px' }}
                                />
                              )}
                            </div>

                            <div
                              className="titleItemContainer"
                              style={{
                                flex: 10,
                              }}
                            >
                              <div className="titleItemText">
                                <div>
                                  {keepBefore ? (
                                    <HighlightChanges
                                      beforeText={afterText}
                                      afterText={beforeText}
                                    />
                                  ) : (
                                    // <HighlightChanges
                                    //   beforeText={beforeText}
                                    //   afterText={afterText}
                                    // />
                                    afterText
                                  )}
                                </div>
                              </div>
                              {item.status !== 'ACTIVE' && (
                                <Status status={item.status} />
                              )}
                            </div>
                          </li>
                        );
                      }}
                    </Draggable>
                  );
                }
              )}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ProtocolDataTableComparison;
