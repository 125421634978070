//Create a search bar.tsx component that takes in a search function and a placeholder text as props. The search bar should call the search function when the user presses the enter key.
//

import React, { useState, useEffect } from 'react';

import './SearchBar.scss';
import { Button, InputGroup } from 'react-bootstrap';
import { IoClose, IoSearch } from 'react-icons/io5';

interface Props {
  value: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onChange: (searchTerm: string, e: any) => void;
  onSubmit?: (searchTerm: string) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

const SearchBar = ({
  value,
  style,
  containerStyle,
  onChange,
  onSubmit,
  onBlur,
  onFocus,
  placeholder,
  autoFocus,
}: Props) => {
  const [searchTerm, setSearchTerm] = useState<string>(value);
  const [focus, setFocus] = useState<boolean>(false);
  const ref = React.createRef<HTMLInputElement>();

  // useEffect(() => {
  //     onChange(searchTerm);
  // }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(value);
  }, [value]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSubmit) onSubmit(searchTerm);
  };

  return (
    <div
      className={`search-bar ${focus ? 'with-focus' : ''}`}
      style={containerStyle}
      onClick={(e) => {
        if (ref.current) ref.current.focus();
      }}
    >
      <InputGroup style={{ zIndex: 0 }} className="input-group-navbar">
        <Button variant="">
          <IoSearch size="1.5rem" color={focus ? '#00534C' : '#616161'} />
        </Button>
        <input
          style={{ ...style, flex: 1 }}
          ref={ref}
          placeholder={placeholder ? placeholder : 'Search...'}
          aria-label="Search"
          value={searchTerm}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onChange={(e) => {
            onChange(e.target.value, e);
          }}
          autoFocus={autoFocus}
          onKeyDown={handleKeyPress}
          className="searchInput"
          onFocus={() => {
            setFocus(true);
            if (onFocus) onFocus(searchTerm);
          }}
          onBlur={() => {
            setFocus(false);
            if (onBlur) onBlur(searchTerm);
          }}
        />
        {searchTerm && (
          <Button
            className="clickable"
            variant=""
            onClick={(e) => onChange('', e)}
          >
            <IoClose size="1.5rem" />
          </Button>
        )}
      </InputGroup>
    </div>
  );
};

export default SearchBar;
