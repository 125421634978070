import { useFormik } from 'formik';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { handleSetSelectedDoseValue } from '../../../../store/actions';
import AddRouteModal from '../../Modal/AddRouteModal';
import CalculationBasisDialog from '../../Modal/CalculationBasisModal';
import ConfirmWarningModal from '../../Modal/ConfirmWarningModal';
import DoseFixedCalcDialogModal from '../../Modal/DoseFixedCalcDialogModal';
import EquipmentVitalValueModal from '../../Modal/EquipmentVitalValueModal';
import RangeDialog from '../../Modal/RangeDialogModal';
import RouteDialog from '../../Modal/RouteDialogModal';
import DoseSelection from '../../dose/DoseSelection';

import * as _ from 'lodash';
import ConfirmModal from '../../Modal/ConfirmModal';
import { EquipmentOption, User, VitalOption } from '../../../../models';
import { findDepartmentOwner, globals } from '../../../_global/common/Utils';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import VitalItem from '../../../../data/model/VitalItem';
import { BiArrowBack } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import SearchableDropdown from '../../SearchableDropdown';
import { DatabaseResponse } from '../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { ProgressStatus } from '../../../../models';
import { UserType } from '../../../../models';
import CopyToClipboard from '../../CopyToClipboard';

interface ProtocolDoseSideoutProps {
  dose?:
    | MedicationSubItem
    | ElectricalSubItem
    | EquipmentOption
    | VitalOption
    | any;
  visible: boolean;
  setVisible: (visible: boolean) => void;
  editDose?: boolean;
  modalName?: string;
  type: 'Medication' | 'Electrical' | 'Equipment' | 'Vital' | 'Infusion';
  protocol: ProtocolItem | null;
  allProtocols?: ProtocolItem[];
  parentModel:
    | MedicationItem
    | ElectricalItem
    | EquipmentItem
    | VitalItem
    | any;
  adminRoutes?: string[];
  doseIndex?: any;
  onSideOutClose?: any;
  onDoseCreationComplete?: () => void;
  onRemove?: (dose: any) => void;
  onSetNewRoutes?: (newRoutes: string[]) => void;
  onSubmit?: (
    dose: any,
    prevDose?:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | any,
    parentProtocol?: ProtocolItem
  ) => void;
}

/*10-16-23 Praveen:  Created common component for Edit and New Dose section sideout view*/
const ProtocolDoseSideout: React.FC<ProtocolDoseSideoutProps> = ({
  type,
  doseIndex,
  protocol,
  parentModel,
  adminRoutes,
  modalName,
  dose,
  visible,
  setVisible,
  editDose,
  onSubmit,
  onSideOutClose,
  onDoseCreationComplete,
  onSetNewRoutes,
  onRemove,
}) => {
  const dispatch = useDispatch();
  const reducerState = useSelector((state: any) => state.department);
  const [maxDialogVisible, setMaxDialogVisible] = useState(false);
  const [minDialogVisible, setMinDialogVisible] = useState(false);
  const [lowRangeDialogVisible, setLowRangeDialogVisible] = useState(false);
  const [highRangeDialogVisible, setHighRangeDialogVisible] = useState(false);
  const [calculationDialogVisible, setCalculationDialogVisible] =
    useState(false);
  const [routeDialogVisible, setRouteDialogVisible] = useState(false);
  const [newRouteDialogVisible, setNewRouteDialogVisible] = useState(false);
  const [isConfirmModal, setIsConfirmMOdal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [calculatedMaxDialogVisible, setCalculatedMaxDialogVisible] =
    useState(false);
  const [fixedMaxDialogVisible, setFixedMaxDialogVisible] = useState(false);
  const [equipmentValueModalVisible, setEquipmentValueModalVisible] =
    useState(false);
  const user: User = useSelector((state: any) => state.user);

  const [deleteData, setDeleteData] = useState<any>(null);
  const [modalType, setModalType] = useState('');
  const [editData, setEditData] = useState(null);
  const [routeModal, setRouteModal] = useState<any>(null);

  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    database.protocols.sort((a, b) => a.name.localeCompare(b.name))
  );
  const [parentProtocol, setParentProtocol] = useState<ProtocolItem | null>(
    protocol
  );
  const isDeactivated = useMemo(() => {
    if (
      parentModel &&
      parentModel.status === ProgressStatus.DEACTIVATED &&
      parentModel.overrideItem &&
      parentModel.overrideItem.departmentID !== database.department.id
    )
      return true;
    return false;
  }, [parentModel, database.department]);

  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    parentModel
      ? findDepartmentOwner(
          database.department,
          reducerState,
          isDeactivated && parentModel.overrideItem
            ? parentModel.overrideItem
            : parentModel
        )
      : null
  );

  const isOwner = useMemo(() => {
    return (
      departmentOwner == null || departmentOwner.id === database.department.id
    );
  }, [departmentOwner, database.department]);

  const [edittable, setEdittable] = useState<boolean>(isOwner);

  useEffect(() => {
    if (parentModel && database.department) {
      let departmentOwner = findDepartmentOwner(
        database.department,
        reducerState,
        isDeactivated && parentModel.overrideItem
          ? parentModel.overrideItem
          : parentModel
      );
      setDepartmentOwner(departmentOwner);

      setEdittable(isOwner);
    }
  }, [parentModel, database.department, isOwner, isDeactivated, reducerState]);

  // const selectedDose = useSelector(
  // 	(state: any) => state.protocol.dose.selectedDose
  // );
  // const editProtocol = useSelector(
  // 	(state: any) => state?.protocol?.editProtocol
  // );
  // const selectedProtocol = useSelector(
  // 	(state: any) => state.protocol.selectedProtocol
  // );
  // const pr = useSelector((state: any) => state.protocol);
  const [isWarningModal, setIsWarningModal] = useState(false);
  // const [initialData, setIsinitialData] = useState(selectedDose);
  // const MedicationInitialData = medicationSubItemValue(
  // 	parentModel,
  // 	editProtocol
  // );

  const getRange = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined,
    isLow: boolean
  ): string => {
    if (!initialValue) return '';
    let range = isLow ? initialValue?.rangeLow : initialValue?.rangeHigh;
    if (range === 0 || range === globals.MAX_VALUE) return '';
    return '' + range;
  };

  const getRoutes = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string[] => {
    if (initialValue && (type === 'Medication' || type === 'Infusion'))
      return (initialValue as MedicationSubItem).routes;
    return [];
  };

  const getCalculationbasis = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string => {
    if (!initialValue) return '+Add';
    if (type === 'Medication')
      return (initialValue as MedicationSubItem).model.basis;
    else if (type === 'Electrical')
      return (initialValue as ElectricalSubItem).model.basis;
    else if (type === 'Infusion')
      return (initialValue as MedicationSubItem).model.basis;
    return '+Add';
  };

  const getValue = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined,
    valueType: 'LOW' | 'HIGH'
  ): string | object | any => {
    if (!initialValue) return '+Add';
    if (type === 'Equipment') {
      let e = initialValue as EquipmentOption;
      if (valueType === 'LOW') return e.amnt;
      else return e.amntHigh;
    } else if (type === 'Vital') {
      let v = initialValue as VitalOption;
      if (valueType === 'LOW') return v.amntLow;
      else return v.amntHigh;
    }
    return '';
  };

  const getMinMaxDose = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined,
    _type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
  ): string => {
    if (!initialValue) return '';
    if (type === 'Medication') {
      let m = initialValue as MedicationSubItem;
      if (_type === 'MIN DOSE')
        return '' + (m.minDose === 0 ? '' : m.model.minDose);
      else if (_type === 'MAX_DOSE')
        return '' + (m.maxDose === globals.MAX_VALUE ? '' : m.model.maxDose);
      else if (_type === 'CALC_MAX') return '' + m.model.calcMax;
      return '';
    } else if (type === 'Electrical') {
      let e = initialValue as ElectricalSubItem;
      if (_type === 'FIXED_MAX')
        return '' + (e.maxDose === globals.MAX_VALUE ? '' : e.model.maxDose);
      else if (_type === 'CALC_MAX')
        return '' + (e.calcMax === globals.MAX_VALUE ? '' : e.model.calcMax);
      return '';
    }
    return '';
  };

  const getTitle = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string => {
    if (!initialValue) return '';
    if (type === 'Medication' || type === 'Infusion')
      return (initialValue as MedicationSubItem).title;
    else if (type === 'Electrical')
      return (initialValue as ElectricalSubItem).model.title ?? '';
    return '';
  };

  const getWarning = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string => {
    if (!initialValue) return '';
    if (type === 'Medication' || type === 'Infusion')
      return (initialValue as MedicationSubItem).warning;
    return '';
  };

  const getInstruction = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string => {
    if (!initialValue) return '';
    if (type === 'Medication' || type === 'Infusion')
      return (initialValue as MedicationSubItem).instruction;
    return '';
  };

  const getNote = (
    initialValue:
      | MedicationSubItem
      | ElectricalSubItem
      | EquipmentOption
      | VitalOption
      | undefined
  ): string => {
    if (!initialValue) return '';
    if (type === 'Medication' || type === 'Infusion')
      return (initialValue as MedicationSubItem).note;
    return '';
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      /* Shared on all models */
      rangeLow: getRange(dose, true),
      rangeHigh: getRange(dose, false),

      /* Medication, Electrical and Infusion */
      basis: getCalculationbasis(dose),
      // parentProtocol: protocol,

      /* Medication and infusion */
      routes: getRoutes(dose),
      minDose: getMinMaxDose(dose, 'MIN DOSE'),
      maxDose: getMinMaxDose(dose, 'MAX_DOSE'),

      /* Equipment and Vital */
      valueLow: getValue(dose, 'LOW'),
      valueHigh: getValue(dose, 'HIGH'),

      /* Electrical */
      calcMax: getMinMaxDose(dose, 'CALC_MAX'),
      fixedMax: getMinMaxDose(dose, 'FIXED_MAX'),

      title: getTitle(dose),
      warning: getWarning(dose),
      instruction: getInstruction(dose),
      note: getNote(dose),
    },
    validationSchema: Yup.object({
      rangeLow: Yup.string().required(),
      rangeHigh: Yup.string().required(),
      routes: Yup.array(),
      basis: Yup.string(),
      minDose: Yup.string(),
      maxDose: Yup.string(),
      valueLow: Yup.string(),
      valueHigh: Yup.string(),
      calcMax: Yup.string(),
      fixedMax: Yup.string(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    if (visible) formik.resetForm();
  }, [visible, dose]);

  const isModalName = useMemo(() => {
    if (type === 'Medication' || type === 'Infusion') {
      return 'Dose';
    } else if (type === 'Equipment' || type === 'Vital') {
      return 'Option';
    } else if (type === 'Electrical') {
      return 'Shock';
    }
  }, [type]);

  /*10-16-23 Praveen: Range button handler*/
  const handleRangeClick = (value: any, type: any) => {
    setEditData(value);
    if (type === 'low') setLowRangeDialogVisible(!lowRangeDialogVisible);
    else setHighRangeDialogVisible(!highRangeDialogVisible);
  };

  /*10-16-23 Praveen: Dose button handler*/
  const handleDoseClick = (
    value: any,
    type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
  ) => {
    setEditData(value);
    if (type === 'MIN DOSE') setMinDialogVisible(!minDialogVisible);
    else if (type === 'MAX_DOSE') setMaxDialogVisible(!maxDialogVisible);
    else if (type === 'CALC_MAX')
      setCalculatedMaxDialogVisible(!calculatedMaxDialogVisible);
    else if (type === 'FIXED_MAX')
      setFixedMaxDialogVisible(!fixedMaxDialogVisible);
  };

  /*10-16-23 Praveen: Route handler*/
  const handleRouteClick = (value: any) => {
    setEditData(value);
    setRouteDialogVisible(!routeDialogVisible);
  };

  /*10-16-23 Praveen: Calculation basis  handler*/
  const handleCalculationBasisClick = (value: any) => {
    setEditData(value);
    setCalculationDialogVisible(!calculationDialogVisible);
  };

  /*10-16-23 Praveen: Route button for the dialog handler*/
  const handleNewRoute = () => {
    setNewRouteDialogVisible(true);
    setRouteDialogVisible(!routeDialogVisible);
  };

  const handleConfirm = (value: any) => {
    setDeleteData(value);
    setIsConfirmMOdal(true);
    setModalType('Route');
  };

  const handleValueClick = (value: any) => {
    setEditData(value);
    setEquipmentValueModalVisible(!equipmentValueModalVisible);
  };

  //Modal Update Function handling
  /*10-30-23 Arul: handling function for range popup onsubmit */
  const handleRangeset = (value: any, type: any) => {
    setLowRangeDialogVisible(false);
    setHighRangeDialogVisible(false);
    if (type === 'low') formik.setFieldValue('rangeLow', value);
    else formik.setFieldValue('rangeHigh', value);
  };

  /*10-30-23 Arul: handling function for Equipment value popup onsubmit */
  const handleSetEquipmentValue = (dataLow: string, dataHigh: string) => {
    setEquipmentValueModalVisible(false);
    // Update the formik state
    formik.setFieldValue('valueLow', dataLow);
    formik.setFieldValue('valueHigh', dataHigh);
  };

  /* 11-01-23 Praveen: handled function to set calcaulation basis popup value */
  const handleCalculationBasisset = async (newBasis: string) => {
    setCalculationDialogVisible(false);
    formik.setFieldValue('basis', newBasis);
  };

  /* 11-01-23 Praveen:  To select and unselect route value */
  const handleEditRouteset = useCallback(
    (routes: string[]) => {
      formik.setFieldValue('routes', routes);
      setRouteDialogVisible(false);
    },
    [formik]
  );

  /* 11-01-23 Praveen: To add new route value */
  const handleNewRouteset = (route: string) => {
    setNewRouteDialogVisible(false);
    if (routeModal !== 'new') {
      setRouteDialogVisible(true);
    }
    setRouteModal(null);
    const routes = (
      adminRoutes
        ? [...adminRoutes]
        : [...(parentModel as MedicationItem)?.routes]
    ) as string[];
    routes.push(route);
    onSetNewRoutes && onSetNewRoutes(routes);
  };

  /* 11-01-23 Praveen: To delete the route value */
  const handleConfirmDelete = (type: any) => {
    setIsConfirmMOdal(false);
    if (type === 'Route')
      formik.setFieldValue(
        'routes',
        formik.values.routes.filter((route: any) => route !== deleteData)
      );
  };

  //*10-30-23 Arul: handling function for create and edit dose sideout */
  const handleSubmit = async () => {
    if (onSubmit)
      onSubmit(
        formik.values,
        dose,
        parentProtocol ? parentProtocol : dose ? dose.parentProtocol : undefined
      );
    // if (onDoseCreationComplete) {
    // 	onDoseCreationComplete();
    // }
    setVisible(false);

    // if (type === "Medication" || type === "Infusion") {
    // 	if (editDose) {
    // 		handleDispatchMedicationInfusionValue("medications", "edit");
    // 	} else {
    // 		handleDispatchMedicationInfusionValue("medications", "new");
    // 	}
    // } else if (type === "Equipment" || type === "Vital") {
    // 	if (editDose) {
    // 		handleDispatchEquipmentVitalValue("equipment", "edit");
    // 	} else {
    // 		handleDispatchEquipmentVitalValue("equipment", "new");
    // 	}
    // } else if (type === "Electrical") {
    // 	if (editDose) {
    // 		handleDispatchElectricalValue("electrical", "edit");
    // 	} else {
    // 		handleDispatchElectricalValue("electrical", "new");
    // 	}
    // }
  };

  /* 11-02-23 Praveen: submit button validation*/
  const isSaveValid = useMemo(() => {
    if (type === 'Medication' || type === 'Infusion') {
      if (formik.values.routes.length === 0 || parentProtocol == null)
        return false;
      else if (formik.values.basis === '' || formik.values.basis === '+Add')
        return false;
      return formik.dirty;
    } else if (type === 'Equipment' || type === 'Vital') {
      if (formik.values.valueLow && formik.values.valueLow !== '+Add')
        return formik.dirty;
      return false;
    } else if (type === 'Electrical') {
      if (
        formik.values.basis &&
        formik.values.basis !== '+Add' &&
        parentProtocol != null
      )
        return formik.dirty;

      return false;
    }
  }, [formik, type, parentProtocol]);

  const handleCloseSideout = () => {
    if (formik.dirty) {
      setIsWarningModal(true);
    } else {
      setVisible(false);
      // onSideOutClose && onSideOutClose();
      // dispatch<any>(handleSetSelectedDoseValue(""));
    }
  };

  const handleCloseWarningModal = () => {
    setVisible(false);
    dispatch<any>(handleSetSelectedDoseValue(''));
  };

  const customHeader = () => {
    return (
      <h4 className="headerText hoverText" onClick={handleCloseSideout}>
        <span className="">
          <BiArrowBack className="header-icon" data-testid="isBackBtn" />
        </span>{' '}
        {editDose ? 'Edit' : 'Create'} {type} {isModalName}
      </h4>
    );
  };

  const handleDeleteDose = () => {
    if (onRemove) onRemove(dose);
    setIsDeleteModal(false);
    handleCloseSideout();
  };

  const Modals = () => {
    return (
      <>
        {/* Max Dose Popup
        <DoseFixedCalcDialogModal
          isVisible={maxDialogVisible}
          isCalculated={false}
          type={type.toLocaleLowerCase()}
          value={formik.values.maxDose}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'max');
          }}
          setDialogVisible={() => {
            setMaxDialogVisible(false);
            setEditData(null);
          }}
          headerText="Max Dose"
          headerTitle="Max"
          InputLabel="Max"
        /> */}

        {/* Min Dose Popup
        <DoseFixedCalcDialogModal
          dialogVisible={minDialogVisible}
          value={formik.values.minDose}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'min');
          }}
          setDialogVisible={() => {
            setMinDialogVisible(false);
            setEditData(null);
          }}
          headerText="Min Dose"
          headerTitle="Min"
          InputLabel="Min"
        /> */}

        {/* Calculated Max Popup
        <DoseFixedCalcDialogModal
          dialogVisible={calculatedMaxDialogVisible}
          value={formik.values.calcMax}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'calcMax');
          }}
          setDialogVisible={() => {
            setCalculatedMaxDialogVisible(false);
            setEditData(null);
          }}
          headerText="Calculated Max"
          headerTitle="calcMax"
          InputLabel="Max"
        /> */}

        {/* Fixed Max Popup
        <DoseFixedCalcDialogModal
          dialogVisible={fixedMaxDialogVisible}
          value={formik.values.fixedMax}
          handleSet={(e: any) => {
            handleDoseFixedCalcset(e, 'fixedMax');
          }}
          setDialogVisible={() => {
            setFixedMaxDialogVisible(false);
            setEditData(null);
          }}
          headerText="Fixed Max"
          headerTitle="fixedMax"
          InputLabel="Max"
        /> */}

        {/*Low Range Popup */}
        <RangeDialog
          dialogVisible={lowRangeDialogVisible}
          type={type}
          value={formik.values.rangeLow}
          handleSet={(e: any) => {
            handleRangeset(e, 'low');
          }}
          setDialogVisible={() => {
            setLowRangeDialogVisible(false);
            setEditData(null);
          }}
          headerText="Low Range"
          headerTitle="Lower"
          InputLabel="LOWER"
        />

        {/* High Range  Popup */}
        <RangeDialog
          dialogVisible={highRangeDialogVisible}
          type={type}
          value={formik.values.rangeHigh}
          handleSet={(e: any) => {
            handleRangeset(e, 'high');
          }}
          setDialogVisible={() => {
            setHighRangeDialogVisible(false);
            setEditData(null);
          }}
          headerText="High Range"
          headerTitle="Upper"
          InputLabel="UPPER"
        />

        {/*Confirm and Warning Popup */}
        <ConfirmWarningModal
          isVisible={isConfirmModal}
          type={modalType}
          handleClose={() => {
            setIsConfirmMOdal(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        {/* Edit Route Popup */}
        {routeDialogVisible && (
          <RouteDialog
            visible={routeDialogVisible}
            // routeItem={addedRoutesList}
            availableRoutes={adminRoutes ?? parentModel?.routes}
            selectedRoutes={formik.values.routes}
            onClose={() => {
              setRouteDialogVisible(false);
              setEditData(null);
            }}
            handleNewRoute={handleNewRoute}
            handleSet={(routes: string[]) => {
              handleEditRouteset(routes);
            }}
          />
        )}

        {/* Add Route Popup */}
        <AddRouteModal
          dialogVisible={newRouteDialogVisible}
          handleSet={handleNewRouteset}
          onNewRouteCancel={() => {
            setNewRouteDialogVisible(false);
            routeModal !== 'new' && setRouteDialogVisible(true);
            setRouteModal('');
          }}
          headerText="New Administration Route"
        />

        {/* Equipment Vital Value Popup */}
        <EquipmentVitalValueModal
          type={type}
          dialogVisible={equipmentValueModalVisible}
          handleSet={handleSetEquipmentValue}
          doseIndex={doseIndex}
          dataLow={
            dose
              ? (dose as EquipmentOption)?.amnt ||
                (dose as VitalOption)?.amntLow
              : formik?.values?.valueLow
          }
          dataHigh={
            dose
              ? (dose as EquipmentOption)?.amntHigh
              : dose?.amntHigh
                ? dose?.amntHigh
                : formik?.values?.valueHigh
          }
          setDialogVisible={() => {
            setEquipmentValueModalVisible(false);
            setEditData(null);
          }}
          headerText="Equipment Value"
        />

        {/* CalculationBasis Popup */}
        <CalculationBasisDialog
          dialogVisible={calculationDialogVisible}
          type={type}
          handleSet={handleCalculationBasisset}
          basis={formik.values.basis}
          setDialogVisible={() => {
            setCalculationDialogVisible(false);
          }}
          headerText="Calculation Basis"
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleCloseWarningModal}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this ${type} ${isModalName}.  Click cancel to return to ${type} ${isModalName} details.  To continue without saving changes, select Abandon Changes.`}
          />
        )}

        {isDeleteModal && (
          <ConfirmModal
            isVisible={isDeleteModal}
            title="Confirm Delete?"
            handleClose={() => {
              setIsDeleteModal(false);
            }}
            handleSubmit={handleDeleteDose}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Delete"
            primaryDescription={` Are you sure you want to delete this ${type} ${isModalName}.`}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <Modals />

      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => {
          if (!formik.dirty) handleCloseSideout();
        }}
        style={{
          minWidth: '600px',
        }}
        className="sidebarWidth"
      >
        <div style={{ padding: '0px 15px' }}>
          <p className="sidebarText">
            {dose ? 'Edit' : 'Create'} the information for{' '}
            {protocol ? protocol.name : ''} {type?.toLocaleLowerCase()}'s dose.
          </p>
          <p className="sidebarText">
            Need more information: <a className="sidebarLink">Learn More</a>
          </p>
        </div>
        {type !== 'Vital' && type !== 'Equipment' && !protocol && (
          <div className="" style={{ padding: '0px 15px' }}>
            <div style={{ marginTop: '15px' }}>
              <span
                className="ketamine-general-label"
                style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
              >
                Protocol
              </span>
              <p className="sidebarText">
                This is the protocol that this dose will belong to.
              </p>
            </div>
            <SearchableDropdown<ProtocolItem>
              id="searchDropdown"
              value={parentProtocol ? parentProtocol : undefined}
              options={allProtocols}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.uid}
              onChange={(option: ProtocolItem) => {
                setParentProtocol(option);
              }}
              onClear={() => {
                setParentProtocol(null);
              }}
              isActive={protocol ? false : true}
              placeholder="Search protocol..."
            />
          </div>
        )}
        <div className="siderbarContent">
          <div>
            <div style={{ marginTop: '15px' }}>
              <span className="ketamine-general-label">
                {isModalName} {doseIndex + 1}
                {user.type === UserType.ADMIN && dose?.id && (
                  <span
                    style={{
                      marginLeft: '5px',
                      fontSize: '12px',
                      color: 'gray',
                      fontWeight: 'normal',
                      marginTop: '2px',
                    }}
                  >
                    ID: {dose.id}
                    <CopyToClipboard text={dose.id} />
                  </span>
                )}
              </span>
            </div>

            <div className="sidebarInputContainer">
              <DoseSelection
                type={type}
                formik={formik}
                doseIndex={doseIndex}
                parent={dose ? dose : '+ADD'}
                onRangeClick={(
                  range: string,
                  isLow: boolean,
                  isVital: boolean
                ) => {
                  handleRangeClick(range, isLow ? 'low' : 'high');
                }}
                onBasisClick={(
                  basis: string,
                  parent: MedicationItem | ElectricalItem
                ) => {
                  setCalculationDialogVisible(true);
                }}
                onValueClick={(
                  value: string,
                  parent: EquipmentItem | VitalItem
                ) => {
                  handleValueClick(value);
                }}
                onMinMaxDoseClick={(
                  value: string,
                  type: 'MIN DOSE' | 'MAX_DOSE' | 'CALC_MAX' | 'FIXED_MAX'
                ) => {
                  handleDoseClick(value, type);
                }}
                onRoutesClick={(routes: string[]) => {
                  // setRoute(routes.join(','));
                  setRouteDialogVisible(true);
                }}
                onSingleRouteClick={(route: string, isDelete: boolean) => {
                  if (isDelete) handleConfirm(route);
                }}
                handleSubmit={(dose: any) => {}}
                disabled={!edittable}
              />
            </div>
          </div>
        </div>
        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleCloseSideout}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>
          {editDose && isOwner && (
            <Button
              className="red-background-button btn-rightMargin "
              data-testid="deleteBtn"
              onClick={() => {
                setIsDeleteModal(true);
              }}
            >
              Delete
            </Button>
          )}
          {isOwner && (
            <Button
              className="primary-button"
              data-testid="saveBtn"
              onClick={handleSubmit}
              disabled={!isSaveValid}
            >
              <span>
                <AiOutlinePlus className="icon-normal" />
              </span>{' '}
              {editDose ? 'Save' : 'Create'}
            </Button>
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default ProtocolDoseSideout;
