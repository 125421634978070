import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { BiSolidRightArrow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  IoArrowBack,
  IoDocuments,
  IoHeart,
  IoMedkit,
  IoWater,
} from 'react-icons/io5';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import {
  FaBell,
  FaListCheck,
  FaSyringe,
  FaUser,
  FaDatabase,
} from 'react-icons/fa6';
import { outputAllDataStoreValues } from '../../../../../data/AmplifyDB';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const IndexesPage = (props: any) => {
  const navigate = useNavigate();

  /* Uncomment when the are finished */
  const actionList = [{ name: 'Contacts', icon: <FaUser /> }];

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (item: any) => {
    navigate(`/actions/indexes/${item.name.toLowerCase()}`);
  };

  const handleBack = () => {
    navigate(`/actions`);
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Actions'}
        name={'Indexes'}
        description={'Ready to unleash your inner data wizard?'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <div className="grid-container">
        {actionList.map((item, index) => {
          return (
            <div
              key={index}
              className="grid-item"
              onClick={() => handleItemClick(item)}
            >
              <div className="item-name">{item.name}</div>
              {item.icon}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default IndexesPage;
