import * as APITypes from '../API';
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDepartment = /* GraphQL */ `query GetDepartment($id: ID!) {
  getDepartment(id: $id) {
    id
    name
    location
    protocolVersions
    shiftTypes
    cognitoID
    logoID
    userID
    neonateCutoff
    pediatricCutoff
    calculators
    adultRanges {
      label
      value
      __typename
    }
    softwarePlan
    subDepIDs
    parentDepID
    indexedParentDepID
    activeStatus
    agencyNumEMS
    stateIdEMS
    gnisCodeEMS
    uniqueCode
    uniquePublicURL
    hashedPin
    saltedPin
    keychainID
    isPublic
    infusionCalculation
    isPublicSignup
    config {
      isTopEnabled
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      infusionCalculation
      isPublic
      realTimeUpdating
      epcrProvider
      oneweightEnabled
      ageFilterEnabled
      ageGroupFilterEnabled
      renewalDate
      infusionGTTS
      signedAgreement
      isToggleAdvancedFiltersEnabled
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    departmentRequireSyncId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDepartmentQueryVariables,
  APITypes.GetDepartmentQuery
>;

export const departmentsByUniquePublicURL =
  /* GraphQL */ `query DepartmentsByUniquePublicURL(
  $uniquePublicURL: String!
  $sortDirection: ModelSortDirection
  $filter: ModelDepartmentFilterInput
  $limit: Int
  $nextToken: String
) {
  departmentsByUniquePublicURL(
    uniquePublicURL: $uniquePublicURL
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      location
      protocolVersions
      shiftTypes
      cognitoID
      logoID
      userID
      neonateCutoff
      pediatricCutoff
      calculators
      adultRanges {
        label
        value
        __typename
      }
      softwarePlan
      subDepIDs
      parentDepID
      indexedParentDepID
      activeStatus
      agencyNumEMS
      stateIdEMS
      gnisCodeEMS
      uniqueCode
      uniquePublicURL
      hashedPin
      saltedPin
      keychainID
      isPublic
      infusionCalculation
      isPublicSignup
      config {
        isTopEnabled
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        infusionCalculation
        isPublic
        realTimeUpdating
        epcrProvider
        oneweightEnabled
        ageFilterEnabled
        ageGroupFilterEnabled
        renewalDate
        infusionGTTS
        signedAgreement
        isToggleAdvancedFiltersEnabled
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      departmentRequireSyncId
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.DepartmentsByUniquePublicURLQueryVariables,
    APITypes.DepartmentsByUniquePublicURLQuery
  >;

export const departmentsByParentDepID =
  /* GraphQL */ `query DepartmentsByParentDepID(
    $indexedParentDepID: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    departmentsByParentDepID(
      indexedParentDepID: $indexedParentDepID
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        location
        protocolVersions
        shiftTypes
        cognitoID
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          adultRanges {
            label
            value
            __typename
          }
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
          isToggleAdvancedFiltersEnabled
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
  ` as GeneratedQuery<
    APITypes.DepartmentsByParentDepIDQueryVariables,
    APITypes.DepartmentsByParentDepIDQuery
  >;

export const listDepartments = /* GraphQL */ `query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        location
        protocolVersions
        shiftTypes
        cognitoID
        logoID
        userID
        neonateCutoff
        pediatricCutoff
        calculators
        adultRanges {
          label
          value
          __typename
        }
        softwarePlan
        subDepIDs
        parentDepID
        indexedParentDepID
        activeStatus
        agencyNumEMS
        stateIdEMS
        gnisCodeEMS
        uniqueCode
        uniquePublicURL
        hashedPin
        saltedPin
        keychainID
        isPublic
        infusionCalculation
        isPublicSignup
        config {
          isTopEnabled
          neonateCutoff
          pediatricCutoff
          calculators
          adultRanges {
            label
            value
            __typename
          }
          softwarePlan
          infusionCalculation
          isPublic
          realTimeUpdating
          epcrProvider
          oneweightEnabled
          ageFilterEnabled
          ageGroupFilterEnabled
          renewalDate
          infusionGTTS
          signedAgreement
        isToggleAdvancedFiltersEnabled
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        departmentRequireSyncId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
  ` as GeneratedQuery<
  APITypes.ListDepartmentsQueryVariables,
  APITypes.ListDepartmentsQuery
>;

export const notificationsByDepartmentID =
  /* GraphQL */ `query NotificationsByDepartmentID(
  $departmentID: ID!
  $timestamp: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
) {
  notificationsByDepartmentID(
    departmentID: $departmentID
    timestamp: $timestamp
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      type
      title
      message
      timestamp
      expirationTimestamp
      deadlineTimestamp
      isReadIDs
      isAckIDs
      imageURLs
      videoURLs
      fileURLs
      taggedProtocols
      questions {
        question
        options
        answer
        taggedProtocol
        __typename
      }
      formQuestions {
        index
        type
        question
        description
        answers
        correctAnswer
        __typename
      }
      isPush
      departmentID
      pairedDepIDs
      Groups {
        items {
          id
          notificationId
          groupId
          group {
            id
            _deleted
            _lastChangedAt
            _version
            createdAt
            departmentID
            isAutoGen
            name
            pairedDepIDs
            pairedUserIDs
            type
            updatedAt
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdBy
      modifiedBy
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.NotificationsByDepartmentIDQueryVariables,
    APITypes.NotificationsByDepartmentIDQuery
  >;
