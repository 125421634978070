import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import { getDepartmentByID } from '../../../../data/functions/DepartmentDB';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import './Comparison.scss';
import { diffWords } from 'diff';
import HighlightChangesRow from '../HighlightChangesRow';
interface FolderComparisonProps {
  focusItem: ProtocolItem;
  changes: any[];
}

const PROTOCOL_DATA = [
  {
    keys: ['name'],
    titles: ['Name'],
  },
  {
    keys: ['nickname'],
    titles: ['Nickname'],
  },
  {
    keys: ['rangeLow', 'rangeHigh'],
    titles: ['Range Low (kg)', 'Range High (kg)'],
  },
];

const ProtocolComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
}) => {
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [beforeDeps, setBeforeDeps] = useState<DepartmentItem[]>([]);
  const [afterDeps, setAfterDeps] = useState<DepartmentItem[]>([]);
  const [allDeps, setAllDeps] = useState<DepartmentItem[]>([]);
  const [depStats, setDepStats] = useState<{
    new: DepartmentItem[];
    removed: DepartmentItem[];
    same: DepartmentItem[];
  }>({
    new: [],
    removed: [],
    same: [],
  });

  const [filteredByStat, setFilteredByStat] = useState<string[]>([]);

  const handleGetDepartments = async (
    ids: string[]
  ): Promise<DepartmentItem[]> => {
    let promises: Promise<DepartmentItem | undefined>[] = [];
    for (let i = 0; i < ids.length; i++)
      promises.push(getDepartmentByID(ids[i].trim()));

    const departmentResults = await Promise.all(promises);
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < departmentResults.length; i++) {
      const department = departmentResults[i];
      if (department) deps.push(department);
    }
    return deps;
  };

  useEffect(() => {
    const getDepartments = async () => {
      // Fetch names for "before" value
      let beforeDeps: DepartmentItem[] = [];
      let afterDeps: DepartmentItem[] = [];
      const beforeIdString = findBeforeValue(changes, 'pairedDepIDs');

      const beforeIds = beforeIdString ? beforeIdString.split(',').sort() : [];
      if (beforeIds) beforeDeps = await handleGetDepartments(beforeIds);

      // Fetch names for "after" value
      const afterIdString = findCurrentValue(changes, 'pairedDepIDs');
      const afterIds = afterIdString ? afterIdString.split(',').sort() : [];
      if (afterIds) afterDeps = await handleGetDepartments(afterIds);

      /* Organize the departments, New Ones on the top, Removed Ones in second and the same ones last */
      const newDeps = afterDeps.filter(
        (dep) => !beforeDeps.find((bdep) => bdep.id === dep.id)
      );
      const removedDeps = beforeDeps.filter(
        (dep) => !afterDeps.find((bdep) => bdep.id === dep.id)
      );
      const sameDeps = beforeDeps.filter((dep) =>
        afterDeps.find((bdep) => bdep.id === dep.id)
      );
      const allDeps = [...sameDeps, ...removedDeps, ...newDeps];

      setDepStats({
        new: newDeps,
        removed: removedDeps,
        same: sameDeps,
      });

      setBeforeDeps(beforeDeps);
      setAfterDeps(afterDeps);
      setAllDeps(allDeps);
    };
    getDepartments();
  }, [changes]);

  const handleFilter = (stat: string) => {
    setFilteredByStat((prev) => {
      if (prev.includes(stat)) return prev.filter((s) => s !== stat);
      return [...prev, stat];
    });
  };

  const filteredDeps = useMemo(() => {
    if (filteredByStat.length === 0) return allDeps;
    else {
      let filteredDeps: DepartmentItem[] = [];
      if (filteredByStat.includes('same')) {
        filteredDeps = filteredDeps.concat(
          allDeps.filter((dep) =>
            depStats.same.find((sdep) => sdep.id === dep.id)
          )
        );
      }
      if (filteredByStat.includes('new')) {
        filteredDeps = filteredDeps.concat(
          allDeps.filter((dep) =>
            depStats.new.find((ndep) => ndep.id === dep.id)
          )
        );
      }
      if (filteredByStat.includes('removed')) {
        filteredDeps = filteredDeps.concat(
          allDeps.filter((dep) =>
            depStats.removed.find((rdep) => rdep.id === dep.id)
          )
        );
      }
      return filteredDeps;
    }
  }, [allDeps, filteredByStat, depStats]);

  return (
    <tbody className="comparison-rows">
      {PROTOCOL_DATA.map((item) => {
        return (
          <HighlightChangesRow
            keys={item.keys}
            titles={item.titles}
            changes={changes}
          />
        );
      })}

      {(findBeforeValue(changes, 'pairedDepIDs') ||
        findCurrentValue(changes, 'pairedDepIDs')) && (
        <tr>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Paired Departments <span className="required-field">*</span>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  gap: '10px',

                  marginTop: '0px',
                }}
              >
                {depStats.same.length > 0 && (
                  <span
                    className={`transparent-stat-count same no-select ${
                      filteredByStat.includes('same') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('same')}
                  >
                    {depStats.same.length} same
                  </span>
                )}
                {depStats.new.length > 0 && (
                  <span
                    className={`transparent-stat-count new no-select ${
                      filteredByStat.includes('new') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('new')}
                  >
                    {depStats.new.length} new
                  </span>
                )}
                {depStats.removed.length > 0 && (
                  <span
                    className={`transparent-stat-count removed no-select ${
                      filteredByStat.includes('removed') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('removed')}
                  >
                    {depStats.removed.length} removed
                  </span>
                )}
              </div>
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
              }}
            >
              {filteredDeps.map((item) => {
                let isBefore = beforeDeps.find((dep) => dep.id === item.id);
                let isAfter = afterDeps.find((dep) => dep.id === item.id);
                return (
                  <>
                    {!isBefore && isAfter ? (
                      <div
                        style={{
                          color: isBefore ? 'black' : 'transparent',
                        }}
                      >
                        {item.name}
                      </div>
                    ) : (
                      <HighlightChanges
                        beforeText={isBefore ? item.name : ''}
                        afterText={isAfter ? item.name : ''}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </td>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Paired Departments <span className="required-field">*</span>
              <div
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  gap: '10px',
                  marginTop: '0px',
                }}
              >
                {depStats.same.length > 0 && (
                  <span
                    className={`stat-count same no-select ${
                      filteredByStat.includes('same') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('same')}
                  >
                    {depStats.same.length} same
                  </span>
                )}
                {depStats.new.length > 0 && (
                  <span
                    className={`stat-count new no-select ${
                      filteredByStat.includes('new') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('new')}
                  >
                    {depStats.new.length} new
                  </span>
                )}
                {depStats.removed.length > 0 && (
                  <span
                    className={`stat-count removed no-select ${
                      filteredByStat.includes('removed') ? 'selected' : ''
                    }`}
                    onClick={() => handleFilter('removed')}
                  >
                    {depStats.removed.length} removed
                  </span>
                )}
              </div>
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                maxHeight: '200px',
                overflowY: 'auto',
                backgroundColor: 'white',
              }}
            >
              {filteredDeps.map((item) => {
                let isBefore = beforeDeps.find((dep) => dep.id === item.id);
                let isAfter = afterDeps.find((dep) => dep.id === item.id);
                return (
                  <>
                    {isBefore && !isAfter ? (
                      <div
                        style={{
                          color: isAfter ? 'black' : 'transparent',
                        }}
                      >
                        {item.name}
                      </div>
                    ) : (
                      <HighlightChanges
                        beforeText={isBefore ? item.name : ''}
                        afterText={isAfter ? item.name : ''}
                      />
                    )}
                  </>
                );
              })}
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default ProtocolComparisonView;
