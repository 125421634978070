import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { getObjectDifference, globals } from '../../ui/_global/common/Utils';
import { LogEvent, Acknowledge, Reviewal, UserComment } from '../../API';
import { ProgressStatus, ACKStatus } from '../../API';
import { DatabaseResponse, Response, ResponseType } from '../AmplifyDB';
import DraftGroupItem from './DraftGroupItem';
import UserCommentItem from './UserCommentItem';
import { User } from '../../models';
import ReviewalItem from './ReviewalItem';
import { fetchUser } from '../functions/UserDB';

export const cloneAcknowledgeItem = (
  item: AcknowledgeItem
): AcknowledgeItem => {
  let newItem = new AcknowledgeItem(
    item.model,
    item.owner,
    item.user,
    item.reviewal
  );
  newItem.comment = item.comment;
  return newItem;
};
class AcknowledgeItem extends ModelItem<Acknowledge> {
  owner: any;
  reviewal: ReviewalItem;
  user: User;
  timestamp: Date;
  comment: UserCommentItem | null | undefined;
  state: ACKStatus;
  isArchived: boolean;

  constructor(
    ack: Acknowledge,
    owner: any,
    user: User,
    reviewal: ReviewalItem
  ) {
    super(ack, 'Acknowledgement', owner.departmentID);
    this.TAG = 'AcknowledgeItem';
    this.user = user;
    this.owner = owner;
    this.reviewal = reviewal;
    this.state = this.getACKStatus(ack.status);
    this.timestamp = new Date(ack.createdAt);
    this.isArchived = ack.isArchived === true;
    if (ack.Comment) {
      fetchUser(ack.Comment.userID, true).then((userResp: Response) => {
        if (userResp.type === ResponseType.Success) {
          let userComment = userResp.data as User;
          this.comment = new UserCommentItem(
            ack.Comment,
            this,
            userComment,
            []
          );
        }
      });
    }
  }

  getACKStatus(status: ACKStatus | string): ACKStatus {
    switch (status) {
      case 'PENDING':
        return ACKStatus.PENDING;
      case 'APPROVED':
        return ACKStatus.APPROVED;
      case 'REJECTED':
        return ACKStatus.REJECTED;
      case 'CLOSED':
        return ACKStatus.CLOSED;
      case 'PUBLISHED':
        return ACKStatus.PUBLISHED;
      default:
        return ACKStatus.DRAFT;
    }
  }

  toString(): string {
    return `AcknowledgeItem -> {
            uid=${this.uid},
            Model=${this.model},
            Owner=${this.owner},
            User=${this.user},
            status=${this.status},
            createdAt=${this.timestamp},
            comment=${this.comment}
        }`;
  }
}

export default AcknowledgeItem;
