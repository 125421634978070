import { DataStore, Storage } from 'aws-amplify';
import { ProgressStatus } from '../../API';
import {
  ResponseType,
  Response,
  executeQuery,
  mapModelItems,
  DatabaseResponse,
  executeSingleQuery,
} from '../AmplifyDB';
import WorkbookItem from '../model/WorkbookItem';
import DepartmentItem from '../model/DepartmentItem';
import {
  findItemByID,
  globals,
  upgradeVersion,
} from '../../ui/_global/common/Utils';
import { clone } from 'lodash';
import { User, WeightObject, Workbook } from '../../models';
import {
  getWorkbook,
  weightObjectsByDepartmentID,
} from '../../graphql/queries';
import { findUsersForModelItems } from './ModelDB';

export type WorkbookDB = {
  isNew?: boolean;
  name: string;
  workbookID: string;
  fileSize: number;
  createdBy: string;
  modifiedBy?: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  version: string | null | undefined;
  departmentID: string;
  activeID: string | null | undefined;
  pairedDepIDs: string[] | undefined | null;
  aiPdfParserResults?: string | null | undefined;
};

export const cloneWorkbook = (workbook: WorkbookItem): WorkbookItem => {
  let newWorkbook: WorkbookItem = new WorkbookItem(workbook.model);
  newWorkbook.modifiedBy = workbook.modifiedBy;
  newWorkbook.parents = workbook.parents;
  newWorkbook.fileSize = workbook.fileSize;
  newWorkbook.aiPdfParserResults = workbook.aiPdfParserResults;
  return newWorkbook;
};

/**
 * Create a new workbook in the database and choose the version
 * @param protocol WorkbookDB JSON format
 * @returns The successful WorkBookItem or the error
 */
export const createWorkbook = async (
  workbook: WorkbookDB | WorkbookItem,
  previousItem?: WorkbookItem
): Promise<Response> => {
  try {
    let json: WorkbookDB;
    if (workbook instanceof WorkbookItem) {
      json = {
        name: workbook.name,
        workbookID: workbook.workbookUrl,
        status: workbook.status,
        activeID: workbook.activeID,
        departmentID: workbook.departmentID,
        fileSize: workbook.fileSize,
        pairedDepIDs:
          workbook.pairedDepIDs != null ? workbook.pairedDepIDs : [],
        version:
          workbook.model.version != null ? workbook.model.version : 'v1.0.0',
        createdBy: workbook.model.createdBy ? workbook.model.createdBy : '',
        modifiedBy: workbook.modifiedBy ? workbook.modifiedBy.id : undefined,
        aiPdfParserResults: workbook.aiPdfParserResults,
      };
    } else json = workbook;

    if (globals.debug) console.log('Creating workbook:', json);

    let w: Workbook;
    if (previousItem) {
      let prev = await DataStore.query(Workbook, previousItem.uid);

      if (prev == null) {
        return {
          type: ResponseType.Failure,
          data: 'The previous workbook does not exist',
        };
      }

      w = await DataStore.save(
        Workbook.copyOf(prev, (updated) => {
          updated.name = json.name;
          updated.workbookID = json.workbookID;
          updated.fileSize = json.fileSize;
          updated.departmentID = json.departmentID;
          updated.status = json.status;
          updated.activeID = json.activeID;
          updated.pairedDepIDs = json.pairedDepIDs ? json.pairedDepIDs : null;
          updated.version = json.version;
          updated.createdBy = json.createdBy;
          updated.modifiedBy = json.modifiedBy;
          updated.aiPdfParserResults = json.aiPdfParserResults
            ? json.aiPdfParserResults
            : null;
        })
      );
    } else {
      w = await DataStore.save(
        new Workbook({
          name: json.name,
          workbookID: json.workbookID,
          fileSize: json.fileSize,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          pairedDepIDs: json.pairedDepIDs ? json.pairedDepIDs : null,
          version: json.version,
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
          aiPdfParserResults: json.aiPdfParserResults
            ? json.aiPdfParserResults
            : null,
        })
      );
    }
    if (globals.debug) console.log('Created workbook:', w);
    let userID = w.modifiedBy ? w.modifiedBy : w.createdBy;
    let modifiedBy = await DataStore.query(User, userID);
    if (modifiedBy == null) {
      return {
        type: ResponseType.Failure,
        data: 'The modified by user does not exist',
      };
    }

    let workbookItem = new WorkbookItem(w);
    workbookItem.modifiedBy = modifiedBy;
    return {
      type: ResponseType.Success,
      data: workbookItem,
    };
  } catch (e) {
    console.error('Error in workbook function: ', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Retrieves all active workbooks from the database.
 * @returns A Promise that resolves to a Response object.
 */
export const fetchWorkbooks = async (
  dep: DepartmentItem,
  useDataStore: boolean = true,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    const activeWorkbooks = await DataStore.query(Workbook, (m) =>
      m.and((m) => [
        m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
        m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
      ])
    );
    let wbItems: WorkbookItem[] = [];
    // let promises: Promise<User | null>[] = [];
    for (let i = 0; i < activeWorkbooks.length; i++) {
      let workbook = new WorkbookItem(activeWorkbooks[i]);
      // promises.push(workbook.findUser());
      mapModelItems(workbook, wbItems, workbook.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(wbItems);

    /* Sort the workbooks by their updated date, if it is null it was just uploaded */
    wbItems.sort((a, b) => a.name.localeCompare(b.name));
    return {
      type: ResponseType.Success,
      data: wbItems,
    };
  } catch (e) {
    console.error('Error fetching active workbooks:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function will publish the workbook to the database
 *    1. Create a new ARCHEIVED workbook based on the current ACTIVE workbook
 *    2. Update the ACTIVE workbook with the new information
 * @param workbookItem The workbook to publish
 */

export const publishWorkbook = async (
  workbookItem: WorkbookItem,
  publisher: User
): Promise<Response> => {
  try {
    let activeWorkbook: Workbook;

    /* Step 1. Fetch the active workbook item */
    let id: string = workbookItem.uid;
    let curWorkbook = await DataStore.query(Workbook, id);

    /* Base Case 1 -- check if the active workbook exists */
    if (curWorkbook == null) {
      return {
        type: ResponseType.Failure,
        data: 'The active workbook does not exist',
      };
    }

    let newArcheivedWorkbook = new WorkbookItem(curWorkbook);
    newArcheivedWorkbook.modifiedBy = publisher;
    newArcheivedWorkbook.status = ProgressStatus.ARCHIVE;
    newArcheivedWorkbook.activeID = curWorkbook.id;

    // 2. Create a new ARCHEIVED workbook based on the current ACTIVE workbook
    let archiveResult: Response = await createWorkbook(newArcheivedWorkbook);
    if (archiveResult.type === ResponseType.Failure) return archiveResult;
    newArcheivedWorkbook = archiveResult.data as WorkbookItem;

    if (globals.debug) console.log('Going to update workbook:', workbookItem);
    // 2. Update the ACTIVE workbook with the new information
    activeWorkbook = await DataStore.save(
      Workbook.copyOf(curWorkbook, (updated) => {
        updated.name = workbookItem.name;
        updated.workbookID = workbookItem.workbookUrl;
        updated.fileSize = workbookItem.fileSize;
        updated.modifiedBy = publisher.id;
        updated.createdBy = workbookItem.model.createdBy;
        updated.pairedDepIDs = workbookItem.pairedDepIDs;
        updated.status = ProgressStatus.ACTIVE;
        updated.activeID = null;
        updated.version = workbookItem.version
          ? upgradeVersion(workbookItem.version)
          : 'v1.0.0';
      })
    );

    let workbook = new WorkbookItem(activeWorkbook);
    workbook.modifiedBy = publisher;
    if (globals.debug) console.log('Published workbook:', workbook);
    return {
      type: ResponseType.Success,
      data: workbook,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteWorkbook = async (
  workBookItem: WorkbookItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = workBookItem.uid;
    if (isSoft) {
      let workbook = await DataStore.query(Workbook, id);
      if (workbook == null) {
        return {
          type: ResponseType.Failure,
          data: 'The workbook does not exist',
        };
      }

      let result = await DataStore.save(
        Workbook.copyOf(workbook, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The workbook did not update correctly',
        };
      }

      if (globals.debug) console.log('Soft Deleted workbook:', workbook);
    } else {
      let workbook = await DataStore.delete(Workbook, id);
      if (workbook == null) {
        return {
          type: ResponseType.Failure,
          data: 'The workbook does not exist',
        };
      }

      if (globals.debug) console.log('Hard Deleted workbook:', workbook);
    }
    return {
      type: ResponseType.Success,
      data: workBookItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const uploadWorkbookPDF = async (
  department: DepartmentItem,
  pdf: File,
  name: string,
  previousWorkbook?: WorkbookItem,
  progressCallback?: (progress: number) => void
): Promise<string> => {
  try {
    let workbookFolder = previousWorkbook ? previousWorkbook.uid : 'initial';
    let versionString = previousWorkbook
      ? upgradeVersion(previousWorkbook.version)
      : 'v1.0.0';
    let filePath = `${department.id}/workbooks/${workbookFolder}/${versionString + '_' + name}.pdf`;

    filePath = filePath.replace(/[^a-zA-Z0-9./-_]/g, '_');

    const result = await Storage.put(filePath, pdf, {
      contentType: 'application/pdf',
      level: 'public',
      progressCallback: (progress: any) => {
        if (progressCallback) {
          progressCallback(progress.loaded / progress.total);
        }
      },
    });

    if (globals.debug) console.log('Upload result:', result);
    return result.key;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};

/* GraphQL API Queries */
export const getWorkbookByID = async (
  db: DatabaseResponse,
  id: string
): Promise<WorkbookItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      executeSingleQuery(getWorkbook, { id: id }, 1500)
        .then((workbook) => {
          if (workbook == null) {
            resolve(null);
          } else {
            let workbookItem = new WorkbookItem(workbook);
            resolve(workbookItem);
          }
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error: any) {
      reject(error);
    }
  });
};
