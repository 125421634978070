import { useFormik } from 'formik';
import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { handleSetSelectedDoseValue } from '../../../../store/actions';
import AddRouteModal from '../../Modal/AddRouteModal';
import CalculationBasisDialog from '../../Modal/CalculationBasisModal';
import ConfirmWarningModal from '../../Modal/ConfirmWarningModal';
import DoseFixedCalcDialogModal from '../../Modal/DoseFixedCalcDialogModal';
import RangeDialog from '../../Modal/RangeDialogModal';
import RouteDialog from '../../Modal/RouteDialogModal';

import * as _ from 'lodash';
import ConfirmModal from '../../Modal/ConfirmModal';
import {
  findDepartmentOwner,
  globals,
  handleCopy,
  medicationRangeKeys,
} from '../../../_global/common/Utils';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import MedicationItem from '../../../../data/model/MedicationItem';
import ElectricalItem from '../../../../data/model/ElectricalItem';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import VitalItem from '../../../../data/model/VitalItem';
import { BiArrowBack, BiCopy, BiSolidCopy } from 'react-icons/bi';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import SearchableDropdown from '../../SearchableDropdown';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  fetchPDF,
} from '../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import GeneralSelection from '../../Selection/GeneralSelection';
import { ViewportList } from 'react-viewport-list';
import MedicationDoseUI from '../../../pages/protocol/edit/Medication/MedicationDoseUI';
import {
  FaChevronRight,
  FaCircleCheck,
  FaCircleXmark,
  FaLock,
} from 'react-icons/fa6';
import { InputSwitch } from 'primereact/inputswitch';
import { InputTextarea } from 'primereact/inputtextarea';
import MedicationDoseSelection from '../../../pages/protocol/edit/Medication/MedicationDoseSelection';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { IoArrowUndo } from 'react-icons/io5';
import { BsCloudFill, BsCloudSlashFill } from 'react-icons/bs';
import { Tooltip } from '@mui/material';
import Status from '../../ProgressStatus/ProgressStatus';
import { DeviceMockup } from '../../DeviceMockup/DeviceMockup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons';
import HMSwitch from '../../general/HMSwitch';
import { User } from '../../../../models';

interface MedicationDoseCPRSideoutProps {
  visible: boolean;
  dose?: MedicationSubItem;
  setVisible: (visible: boolean) => void;
  editDose?: boolean;
  protocol: ProtocolItem | null;
  allProtocols?: ProtocolItem[];
  doseIndex?: any;
  onSideOutClose?: any;
  onRemove: (dose: any, type: 'block' | 'restore' | 'delete') => void;
  onSubmit: (
    parent: MedicationItem,
    dose: any,
    prevDose?: MedicationSubItem,
    parentProtocol?: ProtocolItem
  ) => void;
  onSetNewRoutes?: (newRoutes: string[]) => void;
  showDeviceMockup?: boolean;
}

const NEW_DOSE = 0;
const COPY_DOSE = 1;
const COPY_PROTOCOL_DOSE = 2;
const ADMIN_MED_PROTOCOL = 3;

const SHOW_PDF = 0;
const SHOW_DEVICE_MOCKUP = 1;

/*10-16-23 Praveen:  Created common component for Edit and New Dose section sideout view*/
const MedicationDoseCPRSideout: React.FC<MedicationDoseCPRSideoutProps> = ({
  doseIndex,
  protocol,
  dose,
  visible,
  setVisible,
  editDose,
  onSubmit,
  onSideOutClose,
  onSetNewRoutes,
  onRemove,
  showDeviceMockup,
}) => {
  const dispatch = useDispatch();
  const reducerState = useSelector((state: any) => state.department);
  const [minMaxDialog, setMinMaxDialog] = useState<string | null>(null);
  const [rangeDialog, setRangeDialog] = useState<string | null>(null);
  const [ageRangeDialog, setAgeRangeDialog] = useState<string | null>(null);
  const [routeDialogVisible, setRouteDialogVisible] = useState(false);
  const [newRouteDialogVisible, setNewRouteDialogVisible] = useState(false);
  const [isConfirmModal, setIsConfirmMOdal] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [isBlockModal, setIsBlockModal] = useState(false);
  const [calculationDialogVisible, setCalculationDialogVisible] =
    useState(false);

  const [deleteData, setDeleteData] = useState<any>(null);
  const [modalType, setModalType] = useState('');
  const [editData, setEditData] = useState<any>(null);
  const [routeModal, setRouteModal] = useState<any>(null);

  const user: User = useSelector((state: any) => state.user);
  const database: DatabaseResponse = useSelector(
    (state: any) => state?.protocol?.departmentItem
  );
  const [allProtocols, setAllProtocols] = useState<ProtocolItem[]>(
    database.protocols.sort((a, b) => {
      if (a.parent.index === b.parent.index && a.index === b.index)
        return a.name.localeCompare(b.name);
      else if (a.parent.index === b.parent.index) return a.index - b.index;
      else return a.parent.index - b.parent.index;
    })
  );

  const [allMedications, setAllMedications] = useState<MedicationItem[]>(
    database.medications.filter((m: MedicationItem) =>
      m.name.toLowerCase().includes('epi')
    )
  );

  const [parentProtocol, setParentProtocol] = useState<ProtocolItem | null>(
    protocol ? protocol : dose ? dose.parentProtocol : null
  );
  const [parentMedication, setParentMedication] =
    useState<MedicationItem | null>(dose?.parent ?? null);

  const [medicationProtocol, setMedicationProtocol] = useState<string>('');
  const [mpError, setMpError] = useState('');

  const [isWarningModal, setIsWarningModal] = useState(false);
  const [inputType, setInputType] = useState(NEW_DOSE);
  const [doseProtocols, setDoseProtocols] = useState<ProtocolItem[]>([]);
  const [isCopied, setIsCopied] = useState<string | null>(null);

  const [selectedDose, setSelectedDose] = useState<MedicationSubItem | null>(
    null
  );
  const [selectedProtocol, setSelectedProtocol] = useState<ProtocolItem | null>(
    null
  );
  const [selectedProtocolDoses, setSelectedProtocolDoses] = useState<
    MedicationSubItem[]
  >([]);
  const [pdfLoaded, setPdfLoaded] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [pdfOrDeviceMock, setPdfOrDeviceMock] = useState(SHOW_PDF);

  const [isEmergency, setIsEmergency] = useState(
    dose ? dose.model.basis === '-1' : false
  );
  const [showOnlyEpi, setShowOnlyEpi] = useState(true);
  const isDeactivated = useMemo(() => {
    if (
      dose &&
      dose.status === 'DEACTIVATED' &&
      dose.overrideItem &&
      dose.overrideItem.departmentID !== database.department.id
    )
      return true;
    return false;
  }, [dose, database.department]);

  const [departmentOwner, setDepartmentOwner] = useState<DepartmentItem | null>(
    dose
      ? findDepartmentOwner(
          database.department,
          reducerState,
          isDeactivated && dose.overrideItem ? dose.overrideItem : dose
        )
      : null
  );

  const solidUnit = useMemo(() => {
    if (
      parentMedication &&
      parentMedication.concentrations &&
      parentMedication.concentrations.length > 0
    ) {
      let concen = parentMedication.concentrations[0];
      return concen.firstUnit;
    }
    return '';
  }, [parentMedication]);

  const adminRoutes = useMemo(() => {
    return parentMedication?.routes ?? [];
  }, [parentMedication]);

  const subMedicationItems = useMemo(() => {
    return parentMedication?.subItems ?? [];
  }, [parentMedication]);

  useEffect(() => {
    if (showOnlyEpi) {
      setAllMedications(
        database.medications.filter((m: MedicationItem) =>
          m.name.toLowerCase().includes('epi')
        )
      );
    } else {
      setAllMedications(database.medications);
    }
  }, [showOnlyEpi]);

  const isOwner = useMemo(() => {
    return (
      departmentOwner == null || departmentOwner.id === database.department.id
    );
  }, [departmentOwner, database.department]);

  const [edittable, setEdittable] = useState<boolean>(isOwner);

  const allDoses = useMemo(() => {
    return subMedicationItems.filter((m) => m.status !== 'DEACTIVATED');
  }, [subMedicationItems]);

  useEffect(() => {
    if (dose && database.department) {
      setDepartmentOwner(
        findDepartmentOwner(
          database.department,
          reducerState,
          isDeactivated && dose.overrideItem ? dose.overrideItem : dose
        )
      );

      setEdittable(isOwner);
    }
  }, [dose, database.department, isOwner, isDeactivated, reducerState]);

  useEffect(() => {
    if (dose) {
      setParentMedication(dose.parent);
    }
  }, [dose]);

  const loadPDF = useCallback(async () => {
    let result = await fetchPDF(parentProtocol?.pdfUrl);
    if (result.type === ResponseType.Success) {
      setPdfUrl(result.data);
    } else {
      console.error(
        'ERROR: Could not load pdf for protocol: ' + parentProtocol?.name,
        result.data
      );
      setPdfUrl('');
    }
  }, [parentProtocol]);

  useEffect(() => {
    if (parentProtocol?.pdfUrl && !pdfLoaded) {
      loadPDF();
      setPdfLoaded(true);
    }
  }, [parentProtocol, pdfLoaded, loadPDF]);

  useEffect(() => {
    let prots: ProtocolItem[] = [];
    for (let i = 0; i < allDoses.length; i++) {
      let found = false;
      for (let j = 0; j < prots.length; j++) {
        if (prots[j].uid === allDoses[i].parentProtocol.uid) {
          found = true;
          break;
        }
      }
      if (!found) prots.push(allDoses[i].parentProtocol);
    }
    setDoseProtocols(prots);
  }, [allDoses]);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      /* Shared on all models */
      rangeLow: dose ? dose.rangeLow : '',
      rangeHigh: dose
        ? dose.rangeHigh === globals.MAX_VALUE
          ? ''
          : dose.rangeHigh
        : '',
      ageLow: dose ? dose.ageLow : '',
      ageHigh: dose ? dose.ageHigh : '',

      /* Medication, Electrical and Infusion */
      basis: dose ? dose.model.basis : '+Add',
      // parentProtocol: protocol,

      /* Medication and infusion */
      routes: dose ? dose.routes : [],
      minDose: dose && dose.model.minDose ? dose.model.minDose : '',
      maxDose: dose && dose.model.maxDose ? dose.model.maxDose : '',
      maxTotalDose:
        dose && dose.model.maxTotalDose ? dose.model.maxTotalDose : '',
      calcMax: dose && dose.model.calcMax ? dose.model.calcMax : '',
      calcMin: dose && dose.model.calcMin ? dose.model.calcMin : '',

      repeatTime: dose && dose.model.repeatTime ? dose.model.repeatTime : '',
      incrementAmount:
        dose && dose.model.incrementAmnt ? dose.model.incrementAmnt : '',

      title: dose ? dose.title : '',
      warning: dose ? dose.warning : '',
      instruction: dose ? dose.instruction : '',
      note: dose ? dose.note : '',
    },
    validationSchema: Yup.object({
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      ageLow: Yup.string(),
      ageHigh: Yup.string(),
      repeatTime: Yup.string(),
      incrementAmount: Yup.string(),
      maxTotalDose: Yup.string(),
      calcMax: Yup.string(),
      calcMin: Yup.string(),
      routes: Yup.array(),
      basis: Yup.string().required('Required'),
      minDose: Yup.string(),
      maxDose: Yup.string(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  useEffect(() => {
    //Reset variables when sideout is closed
    if (!visible) {
      setParentProtocol(null);
      setParentMedication(null);
      setMedicationProtocol('');
      setMpError('');
      setSelectedDose(null);
      setSelectedProtocol(null);
      setSelectedProtocolDoses([]);
      formik.resetForm();
    }
  }, [visible]);

  /*10-16-23 Praveen: Route button for the dialog handler*/
  const handleNewRoute = () => {
    setNewRouteDialogVisible(true);
    setRouteDialogVisible(!routeDialogVisible);
  };

  const handleConfirm = (value: any) => {
    setDeleteData(value);
    setIsConfirmMOdal(true);
    setModalType('Route');
  };

  /* 11-01-23 Praveen: handled function to set calcaulation basis popup value */
  const handleCalculationBasisset = async (newBasis: string) => {
    setCalculationDialogVisible(false);
    formik.setFieldValue('basis', newBasis);
  };

  /* 11-01-23 Praveen:  To select and unselect route value */
  const handleEditRouteset = useCallback(
    (routes: string[]) => {
      formik.setFieldValue('routes', routes);
      setRouteDialogVisible(false);
    },
    [formik]
  );

  /* 11-01-23 Praveen: To add new route value */
  const handleNewRouteset = (route: string) => {
    setNewRouteDialogVisible(false);
    if (routeModal !== 'new') {
      setRouteDialogVisible(true);
    }
    setRouteModal(null);
    const routes = (
      adminRoutes
        ? [...adminRoutes]
        : [...(parentMedication as MedicationItem)?.routes]
    ) as string[];
    routes.push(route);
    onSetNewRoutes && onSetNewRoutes(routes);
  };

  /* 11-01-23 Praveen: To delete the route value */
  const handleConfirmDelete = (type: any) => {
    setIsConfirmMOdal(false);
    if (type === 'Route')
      formik.setFieldValue(
        'routes',
        formik.values.routes.filter((route: any) => route !== deleteData)
      );
  };

  //*10-30-23 Arul: handling function for create and edit dose sideout */
  const handleSubmit = async () => {
    let newDose = formik.values;
    if (parentMedication) {
      if (isEmergency) {
        newDose.basis = '-1';
        newDose.routes = [];
      }
      onSubmit(
        parentMedication,
        newDose,
        dose,
        parentProtocol ? parentProtocol : dose ? dose.parentProtocol : undefined
      );
    }
    setVisible(false);
  };

  /* 11-02-23 Praveen: submit button validation*/
  const isSaveValid = useMemo(() => {
    if (inputType === COPY_PROTOCOL_DOSE)
      return selectedProtocol && selectedProtocolDoses.length > 0;
    else if (inputType === ADMIN_MED_PROTOCOL) {
      return mpError === '' && medicationProtocol !== '';
    } else {
      if (inputType === COPY_DOSE && (!selectedDose || !parentMedication))
        return false;
      else {
        if (
          isEmergency &&
          formik.values.warning &&
          parentMedication != null &&
          formik.dirty
        ) {
          return true;
        } else if (
          formik.values.routes.length === 0 ||
          parentMedication == null
        ) {
          return false;
        } else if (
          formik.values.basis === '' ||
          formik.values.basis === '+Add'
        ) {
          return false;
        }
        return (
          formik.dirty ||
          (dose && dose.parent.getUid() !== parentMedication.getUid())
        );
      }
    }
  }, [
    formik,
    selectedProtocol,
    selectedProtocolDoses,
    selectedDose,
    medicationProtocol,
    mpError,
    isEmergency,
    inputType,
    parentMedication,
    dose,
  ]);

  const handleCloseSideout = () => {
    if (formik.dirty) {
      setIsWarningModal(true);
    } else {
      setVisible(false);
      // onSideOutClose && onSideOutClose();
      // dispatch<any>(handleSetSelectedDoseValue(""));
    }
  };

  const handleCloseWarningModal = () => {
    setVisible(false);
    dispatch<any>(handleSetSelectedDoseValue(''));
  };

  const customHeader = () => {
    return (
      <div>
        <h4 className="headerText hoverText" onClick={handleCloseSideout}>
          <span className="">
            <BiArrowBack className="header-icon" data-testid="isBackBtn" />
          </span>{' '}
          {editDose ? 'Edit' : 'Create'} Medication Dose
        </h4>
        {departmentOwner && (
          <Tooltip
            title={departmentOwner.name + ' owns this item.'}
            placement="bottom"
            arrow
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <div
              className="protocol-header-logo-container"
              style={{
                top: '2px',
                right: '0px',
              }}
            >
              <img
                className="no-select owner-logo"
                src={departmentOwner.logoVerifiedUrl ?? ''}
                alt="Agency Logo"
              />
              <div
                className="lock-icon"
                style={{
                  top: '5px',
                }}
              >
                <FaLock
                  data-testid="isLockButton"
                  style={{
                    color:
                      departmentOwner.id !== database.department.id
                        ? '#A3A3A3'
                        : 'transparent',
                    fontSize: '1.25rem',
                  }}
                />
              </div>
            </div>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleDeleteRestoreBlockDose = (
    type: 'block' | 'restore' | 'delete'
  ) => {
    if (onRemove) onRemove(dose, type);
    setIsDeleteModal(false);
    handleCloseSideout();
  };

  const handleSelectCopyDose = (dose: MedicationSubItem | null) => {
    setSelectedDose(dose);
    formik.setFieldValue('rangeLow', dose ? dose.rangeLow : '');
    formik.setFieldValue(
      'rangeHigh',
      dose ? (dose.rangeHigh === globals.MAX_VALUE ? '' : dose.rangeHigh) : ''
    );
    formik.setFieldValue('ageLow', dose ? dose.ageLow : '');
    formik.setFieldValue('ageHigh', dose ? dose.ageHigh : '');

    formik.setFieldValue('basis', dose ? dose.model.basis : '+Add');
    formik.setFieldValue('routes', dose ? dose.routes : []);
    formik.setFieldValue(
      'minDose',
      dose && dose.model.minDose ? dose.model.minDose : ''
    );
    formik.setFieldValue(
      'maxDose',
      dose && dose.model.maxDose ? dose.model.maxDose : ''
    );
    formik.setFieldValue(
      'maxTotalDose',
      dose && dose.model.maxTotalDose ? dose.model.maxTotalDose : ''
    );
    formik.setFieldValue(
      'calcMax',
      dose && dose.model.calcMax ? dose.model.calcMax : ''
    );
    formik.setFieldValue(
      'calcMin',
      dose && dose.model.calcMin ? dose.model.calcMin : ''
    );

    formik.setFieldValue(
      'repeatTime',
      dose && dose.model.repeatTime ? dose.model.repeatTime : ''
    );
    formik.setFieldValue(
      'incrementAmount',
      dose && dose.model.incrementAmnt ? dose.model.incrementAmnt : ''
    );

    formik.setFieldValue('title', dose ? dose.title : '');
    formik.setFieldValue('warning', dose ? dose.warning : '');
    formik.setFieldValue('instruction', dose ? dose.instruction : '');
    formik.setFieldValue('note', dose ? dose.note : '');
  };

  const buttonTitle = useMemo(() => {
    if (inputType === NEW_DOSE) return editDose ? 'Save' : 'Create';
    else if (inputType === COPY_DOSE) return 'Copy Dose';
    else if (inputType === COPY_PROTOCOL_DOSE)
      return 'Copy ' + selectedProtocolDoses.length + ' Doses';
    else if (inputType === ADMIN_MED_PROTOCOL) return 'Add';
  }, [editDose, inputType, selectedProtocolDoses]);

  const Modals = () => {
    return (
      <>
        {/* Min Max Dose Popup */}
        <DoseFixedCalcDialogModal
          unit={solidUnit}
          isVisible={minMaxDialog != null}
          type="medication"
          value={
            // editData
            minMaxDialog === 'min'
              ? formik.values.minDose
              : minMaxDialog === 'max'
                ? formik.values.maxDose
                : minMaxDialog === 'maxTotal'
                  ? formik.values.maxTotalDose
                  : minMaxDialog === 'calcMin'
                    ? formik.values.calcMin
                    : formik.values.calcMax
          }
          handleSet={(data: any) => {
            let e: string = data.value ? data.value + ' ' + data.unit : '';
            minMaxDialog === 'min'
              ? formik.setFieldValue('minDose', e)
              : minMaxDialog === 'max'
                ? formik.setFieldValue('maxDose', e)
                : minMaxDialog === 'maxTotal'
                  ? formik.setFieldValue('maxTotalDose', e)
                  : minMaxDialog === 'calcMin'
                    ? formik.setFieldValue('calcMin', e)
                    : formik.setFieldValue('calcMax', e);
            setMinMaxDialog(null);
          }}
          handleClose={() => {
            setMinMaxDialog(null);
            setEditData(null);
          }}
          headerText={
            minMaxDialog === 'min'
              ? 'Min Dose'
              : minMaxDialog === 'max'
                ? 'Max Dose'
                : minMaxDialog === 'maxTotal'
                  ? 'Max Total Dose'
                  : minMaxDialog === 'calcMin'
                    ? 'Calculated Min Dose'
                    : 'Calculated Max Dose'
          }
          headerTitle={
            minMaxDialog === 'min'
              ? 'Min'
              : minMaxDialog === 'max'
                ? 'Max'
                : minMaxDialog === 'maxTotal'
                  ? 'Max Total'
                  : minMaxDialog === 'calcMin'
                    ? 'Calculated Min'
                    : 'Calculated Max'
          }
          InputLabel={
            minMaxDialog === 'min' || minMaxDialog === 'calcMin' ? 'Min' : 'Max'
          }
          isCalculated={false}
        />

        <RangeDialog
          dialogVisible={rangeDialog != null}
          type={'Medication'}
          value={
            rangeDialog === 'low'
              ? formik.values.rangeLow
              : formik.values.rangeHigh
          }
          handleSet={(e: any) => {
            rangeDialog === 'low'
              ? formik.setFieldValue('rangeLow', e)
              : formik.setFieldValue('rangeHigh', e);
            setRangeDialog(null);
          }}
          setDialogVisible={() => {
            setRangeDialog(null);
            setEditData(null);
          }}
          headerText={rangeDialog === 'low' ? 'Low Range' : 'High Range'}
          headerTitle={rangeDialog === 'low' ? 'Low' : 'High'}
          InputLabel={rangeDialog === 'low' ? 'LOWER' : 'HIGHER'}
        />

        {/*Confirm and Warning Popup */}
        <ConfirmWarningModal
          isVisible={isConfirmModal}
          type={modalType}
          handleClose={() => {
            setIsConfirmMOdal(false);
          }}
          handleDelete={handleConfirmDelete}
        />

        {/* Edit Route Popup */}
        {routeDialogVisible && (
          <RouteDialog
            visible={routeDialogVisible}
            // routeItem={addedRoutesList}
            availableRoutes={adminRoutes ?? parentMedication?.routes}
            selectedRoutes={formik.values.routes}
            onClose={() => {
              setRouteDialogVisible(false);
              setEditData(null);
            }}
            handleNewRoute={handleNewRoute}
            handleSet={(routes: string[]) => {
              handleEditRouteset(routes);
            }}
          />
        )}

        {/* Add Route Popup */}
        <AddRouteModal
          dialogVisible={newRouteDialogVisible}
          handleSet={handleNewRouteset}
          onNewRouteCancel={() => {
            setNewRouteDialogVisible(false);
            routeModal !== 'new' && setRouteDialogVisible(true);
            setRouteModal('');
          }}
          headerText="New Administration Route"
        />

        {/* CalculationBasis Popup */}
        <CalculationBasisDialog
          unit={solidUnit}
          dialogVisible={calculationDialogVisible}
          type={'Medication'}
          handleSet={handleCalculationBasisset}
          basis={formik.values.basis}
          setDialogVisible={() => {
            setCalculationDialogVisible(false);
          }}
          headerText="Calculation Basis"
        />

        {isWarningModal && (
          <ConfirmModal
            isVisible={isWarningModal}
            title="Abandon Changes?"
            handleClose={() => {
              setIsWarningModal(false);
            }}
            handleSubmit={handleCloseWarningModal}
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName="Abandon"
            primaryDescription={`Changes were made to this Medication Dose.  Click cancel to return to Medication Dose details.  To continue without saving changes, select Abandon Changes.`}
          />
        )}

        {isDeleteModal && (
          <ConfirmModal
            isVisible={isDeleteModal}
            title={`Confirm ${dose?.status === 'DRAFT_DELETE' ? 'Restore?' : 'Delete?'}`}
            handleClose={() => {
              setIsDeleteModal(false);
            }}
            handleSubmit={() =>
              handleDeleteRestoreBlockDose(
                dose?.status === 'DRAFT_DELETE' ? 'restore' : 'delete'
              )
            }
            isDeleteBtn={true}
            primaryBtnName="Cancel"
            secondaryBtnName={
              dose?.status === 'DRAFT_DELETE' ? 'Restore' : 'Delete'
            }
            primaryDescription={`Are you sure you want to ${dose?.status === 'DRAFT_DELETE' ? 'restore' : 'delete'} this Medication Dose. This will ${dose?.status === 'DRAFT_DELETE' ? 'restore the active medication and make no change to the published medications' : 'immediately delete this Medication Dose for all OneDose users. This will be able to be restored at any time.'}`}
          />
        )}
        {isBlockModal && (
          <ConfirmModal
            isVisible={isBlockModal}
            title={`${
              isDeactivated ? 'Reactivate' : 'Deactivate'
            } ${departmentOwner?.name} ${dose?.name} Dose?`}
            handleClose={() => {
              setIsBlockModal(false);
            }}
            handleSubmit={() =>
              handleDeleteRestoreBlockDose(isDeactivated ? 'restore' : 'block')
            }
            isDeleteBtn={!isDeactivated}
            primaryBtnName="Cancel"
            secondaryBtnName={isDeactivated ? 'Reactivate' : 'Deactivate'}
            primaryDescription={`This will ${isDeactivated ? 'reactivate' : 'deactivate'} the ${departmentOwner?.name} ${dose?.name} Dose for ${database.department?.name}. ${isDeactivated ? '' : 'This can be reactiveated at any time.'}`}
          />
        )}
      </>
    );
  };

  const findDoses = (protocol: ProtocolItem) => {
    let doses = allDoses.filter(
      (dose: MedicationSubItem) => dose.parentProtocol.uid === protocol.uid
    );
    return doses.length + ' doses';
  };

  const validateMedicationProtocol = (mp: any) => {
    if (mp == null) return false;
    if (mp.protocolID == null) return false;
    if (mp.options == null) return false;
    let availableKeys = medicationRangeKeys;
    for (let i = 0; i < mp.options.length; i++) {
      let mr: any = mp.options[i];
      let keys = Object.keys(mr);
      for (let j = 0; j < keys.length; j++) {
        if (!availableKeys.includes(keys[j])) return false;
      }
    }
    return true;
  };

  return (
    <div>
      <Modals />

      <Sidebar
        header={customHeader}
        visible={visible}
        position="right"
        onHide={() => {
          if (!formik.dirty) handleCloseSideout();
        }}
        style={{
          minWidth: '600px',
        }}
        className="sidebarWidth"
      >
        {parentProtocol && showDeviceMockup && (
          <>
            <div
              style={{
                position: 'absolute',
                left: `calc(-40vw - 20px)`, // Adjust for the sidebar width (40% of viewport) and the clip width (20px)
                top: '0',
                height: '100%',
                backgroundColor: '#fff',

                padding: '10px',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                width: `calc(100vw - 60vw)`, // Full width minus the sidebar width (40% of viewport) + 20% for clip
                overflowY: 'auto', // Allow scrolling if content overflows
              }}
            >
              <GeneralSelection
                items={['PDF', 'Show Device Mockup']}
                selectedIndex={pdfOrDeviceMock}
                onPress={(item, index) => {
                  setPdfOrDeviceMock(index);
                }}
                labelField={(option) => option}
                valueField={(option) => option}
              />
              {pdfOrDeviceMock === SHOW_DEVICE_MOCKUP && (
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    // paddingTop: '20px',

                    alignItems: 'center',
                    // height: '90vh',
                  }}
                >
                  <DeviceMockup
                    parentModel={parentMedication as MedicationItem}
                    formik={formik}
                    protocol={parentProtocol}
                  />
                </div>
              )}
              {pdfOrDeviceMock === SHOW_PDF && (
                <div
                  style={{
                    justifyContent: 'center',
                    display: 'flex',
                    paddingTop: '20px',
                  }}
                >
                  <iframe
                    src={pdfUrl}
                    title="PDF Viewer"
                    width="100%"
                    style={{
                      border: 'none',
                      height: '90vh',
                    }}
                  />
                </div>
              )}
            </div>
          </>
        )}
        <div style={{ padding: '0px 15px' }}>
          <p className="sidebarText">
            {dose ? 'Edit' : 'Create'} the information for{' '}
            {protocol ? protocol.name : ''} medications's dose.
          </p>
          <p className="sidebarText">
            Need more information: <a className="sidebarLink">Learn More</a>
          </p>
          {dose != null && user.type === 'ADMIN' && (
            <div>
              <div
                className="contentText"
                style={{
                  marginTop: '10px',
                }}
              >
                ID: {dose.uid}
                <span>
                  {isCopied && isCopied === dose.uid ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size=".75rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size=".75rem"
                      className="copy_icon"
                      onClick={(e) => handleCopy(dose.uid, e, setIsCopied)}
                    />
                  )}
                </span>
              </div>
              {dose && dose.parentCPR && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  CPR: {dose.parentCPR.uid}
                  <span>
                    {isCopied && isCopied === dose.parentCPR.uid ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose?.parentCPR?.uid ?? '', e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              )}
              {dose && dose.parent && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  Medication: {dose.parent.uid} - {dose.parent.name}
                  <span>
                    {isCopied && isCopied === dose.parent.uid ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose.parent.uid, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              )}
              {dose && dose.parentProtocol && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  Protocol: {dose.parentProtocol.uid}
                  <span>
                    {isCopied && isCopied === dose.parentProtocol.uid ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose.parentProtocol.uid, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                </div>
              )}

              {dose.activeID != null && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  Actv. ID: {dose.activeID}
                  <span>
                    {isCopied && isCopied === dose.activeID ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose.activeID as string, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                  <span>
                    {dose.activeItem != null ? (
                      <FaCircleCheck
                        color={'#037F02'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <FaCircleXmark
                        color={'#8c1010'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    )}
                  </span>
                </div>
              )}
              {dose.overrideID != null && (
                <div
                  className="contentText"
                  style={{
                    marginTop: '10px',
                  }}
                >
                  Ovrd. ID: {dose.overrideID}
                  <span>
                    {isCopied && isCopied === dose.overrideID ? (
                      <BiSolidCopy
                        color={'#00534C'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <BiCopy
                        size=".75rem"
                        className="copy_icon"
                        onClick={(e) =>
                          handleCopy(dose.overrideID as string, e, setIsCopied)
                        }
                      />
                    )}
                  </span>
                  <span>
                    {dose.overrideItem != null ? (
                      <FaCircleCheck
                        color={'#037F02'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    ) : (
                      <FaCircleXmark
                        color={'#8c1010'}
                        size=".75rem"
                        className="copy_icon"
                      />
                    )}
                  </span>
                </div>
              )}
            </div>
          )}
          {dose && isDeactivated && (
            <div style={{ marginTop: '15px' }}>
              <span
                className="ketamine-general-label"
                style={{
                  marginBottom: 0,
                  paddingBottom: 0,
                  marginLeft: 0,
                }}
              >
                {dose.name} Dose is
                <span>
                  <Status status="DEACTIVATED" />
                </span>
              </span>
              <p className="sidebarText">
                This does has been deactivated by {database.department.name}.
                So, it is hidden in OneDose for {database.department.name}
                {"'s "}
                users. This can be reactivated at any time.
              </p>
            </div>
          )}
        </div>
        {!dose && (
          <div className="" style={{ padding: '0px 15px' }}>
            <span
              className="ketamine-general-label"
              style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 0 }}
            >
              Input Type
            </span>
            <GeneralSelection
              items={['Create New', 'Copy Dose']}
              selectedIndex={inputType}
              onPress={(item, index) => {
                if (inputType === COPY_PROTOCOL_DOSE)
                  handleSelectCopyDose(null);
                else handleSelectCopyDose(null);
                setParentProtocol(protocol ? protocol : null);
                setInputType(index);
              }}
              labelField={(option) => option}
              valueField={(option) => option}
            />
          </div>
        )}
        {inputType === NEW_DOSE && (
          <>
            {
              <div className="" style={{ padding: '0px 15px' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ marginTop: '15px' }}>
                    <span
                      className="ketamine-general-label"
                      style={{
                        marginBottom: 0,
                        paddingBottom: 0,
                        marginLeft: 0,
                      }}
                    >
                      Medication
                    </span>

                    <p className="sidebarText">
                      This is the Medication that this dose will belong to.
                    </p>
                  </div>

                  {!editDose && (
                    <div
                      className="calculationDialogContent"
                      style={{ marginBottom: '0px' }}
                    >
                      <label
                        htmlFor="isSolidOnly"
                        className="departmentItemText"
                        style={{ flexDirection: 'row' }}
                      >
                        Show Only Epi:
                        <HMSwitch
                          style={{ marginLeft: '0px' }}
                          checked={showOnlyEpi}
                          onChange={(e: any, checked: boolean) => {
                            setShowOnlyEpi(checked);
                          }}
                          tooltipText='This will only display medications with "Epi" in the name.'
                        />
                      </label>
                    </div>
                  )}
                </div>

                <SearchableDropdown<MedicationItem>
                  id="searchDropdown"
                  value={parentMedication ? parentMedication : undefined}
                  options={allMedications}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.uid}
                  disabled={!edittable || editDose}
                  onChange={(option: MedicationItem) => {
                    setParentMedication(option);
                  }}
                  onClear={() => {
                    setParentMedication(null);
                  }}
                  isActive={protocol ? false : true}
                  placeholder="Search Medication..."
                />
              </div>
            }

            <div className="siderbarContent">
              <div style={{ marginTop: '15px' }}>
                <span className="ketamine-general-label">
                  Dose {doseIndex + 1}
                </span>
              </div>

              <div className="sidebarInputContainer">
                {parentMedication && (
                  <MedicationDoseSelection
                    isEmergency={isEmergency}
                    formik={formik}
                    doseIndex={doseIndex}
                    disabled={!edittable}
                    parent={parentMedication}
                    onWeightRange={(range: string, isLow: boolean) => {
                      // handleRangeClick(range, isLow ? 'low' : 'high');

                      console.log('Range Clicked', range, isLow);

                      setRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onAgeRange={(range: string, isLow: boolean) => {
                      // handleRangeClick(range, isLow ? 'low' : 'high');

                      console.log('AGE Range Clicked', range, isLow);

                      setAgeRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onBasisClick={(basis: string, parent: MedicationItem) => {
                      setCalculationDialogVisible(true);
                    }}
                    onMinMaxDoseClick={(
                      value: string,

                      type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
                    ) => {
                      setEditData(value);

                      setMinMaxDialog(type);
                    }}
                    onRoutesClick={(routes: string[]) => {
                      setRouteDialogVisible(true);
                    }}
                    onSingleRouteClick={(route: string, isDelete: boolean) => {
                      if (isDelete) handleConfirm(route);
                    }}
                    handleSubmit={(dose: any) => {
                      if (isSaveValid) handleSubmit();
                    }}
                  />
                )}
              </div>

              {/* {dose?.overrideItem && (
      <div style={{ marginTop: '15px' }}>
        <span
          className="ketamine-general-label"
          style={{
            marginBottom: 0,
            paddingBottom: 0,
            marginLeft: 0,
          }}
        >
          NOTE: This overrides {overrideDepartment?.name} {dose.name}{' '}
          Dose
        </span>
        <p className="sidebarText">
          {overrideDepartment?.name} created a dose for this protocol
          and {database.department.name} has choosen to override it.
          This can be reverted at the bottom to undo this.
        </p>
      </div>
    )} */}
            </div>
          </>
        )}

        {inputType === COPY_DOSE && (
          <>
            {!selectedDose && (
              <span
                className="ketamine-general-label"
                style={{
                  padding: '0px 15px',

                  marginTop: 10,

                  marginBottom: 0,

                  paddingBottom: 0,

                  marginLeft: 0,

                  fontSize: '18px',
                }}
              >
                Select Dose to Copy From
              </span>
            )}

            {selectedDose && (
              <h6
                className="headerText hoverText"
                style={{ padding: '0px 15px', marginTop: 10 }}
                onClick={() => {
                  handleSelectCopyDose(null);
                }}
              >
                <span className="" style={{ marginRight: '8px' }}>
                  <BiArrowBack
                    className="header-icon"
                    data-testid="isBackBtn"
                  />
                </span>
                {/* <span className="ketamine-general-label" style={{marginTop: 10, marginBottom: 0, paddingBottom: 0, marginLeft: 0, fontSize: '18px'}}> */}
                Edit Copied Dose
                {/* </span> */}
              </h6>
            )}

            {selectedDose && (
              <>
                <div
                  className=""
                  style={{ padding: '0px 15px', marginBottom: 20 }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ marginTop: '15px' }}>
                      <span
                        className="ketamine-general-label"
                        style={{
                          marginBottom: 0,
                          paddingBottom: 0,
                          marginLeft: 0,
                        }}
                      >
                        Medication:
                      </span>

                      <p className="sidebarText">
                        This is the Medication that this dose will belong to.
                      </p>
                    </div>

                    <div
                      className="calculationDialogContent"
                      style={{ marginBottom: '0px' }}
                    >
                      <label
                        htmlFor="isSolidOnly"
                        className="departmentItemText"
                        style={{ flexDirection: 'row' }}
                      >
                        Show Only Epi:
                        <HMSwitch
                          style={{ marginLeft: '10px' }}
                          checked={showOnlyEpi}
                          onChange={(e: any, checked: boolean) => {
                            setShowOnlyEpi(checked);
                          }}
                        />
                        {/* <InputSwitch
                          name="isSolidOnly"
                          style={{ marginLeft: '10px' }}
                          checked={showOnlyEpi}
                          onChange={(e) => {
                            setShowOnlyEpi(e.value);
                          }}
                        /> */}
                      </label>
                    </div>
                  </div>

                  <SearchableDropdown<MedicationItem>
                    id="searchDropdown"
                    value={parentMedication ? parentMedication : undefined}
                    options={allMedications}
                    labelField={(option) => option.name}
                    valueField={(option) => option.name}
                    keyField={(option) => option.uid}
                    disabled={!edittable}
                    onChange={(option: MedicationItem) => {
                      setParentMedication(option);
                    }}
                    onClear={() => {
                      setParentMedication(null);
                    }}
                    isActive={protocol ? false : true}
                    placeholder="Search Medication..."
                  />
                </div>
              </>
            )}

            <div className="siderbarContent">
              {!selectedDose && <hr style={{ margin: 0, padding: 0 }} />}

              {allDoses.length === 0 && (
                <div style={{ marginTop: '15px' }}>
                  <p className="sidebarText">
                    There are no doses to copy from.
                  </p>
                </div>
              )}

              {selectedDose && parentMedication ? (
                <div className="sidebarInputContainer">
                  <MedicationDoseSelection
                    formik={formik}
                    doseIndex={doseIndex}
                    parent={parentMedication}
                    onWeightRange={(range: string, isLow: boolean) => {
                      // handleRangeClick(range, isLow ? 'low' : 'high');

                      console.log('Range Clicked', range, isLow);

                      setRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onAgeRange={(range: string, isLow: boolean) => {
                      // handleRangeClick(range, isLow ? 'low' : 'high');

                      console.log('AGE Range Clicked', range, isLow);

                      setAgeRangeDialog(isLow ? 'low' : 'high');
                    }}
                    onBasisClick={(
                      basis: string,

                      parent: MedicationItem | ElectricalItem
                    ) => {
                      setCalculationDialogVisible(true);
                    }}
                    onMinMaxDoseClick={(
                      value: string,

                      type: 'min' | 'max' | 'maxTotal' | 'calcMin' | 'calcMax'
                    ) => {
                      setEditData(value);

                      setMinMaxDialog(type);
                    }}
                    onRoutesClick={(routes: string[]) => {
                      setRouteDialogVisible(true);
                    }}
                    onSingleRouteClick={(route: string, isDelete: boolean) => {
                      if (isDelete) handleConfirm(route);
                    }}
                    handleSubmit={(dose: any) => {}}
                  />
                </div>
              ) : (
                <ViewportList items={allDoses}>
                  {(dose: MedicationSubItem, index: number) => {
                    return (
                      <div
                        key={index}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          handleSelectCopyDose(dose);
                        }}
                      >
                        <MedicationDoseUI
                          dose={dose}
                          doseIndex={index + 1}
                          showProtocol={true}
                        />
                        Change 40 of 61 Previous change Next change Explain
                      </div>
                    );
                  }}
                </ViewportList>
              )}
            </div>
          </>
        )}

        <div className="sidebarButtons">
          <Button
            className="secondary-button btn-rightMargin"
            data-testid="cancelBtn"
            onClick={handleCloseSideout}
          >
            <span>
              <AiOutlineClose className="icon-normal" />
            </span>{' '}
            Cancel
          </Button>
          {!isOwner ? (
            <>
              {isDeactivated ? (
                <Button
                  className="primary-button"
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsBlockModal(true);
                  }}
                >
                  <span>
                    <BsCloudFill className="icon-normal" />
                  </span>{' '}
                  Reactivate
                </Button>
              ) : (
                <Button
                  className="red-background-button btn-rightMargin "
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsBlockModal(true);
                  }}
                >
                  <span>
                    <BsCloudSlashFill className="icon-normal" />
                  </span>{' '}
                  Deactivate
                </Button>
              )}
            </>
          ) : (
            <>
              {editDose && (
                <Button
                  className="red-background-button btn-rightMargin "
                  data-testid="deleteBtn"
                  onClick={() => {
                    setIsDeleteModal(true);
                  }}
                >
                  {dose?.status === 'DRAFT_DELETE' ? 'Restore' : 'Delete'}
                </Button>
              )}
              <Button
                className="primary-button"
                data-testid="saveBtn"
                onClick={handleSubmit}
                disabled={!isSaveValid}
              >
                <span>
                  <AiOutlinePlus className="icon-normal" />
                </span>{' '}
                {buttonTitle}
              </Button>
            </>
          )}
        </div>
      </Sidebar>
    </div>
  );
};

export default MedicationDoseCPRSideout;
