import { useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { HiPlus } from 'react-icons/hi';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'react-bootstrap';
import CategoryItem from '../../../data/model/CategoryItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import {
  findPairedDepartments,
  getDepartmentsFromState,
  handleCopy,
  toTitleCase,
} from '../../_global/common/Utils';
import Status from '../ProgressStatus/ProgressStatus';
import EditAccessModal from './EditAccessModal';
import { useSelector } from 'react-redux';
import { DatabaseResponse } from '../../../data/AmplifyDB';
import { User } from '../../../models';
import { BiArrowBack, BiCopy, BiRename, BiSolidCopy } from 'react-icons/bi';
import FolderProtocolAdvancedSettings from '../FolderProtocolAdvancedSettings';
import { FaLock } from 'react-icons/fa6';
import HMSwitch from '../general/HMSwitch';

interface FolderProtocolModalProps {
  isVisible: boolean;
  handleClose: () => void;
}
/* 10-11-23 Arul: Created the FolderProtocolModal component for globally for Edit Folder Page */
const AIParserSettingsModal = (props: FolderProtocolModalProps) => {
  const { isVisible, handleClose } = props;
  const [visible, setVisible] = useState(isVisible);
  const [state, setState] = useState<any>({
    isBetaLambda: false,
  });

  useEffect(() => {
    setVisible(isVisible);
    let json = localStorage.getItem('aiPdfParserSettings');
    let settings = json
      ? JSON.parse(json)
      : {
          isBetaLambda: false,
        };
    if (isVisible) {
      setState({
        isBetaLambda: settings.isBetaLambda,
      });
    } else {
      setState({
        isBetaLambda: false,
      });
    }
  }, [isVisible]);

  const customHeader = (
    <div
      className="buttonContainer contentTitleLarge hoverText"
      onClick={handleClose}
    >
      <span className="">
        <HiPlus className="header-icon" style={{ marginLeft: '5px' }} /> Add{' '}
      </span>
    </div>
  );

  const handleToggle = (key: string, value: boolean) => {
    setState({
      ...state,
      [key]: value,
    });
    localStorage.setItem(
      'aiPdfParserSettings',
      JSON.stringify({
        [key]: value,
      })
    );
  };

  return (
    <Sidebar
      visible={visible}
      position="right"
      onHide={handleClose}
      style={{ width: '25%', minWidth: '400px' }}
      className="protocolSidebar sidebarWidth"
    >
      <div className="sidebarContainer">
        <div
          className="buttonContainer contentTitleLarge hoverText"
          onClick={handleClose}
        >
          <span className="headerTilte">
            <BiArrowBack
              className="header-icon cursorPointer"
              style={{ paddingLeft: '4px', marginRight: '10px' }}
            />
            AI PDF Parser Settings
          </span>
        </div>

        <div className="contentText">
          Edit the settings for the AI PDF Parser.
        </div>

        <label
          className="ketamine-general-label"
          style={{ marginTop: '10px', marginLeft: '0px' }}
        >
          General Settings:
        </label>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>Beta Lambda Function</div>
          <HMSwitch
            checked={state.isBetaLambda}
            onChange={(e: any, checked: boolean) => {
              handleToggle('isBetaLambda', checked);
            }}
            tooltipText="Use the beta lambda function for the AI PDF Parser Protocol Name Extractor. This is a beta feature and may not be stable."
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default AIParserSettingsModal;
