import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import React, { useEffect, useMemo, useState } from 'react';
import DepartmentItem from '../../../data/model/DepartmentItem';
import OwnerImage from '../OwnerImage/OwnerImage';
import { FaTimes } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa';
import { RadioButton } from 'primereact/radiobutton';
import { ViewportList } from 'react-viewport-list';
import SearchBar from '../Search/SearchBar';
import { useSelector } from 'react-redux';
import { getDepartmentsFromState } from '../../_global/common/Utils';

interface SignUpModalProps {
  isVisible: boolean;
  handleClose: () => void;
  handleSubmit: (departments: DepartmentItem[]) => void;
  department: DepartmentItem;
  values?: DepartmentItem[];
  availableDepartments?: DepartmentItem[];
  title?: string;
  description?: string;
  includeOwner?: boolean;
  canRemoveOwner?: boolean;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
}

/* 12-11-24 Gagan: Created the SelectDepartmentsSignUpModal component for Sign Up Page */
const SelectDepartmentsSignUpModal: React.FC<SignUpModalProps> = (
  props: SignUpModalProps
) => {
  const {
    isVisible,
    handleClose,
    handleSubmit,
    department,
    values,
    availableDepartments,
    description,
    includeOwner,
    canRemoveOwner,
    title,
  } = props;

  const [state, setState] = React.useState<DepartmentItem[]>(values ?? []);
  const [search, setSearch] = useState('');
  const reducerState = useSelector((state: any) => state.department);
  const allSubDeps = useMemo(
    () => getDepartmentsFromState(department, reducerState).allSubDeps,
    [department, reducerState]
  );

  useEffect(() => {
    if (isVisible) {
      const initialState = values || [];
      setState(
        initialState.filter(
          (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
        )
      );
    }
  }, [isVisible, values]);

  //   useEffect(() => {
  //     if (isVisible && values) {
  //       let list = includeOwner === false ? [...values] : [department, ...values];
  //       setState(
  //         list.filter(
  //           (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
  //         )
  //       );
  //     }
  //   }, [isVisible, values, includeOwner, department]);

  const fullDepartmentList = useMemo(() => {
    let list: DepartmentItem[] = [];
    if (availableDepartments) list = [...availableDepartments];
    else list = [...(allSubDeps ?? [])];
    if (includeOwner !== false) list = [department, ...list];
    // Filter out any duplicates
    list = list.filter(
      (dep, index, self) => index === self.findIndex((t) => t.id === dep.id)
    );
    return list;
  }, [allSubDeps, availableDepartments, includeOwner, department]);

  const isButtonValid = useMemo(() => {
    if (canRemoveOwner === false && state.length === 0) return false;
    else return true;
  }, [state, canRemoveOwner]);

  const filteredDepartmentList = useMemo(() => {
    return fullDepartmentList.filter((dept) =>
      dept.name.toLowerCase().includes(search.toLowerCase())
    );
  }, [fullDepartmentList, search]);

  return (
    <div>
      <Dialog
        visible={isVisible}
        style={{
          minWidth: '800px',
          minHeight: '500px',
          backgroundColor: 'white',
          padding: '20px',
          margin: 0,
          display: 'flex',
          borderRadius: '12px',
          maxWidth: '500px',
        }}
        onHide={() => {
          setState(values ?? []);
          handleClose();
        }}
        showHeader={false}
        className="parseDialog"
      >
        <div
          style={{ background: 'white', padding: 0, margin: 0, gap: '3rem' }}
        >
          <h4
            className="keychain-title"
            style={{
              width: '100%',
              flex: 1,
            }}
          >
            <OwnerImage owner={department} size={40} />
            <span
              style={{
                fontSize: '1.25rem',
                fontWeight: '600',
                color: 'black',
                marginRight: '6rem',
                flex: 1,
              }}
            >
              {title ?? 'Select Departments'}
            </span>
            <Button
              className="iconButton"
              variant=""
              style={{ marginRight: '10px' }}
              onClick={() => {
                setState(values ?? []);
                handleClose();
              }}
            >
              <IoClose size="1.5rem" />
            </Button>
          </h4>
          <p
            className="departmentItemText"
            style={{
              fontSize: '0.9rem',
              color: 'black',
              margin: '10px 20px',
            }}
          >
            {description
              ? description
              : `Select the departments that will have access to this folder. ${
                  department.name
                } will have access by default.`}
          </p>
          {department && (
            <div
              style={{
                padding: '0 1.25rem',
              }}
            >
              <SearchBar
                value={search}
                onChange={(value: string, e: any) => setSearch(value)}
                placeholder="Search List..."
                containerStyle={{ width: '100%', marginBottom: '15px' }}
                style={{ fontSize: '14px' }}
              />

              <label
                htmlFor=""
                className={`notification-css-title`}
                style={{
                  alignItems: 'center',
                }}
              >
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '5px' }}
                >
                  {state.length + ' / '}
                  {fullDepartmentList.length} Agencies
                </span>
              </label>

              {/* Adding the checklist container */}
              {/* data-testid added for unit testing*/}
              <div
                data-testid="departments-list"
                style={{
                  //Show only 10 items
                  maxHeight: '400px',
                  overflowY: 'auto',
                }}
                className="contentBorder protocolCalculationPad secondaryListScroll"
              >
                <ViewportList items={filteredDepartmentList}>
                  {(department: DepartmentItem, index: number) => (
                    <div
                      key={department.id}
                      data-testid={`department-item-${department.id}`}
                      onClick={() => {
                        if (state.some((d) => d.id === department.id)) {
                          setState(state.filter((d) => d.id !== department.id));
                        } else {
                          setState([...state, department]);
                        }
                      }}
                      className={`radioBtnSelectedColor listhover cursorPointer item contentHeading contentHeight ${
                        filteredDepartmentList?.length !== index + 1
                          ? 'contentUnderline '
                          : ''
                      } `}
                    >
                      <RadioButton
                        inputId={`department-${department.id}`}
                        name="department"
                        className="radioBtnColor"
                        style={{ marginRight: '10px' }}
                        value={department.name}
                        checked={state.some((d) => d.id === department.id)}
                        onChange={() => {
                          if (state.some((d) => d.id === department.id)) {
                            setState(
                              state.filter((d) => d.id !== department.id)
                            );
                          } else {
                            setState([...state, department]);
                          }
                        }}
                      />
                      <div>{department.name}</div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
            marginTop: '10px',
            gap: '10px',
          }}
        >
          {/* Dismiss Button */}
          <Button
            className="secondary-button btn-rightMargin  btn btn-primary"
            onClick={() => {
              setState(values ?? []);
              handleClose();
            }}
          >
            <span>{<FaTimes size="0.7rem" />}</span> Dismiss
          </Button>

          {/* Accept Button */}
          <Button
            className="primary-button btn-rightMargin btn btn-primary"
            disabled={!isButtonValid}
            onClick={() => {
              handleSubmit(state);
              handleClose();
            }}
          >
            <span>{<FaCheck size="0.7rem" />}</span> Accept
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default SelectDepartmentsSignUpModal;
