import React from 'react';
import HighlightChangesRow from '../HighlightChangesRow';
import ModelItem from '../../../../data/model/ModelItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import ModelSubItem from '../../../../data/model/ModelSubItem';
interface FolderComparisonProps {
  focusItem: ModelItem<any> | ModelSubItem<any>;
  data: any[];
  changes: any[];
  isNewItem?: boolean;
}

const ComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
  data,
  isNewItem,
}) => {
  const isChange = (keys: string[]) => {
    return keys.some((key) => {
      return findBeforeValue(changes, key) || findCurrentValue(changes, key);
    });
  };

  return (
    <tbody className="comparison-rows">
      {data.map((item: any) => {
        return (
          isChange(item.keys) && (
            <HighlightChangesRow
              keys={item.keys}
              titles={item.titles}
              changes={changes}
              isNewItem={isNewItem}
            />
          )
        );
      })}
      {data.length === 0 && (
        <tr>
          <td colSpan={100} className="noChanges">
            No changes
          </td>
        </tr>
      )}
    </tbody>
  );
};
export default ComparisonView;
