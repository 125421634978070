import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Copy from '../ui/pages/actions/details/copy/Copy';
import MedicationCopy from '../ui/pages/actions/details/copy/MedicationCopy';
import EquipmentCopy from '../ui/pages/actions/details/copy/EquipmentCopy';
import VitalsCopy from '../ui/pages/actions/details/copy/VitalsCopy';
import ChecklistCopy from '../ui/pages/actions/details/copy/ChecklistCopy';
import ProtocolCopy from '../ui/pages/actions/details/copy/ProtocolCopy';
import SubDepCopy from '../ui/pages/actions/details/sub-departments/SubDepCopy';
import CategoryOwner from '../ui/pages/actions/details/sub-departments/CategoryOwner';
import ProtocolOwner from '../ui/pages/actions/details/sub-departments/ProtocolOwner';
import ImportPage from '../ui/pages/actions/details/import/ImportPage';
import { User } from '../models';
import { useSelector } from 'react-redux';
import EncryptionPage from '../ui/pages/actions/details/encryption/EncryptionPage';
import ImportDosesPage from '../ui/pages/actions/details/import/medicationDoses/ImportDosesPage';
import ImportDoseResultsPage from '../ui/pages/actions/details/import/medicationDoses/ImportDoseResultsPage';
import ImportMedicationResultsPage from '../ui/pages/actions/details/import/protocolDoses/ImportMedicationsPage';
import AuditPage from '../ui/pages/actions/details/audit/AuditPage';
import { globals } from '../ui/_global/common/Utils';
import DepartmentPage from '../ui/pages/actions/details/department/DepartmentPage';
import AIPdfParserPage from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParser';
import ImportProtocolDosesPage from '../ui/pages/actions/details/import/protocolDoses/ImportProtocolDosesPage';
import ImportPagesResults from '../ui/pages/actions/details/import/medicationDoses/ImportPagesResults';
import HinckleyDatabasePage from '../ui/pages/actions/details/hinckley-database/HinckleyDatabasePage';
import HinckleyMedications from '../ui/pages/actions/details/hinckley-database/hinckley-medications/HinckleyMedications';
import HinckleyEditMedication from '../ui/pages/actions/details/hinckley-database/hinckley-medications/HinckleyEditMedication';
import AIPdfParserProtocolAutomatic from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserProtocolAutomatic';
import AIPdfParserResultAutomatic from '../ui/pages/actions/details/ai-pdf-parser/AIPdfParserResultAutomatic';
import DatabaseMigratePage from '../ui/pages/actions/details/migrate/DatabaseMigratePage';
import MigrateMedicationDoses from '../ui/pages/actions/details/migrate/details/MigrateMedicationDoses';
import MigrateInfusionDoses from '../ui/pages/actions/details/migrate/details/MigrateInfusionDoses';
import MigrateElectricalDoses from '../ui/pages/actions/details/migrate/details/MigrateElectricalDoses';
import MigrateConcentrations from '../ui/pages/actions/details/migrate/details/MigrateConcentrations';
import IDsPage from '../ui/pages/actions/details/ids/IDsPage';
import CopySubDepConcentrations from '../ui/pages/actions/details/sub-departments/details/CopyConcentrations';
import AddIDToModelItems from '../ui/pages/actions/details/ids/details/AddIDToModelItems';
import NotificationCopy from '../ui/pages/actions/details/copy/NotificationCopy';
import ModelsPage from '../ui/pages/actions/details/models/DatabaseActionsPage';
import ListAllWorkbooks from '../ui/pages/actions/details/models/ListAllWorkbooks';
import ViewProtocolSet from '../ui/pages/actions/details/models/ViewProtocolSet';
import AddIDToDeptAdmins from '../ui/pages/actions/details/ids/details/AddIDToDeptAdmins';
import ExportPage from '../ui/pages/actions/details/export/Export';
import ExportUsers from '../ui/pages/actions/details/export/ExportUsers';
import MigrateIsRestrive from '../ui/pages/actions/details/migrate/details/MigrateIsRetrictive';
import HinckleyInfusions from '../ui/pages/actions/details/hinckley-database/hinckley-infusions/HinckleyInfusions';
// import EmailTemplateCreationPage from '../ui/pages/actions/details/email-creation/EmailCreate';
import ImportDepartmentPage from '../ui/pages/actions/details/import/departments/ImportDepartmentPage';
import MigrateDepartmentAudit from '../ui/pages/actions/details/migrate/details/MigrateDepartmentAudit';
import EmailTemplateCreationPage from '../ui/pages/actions/details/email-creation/EmailCreate';
import IndexesPage from '../ui/pages/actions/details/indexes/Indexes';
import ContactIndexes from '../ui/pages/actions/details/indexes/ContactIndexes';
import DeleteModelPage from '../ui/pages/actions/details/models/DeleteModelPage';

/* 10-27-23 praveen : Created the common component protocol route navigation */
const ActionsNavigator = () => {
  const user: User = useSelector((state: any) => state?.user);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const page = useMemo(() => {
    if (path === '/actions/copy') return <Copy />;
    else if (path === '/actions/medication-copy') return <MedicationCopy />;
    else if (path === '/actions/infusion-copy') return <MedicationCopy />;
    else if (path === '/actions/equipment-copy') return <EquipmentCopy />;
    else if (path === '/actions/vitals-copy') return <VitalsCopy />;
    else if (path === '/actions/checklist-copy') return <ChecklistCopy />;
    else if (path === '/actions/notifications-copy')
      return <NotificationCopy />;
    else if (path === '/actions/category-protocols-copy')
      return <ProtocolCopy />;
    else if (path === '/actions/sub-departments') return <SubDepCopy />;
    else if (path === '/actions/sub-departments/category-owner')
      return <CategoryOwner />;
    else if (path === '/actions/sub-departments/protocol-owner')
      return <ProtocolOwner />;
    else if (path === '/actions/sub-departments/copy-concentrations')
      return <CopySubDepConcentrations />;
    else if (path === '/actions/ids') return <IDsPage />;
    else if (path === '/actions/ids/all-modelitems')
      return <AddIDToModelItems />;
    else if (path === '/actions/ids/admin-privelages')
      return <AddIDToDeptAdmins />;
    else if (path === '/actions/category-owner') return <CategoryOwner />;
    else if (path === '/actions/database-actions') return <ModelsPage />;
    else if (path === '/actions/database-actions/delete-item')
      return <DeleteModelPage />;
    else if (path === '/actions/database-actions/protocol-sets')
      return <ListAllWorkbooks />;
    else if (path === '/actions/database-actions/view-protocol-set')
      return <ViewProtocolSet />;
    else if (path === '/actions/protocol-owner') return <ProtocolOwner />;
    else if (path === '/actions/import') return <ImportPage />;
    else if (path === '/actions/import-sub-departments')
      return <ImportDepartmentPage />;
    else if (path === '/actions/encryption') return <EncryptionPage />;
    else if (path === '/actions/audit') return <AuditPage />;
    else if (
      path === '/actions/ai-pdf-parser' ||
      path === '/actions/ai-pdf-parser/'
    )
      return <AIPdfParserPage />;
    else if (
      path === '/actions/ai-pdf-parser/auto-protocol-extraction' ||
      path === '/actions/ai-pdf-parser/auto-protocol-extraction/'
    )
      return <AIPdfParserProtocolAutomatic />;
    else if (
      path === '/actions/ai-pdf-parser/results-automatic' ||
      path === '/actions/ai-pdf-parser/results-automatic'
    )
      return <AIPdfParserResultAutomatic />;
    else if (path === '/actions/indexes') return <IndexesPage />;
    else if (path === '/actions/indexes/contacts') return <ContactIndexes />;
    else if (path === '/actions/export') return <ExportPage />;
    else if (path === '/actions/export/users') return <ExportUsers />;
    else if (path === '/actions/hinckley-database')
      return <HinckleyDatabasePage />;
    else if (path === '/actions/hinckley-database/medications')
      return <HinckleyMedications />;
    else if (path === '/actions/hinckley-database/infusions')
      return <HinckleyInfusions />;
    else if (path === '/actions/hinckley-database/view-medication')
      return <HinckleyEditMedication stateData={location.state} />;
    else if (path === '/actions/database-migrate')
      return <DatabaseMigratePage />;
    else if (path === '/actions/database-migration/department-audit')
      return <MigrateDepartmentAudit />;
    else if (path === '/actions/database-migration/medication-doses')
      return <MigrateMedicationDoses />;
    else if (path === '/actions/database-migration/infusion-doses')
      return <MigrateInfusionDoses />;
    else if (path === '/actions/database-migration/electrical-doses')
      return <MigrateElectricalDoses />;
    else if (path === '/actions/database-migration/concentrations')
      return <MigrateConcentrations />;
    else if (path === '/actions/database-migration/is-restrictive')
      return <MigrateIsRestrive />;
    else if (path === '/actions/import-protocol-medication-doses')
      return <ImportProtocolDosesPage />;
    else if (path === '/actions/import-medication-doses')
      return <ImportDosesPage />;
    else if (path === '/actions/create-department') return <DepartmentPage />;
    else if (path === '/actions/import-medication-doses/results') {
      if (
        location.state &&
        location.state.data &&
        location.state.file &&
        location.state.database
      )
        return <ImportDoseResultsPage />;
      else {
        if (globals.debug) console.log('Invalid state', location.state);
        navigate('/actions/import-protocol-medication-doses');
      }
    } else if (path === '/actions/import-medication-doses/medication-results') {
      if (
        location.state &&
        location.state.data &&
        location.state.file &&
        location.state.database
      )
        return <ImportPagesResults />;
      else {
        navigate('/actions/import-medication-doses/results');
      }
    }
    // else if (path === '/actions/email-template-creation')
    //   return <EmailTemplateCreationPage />;
    return <>null</>;
  }, [location]);

  if (!user || user.type !== 'ADMIN') {
    navigate('/');
    return null;
  }
  return <>{page}</>;
};

export default ActionsNavigator;
