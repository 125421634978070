import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkSubDeps,
  DatabaseResponse,
  deleteDepartment,
  loadDatabase,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  getDepartmentsFromState,
  getFormattedDate,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import { FaChevronRight } from 'react-icons/fa6';
import { User, UserType } from '../../../../models';
import SearchBar from '../../../components/Search/SearchBar';
import NewModelItemSideout from '../../../components/SideOut/NewModelItemSideout';
import { useDispatch } from 'react-redux';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import HMCheckbox from '../../../components/general/HMCheckbox';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import { handleGetDepartment } from '../../../../store/actions';
import Loading from '../../../components/Loading/Loading';
import CopyToClipboard from '../../../components/CopyToClipboard';
import { Tooltip } from '@mui/material';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListAgencies = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;

  const [isCreate, setIsCreate] = useState(false);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = useMemo(() => database.department, [database]);
  const reducerState = useSelector((state: any) => state.department);
  const user: User = useSelector((state: any) => state.user);

  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [agencies, setAgencies] = useState<DepartmentItem[]>(allSubDeps ?? []);
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );

  const [list, setList] = useState<DepartmentItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<DepartmentItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  const [loading, setLoading] = useState<string | null>(null);
  const [switchDepartment, setSwitchDepartment] =
    useState<DepartmentItem | null>(null);
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );

  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);

  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);

  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    DepartmentItem[]
  >([]);

  const reloadDatabase = async () => {
    // department.checkSubDeps().then((deps: DepartmentItem[]) => {
    //   setAgencies(department.allSubDeps ?? []);
    // });
    let subDepsMap = new Map();
    let allSubDepsMap = new Map();
    checkSubDeps(
      department,
      subDepsMap,
      allSubDepsMap,
      user,
      dispatch,
      undefined,
      true,
      true
    );
  };

  useEffect(() => {
    setAgencies(allSubDeps);
  }, [allSubDeps]);

  useEffect(() => {
    reloadDatabase();
    setAllCheckedBtn(false);
  }, []);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : agencies)];
    if (searchQuery !== '') {
      filteredList = filteredList.filter((item) => {
        if (user.type === UserType.ADMIN) {
          return (
            item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.id.toLowerCase().includes(searchQuery.toLowerCase())
          );
        } else {
          return item.name.toLowerCase().includes(searchQuery.toLowerCase());
        }
      });
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: DepartmentItem, b: DepartmentItem) => {
      return a.name.localeCompare(b.name);
    });
    setList(filteredList);
  };

  useEffect(() => {
    handleFilterChange(agencies);
  }, [searchQuery, agencies]);

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (agency: DepartmentItem | undefined) => {
    if (!agency) return;
    setSwitchDepartment(agency);
  };

  const handleCreateItem = () => {
    const state = {
      selectedProtocol: null,
      page: 'listVitalPage',
    };
    navigate(`database/create-agency`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const switchToDepartment = async (dep: DepartmentItem | null) => {
    if (!dep) return;
    try {
      // await department.checkParentDep(useDataStore);
      setLoading('Switching to ' + dep.name + '...');
      const response: Response = await loadDatabase(
        dep,
        dispatch,
        true,
        true,
        true,
        database.subDepsMap,
        database.allSubDepsMap
      );
      dep.calculateAdminLevel(user);
      dep.isSearchedPairedDeps = true;
      if (response.type === ResponseType.Success) {
        localStorage.setItem(
          'loggedInDepartment',
          JSON.stringify({
            id: dep.id,
            parentDepID: dep.parentDepID,
          })
        );
        document.title = `${dep.name} | OneDose`;

        await dispatch<any>(handleGetDepartment(response.data));
        navigate('/protocol');
        setLoading(null);
      } else {
        console.log('Error switching department: ', response.data);
        alert('Error switching department: ' + response.data);
        setLoading(null);
      }
    } catch (error) {
      console.log('Error switching department: ', error);
      setLoading(null);
    }
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'input',
        flex: 0.25,
        disableColumnMenu: true,
        headerAlign: 'center',
        renderHeader: () =>
          adminAccess ? (
            <div style={{ marginRight: '24px' }}>
              <HMCheckbox
                checked={mainCheckbox}
                onChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                  checked: boolean
                ) => {
                  setMainCheckbox(checked);
                  if (checked) {
                    setSelectedItems(agencies);
                  } else {
                    setSelectedItems([]);
                  }
                }}
              />
            </div>
          ) : (
            <></>
          ),
        renderCell: (params: any) => {
          let item = list.find((item) => item.id === params.value.id);
          if (item == null) return <div></div>;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );
          const isChecked = selectedItems.find(
            (selectedItem) => selectedItem.id === (item as DepartmentItem).id
          );

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
                height: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {adminAccess && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      flexDirection: 'row',
                    }}
                  >
                    <HMCheckbox
                      checked={isChecked != null}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>,
                        checked: boolean
                      ) => {
                        handleSelectionChange(item as DepartmentItem);
                      }}
                    />
                    <div
                      style={{
                        marginLeft: '2px',
                        border: '1px solid #E0E0E0',
                        height: '50%',
                        width: '1px',
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        filterOperators: [],
      },
      {
        field: 'name',
        flex: 2,
        renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
        renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {params.value.name}
            {/* {user.type === UserType.ADMIN && (
              <CopyToClipboard text={params.value.id} />
            )} */}
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
        filterable: false,
      },
      {
        field: 'modified_date',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Modified Date</div>
        ),
        filterable: false,
      },
      {
        field: 'userCount',
        flex: 1,
        renderHeader: () => <div style={{ fontWeight: 'bold' }}>Users</div>,
        renderCell: (params) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{params.value.count}</span>
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.count - v2.count,
        filterable: false,
      },
      {
        field: 'sizes',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Sub-Agencies</div>
        ),
        renderCell: (params) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{params.value.count}</span>
            <span>
              <FaChevronRight
                className="icon-normal "
                style={{ margin: '4px' }}
              />
            </span>
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.count - v2.count,
        filterable: false,
      },
    ],
    [list, department, user, selectedItems, mainCheckbox, database.users]
  );

  const rows = useMemo(() => {
    return list.map((item: DepartmentItem) => {
      return {
        id: item.id,
        input: {
          id: item.id,
        },
        name: {
          name: item.name,
        },
        modified_date: getFormattedDate(
          item?.model?.updatedAt ? item?.model?.updatedAt : new Date(),
          true
        ),
        userCount: {
          count: database.users.filter((user) => {
            if (
              user.departmentID === item.id ||
              user.pairedDepIDs?.includes(item.id)
            )
              return true;

            return false;
          }).length,
        },
        sizes: {
          count:
            allSubDeps?.filter((dep) => dep.parentDepID === item.id).length ||
            0,
        },
      };
    });
  }, [list, allSubDeps, database.users]);

  const handleSelectionChange = (agency: DepartmentItem) => {
    if (selectedItems.find((item) => item.id === agency.id)) {
      setSelectedItems((prevItems: DepartmentItem[]) => {
        const updatedItems = prevItems.filter(
          (item: DepartmentItem) => item.id !== agency.id
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, agency];
      let available = list.filter((item) => department.id === item.id);
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };
  const deleteNextAgency = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }
      // Get the current medication to delete (the first item in the array)
      const currentAgency = selectedItems[0];
      // Delete the medication
      let response = await deleteDepartment(currentAgency);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted agency: ${currentAgency.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: DepartmentItem[]) => {
          const updatedItems = prevItems.filter(
            (item: DepartmentItem) => item.id !== currentAgency.id
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete agency');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete agency');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteAgencies = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;
        for (const parmAgency of selectedItems) {
          let response = await deleteDepartment(parmAgency);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }
        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(`Successfully deleted ${successCount} vitals.`);
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} vitals.`);
          setSnackbarSeverity('error');
        }
        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete vital');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextAgency(); // Call the refactored function
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getRowClassName = useCallback(
    (params: any) => {
      const rowItem = list.find((item) => item.id === params.id);
      const isSelected = selectedItems.some((item) => item.id === params.id);
      // const adminAccess = hasAdminUserAccess(department, allSubDeps, user);

      // If the row is selected, give it the selected class.
      if (isSelected) {
        return 'selectedRow';
      }
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      // if (!adminAccess) {
      //   return 'inactiveRow';
      // }

      // Default return for other cases
      return 'white';
    },
    [department, list, selectedItems, user]
  );
  const handleSave = async () => {
    // if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
    //   try {
    //     let promises: any[] = [];
    //     for (let i = 0; i < itemDeactivationStatus.length; i++) {
    //       promises.push(
    //         handleToggleEnabled(
    //           user,
    //           database.department,
    //           itemDeactivationStatus[i]
    //         )
    //       );
    //     }
    //     let results = await Promise.all(promises);
    //     for (let i = 0; i < results.length; i++) {
    //       if (results[i].type === ResponseType.Success) {
    //         if (globals.debug) console.log('SAVED ITEM', results[i].data);
    //       } else {
    //         console.error('ERROR SAVING ITEM', results[i].data);
    //       }
    //     }
    //     reloadDatabase().then(() => {
    //       setItemDeactivationStatus([]);
    //     });
    //   } catch (error) {
    //     console.error('ERROR SAVING DEACTIVATE STATE', error);
    //   }
    // }
  };

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={
            'Delete ' +
            (selectedItems.length > 1
              ? selectedItems.length + ' Agencies?'
              : selectedItems[0].name + '?')
          }
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleConfirmDeleteAgencies}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} agencies?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}

      <ConfirmModal
        isVisible={switchDepartment != null}
        title={'Switch to ' + switchDepartment?.name + '?'}
        handleClose={() => {
          setSwitchDepartment(null);
        }}
        handleSubmit={() => switchToDepartment(switchDepartment)}
        primaryBtnName="Cancel"
        secondaryBtnName="Switch"
        primaryDescription={
          'This will switch your current agency to ' +
          switchDepartment?.name +
          ' and you will be able to see all the data for this agency.'
        }
      />

      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Agencies: ' + list.length + ' items'
            : 'Agencies: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        page={department.name}
        rightSideBtn={'edit'}
        isEditButton={false}
        // isCreateButton={adminLevel > 2 && adminAccess}
        // isCreateActive={true}
        // handleCreate={() => setIsCreate(true)}
        handleEdit={() => {}}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          setItemDeactivationStatus([]);
        }}
      />
      {loading && <Loading type="bubbles" message={loading} />}

      <Row>
        <Col sm={10}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            placeholder={'Search Agencies...'}
          />
        </Col>
        {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[25, 50, 100]}
          getRowClassName={getRowClassName}
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.id === params.id))
          }
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              // Target the <p> tags
              margin: 0, // Remove margin
            },

            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#E0EADD !important',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none', // Remove blue border on click
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none', // Remove focus outline when cell is focused
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .selectedRow': {
              backgroundColor: '#E0EADD !important',
            },

            '& .inactiveRow': {
              backgroundColor: '#f2f2f2',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListAgencies;
