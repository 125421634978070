import React, { useMemo } from 'react';
import EquipmentItem from '../../../../data/model/EquipmentItem';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import { globals } from '../../../_global/common/Utils';
import HighlightChangesRow from '../HighlightChangesRow';
interface FolderComparisonProps {
  focusItem: EquipmentItem;
  changes?: any;
}

const EQUIPMENT_DATA = [
  {
    keys: ['name'],
    titles: ['Name'],
  },
  {
    keys: ['title'],
    titles: ['Title'],
  },
  {
    keys: ['warning'],
    titles: ['Warning'],
  },
  {
    keys: ['instruction'],
    titles: ['Instructions'],
  },
  {
    keys: ['note'],
    titles: ['Note'],
  },
];

const EquipmentComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
}) => {
  const equipmentOptions = useMemo(() => {
    return {
      currentOptions: focusItem.options,
      beforeOptions: focusItem.activeItem?.options || [],
    };
  }, [focusItem]);

  const findBeforeEquipmentValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const findCurrentEquipmentValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const formatOptions = (options: any[]) => {
    return options.map((change: any, index: number) => {
      const hasValues =
        (change.archiveValue?.rangeLow !== undefined &&
          change.archiveValue?.rangeLow !== '') ||
        (change.archiveValue?.rangeHigh !== undefined &&
          change.archiveValue?.rangeHigh !== '') ||
        (change.archiveValue?.amnt !== undefined &&
          change.archiveValue?.amnt !== '');

      if (!hasValues) {
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '0.25fr 1fr 1fr 1fr',
              gap: '15px',
              padding: '10px 0px',
              minHeight: '72px',
              backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
              borderTopLeftRadius: index === 0 ? '8px' : '',
              borderTopRightRadius: index === 0 ? '8px' : '',
              borderBottomLeftRadius: index === options.length - 1 ? '8px' : '',
              borderBottomRightRadius:
                index === options.length - 1 ? '8px' : '',
              borderBottom:
                index !== options.length - 1 ? '1px solid #eaeded' : '',
            }}
          ></div>
        );
      }

      return (
        <div
          key={index}
          style={{
            display: 'grid',
            gridTemplateColumns: '0.25fr 1fr 1fr 1.75fr',
            gap: '15px',
            padding: '10px 0px',
            minHeight: '50px',
            backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
            borderTopLeftRadius: index === 0 ? '8px' : '',
            borderTopRightRadius: index === 0 ? '8px' : '',
            borderBottomLeftRadius: index === options.length - 1 ? '8px' : '',
            borderBottomRightRadius: index === options.length - 1 ? '8px' : '',
            borderBottom:
              index !== options.length - 1 ? '1px solid #eaeded' : '',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '8px',
              }}
            >
              {index + 1}:
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={`rangeLow-${index}`} className="input-label">
              Range Low (Kg)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: change.archiveValue?.rangeLow ? '#000' : '#616161',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <div>{change.archiveValue?.rangeLow || 'Min'}</div>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={`rangeHigh-${index}`} className="input-label">
              Range High (Kg)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: change.archiveValue?.rangeHigh ? '#000' : '#616161',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.rangeHigh || 'Max'}
                afterText={change.currentValue?.rangeHigh || 'Max'}
                onlyPrevious={true}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '10px',
            }}
          >
            <label htmlFor={`value-${index}`} className="input-label">
              Value
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.amnt || ''}
                afterText={change.currentValue?.amnt || ''}
                onlyPrevious={true}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  const renderOptionsChanges = (optionsChanges: any) => {
    return optionsChanges.map((change: any, index: number) => {
      let currentRangeLow =
        change.currentValue?.rangeLow === 0 ||
        change.currentValue?.rangeLow == null
          ? 'Min'
          : change.currentValue?.rangeLow;
      let currentRangeHigh =
        change.currentValue?.rangeHigh === globals.MAX_VALUE_STR ||
        change.currentValue?.rangeHigh == null
          ? 'Max'
          : change.currentValue?.rangeHigh;

      let archiveRangeLow =
        change.archiveValue?.rangeLow === 0 ||
        change.archiveValue?.rangeLow == null
          ? 'Min'
          : change.archiveValue?.rangeLow;

      let archiveRangeHigh =
        change.archiveValue?.rangeHigh === globals.MAX_VALUE_STR ||
        change.archiveValue?.rangeHigh == null
          ? 'Max'
          : change.archiveValue?.rangeHigh;

      return (
        <div
          className=""
          style={{
            display: 'grid',
            gridTemplateColumns: '0.25fr 1fr 1fr 1.75fr',
            gap: '15px',
            padding: '10px 0px',
            backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
            borderTopLeftRadius: index === 0 ? '8px' : '',
            borderTopRightRadius: index === 0 ? '8px' : '',
            borderBottomLeftRadius:
              index === optionsChanges.length - 1 ? '8px' : '',
            borderBottomRightRadius:
              index === optionsChanges.length - 1 ? '8px' : '',
            borderBottom:
              index !== optionsChanges.length - 1 ? '1px solid #eaeded' : '',
          }}
          key={index}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              marginTop: '8px',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {index + 1}:
            </div>
          </div>

          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Range Low (Kg)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: change.currentValue?.rangeLow ? '#000' : '#616161',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={archiveRangeLow}
                afterText={currentRangeLow}
                onlyCurrent={true}
              />
            </div>
          </div>

          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Range High (Kg)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: change.currentValue?.rangeHigh ? '#000' : '#616161',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={archiveRangeHigh}
                afterText={currentRangeHigh}
                onlyCurrent={true}
              />
            </div>
          </div>

          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: '10px',
            }}
          >
            <label htmlFor={''} className={` input-label`}>
              Value
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: '#000',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.amnt || ''}
                afterText={change.currentValue?.amnt || ''}
                onlyCurrent={true}
              />
            </div>
          </div>
        </div>
      );
    });
  };

  const findOptionsKey = (changes: any[]) => {
    return changes.find((change) => change.key === 'options');
  };

  const isChange = (keys: string[]) => {
    return keys.some((key) => {
      return findBeforeValue(changes, key) || findCurrentValue(changes, key);
    });
  };

  return (
    <tbody className="comparison-rows">
      {EQUIPMENT_DATA.map((item: any) => {
        return (
          isChange(item.keys) && (
            <HighlightChangesRow
              keys={item.keys}
              titles={item.titles}
              changes={changes}
              debug={item.key === 'nickname'}
            />
          )
        );
      })}

      {findOptionsKey(changes) && (
        <tr>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Equipment Options: ({equipmentOptions.beforeOptions.length} items)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                fontWeight: '500',
                fontSize: '15px',
                borderRadius: '8px',
                // paddingRight: '10px',
                backgroundColor: 'white',
              }}
            >
              {formatOptions(
                findBeforeEquipmentValue(changes, 'options') || []
              )}
            </div>
          </td>
          <td>
            <label htmlFor="Name" className="ketamine-general-label">
              Equipment Options: ({equipmentOptions.currentOptions.length}{' '}
              items)
            </label>
            <div
              style={{
                border: '1px solid #e0e0e0',
                fontWeight: '500',
                fontSize: '15px',
                borderRadius: '8px',
                // paddingRight: '10px',
                backgroundColor: 'white',
              }}
            >
              {renderOptionsChanges(
                findCurrentEquipmentValue(changes, 'options') || []
              )}
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default EquipmentComparisonView;
