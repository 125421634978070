import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useState } from 'react';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import {
  handleGetDepartment,
  handleSetSelectedDoseValue,
} from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import ChecklistSideout from '../../../../components/SideOut/doseSideOut/ChecklistSideout';
import ProtocolHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import FormItem from '../../../../../data/model/FormItem';
// import { Accordion, Button, Form, InputGroup } from "react-bootstrap";
// import { IoChevronForward, IoClose, IoSearch } from "react-icons/io5";
import {
  CheckListDB,
  createCheckList,
} from '../../../../../data/functions/CheckListDB';
import { FormGroup, User } from '../../../../../models';
import { FormOption, ProgressStatus } from '../../../../../API';
import CheckListGroups from './CheckListGroups';
import {
  generateID,
  globals,
  isObjectEqual,
} from '../../../../_global/common/Utils';

interface CheckListNewPageProps {
  stateData?: any;
}

/* 10-17-23 Arul: Created checklist Protocol Edit Page component globally */
const CheckListNewPage: React.FC<CheckListNewPageProps> = ({ stateData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [editDose, setEditDose] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<FormGroup | null>(null);
  const [doseIndex, setDoseIndex] = useState<number>(0);
  const user: User = useSelector((state: any) => state?.user);

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department = database.department;
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [isWarningModal, setIsWarningModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      items: [],
      departmentID: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      items: Yup.array(),
      departmentID: Yup.string(),
    }),
    onSubmit: (values) => {
      if (globals.debug) console.log(values);
    },
  });

  const handleCheckListCreate = (data: any, prevGroup?: FormGroup) => {
    let newCheckList: FormGroup = {
      id: prevGroup ? prevGroup.id : generateID(),
      title: data.title,
      options: data.items.map((item: FormOption, index: number) => ({
        ...item,
        id: item.id == null ? generateID() : item.id,
        index: item.index == null ? index : item.index,
      })),
      isUrgent: data.isUrgent,
      isDouble: data.isDouble,
      index: prevGroup ? prevGroup.index : formik.values.items.length,
    };
    if (!prevGroup || formik.values.items.length === 0) {
      let newOptions = [...formik.values.items, newCheckList];
      formik.setFieldValue('items', newOptions);
    } else if (prevGroup) {
      let updatedItems: FormGroup[] = formik.values.items.filter(
        (item: any) => !isObjectEqual(prevGroup, item)
      );
      updatedItems.push(newCheckList);
      formik.setFieldValue('items', updatedItems);
    } else {
      console.error(
        'Unexpected case: prevDose is not provided and also items is not empty.'
      );
    }
  };

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-24-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleCreateCheckList = async () => {
    try {
      if (formik.isValid) {
        const newChecklist: CheckListDB = {
          name: formik.values.name,
          departmentID: department.id,
          items: formik.values.items,
          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
          createdBy: user.id,
        };
        let results: Response = await createCheckList(newChecklist);
        if (results.type === ResponseType.Success) {
          let isLoaded: boolean = await reloadDatabase();
          if (isLoaded) {
            if (globals.debug) console.log('Database reloaded successfully');
            const checklist: FormItem = results.data;
            if (protocol) {
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else {
              navigate(`/database/list-checklists`, {
                state: {
                  department: department,
                  data: database.checklists,
                  database: database,
                },
              });
            }
          }
        }
      } else {
        /* If the form is invalid, then show the error message */
        if (globals.debug)
          console.log('Form is invalid, showing error message');
      }
    } catch (e) {
      if (globals.debug) console.log(e);
    }
  };

  /* 11-17-23 Arul: Function to handle Cancel button */
  const handleCancel = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-checklists`, {
        state: {
          department: department,
          data: database.checklists,
          database: database,
        },
      });
    }
  };

  //create new group and open sideout modal
  const onAddDoseClick = () => {
    setEditDose(false);
    setSidebarVisible(!sidebarVisible);
    setSelectedData(null);
    dispatch<any>(handleSetSelectedDoseValue(null));
  };

  //close sideout modal
  const handleCloseModal = (e: any) => {
    setSidebarVisible(false);
    setSelectedData(null);
  };

  const handleCheckListGroupRemove = (dose: FormGroup) => {
    const updateItems = formik.values.items.filter(
      (item: any) => !isObjectEqual(dose, item)
    );
    formik.setFieldValue('items', updateItems);
  };

  const handleEditPage = (item: any, index: any) => {
    setSidebarVisible(true);
    setDoseIndex(index);
    setSelectedData(item);
    setEditDose(true);
  };

  return (
    <div className="screen-container">
      {sidebarVisible && (
        <ChecklistSideout
          group={selectedData ? selectedData : undefined}
          visible={sidebarVisible}
          setVisible={handleCloseModal}
          editDose={editDose}
          doseIndex={doseIndex}
          onSubmit={handleCheckListCreate}
          onRemove={handleCheckListGroupRemove}
        />
      )}
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={() => {
            setIsWarningModal(false);
            if (protocol) {
              if (globals.debug)
                console.log('Navigate to protocol detail page');
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else {
              navigate(`/database/list-checklists`, {
                state: {
                  department: department,
                  data: database.checklists,
                  database: database,
                },
              });
            }
          }}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Checklist.  Click cancel to return to Checklist details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      <ProtocolHeader
        name={'Create Checklist'}
        page={protocol ? protocol.name : 'Checklists'}
        type={'protocol'}
        protocolDetail={protocol}
        isCreateButton={true}
        isCreateActive={formik.isValid && formik.dirty}
        isBackButton={true}
        isDotButton={true}
        handleCancel={handleCancel}
        handleCreate={handleCreateCheckList}
      />
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header">
            Checklist
          </h5>
          <label htmlFor="Name" className="ketamine-general-label">
            Name<span className="required-field">*</span>
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="form-control-general"
              id="Name"
              required={true}
              data-testid="name"
              value={formik.values.name}
              onChange={(e: any) => {
                formik.setFieldValue('name', e.target.value);
              }}
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.name && formik.errors.name ? (
            <span className="errorText" data-testid="name-error">
              {formik.errors.name}
            </span>
          ) : null}
        </div>
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text" data-testid="form-header-right">
            Checklist Groups
          </h5>
          <div style={{ marginTop: '5px' }}>
            <span className="ketamine-general-label"> Option</span>
            <div
              onClick={onAddDoseClick}
              className="cursorPointer contentBorder protocolCalculationPad contentHeading newProtocolBorder newRouteButton"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <span
                style={{
                  textDecoration: 'underLine',
                  textUnderlinePosition: 'under',
                }}
              >
                <HiPlus className="text-icon " /> Add Group
              </span>
            </div>
          </div>
          <ViewportList items={formik.values.items}>
            {(item: any, index: any) => (
              <div
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  handleEditPage(item, index);
                }}
              >
                <CheckListGroups data={item} groupIndex={index} />
              </div>
            )}
          </ViewportList>
        </div>
      </div>
    </div>
  );
};

export default CheckListNewPage;
