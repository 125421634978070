import { Concentration, ProgressStatus } from '../../models';
import { User } from '../../models';
import {
  findDepartmentOwner,
  getActiveID,
  globals,
} from '../../ui/_global/common/Utils';
import {
  BatchQuery,
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  Response,
  ResponseType,
} from '../AmplifyDB';
import CategoryItem, { cloneCategory } from '../model/CategoryItem';
import DepartmentItem from '../model/DepartmentItem';
import MedicationItem, { cloneMedication } from '../model/MedicationItem';
import ModelItem from '../model/ModelItem';
import ModelSubItem from '../model/ModelSubItem';
import ProtocolItem, { cloneProtocol } from '../model/ProtocolItem';
import { createCategory, deleteCategory } from './CategoryDB';
import {
  createMedicationDoseItem,
  createParentMedication,
  deleteMedication,
  deleteMedicationDoseItem,
} from './MedicationDB';
import { createProtocol, deleteProtocol } from './ProtocolDB';
import MedicationSubItem, {
  cloneMedicationSubItem,
} from '../model/MedicationSubItem';
import {
  createInfusionDoseItem,
  createParentInfusion,
  deleteInfusion,
  deleteInfusionDoseItem,
} from './InfusionDB';
import InfusionItem, { cloneInfusion } from '../model/InfusionItem';
import InfusionSubItem, {
  cloneInfusionSubItem,
} from '../model/InfusionSubItem';
import {
  createElectrical,
  createElectricalDoseItem,
  deleteElectrical,
  deleteElectricalDoseItem,
} from './ElectricalDB';
import ElectricalItem, { cloneElectrical } from '../model/ElectricalItem';
import ElectricalSubItem, {
  cloneElectricalSubItem,
} from '../model/ElectricalSubItem';
import { createEquipment, deleteEquipment } from './EquipmentDB';
import EquipmentItem from '../model/EquipmentItem';
import { createVital, deleteVital } from './VitalDB';
import VitalItem from '../model/VitalItem';
import FormItem from '../model/FormItem';
import { createCheckList, deleteCheckList } from './CheckListDB';
import { concentrationsByDepartmentID } from '../../graphql/queries';
import { DataStore } from 'aws-amplify';
import { CreateConcentrationInput, DeleteConcentrationInput } from '../../API';
import {
  createConcentration,
  deleteConcentration,
} from '../../graphql/mutations';

export const cloneModelItem = (
  item: ModelItem<any> | ModelSubItem<any>
): ModelItem<any> | ModelSubItem<any> => {
  switch (item.TAG) {
    case 'CategoryItem':
      return cloneCategory(item as CategoryItem);
    case 'ProtocolItem':
      return cloneProtocol(item as ProtocolItem);
    case 'MedicationItem':
      return cloneMedication(item as MedicationItem);
    case 'MedicationSubItem':
      return cloneMedicationSubItem(item as MedicationSubItem);
    case 'InfusionItem':
      return cloneInfusion(item as InfusionItem);
    case 'InfusionSubItem':
      return cloneInfusionSubItem(item as InfusionSubItem);
    case 'ElectricalItem':
      return cloneElectrical(item as ElectricalItem);
    case 'ElectricalSubItem':
      return cloneElectricalSubItem(item as ElectricalSubItem);
    case 'EquipmentItem':
      return new EquipmentItem(item.model);
    case 'VitalItem':
      return new VitalItem(item.model);
    case 'FormItem':
      return new FormItem(item.model);
    default:
      console.error('Error in cloneItem: unknown item type');
      return item;
  }
};

export const handleToggleEnabled = (
  user: User,
  department: DepartmentItem,
  reducerState: any,
  item: ModelItem<any> | ModelSubItem<any>
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Find the owner (Department) of the ModelItem or ModelSubItem */
      const owner = findDepartmentOwner(department, reducerState, item);
      if (owner == null) {
        console.error('Error in handleToggleEnabled: owner not found');
        return reject('Owner not found');
      }
      const isDisable = item.status !== ProgressStatus.DEACTIVATED;
      if (isDisable) {
        let clone = cloneModelItem(item);
        clone.departmentID = department.id;
        clone.modifiedBy = user;
        clone.overrideID =
          item.status.includes('DRAFT') && item.activeID
            ? item.activeID
            : item.uid;
        clone.status = ProgressStatus.DEACTIVATED;

        clone.activeID = undefined;
        let createPromise;
        switch (item.TAG) {
          case 'CategoryItem':
            createPromise = createCategory(
              clone as CategoryItem,
              item as CategoryItem
            );
            break;
          case 'ProtocolItem':
            createPromise = createProtocol(
              clone as ProtocolItem,
              item as ProtocolItem
            );
            break;
          case 'MedicationItem':
            createPromise = createParentMedication(
              department,
              clone as MedicationItem,
              item as MedicationItem
            );
            break;
          case 'MedicationSubItem':
            createPromise = createMedicationDoseItem(
              clone as MedicationSubItem,
              item as MedicationSubItem
            );
            break;
          case 'InfusionItem':
            createPromise = createParentInfusion(
              department,
              clone as InfusionItem,
              item as InfusionItem
            );
            break;
          case 'InfusionSubItem':
            createPromise = createInfusionDoseItem(
              clone as InfusionSubItem,
              item as InfusionSubItem
            );
            break;
          case 'ElectricalItem':
            createPromise = createElectrical(
              clone as ElectricalItem,
              item as ElectricalItem
            );
            break;
          case 'ElectricalSubItem':
            createPromise = createElectricalDoseItem(
              clone as ElectricalSubItem,
              item as ElectricalSubItem
            );
            break;
          case 'EquipmentItem':
            createPromise = createEquipment(
              clone as EquipmentItem,
              item as EquipmentItem
            );
            break;
          case 'VitalItem':
            createPromise = createVital(clone as VitalItem, item as VitalItem);
            break;
          case 'FormItem':
            createPromise = createCheckList(
              clone as FormItem,
              item as FormItem
            );
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let createResult: Response = await createPromise;
        if (createResult.type === ResponseType.Success) {
          console.log('Success in handleToggleEnabled: CREATE succeeded', item);
          const newItem = createResult.data as
            | ModelItem<any>
            | ModelSubItem<any>;
          newItem.overrideItem = item;

          return resolve(createResult);
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            createResult.data
          );
          return resolve(createResult);
        }
      } else {
        /* Find the Delete function */
        let deletePromise;
        switch (item.TAG) {
          case 'CategoryItem':
            deletePromise = deleteCategory(
              department,
              item as CategoryItem,
              user,
              false
            );
            break;
          case 'ProtocolItem':
            deletePromise = deleteProtocol(
              department,
              item as ProtocolItem,
              user,
              false,
              false,
              false
            );
            break;
          case 'MedicationItem':
            deletePromise = deleteMedication(item as MedicationItem, false);
            break;
          case 'MedicationSubItem':
            deletePromise = deleteMedicationDoseItem(
              item as MedicationSubItem,
              false
            );
            break;
          case 'InfusionItem':
            deletePromise = deleteInfusion(item as InfusionItem, false);
            break;
          case 'InfusionSubItem':
            deletePromise = deleteInfusionDoseItem(
              item as InfusionSubItem,
              false
            );
            break;
          case 'ElectricalItem':
            deletePromise = deleteElectrical(item as ElectricalItem, false);
            break;
          case 'ElectricalSubItem':
            deletePromise = deleteElectricalDoseItem(
              item as ElectricalSubItem,
              false
            );
            break;
          case 'EquipmentItem':
            deletePromise = deleteEquipment(item as EquipmentItem, false);
            break;
          case 'VitalItem':
            deletePromise = deleteVital(item as VitalItem, false);
            break;
          case 'FormItem':
            deletePromise = deleteCheckList(item as FormItem, false);
            break;
          default:
            console.error('Error in handleToggleEnabled: unknown item type');
            return reject('Unknown item type');
        }
        let deleteResult: Response = await deletePromise;
        if (deleteResult.type === ResponseType.Success) {
          if (globals.debug)
            console.log(
              'Success in handleToggleEnabled: delete succeeded',
              item
            );
          if (item.overrideItem) {
            let newItem = cloneModelItem(item.overrideItem);
            newItem.status = ProgressStatus.DEACTIVATED;
            return resolve({
              type: ResponseType.Success,
              data: newItem,
            });
          } else {
            if (globals.debug)
              console.log('No override item found in handleToggleEnabled');
            return resolve(deleteResult);
          }
        } else {
          console.error(
            'Error in handleToggleEnabled: delete failed: ',
            deleteResult.data
          );
          return resolve({
            type: ResponseType.Failure,
            data: deleteResult.data,
          });
        }
      }
    } catch (error) {
      console.error('Error in handleToggleEnabled:', error);
      reject(error);
    }
  });
};

/**
 * Finds all users for a list of ModelItems.
 * @param items - The list of ModelItems to find users for.
 * @returns A Promise that resolves to an array of User objects.
 */
export const findUsersForModelItems = async (
  items: ModelItem<any>[] | ModelSubItem<any>[],
  users?: User[]
): Promise<ModelItem<any>[] | ModelSubItem<any>[]> => {
  let promises: Promise<User | null>[] = [];
  for (let i = 0; i < items.length; i++) {
    let userID = items[i].modifiedByID ?? items[i].createdByID;
    let find = users?.find((user) => user.id === userID);
    if (find) items[i].modifiedBy = find;
    else promises.push(items[i].findUser());

    if (promises.length > globals.QUERY_BATCH_SIZE) {
      await Promise.all(promises);
      promises = [];
    }
  }
  if (promises.length > 0) await Promise.all(promises);
  return items;
};

/**
 * Finds all users for a list of ModelItems.
 * @param items - The list of ModelItems to find users for.
 * @returns A Promise that resolves to an array of User objects.
 */
export const findUsersForDatabase = async (db: DatabaseResponse) => {
  let items: (ModelItem<any> | ModelSubItem<any>)[] = [
    ...db.medications,
    ...db.medicationDoses,
    ...db.infusions,
    ...db.infusionDoses,
    ...db.equipment,
    ...db.electrical,
    ...db.electricalDoses,
    ...db.checklists,
    ...db.vitals,
    ...db.contacts,
    ...db.keychains,
    ...db.groups,
  ];
  if (db.cprModel) items.push(db.cprModel);

  let promises: Promise<User | null>[] = [];
  for (let i = 0; i < items.length; i++) {
    let userID = items[i].modifiedByID ?? items[i].createdByID;
    let find = db.users.find((user) => user.id === userID);
    if (find) items[i].modifiedBy = find;
    else promises.push(items[i].findUser());

    if (promises.length > globals.QUERY_BATCH_SIZE) {
      await Promise.all(promises);
      promises = [];
    }
  }
  if (promises.length > 0) await Promise.all(promises);

  return items;
};

type PropagateConcenResponse = {
  department: DepartmentItem;
  medication: MedicationItem | InfusionItem;
  previousConcentrations: Concentration[];
  concentrations: Concentration[];
  createdConcentrations: Concentration[];
  duplicateConcentrations: Concentration[];
  deletedConcentrations: Concentration[];
  errors?: string[];
};

/**
 * Propagates the concentrations from the parent department to the sub departments.
 * @param department - The parent department to copy the concentrations from.
 * @param medication - The medication to copy the concentrations from.
 * @param subDeps - The list of sub departments to copy the concentrations to.
 * @returns A Promise that resolves to a Response object.
 *  @data: PropagateConcenResponse[]
 */
export const propagateConcentrations = async (
  department: DepartmentItem,
  concentrations: Concentration[],
  medication: MedicationItem | InfusionItem,
  subDeps: DepartmentItem[]
): Promise<Response> => {
  try {
    if (concentrations == null || concentrations.length === 0)
      return {
        type: ResponseType.Success,
        data: [],
      };

    let filteredDeps = subDeps.filter((dep) => dep.id !== department.id);
    let promises: Promise<PropagateConcenResponse | null>[] = [];
    for (let i = 0; i < filteredDeps.length; i++) {
      promises.push(
        copyConcentrationsToSubDepartment(
          filteredDeps[i],
          medication,
          concentrations
        )
      );
    }
    let copyResults: (PropagateConcenResponse | null)[] = await BatchQuery(
      promises,
      5
    ).catch((error) => {
      console.error('Error in propagateConcentrations:', error);
      throw error;
    });
    let results: PropagateConcenResponse[] = [];
    for (let i = 0; i < copyResults.length; i++) {
      if (copyResults[i] != null) {
        results.push(copyResults[i] as PropagateConcenResponse);
      } else {
        console.error('Copy Results is NULL: ', copyResults[i], i, subDeps[i]);
      }
    }
    if (globals.debug) {
      console.log('Propagated Concentrations Results: ', results);
      let createdConcentrations = results
        .map((r) => r.createdConcentrations)
        .flat();
      let duplicateConcentrations = results
        .map((r) => r.duplicateConcentrations)
        .flat();
      let deletedConcentrations = results
        .map((r) => r.deletedConcentrations)
        .flat();
      console.log('\tCreated Concentrations: ', createdConcentrations);
      console.log('\tDuplicate Concentrations: ', duplicateConcentrations);
      console.log('\tDeleted Concentrations: ', deletedConcentrations);
    }

    return {
      type: ResponseType.Success,
      data: results,
    };
  } catch (error) {
    console.error('Error in propagateConcentrations:', error);
    throw error;
  }
};

/**
 * Copies the concentrations from the parent department to the sub department.
 * @param dep - The sub department to copy the concentrations to.
 * @param medication - The medication to copy the concentrations from.
 * @returns A Promise that resolves to an array of Concentration objects.
 */
const copyConcentrationsToSubDepartment = async (
  dep: DepartmentItem,
  medication: MedicationItem | InfusionItem,
  newConcentrations: Concentration[]
): Promise<PropagateConcenResponse | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      let filter: any;
      const medicationID = getActiveID(medication);
      const isMedItem = medication.TAG === 'MedicationItem';
      if (medicationID == null) {
        console.error(
          'Error in copyConcentrationsToSubDepartment: medicationID is null'
        );
        return null;
      }
      if (isMedItem) {
        filter = {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
            {
              medicationID: {
                eq: medicationID,
              },
            },
          ],
        };
      } else {
        filter = {
          and: [
            {
              _deleted: {
                ne: true,
              },
            },
            {
              dripID: {
                eq: medicationID,
              },
            },
          ],
        };
      }
      /* Load the Concentrations for the Medication */
      let concenResponse = await executeQuery(
        concentrationsByDepartmentID,
        {
          departmentID: dep.id,
          filter: filter,
        },
        undefined,
        true,
        undefined,
        false,
        false
      ).catch((error) => {
        console.error(dep.name, 'Error in finding concentrations:', error);
        reject(error);
        return null;
      });

      /* Base case for error handling */
      if (concenResponse == null) {
        console.error(
          dep.name,
          'Error in querying concentrations:',
          concenResponse
        );
        return null;
      }

      const currentConcentrations: Concentration[] = concenResponse;
      // console.log(dep.name, 'Current Concentrations: ', currentConcentrations);

      /* Grab all the concentrations that are already in the current concentrations */
      let duplicateConcentrations = currentConcentrations.filter(
        (concentration) =>
          newConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) !== -1
      );
      /* Filter out concentrations that are already in the current concentrations */
      let createConcentrations = newConcentrations.filter(
        (concentration) =>
          currentConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) === -1
      );
      /* Find any concentrations that are in the current concentrations but not in the new concentrations */
      let deletedConcentrations = currentConcentrations.filter(
        (concentration) =>
          newConcentrations.findIndex(
            (c) =>
              c.firstAmnt === concentration.firstAmnt &&
              c.firstUnit === concentration.firstUnit &&
              c.secAmnt === concentration.secAmnt &&
              c.secUnit === concentration.secUnit
          ) === -1
      );
      // console.log(dep.name, 'CREATING Concentrations: ', createConcentrations);
      // console.log(
      //   dep.name,
      //   'DUPLICATE Concentrations: ',
      //   duplicateConcentrations
      // );
      // console.log(dep.name, 'DELETING Concentrations: ', deletedConcentrations);
      /* Loop through the new concentrations and create new Concentration objects */
      let concentrations: Concentration[] | null = [];
      let promises: Promise<Concentration | null>[] = [];
      for (let i = 0; i < createConcentrations.length; i++) {
        let c = createConcentrations[i];
        let newConcentration: CreateConcentrationInput = {
          departmentID: dep.id,
          medicationID: isMedItem ? medicationID : null,
          dripID: !isMedItem ? medicationID : null,
          firstAmnt: c.firstAmnt,
          firstUnit: c.firstUnit,
          secAmnt: c.secAmnt,
          secUnit: c.secUnit,
          color: c.color,
          status: ProgressStatus.ACTIVE,
        };
        promises.push(
          executeSingleQuery(
            createConcentration,
            { input: newConcentration },
            undefined,
            false
          )
        );
      }

      /* Delete the concentrations that are in the deletedConcentrations array */
      for (let i = 0; i < deletedConcentrations.length; i++) {
        let c = deletedConcentrations[i];
        let input: DeleteConcentrationInput = {
          id: c.id,
          _version: (c as any)._version,
        };
        promises.push(
          executeSingleQuery(
            deleteConcentration,
            { input: input },
            undefined,
            false
          )
        );
      }

      /* Batch query the promises */
      concentrations = await BatchQuery(promises).catch((error) => {
        console.error(
          dep.name,
          'Error in copyConcentrationsToSubDepartment:',
          error
        );
        reject(error);
        return null;
      });

      /* Return the results */
      if (concentrations) {
        let errors: string[] = [];
        for (let i = 0; i < concentrations.length; i++) {
          if (concentrations[i] == null) {
            errors.push(`Error in copyConcentrationsToSubDepartment: ${i}`);
          }
        }
        resolve({
          department: dep,
          medication: medication,
          previousConcentrations: currentConcentrations,
          concentrations: newConcentrations,
          createdConcentrations: createConcentrations,
          duplicateConcentrations: duplicateConcentrations,
          deletedConcentrations: deletedConcentrations,
        });
      } else {
        resolve({
          department: dep,
          medication: medication,
          previousConcentrations: currentConcentrations,
          concentrations: newConcentrations,
          createdConcentrations: [],
          duplicateConcentrations: [],
          deletedConcentrations: [],
        });
      }
      // else reject('Error in copyConcentrationsToSubDepartment');
    } catch (error) {
      console.error('Error in propagateConcentrations:', error);
      reject(error);
    }
  });
};
