import { ACKStatus } from '../../../API';
import { DraftChangeType } from '../../../data/model/DraftChangeItem';

export const commonStyle = {
  padding: '5px',
  marginBottom: '5px',
  justifyContent: 'center',
  marginRight: '5px',
  borderRadius: '5px',
};

export const getStatusStyles = (status: ACKStatus | DraftChangeType) => {
  switch (status) {
    case DraftChangeType.FOLDER:
      return {
        borderColor: '#C8C8C8',
        backgroundColor: '#C8C8C8',
        color: '#434343',
      };
    case ACKStatus.PUBLISHED:
    case ACKStatus.APPROVED:
    case DraftChangeType.PROTOCOL:
      return {
        borderColor: '#C3DBB0', // Green
        backgroundColor: '#C3DBB0', // Lighter Green
        color: '#037F02', // Dark Green
      };
    case DraftChangeType.PROTOCOL_PDF:
      return {
        borderColor: '#FFC7CE', // Red
        backgroundColor: '#FFC7CE', // Lighter Red
        color: '#DC143C', // Dark Red
      };
    case ACKStatus.REJECTED:
    case DraftChangeType.MEDICATION:
      return {
        borderColor: '#fbd2d0', // Red
        backgroundColor: '#fbd2d0', // Lighter Red
        color: '#880808', // Dark Red
      };
    case DraftChangeType.MEDICATION_DOSE:
      return {
        borderColor: '#fce9ed', // Red
        backgroundColor: '#fce9ed', // Lighter Red
        color: '#880808', // Dark Red
      };
    case DraftChangeType.INFUSION:
      return {
        borderColor: '#FCFF8B', // Red
        backgroundColor: '#FFCF8B', // Lighter Red
        color: '#bb5c34', // Dark Red
      };
    case DraftChangeType.INFUSION_DOSE:
      return {
        borderColor: '#ffe7c5', // Red
        backgroundColor: '#ffe7c5', // Lighter Red
        color: '#bb5c34', // Dark Red
      };
    case ACKStatus.PENDING:
    case DraftChangeType.ELECTRICAL:
      return {
        borderColor: '#ADD8E6', // Red
        backgroundColor: '#ADD8E6', // Lighter Red
        color: '#385285', // Dark Red
      };
    case DraftChangeType.ELECTRICAL_SHOCK:
      return {
        borderColor: '#d6ecf2', // Red
        backgroundColor: '#d6ecf2', // Lighter Red
        color: '#385285', // Dark Red
      };
    case DraftChangeType.EQUIPMENT:
      return {
        borderColor: '#e0cde5', // Red
        backgroundColor: '#e0cde5', // Lighter Red
        color: '#51235e', // Dark Red
      };
    case DraftChangeType.VITAL:
      return {
        borderColor: '#faf0b7', // Red
        backgroundColor: '#faf0b7', // Lighter Red
        color: '#745916', // Dark Red
      };
    case DraftChangeType.CHECKLIST:
      return {
        borderColor: '#F3F3F3', // Red
        backgroundColor: '#F3F3F3', // Lighter Red
        color: '#000', // Dark Red
      };
    case DraftChangeType.CPR:
      return {
        borderColor: '#E0EADD', // Red
        backgroundColor: '#E0EADD', // Lighter Red
        color: '#00534C', // Dark Red
      };
    default:
      return {
        borderColor: '#D3D3D3', // Default Gray
        backgroundColor: '#D3D3D3', // Lighter Gray
        color: '#3d3d3d', // Dark Gray
      };
  }
};
