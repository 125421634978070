import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  Response,
  ResponseType,
  getDepartments,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import VitalItem from '../../../../../data/model/VitalItem';
import FormItem from '../../../../../data/model/FormItem';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import {
  copyNotificationsFromDeptoDep,
  copyNotificationsToDepartment,
} from '../../../../../data/AmplifyActions';
import {
  getDepartmentsFromState,
  globals,
} from '../../../../_global/common/Utils';
import NotificationItem from '../../../../../data/model/NotificationItem';
import { fetchNotifications } from '../../../../../data/functions/NotificationDB';
import { ViewportList } from 'react-viewport-list';
import { FaTimes } from 'react-icons/fa';
import HMSwitch from '../../../../components/general/HMSwitch';
import { BiChevronRight } from 'react-icons/bi';
import SearchBar from '../../../../components/Search/SearchBar';
import { getAllDepartments } from '../../../../../data/functions/DepartmentDB';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const NotificationCopy = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [departments, setDepartments] = useState<DepartmentItem[]>([]);
  const [list, setList] = useState<DepartmentItem[]>([]);
  const [isChooseNotifications, setIsChooseNotifications] =
    useState<boolean>(true);

  const [info, setInfo] = useState<any>({
    departmentFrom: null,
    departmentTo: null,
    notificationListFrom: [],
    notificationListTo: [],
    pairedDeps: [] as DepartmentItem[],
  });
  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    if (info.departmentTo)
      return getDepartmentsFromState(info.departmentTo, reducerState);

    return { subDeps: [], allSubDeps: [], userDepartments: [] };
  }, [info.departmentTo, reducerState]);

  const [lists, setLists] = useState<any>({
    departmentFrom: [] as NotificationItem[],
    departmentTo: [] as NotificationItem[],
  });

  const [searchNotifications, setSearchNotifications] = useState<string>('');

  const availableDeps = useMemo(() => {
    if (info.departmentTo && allSubDeps) {
      let deps = [...allSubDeps];
      deps.push(info.departmentTo);
      return deps;
    }
    return [info.departmentTo];
  }, [info.departmentTo, allSubDeps]);

  const filteredNotifications = useMemo(() => {
    return lists.departmentFrom.filter(
      (notification: NotificationItem) =>
        notification.title
          .toLowerCase()
          .includes(searchNotifications.toLowerCase()) ||
        (notification.message &&
          notification.message
            .toLowerCase()
            .includes(searchNotifications.toLowerCase()) &&
          /* Filter out notifications that are already in the list */
          !info.notificationListFrom.find(
            (not: NotificationItem) => not.uid === notification.uid
          ))
    );
  }, [lists.departmentFrom, searchNotifications, info.notificationListFrom]);

  const loadDepartmentData = async () => {
    const response = await getAllDepartments();
    setDepartments(response);
    setList(response);
  };

  const loadNotificationData = async (
    department: DepartmentItem,
    isFrom: boolean
  ): Promise<VitalItem[] | null> => {
    const response = await fetchNotifications(department);
    if (response.type === ResponseType.Success) {
      let notification = response.data;
      return notification;
    }
    return null;
  };

  useEffect(() => {
    loadDepartmentData();
  }, []);

  // useEffect(() => {
  //     if(info.departmentFrom !== null) loadVitalsData(info.departmentFrom, true);
  //     if(info.departmentTo !== null)
  // }
  // , [info.departmentFrom, info.departmentTo]);

  const handleBack = () => {
    navigate(`/actions/copy`);
  };

  const handleDepartmentSelected = async (
    department: DepartmentItem,
    isFrom: boolean
  ) => {
    if (globals.debug) console.log('Department selected', department);
    let notification = await loadNotificationData(department, isFrom);
    if (isFrom) {
      setLists({
        ...lists,
        departmentFrom: notification,
      });
      if (isChooseNotifications === false) {
        setInfo({
          ...info,
          departmentFrom: department,
          notificationListFrom: notification,
        });
      } else {
        setInfo({
          ...info,
          departmentFrom: department,
          notificationListFrom: [],
        });
      }
    } else {
      setLists({
        ...lists,
        departmentTo: notification,
      });
      setInfo({
        ...info,
        departmentTo: department,
        notificationListTo: notification,
        pairedDeps: allSubDeps ? [...allSubDeps, department] : [department],
      });
    }
  };

  useEffect(() => {
    let l = [...departments];
    l.sort((a, b) => a.name.localeCompare(b.name));
    /* Remove the current department from the list */
    l = l.filter(
      (item) =>
        item.id !== info.departmentFrom?.id && item.id !== info.departmentTo?.id
    );
    if (globals.debug) console.log('List', l);
    setList(l);
  }, [info]);

  const handleSubmit = async () => {
    if (info.departmentFrom === null || info.departmentTo === null) {
      if (globals.debug) console.log('Error: missing department', info);
      alert('Please fill out all fields');
      return;
    } else if (info.notificationListFrom.length === 0) {
      alert(
        'Error: trying to copy an EMPTY list of notification\nFrom: ' +
          info.departmentFrom.name +
          '\nTo: ' +
          info.departmentTo.name
      );
      return;
    }
    let result: Response | undefined;
    if (isChooseNotifications) {
      console.log(
        'Copying notifications from',
        info.notificationListFrom.length,
        'to',
        info.notificationListTo.length
      );
      result = await copyNotificationsToDepartment(
        info.notificationListFrom,
        info.departmentTo,
        info.pairedDeps?.map((dep: DepartmentItem) => dep.id) ?? []
      ).catch((error) => {
        console.log('Error', error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      });
    } else {
      result = await copyNotificationsFromDeptoDep(
        info.departmentFrom,
        info.departmentTo,
        info.pairedDeps?.map((dep: DepartmentItem) => dep.id) ?? []
      ).catch((error) => {
        console.log('Error', error);
        return {
          type: ResponseType.Failure,
          data: error,
        };
      });
    }

    if (result.type === ResponseType.Success) {
      let results = result.data as number;
      if (globals.debug)
        console.log(
          'Copied',
          results,
          'notification from',
          info.departmentFrom.name,
          'to',
          info.departmentTo.name
        );
      let isConfirmed = window.confirm(
        `Copied ${results} notification from ${info.departmentFrom.name} to ${info.departmentTo.name}, route to ${info.departmentTo.name} Vitals?`
      );
      setInfo({
        departmentFrom: null,
        departmentTo: null,
        notificationListFrom: [],
        notificationListTo: [],
        pairedDeps: [] as DepartmentItem[],
      });
      setLists({
        departmentFrom: [],
        departmentTo: [],
      });
      setIsChooseNotifications(true);
    } else {
      if (globals.debug)
        console.log(
          'Copy notification from ' +
            info.departmentFrom.name +
            ' to ' +
            info.departmentTo.name +
            ' failed: ',
          result.data
        );
    }
  };

  const isSaveValid = useMemo(() => {
    console.log(
      'info',
      info.notificationListFrom.label,
      info.departmentFrom === null,
      info.departmentTo === null,
      info.notificationListFrom.length > 0
    );
    return (
      info.departmentFrom !== null &&
      info.departmentTo !== null &&
      info.notificationListFrom.length > 0
    );
  }, [info]);

  return (
    <div className="screen-container">
      <ProtocolHeader
        page={'Copy Actions'}
        name={'Copy Notifications'}
        isBackButton={true}
        rightSideBtn={'edit'}
        isEditButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleSave={handleSubmit}
        type={'protocol'}
        handleCancel={handleBack}
      />
      <Row>
        <Col sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <label
              htmlFor=""
              className="settings-general-label"
              style={
                {
                  // marginTop: '18px',
                }
              }
            >
              Choose Notifications
            </label>
            <div style={{ marginTop: '-18px' }}>
              <HMSwitch
                checked={isChooseNotifications}
                onChange={(e, checked) => {
                  setIsChooseNotifications(checked);
                }}
                tooltipText="Choose notifications to copy"
              />
            </div>
          </div>
          {/* Left column */}
          <label className="ketamine-general-label">
            Department FROM: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy notifications from.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              handleDepartmentSelected(option, true);
            }}
            onClear={() => {
              setInfo({
                ...info,
                departmentFrom: null,
                notificationListFrom: [],
              });
            }}
            placeholder="Search department..."
          />
          <label
            className="ketamine-general-label"
            style={{ marginTop: '20px' }}
          >
            Department TO: <span className="required-field">*</span>
          </label>
          <p className="sidebarText" style={{ marginLeft: '10px' }}>
            Select the department you want to copy notifications to.
          </p>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={list}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) => {
              handleDepartmentSelected(option, false);
            }}
            onClear={() => {
              setInfo({ ...info, departmentTo: null, notificationListTo: [] });
            }}
            placeholder="Search department..."
          />
          {availableDeps && availableDeps.length > 1 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {info.pairedDeps.length} /{' '}
                  {availableDeps.length}
                  <span
                    onClick={() =>
                      setInfo({
                        ...info,
                        pairedDeps: availableDeps as DepartmentItem[],
                      })
                    }
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={availableDeps}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) => {
                  let deps = [...info.pairedDeps];
                  deps.push(option);
                  setInfo({ ...info, pairedDeps: deps });
                }}
                onClear={() => {
                  setInfo({ ...info, pairedDeps: [] });
                }}
                placeholder="Search department..."
              />
              {info.pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '200px',
                  border:
                    info.pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={info.pairedDeps}>
                  {(item: DepartmentItem, index: number) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === info.pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                        // borderTopLeftRadius: '6px',
                        // borderTopRightRadius: '6px',
                        // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => {
                          let deps = [...info.pairedDeps];
                          deps = deps.filter((dep) => dep.id !== item.id);
                          setInfo({ ...info, pairedDeps: deps });
                        }}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}
          {isChooseNotifications && (
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <label className="ketamine-general-label">
                Search Notifications: {filteredNotifications.length} items
              </label>
              <SearchBar
                value={searchNotifications}
                onChange={(e) => setSearchNotifications(e)}
                placeholder="Search notifications..."
                containerStyle={{
                  width: '100%',
                  marginTop: '10px',
                }}
              />
              <div
                style={{
                  overflowY: 'auto',
                  maxHeight: '500px',
                  border:
                    filteredNotifications.length === 0
                      ? '0px'
                      : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={filteredNotifications}>
                  {(item: NotificationItem, index: number) => (
                    <div
                      key={index}
                      style={{
                        borderBottom:
                          index === filteredNotifications.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                      onClick={() => {
                        //Add to the list
                        let notifications = [
                          item,
                          ...info.notificationListFrom,
                        ];
                        setInfo({
                          ...info,
                          notificationListFrom: notifications,
                        });
                      }}
                      className="notification-copy-container"
                    >
                      <div>
                        <h6>Title: {item.title}</h6>
                        <h6>Message: {item.message}</h6>
                        <h6>Questions: {item.formQuestions.length}</h6>
                      </div>
                      <BiChevronRight className="icon" size={16} />
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </Col>
        <Col sm={6}>
          {' '}
          {/* Right column */}
          <label style={{ marginTop: 20 }} className="ketamine-general-label">
            FROM Options - {info.notificationListFrom.length} items:
          </label>
          <hr style={{ margin: 10 }} />
          {info.notificationListFrom.map(
            (item: NotificationItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{
                  marginTop: 6,
                  marginBottom: 6,
                  display: 'grid',
                  gridTemplateColumns: '16fr 1fr',
                  alignItems: 'center',
                }}
              >
                <div>
                  <h6>Title: {item.title}</h6>
                  <h6>Message: {item.message}</h6>
                  <h6>Questions: {item.formQuestions.length}</h6>
                </div>
                <FaTimes
                  className="icon-cancel"
                  size={16}
                  onClick={(e) => {
                    let notifications = [...info.notificationListFrom];
                    notifications = notifications.filter(
                      (not) => not.uid !== item.uid
                    );
                    setInfo({ ...info, notificationListFrom: notifications });
                  }}
                />
              </div>
            )
          )}
          <label style={{ marginTop: 20 }} className="ketamine-general-label">
            TO Options - {info.notificationListTo.length} items:
          </label>
          <hr style={{ margin: 10 }} />
          {info.notificationListTo.map(
            (item: NotificationItem, index: number) => (
              <div
                className="string-list-item"
                key={index.toString()}
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                <div>
                  <h6>Title: {item.title}</h6>
                  <h6>Message: {item.message}</h6>
                  <h6>Questions: {item.formQuestions.length}</h6>
                </div>
              </div>
            )
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NotificationCopy;
