import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import EquipmentItem, {
  formatForMatchingUnits,
} from '../../../../data/model/EquipmentItem';
import { globals, toTitleCase } from '../../../_global/common/Utils';
import EquipmentFullView from '../fullView/EquipmentFullView';
import FullViewDialog from '../fullView/FullViewDialog';
import { EquipmentOption } from '../../../../models';
import EquipmentComparisonView from '../comparisonView/EquipmentComparisonView';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { useNavigate } from 'react-router-dom';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

export const compareEquipmentItems = (
  currentItem: EquipmentItem,
  archiveItem: EquipmentItem
) => {
  const changes: any[] = [];

  // Define the specific fields to check in the main EquipmentItem
  const fieldsToCompare: (keyof EquipmentItem)[] = [
    'name',
    'title',
    'warning',
    'instruction',
    'note',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  // Compare non-array fields
  fieldsToCompare.forEach((key) => {
    const currentValue = currentItem[key];
    const archiveValue = archiveItem ? archiveItem[key] : '';

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  // Compare options array
  const currentOptions = currentItem.options || [];
  const archiveOptions = archiveItem?.options || [];

  const optionsChanges = currentOptions.reduce(
    (acc: any[], currentOption, index) => {
      const archiveOption = archiveOptions.find(
        (option) => option.id === currentOption.id
      );

      // Define the specific fields to check in options
      const fieldsToCompareInOptions: (keyof EquipmentOption)[] = [
        'amnt',
        'rangeLow',
        'rangeHigh',
      ];

      let optionChanged = false;
      const optionChanges: any = { index, currentValue: {}, archiveValue: {} };

      fieldsToCompareInOptions.forEach((field) => {
        let currentValue = currentOption[field];
        let archiveValue = archiveOption ? archiveOption[field] : '';
        if (field === 'amnt') {
          currentValue = formatForMatchingUnits(
            currentOption.amnt,
            currentOption.amntHigh || ''
          );
          archiveValue = formatForMatchingUnits(
            archiveOption?.amnt || '',
            archiveOption?.amntHigh || ''
          );
        }

        if (field === 'rangeHigh') {
          currentValue =
            currentValue === globals.MAX_VALUE
              ? 'Max'
              : (currentValue as string) || '';
          archiveValue =
            archiveValue === globals.MAX_VALUE
              ? 'Max'
              : (archiveValue as string) || '';
        }

        if (field === 'rangeLow') {
          currentValue =
            currentValue === 0 ? 'Min' : (currentValue as string) || '';
          archiveValue =
            archiveValue === 0 ? 'Min' : (archiveValue as string) || '';
        }

        if (compareValues(currentValue, archiveValue)) {
          optionChanged = true;
          optionChanges.currentValue[field] = String(currentValue);
          optionChanges.archiveValue[field] = String(archiveValue);
        }
      });

      // Only add to the changes if any field has changed
      if (optionChanged) {
        acc.push(optionChanges);
      } else {
        acc.push({
          index,
          currentValue: {
            amnt: formatForMatchingUnits(
              currentOption.amnt,
              currentOption.amntHigh || ''
            ),
            rangeLow: String(currentOption.rangeLow || 'Min'),
            rangeHigh: String(currentOption.rangeHigh || 'Max'),
          },
          archiveValue: {
            amnt: formatForMatchingUnits(
              archiveOption?.amnt || '',
              archiveOption?.amntHigh || ''
            ),
            rangeLow: String(archiveOption?.rangeLow || 'Min'),
            rangeHigh: String(archiveOption?.rangeHigh || 'Max'),
          },
        });
      }
      return acc;
    },
    []
  );

  if (optionsChanges.length > 0) {
    changes.push({
      key: 'options',
      changes: optionsChanges,
    });
  }

  return changes;
};

const EquipmentComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  onChangesCount,
}) => {
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as EquipmentItem,
      previousItem: currentDraft?.previousItem as EquipmentItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareEquipmentItems(
      changeItem as EquipmentItem,
      previousItem as EquipmentItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);
  const [viewMode, setViewMode] = useState<'none' | 'before' | 'after'>('none');

  const handleViewClick = (mode: 'before' | 'after') => {
    let equipment =
      mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: null,
      value: equipment,
      subValue: null,
      type: 'Equipment',
      editType: 'edit',
      editMode: false,
      page: 'reviewPage',
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = equipment.name.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/review/view/${cleanedPath}`, { state });
  };

  return (
    <div>
      {viewMode === 'none' ? (
        <div className="doseChangesWrapper">
          <table className="changesTable">
            {previousItem ? (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader beforeChange">
                      <div className="headerContent">
                        Before
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('before')}
                        >
                          View
                        </button>
                      </div>{' '}
                    </th>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        After
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <EquipmentComparisonView
                  focusItem={changeItem as EquipmentItem}
                  changes={changes}
                />
              </>
            ) : (
              <>
                <thead>
                  <tr>
                    <th className="changeHeader afterChange">
                      <div className="headerContent">
                        New {toTitleCase(currentDraft.changeType)}
                        <button
                          className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                          onClick={() => handleViewClick('after')}
                        >
                          View
                        </button>
                      </div>
                    </th>
                  </tr>
                </thead>
                <EquipmentComparisonView
                  focusItem={changeItem as EquipmentItem}
                  changes={changes}
                />
              </>
            )}
          </table>
        </div>
      ) : (
        <>
          <FullViewDialog
            isVisible={true}
            handleClose={() => setViewMode('none')}
            title="View Equipment"
          >
            <EquipmentFullView
              focusItem={
                viewMode === 'before'
                  ? (previousItem as EquipmentItem)
                  : (changeItem as EquipmentItem)
              }
              handleBack={() => setViewMode('none')}
              viewText={viewMode === 'before' ? 'Before' : 'After'}
            />
          </FullViewDialog>
        </>
      )}
    </div>
  );
};

export default EquipmentComparison;
