// Note: EditDB for Equipment
// Date: 01/11/2024
// Author: Guruprasad Venkatraman
//...........................................................................
import { DataStore } from '@aws-amplify/datastore';
import {
  DatabaseResponse,
  executeQuery,
  executeSingleQuery,
  mapModelItems,
  Response,
  ResponseType,
} from '../AmplifyDB';
import EquipmentItem from '../model/EquipmentItem';
import { FormOption, ProgressStatus } from '../../API';
import DepartmentItem from '../model/DepartmentItem';
import {
  findItemByID,
  generateID,
  getActiveID,
  globals,
  removeTypename,
} from '../../ui/_global/common/Utils';
import ProtocolItem from '../model/ProtocolItem';
import { validatePointerID } from './ProtocolDB';
import { getEquipment, listEquipment } from '../../graphql/queries';
import DraftChangeItem, { DraftChangeType } from '../model/DraftChangeItem';
import { Department, Equipment, EquipmentOption, User } from '../../models';
import { checkActiveToArchiveDraftChange, Draft } from '../AmplifyVersion';
import { DraftChangeJSON, updateDraftChangeItem } from './ReviewalDB';
import { findUsersForModelItems } from './ModelDB';

export type EquipmentDB = {
  name: string;
  title: string;
  note: string;
  warning: string;
  instruction: string;
  optionItems: EquipmentOption[];
  departmentID: string;
  status: ProgressStatus | keyof typeof ProgressStatus;
  activeID: string | null | undefined;
  overrideID?: string | null | undefined;
  version: string | null | undefined;
  modifiedBy?: string;
  createdBy: string;

  taggedProtocols?: ProtocolItem[];
};

/**
 * This function will check if the Equipment is a draft version and delete it
 * @param equipmentItem The Equipment to check
 * @returns Success if ready to create a new Equipment or Failure if there is a draft version
 */
export const checkUpgradeDraftVersion = async (
  id: string,
  isActive: boolean
): Promise<Response> => {
  try {
    let results: Equipment[];
    if (isActive) {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.activeID.eq(id)])
      );
    } else {
      results = await DataStore.query(Equipment, (e) =>
        e.and((e) => [e.status.eq('DRAFT'), e.id.eq(id)])
      );
    }

    /* There is no current draft version */
    if (results == null || results.length === 0) {
      return {
        type: ResponseType.Success,
        data: undefined,
      };
    }
    if (results.length > 1) {
      return {
        type: ResponseType.Failure,
        data: 'There are multiple draft versions',
      };
    }

    let dbEquip = results[0];
    if (dbEquip.status === ProgressStatus.DRAFT) {
      let result = await DataStore.delete(Equipment, dbEquip.id);
      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not delete correctly',
        };
      }
    }

    return {
      type: ResponseType.Success,
      data: dbEquip,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Create a new equipment in the database and choose the version
 * @param equipment EquipmentDB JSON format
 * @returns The successful equipmentItem or the error
 */
export const createEquipment = async (
  equipment: EquipmentDB | EquipmentItem,
  previousItem?: EquipmentItem
): Promise<Response> => {
  try {
    let json: EquipmentDB;
    if (equipment instanceof EquipmentItem) {
      json = {
        name: equipment.name,
        status: equipment.status,
        activeID: equipment.activeID,
        overrideID: equipment.overrideID,
        title: equipment.title,
        note: equipment.note,
        instruction: equipment.instruction,
        warning: equipment.warning,
        departmentID: equipment.departmentID,
        version:
          equipment.model?.version != null ? equipment.model.version : 'v1.0.0',
        optionItems:
          equipment.model.optionItems != null
            ? equipment.model.optionItems
            : [],
        modifiedBy: equipment.modifiedBy ? equipment.modifiedBy.id : undefined,
        createdBy: equipment.model.createdBy ? equipment.model.createdBy : '',
      };
    } else json = equipment as EquipmentDB;

    let e: Equipment;

    /* Use Case 3: Updating a current DRAFT version */
    if (
      previousItem &&
      previousItem.status.includes('DRAFT') &&
      json.status.includes('DRAFT')
    ) {
      let dbEquip = await DataStore.query(Equipment, previousItem.uid);
      if (dbEquip == null) {
        return {
          type: ResponseType.Failure,
          data: 'The DRAFT Equipment does not exist could not update',
        };
      }

      e = await DataStore.save(
        Equipment.copyOf(dbEquip, (updated) => {
          updated.name = json.name;
          updated.title = json.title;
          updated.instruction = json.instruction;
          updated.note = json.note;
          updated.warning = json.warning;
          updated.optionItems = removeTypename(json.optionItems);
          updated.modifiedBy = json.modifiedBy;
        })
      );
    } else {
      /* Use Case 1, 2, & 4: Creating a DRAFT the first time */
      e = await DataStore.save(
        new Equipment({
          name: json.name,
          departmentID: json.departmentID,
          status: json.status,
          activeID: json.activeID,
          overrideID: json.overrideID,
          version: json.version,
          title: json.title,
          instruction: json.instruction,
          note: json.note,
          warning: json.warning,
          optionItems: removeTypename(json.optionItems),
          createdBy: json.createdBy,
          modifiedBy: json.modifiedBy,
        })
      );
    }

    /* Confirm that all items have an ID */
    json.optionItems = json.optionItems.map(
      (item: EquipmentOption, index: number) => {
        let newItem: EquipmentOption = {
          ...item,
          id: item.id ? item.id : generateID(),
          index: item.index == null ? index : item.index,
        };
        return newItem;
      }
    );

    if (globals.debug)
      console.log('EQUIPMENT json.optionItems', json.optionItems);

    let equipItem = new EquipmentItem(e);

    /* Validate the protocols point to the medication --TODO need to update this for if there is an ACTIVE ID */
    if (json.taggedProtocols != null) {
      for (let i = 0; i < json.taggedProtocols.length; i++) {
        let protocol = json.taggedProtocols[i];

        /* ACTIVE - pass ID, DRAFT and activeID is null (HAS NEVER BEEN PUBLISHED) - pass UID, DRAFT with previous publish - pass activeID */
        let equipID: string | null = getActiveID(equipItem);
        if (equipID == null) {
          return {
            type: ResponseType.Failure,
            data:
              'The equipment does not have a proper version ID ' +
              equipItem.uid +
              ' ' +
              equipItem.activeID +
              ' ' +
              equipItem.status,
          };
        }
        let result = await validatePointerID(
          protocol,
          equipID,
          json.modifiedBy,
          'Equipment'
        );
        if (result.type === ResponseType.Failure) {
          return {
            type: ResponseType.Failure,
            data: result.data,
          };
        }
      }
    }
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    if (globals.debug) console.log('ERROR CREATING EQUIPMENT:', e, equipment);

    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchEquipment = async (
  dep: DepartmentItem,
  db?: DatabaseResponse,
  useDataStore: boolean = true,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

    let equipmentList: Equipment[];
    if (useDataStore) {
      equipmentList = await DataStore.query(Equipment, (m) =>
        m.and((m) => [
          m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
          m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
        ])
      );
    } else {
      equipmentList = await executeQuery(listEquipment, {
        filter: {
          and: [
            { or: depIDs.map((id) => ({ departmentID: { eq: id } })) },
            { status: { ne: 'ARCHIVE' } },
            { status: { ne: 'DELETED' } },
          ],
        },
      });
    }
    // let promises: Promise<User | null>[] = [];
    let equipment: EquipmentItem[] = [];
    for (let i = 0; i < equipmentList.length; i++) {
      let equip = new EquipmentItem(equipmentList[i]);
      // promises.push(equip.findUser());
      /* Take out the active version if there is one */
      mapModelItems(equip, equipment, equip.status, dep);
    }
    // if (waitForUsers) await Promise.all(promises);
    if (waitForUsers) await findUsersForModelItems(equipment);

    equipment.sort((a, b) => a.getName().localeCompare(b.getName()));
    return {
      type: ResponseType.Success,
      data: equipment,
    };
  } catch (error) {
    console.error('Error fetching equipment:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will publish the equipment to the database
 *    1. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
 *    2. Update the ACTIVE equipment with the new information
 *    3. Delete the DRAFT equipment
 * @param draftEquipmentItem The equipment to publish
 */

export const publishEquipment = async (
  draftEquipmentItem: EquipmentItem,
  draftChangeItem?: DraftChangeItem
): Promise<Response> => {
  try {
    /* Base Case 1 -- check if the equipment is configured correctly as a draft version */
    if (draftEquipmentItem.status !== ProgressStatus.DRAFT) {
      return {
        type: ResponseType.Failure,
        data: 'The equipment is not a draft version',
      };
    }

    let dbEquip = await DataStore.query(Equipment, draftEquipmentItem.uid);
    if (dbEquip == null) {
      return {
        type: ResponseType.Failure,
        data: 'The equipment does not exist',
      };
    }

    let activeEquipment: Equipment;

    /* Use case 1: Creating the FIRST active version */
    if (draftEquipmentItem.activeID == null) {
      /* Update the draft equipment to be active */
      activeEquipment = await DataStore.save(
        Equipment.copyOf(dbEquip, (updated) => {
          updated.status = ProgressStatus.ACTIVE;
        })
      );
    } else {
      /* Use case 2: Upgrading a active version */
      /* Step 1. Fetch the active equipment item */
      let id: string = draftEquipmentItem.activeID;
      let curEquipment = await DataStore.query(Equipment, id);

      /* Base Case 3 -- check if the active equipment exists */
      if (curEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The active equipment does not exist',
        };
      }

      let archive = new EquipmentItem(curEquipment);
      archive.status = ProgressStatus.ARCHIVE;
      archive.activeID = curEquipment.id;

      // 2. Create a new ARCHEIVED equipment based on the current ACTIVE equipment
      let archiveResult: Response = await createEquipment(archive);
      if (archiveResult.type === ResponseType.Failure) return archiveResult;
      archive = archiveResult.data as EquipmentItem;

      // 2. Update the ACTIVE equipment with the new information
      activeEquipment = await DataStore.save(
        Equipment.copyOf(curEquipment, (updated) => {
          updated.name = draftEquipmentItem.name;
          updated.optionItems = removeTypename(
            draftEquipmentItem.model.optionItems
          );
          updated.title = draftEquipmentItem.title;
          updated.note = draftEquipmentItem.note;
          updated.warning = draftEquipmentItem.warning;
          updated.instruction = draftEquipmentItem.instruction;

          updated.createdBy = draftEquipmentItem.model.createdBy;
          updated.modifiedBy = draftEquipmentItem.model.modifiedBy;

          updated.status = ProgressStatus.ACTIVE;
          updated.activeID = null;
          updated.version = draftEquipmentItem.version;
        })
      );

      // 3. Delete the DRAFT equipment
      let draftEquipment = await DataStore.delete(
        Equipment,
        draftEquipmentItem.uid
      );
      if (draftEquipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The draft equipment does not exist',
        };
      }

      // 4. Query if there any closed draft changes with the actvie model item
      checkActiveToArchiveDraftChange(activeEquipment.id, archive.uid);

      // 5. If there is a draftChangeItem then update the changeID to the new active category and the previousID to the archeived category
      if (draftChangeItem) {
        console.log('Draft Change Item', draftChangeItem);
        let new_dc: DraftChangeJSON = {
          previousDraftChange: draftChangeItem,
          changeItem: activeEquipment.id,
          changeType: draftChangeItem.changeType,
          previousItem: archive.uid,
          isClosed: true,
        };

        console.log('Preivous Draft Change Item', draftChangeItem);
        updateDraftChangeItem(new_dc).then((result) => {
          console.log('Modified Draft Change Item', result);
          if (result == null) {
            return {
              type: ResponseType.Failure,
              data: 'The draft change item did not update correctly',
            };
          }
        });
      }
    }

    let equipItem = new EquipmentItem(activeEquipment);
    return {
      type: ResponseType.Success,
      data: equipItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const deleteEquipment = async (
  equipmentItem: EquipmentItem,
  isSoft: boolean
): Promise<Response> => {
  try {
    let id: string = equipmentItem.uid;
    if (isSoft) {
      let equipment = await DataStore.query(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }

      let result = await DataStore.save(
        Equipment.copyOf(equipment, (updated) => {
          updated.status = ProgressStatus.DELETED;
        })
      );

      if (result == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment did not update correctly',
        };
      }
    } else {
      let equipment = await DataStore.delete(Equipment, id);
      if (equipment == null) {
        return {
          type: ResponseType.Failure,
          data: 'The equipment does not exist',
        };
      }
    }
    return {
      type: ResponseType.Success,
      data: equipmentItem,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const isEquipmentDraftCreated = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    let drafts = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(department.id)])
    );
    return {
      type: ResponseType.Success,
      data: drafts.length !== 0,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * This function will get all the equipment drafts from the database
 * @param db The database to get the equipment drafts from
 * @returns Success if the drafts were found with an array of the updates or Failure if there was an error
 */
export const getEquipmentDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let modelUpdates = await DataStore.query(Equipment, (c) =>
      c.and((c) => [c.status.eq('DRAFT'), c.departmentID.eq(db.department.id)])
    );

    for (let i = 0; i < modelUpdates.length; i++) {
      let model = new EquipmentItem(modelUpdates[i]);
      let activeItem = db.equipment.find(
        (c) =>
          c.uid === model.activeID ||
          (c.activeItem && c.activeItem.uid === model.activeID)
      );
      if (activeItem) {
        model.activeItem =
          activeItem.uid === model.activeID
            ? activeItem
            : activeItem.activeItem;
      }

      updates.push({
        model: model,
        title: 'Equipment ' + model.name,
        message: getChangeDescription(model),
        changeType: DraftChangeType.EQUIPMENT,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const convertEquipmentChangeToDraft = (
  dc: DraftChangeItem
): Draft | null => {
  try {
    if (dc.changeItem == null) {
      return null;
    }
    let update: Draft = {
      draftChangeItem: dc,
      model: dc.changeItem,
      title: 'Equipment ' + dc.changeItem.name,
      message: getChangeDescription(dc.changeItem as EquipmentItem),
      changeType: DraftChangeType.EQUIPMENT,
    };

    return update;
  } catch (error) {
    return null;
  }
};

function getChangeDescription(draftItem: EquipmentItem): string {
  if (draftItem.activeItem == null)
    return `Created Equipment: ${draftItem.name}`;
  return `Updated Equipment: ${draftItem.name}`;
}

export const removeCurrentEquipmentDrafts = async (
  db: DatabaseResponse
): Promise<Response> => {
  try {
    let updates: any[] = [];
    let draftEquipments = await DataStore.query(Equipment, (e) =>
      e.and((e) => [e.status.eq('DRAFT'), e.departmentID.eq(db.department.id)])
    );
    for (let i = 0; i < draftEquipments.length; i++) {
      let equip: Equipment = draftEquipments[i];
      await DataStore.delete(equip);
      updates.push({
        model: equip,
        message: `Removed Equipment: ${equip.name}`,
      });
    }

    return {
      type: ResponseType.Success,
      data: updates,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/* GraphQL API Queries */
export const getEquipmentByID = async (
  db: DatabaseResponse,
  id: string
): Promise<EquipmentItem | null> => {
  return new Promise(async (resolve, reject) => {
    try {
      /* Fetch the category from the database */
      const dbEquip = findItemByID(id, db.equipment);
      if (dbEquip != null) return resolve(dbEquip as EquipmentItem);
      else {
        executeSingleQuery(getEquipment, { id: id }, 1500)
          .then((equipment: Equipment | null | undefined) => {
            if (equipment == null) {
              resolve(null);
            } else {
              let equip = new EquipmentItem(equipment);
              /* TODO MAP THE SUB ITEMS AND TO PROTOCOLS */
              resolve(equip);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    } catch (error: any) {
      reject(error);
    }
  });
};
