import ProgressBar from '@asyrafhussin/react-progress-bar';
import { useFormik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { useEffect, useMemo, useState } from 'react';
import { IoChevronForward } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import * as Yup from 'yup';
import {
  getFormattedDate,
  getFormattedDateTime,
  handleCopy,
  globals,
  toTitleCase,
  getDepartmentsFromState,
} from '../../../_global/common/Utils';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import QuestionSideout from '../../../components/SideOut/notificationSideOut/QuestionSideout';
import ViewUserSideout from '../../../components/SideOut/notificationSideOut/ViewUserSideout';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import NotificationItem from '../../../../data/model/NotificationItem';
import ReactLoading from 'react-loading';
import {
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../../data/AmplifyDB';
import {
  deleteNotificationItem,
  getFileFromS3,
  updateNotificationItem,
} from '../../../../data/functions/NotificationDB';
import { FormQuestion, Question, User } from '../../../../models';
import FullScreenModal from './FullScreeenModal';
import GenerateThumbnail from '../../../_global/common/GenerateThumbnail';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import SearchableDropdown from '../../../components/SearchableDropdown';
import { FaTimes } from 'react-icons/fa';
import GroupItem from '../../../../data/model/GroupItem';
import { utils, writeFile } from 'xlsx-js-style';
import { getDepartmentByID } from '../../../../data/functions/DepartmentDB';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { UserType } from '../../../../models';
import { AiOutlineCloseCircle } from 'react-icons/ai';

interface NotificationViewPageProps {
  _state?: any;
}
/* 11-29-23 praveen: Created the  common component for notification view page*/
const NotificationViewPage: React.FC<NotificationViewPageProps> = ({
  _state,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location.state;
  const dispatch = useDispatch();

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state.protocol.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const [notification, setNotification] = useState<NotificationItem>(state);
  const [isQuestionModal, setIsQuestionModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>('');
  const [isDelete, setIsDelete] = useState(false);
  const [isModalVisisble, setIsModalVisible] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState('');
  const user: User = useSelector((state: any) => state.user);
  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [viewList, setViewList] = useState<User[]>([]);
  const [ackList, setAckList] = useState<User[]>([]);
  const [notViewList, setNotViewList] = useState<User[]>([]);
  const [notAckList, setNotAckList] = useState<User[]>([]);
  const [pairedDeps, setPairedDeps] = useState<DepartmentItem[]>([]);
  const [deleteFiles, setDeleteFiles] = useState(true);
  const [fullScreenIndex, setFullScreenIndex] = useState(-1);
  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const [autoGenGroup, setAutoGenGroup] = useState<GroupItem[]>([]);
  const [groups, setGroups] = useState<GroupItem[]>([]);
  const [isExportExcel, setIsExportExcel] = useState(false);
  const adminLevel = useMemo(() => {
    return database.department.adminLevel;
  }, [database.department]);

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const validAckIds = useMemo(
    () => [...new Set(notification.acknowledged.map((ack) => ack.userID))],
    [notification.acknowledged]
  );
  const validViewIds = useMemo(
    () => [...new Set(notification.viewed.map((ack) => ack.userID))],
    [notification.viewed]
  );
  const totalUsers = state?.totalUsers;
  const availableDeps = useMemo(() => {
    return department.isTopEnabled || user.type === 'ADMIN'
      ? [department, ...(allSubDeps || [])]
      : allSubDeps || [];
  }, [department, allSubDeps, user]);

  const modifiedBy = useMemo(() => {
    return database.users.find((user) =>
      notification.dbNotification.modifiedBy
        ? notification.dbNotification.modifiedBy === user.id
        : notification.dbNotification.createdBy === user.id
    );
  }, [database.users, notification]);
  //initialize the formik values
  const formik = useFormik({
    initialValues: {
      title: notification.title,
      message: notification.message ? notification.message : '',
      notifyType: 'Silent',
      type: notification.type,
      trainingDeadline: notification.deadline,
      pairedProtocol: notification.taggedProtocols,
      question: notification.questions,
      formQuestions: notification.formQuestions,
      user: notification.modifiedBy,
      viewPercentage: Math.round(
        (validViewIds?.length / totalUsers?.length) * 100
      ),
      ackPercentage: Math.round(
        (validAckIds?.length / totalUsers?.length) * 100
      ),
      attachments: [],
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Name is required'),
      message: Yup.string().required('Message is required'),
      notifyType: Yup.string(),
      type: Yup.string(),
      pairedProtocol: Yup.array(),
      question: Yup.array(),
      formQuestions: Yup.array(),
      user: Yup.string(),
      trainingDeadline: Yup.date().nullable(),
      attachment: Yup.array(),
    }),
    onSubmit: (values) => {},
  });

  const objURLS = useMemo(
    () =>
      formik.values.attachments.map((file: any) =>
        file.file ? URL.createObjectURL(file.file) : null
      ),
    [formik.values.attachments]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'O') {
        console.log('NOTIFICATION', notification);
      }
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [notification]);

  useEffect(() => {
    if (database) {
      const uniqueViewIds = new Set(validViewIds);
      const uniqueAckIds = new Set(validAckIds);
      formik.setFieldValue(
        'viewPercentage',
        Math.round((uniqueViewIds.size / totalUsers.length) * 100)
      );
      formik.setFieldValue(
        'ackPercentage',
        Math.round((uniqueAckIds.size / totalUsers.length) * 100)
      );
      const viewList: User[] = database.users.filter((user) =>
        validViewIds.includes(user.id)
      );
      const ackList: User[] = database.users.filter((user) =>
        validAckIds.includes(user.id)
      );

      const notViewList = database.users.filter(
        (user) => !validViewIds.includes(user.id)
      );
      const notAckList = database.users.filter(
        (user) => !validAckIds.includes(user.id)
      );

      setViewList(viewList);
      setAckList(ackList);
      setNotViewList(notViewList);
      setNotAckList(notAckList);
    }
  }, [database, notification]);

  // const checkForChanges = () => {
  //   const titleChanged = formik.values.title !== notification.title;
  //   const messageChanged = formik.values.message !== notification.message;
  //   const pairedDepsChanged =
  //     JSON.stringify(pairedDeps.map((d) => d.id)) !==
  //     JSON.stringify(notification.pairedDepIDs);
  //   return titleChanged || messageChanged || pairedDepsChanged;
  // };

  // useEffect(() => {
  //   setisDataChange(checkForChanges());
  // }, [formik.values.title, formik.values.message, pairedDeps]);

  const isSaveVaild = useMemo(() => {
    return (
      formik.values.title !== notification.title ||
      formik.values.message !== notification.message ||
      JSON.stringify(pairedDeps.map((d) => d.id)) !==
        JSON.stringify(notification.pairedDepIDs) ||
      formik.values.attachments.length !== notification.fileURLs.length
    );
  }, [
    formik.values.attachments,
    formik.values.title,
    formik.values.message,
    pairedDeps,
  ]);

  const downloadAttachments = async () => {
    setLoadingAttachments(true);
    let respIndex = 0;
    let attachments: any[] = [];
    for (let i = 0; i < notification.fileURLs.length; i++) {
      try {
        let result: Response = await getFileFromS3(notification.fileURLs[i]);
        if (result.type === ResponseType.Success) {
          let file = result.data as File;
          attachments.push({
            url: notification.fileURLs[i],
            file: file,
          });
        } else {
          console.error(
            `Failed to get file from S3. Error: ${result.data} Path: ${notification.fileURLs[i]}`
          );
          attachments.push({
            url: notification.fileURLs[i],
            file: null,
          });
        }
        respIndex++;
      } catch (e) {
        console.error(
          `An unexpected error occurred while downloading file. Error: ${e}`
        );
      }
    }
    formik.setFieldValue('attachments', attachments);
    setLoadingAttachments(false);
  };

  useEffect(() => {
    if (notification.fileURLs.length > 0) downloadAttachments();
  }, [notification.fileURLs]);

  useEffect(() => {
    if (notification.groups && notification.groups.length > 0) {
      const notificationGroups = notification.groups;
      const autoGenGroups = notificationGroups.filter(
        (group) => group.isAutoGen
      );
      setAutoGenGroup(autoGenGroups);

      const nonAutoGenGroups = notificationGroups.filter(
        (group) => !group.isAutoGen
      );
      setGroups(nonAutoGenGroups);
    }
  }, [notification.groups]);

  const toggleEdit = () => {
    downloadAttachments();
  };

  useEffect(() => {
    if (notification.pairedDepIDs) {
      let l: DepartmentItem[] = [];
      notification.pairedDepIDs.forEach((id: string) => {
        if (department.id === id) {
          l.push(department);
        }

        let dep = allSubDeps?.find((d: DepartmentItem) => d.id === id);
        if (dep) l.push(dep);
      });

      setPairedDeps(l);
    }
  }, [notification.pairedDepIDs, allSubDeps]);

  useEffect(() => {
    if (allSubDeps) {
      let l = [...allSubDeps];
      l = l.filter((dep) => {
        return !pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [allSubDeps, pairedDeps]);

  /* 11-29-23 praveen: function for return protocol main page*/
  const handleCancel = () => {
    navigate('/notification');
  };

  /* 11-29-23 praveen: open paired protocol sideout*/
  const handleEditProtocol = () => {
    // if (protocolData?.length >= 1) {
    //   setIsProtocolModalVisible(true)
    // }
  };

  /* 11-29-23 praveen: delete notification */
  const handleDeleteNotification = async () => {
    // const data: any = [...notification];
    // data.splice(stateData.index, 1);
    // dispatch<any>(handleSetNotification(data));
    if (globals.debug)
      console.log('Deleting notification', notification, deleteFiles);
    let results: Response = await deleteNotificationItem(
      notification,
      deleteFiles
    );
    if (results.type == ResponseType.Success) {
      let deletedNotification = results.data as NotificationItem;
      if (globals.debug)
        console.log('Deleted notification', deletedNotification);
      navigate('/notification');
    } else alert('Failed to delete notification\nError: ' + results.data);
    setIsDelete(false);
  };

  /* 11-29-23 praveen: function for saving the changes*/
  const handleSave = async () => {
    if (isSaveVaild) {
      const updatedNotifications = {
        ...notification,
        title: formik.values.title,
        message: formik.values.message,
        pairedDepIDs: pairedDeps.map((d) => d.id),
        fileURLs: formik.values.attachments.map((file: any) => file.url),
      };
      let deletedFileURLs = notification.fileURLs.filter(
        (url: string) => !updatedNotifications.fileURLs.includes(url)
      );

      try {
        const response = await updateNotificationItem(
          updatedNotifications,
          user,
          deletedFileURLs
        );
        if (response.type === ResponseType.Success) {
          setIsEdit(false);
          navigate('/notification');
        } else {
          console.error('Failed to update notification:', response.data);
        }
      } catch (error) {
        console.error('Error updating notification:', error);
      }
    }
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    setPairedDeps(l);
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    setPairedDeps(l);
  };

  const handleClearDepartments = () => {
    setPairedDeps([]);
  };

  const applyStylesToWorksheet = (ws: any) => {
    const headerStyle = {
      font: { bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '00534C' } },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
    };
    // Apply the style to the first column and set column widths
    const range = utils.decode_range(ws['!ref' as any]);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = utils.encode_cell({ r: R, c: C });
        const cell = ws[cellAddress];

        if (cell) {
          if (R === 0) {
            cell.s = headerStyle;
          }
        }
      }
    }
    // Set the width of all columns
    const columnWidth = 35; // Adjust the width as needed
    ws['!cols'] = Array(range.e.c + 1).fill({ wch: columnWidth });
  };
  const handleExportExcel = async () => {
    // Retrieve user names with additional details asynchronously
    const totalUsersDetails = await Promise.all(
      totalUsers.map(async (userId: string) => {
        const user = database.users.find((user: User) => user.id === userId);
        const notificationTitle = notification.title;

        if (user) {
          const userPairedDepIds = user.pairedDepIDs ?? [];
          const departmentAllSubs: DepartmentItem[] = allSubDeps ?? [];
          const subscribedDepartments = departmentAllSubs.filter((dep) =>
            userPairedDepIds.includes(dep.id)
          );
          const subscribedDepartmentsNames = subscribedDepartments.map(
            (dep) => dep.name
          );
          const trainigAcknowledge = notification.acknowledged.map(
            (ack: any) => {
              return ack.userID === user.id ? ack : null;
            }
          )[0];

          const trainingAckCompletionDate = trainigAcknowledge?.createdAt;
          const trainingAckCompletionDateString = trainingAckCompletionDate
            ? new Date(trainingAckCompletionDate).toLocaleDateString()
            : '';
          // Return formatted user data
          return {
            'User Name': `${user.firstName} ${user.lastName}`,
            'Notification Title': notificationTitle,
            'Notification Viewed': validViewIds.includes(user.id)
              ? 'Yes'
              : 'No',
            'Notification Acknowledged': validAckIds.includes(user.id)
              ? 'Yes'
              : 'No',

            'Training/Acknowledge Completion Date':
              trainingAckCompletionDateString,

            'Training/Acknowledge Deadline Date': notification.deadline
              ? new Date(notification.deadline.toLocaleDateString())
              : '',
            'Subscribed Departments': subscribedDepartmentsNames.join(', '),
          };
        }

        return null; // Handle case when user is not found
      })
    );

    // Filter out any null values in case some users were not found
    const validUsersDetails = totalUsersDetails.filter((user) => user !== null);

    // Prepare the data to be written in Excel
    const worksheetData = validUsersDetails as Array<{ [key: string]: string }>;

    // Create a new workbook and add the worksheet
    const ws = utils.json_to_sheet(worksheetData);
    const wb = utils.book_new();
    applyStylesToWorksheet(ws);
    utils.book_append_sheet(wb, ws, 'Notification');
    const fileName = `${department.name}_Notification_${getFormattedDateTime(new Date(), false, true)}.xlsx`;
    writeFile(wb, fileName);
  };
  //progress value
  const handleGetProgress = (notification: NotificationItem) => {
    let amount =
      notification.type === 'GENERAL'
        ? notification.isReadIDs.length
        : notification.isAckIds.length;
    return {
      amount: amount,
      percentage: Math.round((amount / totalUsers.length) * 100),
    };
  };

  const handleViewFile = (e: any, selectedIndex: number) => {
    e.stopPropagation();
    setFullScreenIndex(selectedIndex);
  };

  return (
    <div className="screen-container">
      <FullScreenModal
        isVisible={fullScreenIndex >= 0}
        objURLs={objURLS}
        files={formik.values.attachments.map((file: any) => file.file)}
        onClose={() => {
          setFullScreenIndex(-1);
        }}
        startIndex={fullScreenIndex}
      />
      <ViewUserSideout
        isVisible={isModalVisisble}
        handleClose={() => {
          setIsModalVisible(false);
        }}
        type={type}
        users={type === 'viewed' ? viewList : ackList}
      />
      {/* {isProtocolModalVisisble && <NotificationProtocolSideout
        isVisible={isProtocolModalVisisble}
        handleClose={() => { setIsProtocolModalVisible(false) }}
        data={protocolData}
        isView={true}
      />} */}
      <QuestionSideout
        dialogVisible={isQuestionModal}
        onClose={() => {
          setIsQuestionModal(false);
          setSelectedData(null);
        }}
        handleSet={() => {}}
        headerText="Notification Questions"
        question={selectedData}
        isView={true}
      />
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title="Confirm Delete?"
          handleClose={() => {
            setIsDelete(false);
          }}
          handleSubmit={handleDeleteNotification}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription="Are you sure you want to delete this notification?"
          isSelectAllBtn={user.type === UserType.ADMIN}
          selectAllDescription="Delete files with notification?"
          handleCheckAll={(checked: boolean) => {
            setDeleteFiles(checked);
          }}
        />
      )}
      {isExportExcel && (
        <ConfirmModal
          isVisible={isExportExcel}
          title="Export Excel"
          handleClose={() => {
            setIsExportExcel(false);
          }}
          handleSubmit={() => {
            handleExportExcel();
            setIsExportExcel(false);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName="Submit"
          primaryDescription="Are you sure you want to export this Notification Data as Excel?"
        />
      )}

      <div className="fixedHeader">
        <ProtocolHeader
          name={`${notification.title}`}
          description={getFormattedDate(notification.timestamp, false)}
          type={'protocol'}
          page={'Notifications'}
          isEditIcon={adminLevel > 2 && !isEdit}
          isBackButton={true}
          isDeleteButton={adminLevel > 2}
          isSaveActive={isSaveVaild}
          isDotButton={true}
          rightSideBtn={'edit'}
          handleEdit={() => {
            setIsEdit(true);
          }}
          handleDelete={() => {
            setIsDelete(true);
          }}
          isSaveButton={isEdit}
          handleSave={() => {
            handleSave();
          }}
          handleCancelEdit={() => {
            if (formik.dirty) formik.resetForm();
            setIsEdit(false);
            toggleEdit();
          }}
          handleCancel={handleCancel}
          isExportIcon={true}
          handleExport={() => setIsExportExcel(true)}
        />
      </div>
      <div className="ketamineContent">
        <div className="KetamineGeneral">
          <h5 className="ketmine-header-text">Notification</h5>
          <label htmlFor="Title" className="ketamine-general-label">
            General
          </label>
          <div className="input-container roundBorder">
            <div>
              <div
                style={{ display: 'flex', marginTop: '5px' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Modified By:</div>
                <div style={{ fontWeight: '500' }}>
                  {modifiedBy
                    ? modifiedBy?.firstName + ' ' + modifiedBy?.lastName
                    : 'Hinckley Medical'}
                </div>
              </div>
              {notification.timestamp && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>Publish Date:</div>
                  <div style={{ fontWeight: '500' }}>
                    {getFormattedDate(notification.timestamp, true)}
                  </div>
                </div>
              )}
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Style:</div>
                <div style={{ fontWeight: '500' }}>
                  {notification.isPush ? 'Push' : 'Silent'}
                </div>
              </div>
              <div
                style={{ display: 'flex' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Type:</div>
                <div style={{ fontWeight: '500' }}>
                  {toTitleCase(notification.type)}
                </div>
              </div>
              {user.type === UserType.ADMIN && (
                <div
                  style={{ display: 'flex' }}
                  className="ketamine-general-label"
                >
                  <div style={{ marginRight: '10px' }}>ID:</div>
                  <div style={{ fontWeight: '500' }}>
                    {notification.uid}
                    <span>
                      {isCopied && isCopied === notification.uid ? (
                        <BiSolidCopy
                          color={'#00534C'}
                          size="1rem"
                          className="copy_icon"
                        />
                      ) : (
                        <BiCopy
                          size="1rem"
                          className="copy_icon"
                          onClick={(e) =>
                            handleCopy(notification.uid, e, setIsCopied)
                          }
                        />
                      )}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <label htmlFor="title" className={`notification-css-title`}>
            Title
          </label>
          <div className="input-container">
            <InputText
              type="text"
              className="  form-control-general notificatonTextColor cursorPointer titleForm"
              id="title"
              name="title"
              value={formik.values.title}
              data-testid="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              readOnly={!isEdit}
              style={
                isEdit ? { cursor: 'pointer' } : { cursor: 'context-menu' }
              }
            />
            <div className="input-border"></div>
          </div>
          {formik.touched.title && formik.errors.title && (
            <div className="errorText">{formik.errors.title}</div>
          )}
          {notification.type === 'TRAINING' && (
            <div style={{ width: '100%' }}>
              <label htmlFor="notifyType" className={`notification-css-title`}>
                Training Deadline
              </label>
              {!isEdit && !formik.values.trainingDeadline && (
                <div
                  className="contentBorder"
                  style={{
                    marginTop: '10px',
                    height: '40px',
                    display: 'flex',
                  }}
                >
                  <div
                    className="contentHeadingBold"
                    style={{ color: '#616161' }}
                  >
                    No Deadline
                  </div>
                </div>
              )}
              {(isEdit || (!isEdit && formik.values.trainingDeadline)) && (
                <div className="input-container-we cursorPointer">
                  <Calendar
                    value={formik.values.trainingDeadline}
                    className="calenderWidthStyle"
                    showIcon
                    data-testid="training_calender"
                    onChange={(e) => {
                      formik.setFieldValue('trainingDeadline', e.value);
                    }}
                    disabled={!isEdit}
                    style={{
                      width: '100%',
                      display: 'inline-flex',
                      cursor: isEdit ? 'pointer' : 'context-menu',
                    }}
                  />
                </div>
              )}
            </div>
          )}
          {notification.taggedProtocols.length >= 1 && (
            <div>
              <label htmlFor="question" className={`notification-css-title`}>
                Paired Protocol
              </label>
              <div
                data-div-id="outsideRoute"
                onClick={handleEditProtocol}
                data-testid="edit-button"
                className={`flex_wrap  ${notification.taggedProtocols.length ? 'cursorPointer protocol-hover  ' : 'centerContent protocol-not-hover'}`}
              >
                <ViewportList items={notification.taggedProtocols}>
                  {(item: any, index: any) => {
                    return (
                      <div
                        key={index + item}
                        className={`selectedGreybox`}
                        style={{ margin: '4px 2px' }}
                      >
                        <div>{item?.name}</div>
                      </div>
                    );
                  }}
                </ViewportList>
              </div>
            </div>
          )}
          {notification.type === 'TRAINING' &&
            formik.values.formQuestions?.length >= 1 && (
              <div style={{ marginTop: '0px' }}>
                <label
                  data-testid="question"
                  htmlFor="question"
                  className={`notification-css-title`}
                >
                  Question
                </label>

                <div className="contentBorder cursorPointer ">
                  {/* <ViewportList items={formik.values.formQuestions}>
                    {(question: FormQuestion, index: number) => (
                      <div
                        className="listhover"
                        style={
                          formik.values.formQuestions?.length !== index + 1
                            ? { borderBottom: '1px solid #e0e0e0' }
                            : {}
                        }
                      >
                        <div
                          onClick={() => {
                            // setSelectedData(question);
                            // setIsQuestionModal(true);
                            navigate('/create/notification/question', {
                              state: {
                                previousFormQuestions:
                                  formik.values.formQuestions,
                                questionIndex: index,
                                isViewPageState: location.state,
                              },
                            });
                          }}
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0px 5px',
                            fontSize: '15px',
                          }}
                        >
                          <div className="contentHeading">
                            {question.question}
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div className="contentHeading">
                              {question.answers?.length} answers
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginLeft: '5px',
                              }}
                            >
                              {' '}
                              <IoChevronForward
                                size="1.5rem"
                                style={{ marginRight: '10px' }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </ViewportList> */}
                  <div
                    className="contentHeading greyText"
                    style={{ padding: '8px', marginLeft: '10px' }}
                    onClick={() => {
                      // setSelectedData(null);
                      // setIsQuestionModal(true);
                      navigate('/create/notification/question', {
                        state: {
                          previousFormQuestions: formik.values.formQuestions,
                          isViewPageState: location.state,
                        },
                      });
                    }}
                  >
                    Quiz Created : {formik.values.formQuestions.length}{' '}
                    Questions Added
                  </div>
                </div>
              </div>
            )}
          <label htmlFor="notifyType" className={`notification-css-title`}>
            Message
          </label>
          <InputTextarea
            className="notificatonText form-control-general"
            id="message"
            name="message"
            required={true}
            value={formik.values.message}
            data-testid="message"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            readOnly={!isEdit}
            style={{ height: '110px', verticalAlign: 'top' }}
          />
          {formik.touched.message && formik.errors.message && (
            <div className="errorText">{formik.errors.message}</div>
          )}
          {notification.pairedDepIDs &&
            notification.pairedDepIDs.length > 0 &&
            availableDeps &&
            availableDeps.length > 0 && (
              <>
                <label htmlFor="" className={`notification-css-title`}>
                  <span
                    className="headerTextMargin"
                    style={{ fontSize: '16px', marginTop: '10px' }}
                  >
                    Subscribed Departments: {pairedDeps.length} /{' '}
                    {availableDeps.length}
                    {isEdit && (
                      <span
                        onClick={() =>
                          // formik.setFieldValue('pairedDeps', department.subDeps)
                          setPairedDeps(availableDeps)
                        }
                      >
                        <div className="clickableText">Add All</div>
                      </span>
                    )}
                  </span>
                </label>
                <span
                  className="contentText greyText"
                  style={{ fontSize: '13px', marginLeft: '10px' }}
                >
                  This is a list of every department that the user subscribes
                  to.
                </span>
                <SearchableDropdown<DepartmentItem>
                  id="searchDropdown"
                  options={departmentList}
                  labelField={(option) => option.name}
                  valueField={(option) => option.name}
                  keyField={(option) => option.id}
                  multiSelect={true}
                  isActive={isEdit}
                  onChange={(option: DepartmentItem) =>
                    handleAddDepartment(option)
                  }
                  onClear={handleClearDepartments}
                  placeholder="Search department..."
                />
                {pairedDeps.length === 0 && (
                  <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                    No paired departments...
                  </h6>
                )}
                <div
                  style={{
                    // overflowY: 'auto',
                    // maxHeight: '200px',
                    border: pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                    borderRadius: '5px',
                    marginBottom: '20px',
                    marginTop: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ViewportList items={pairedDeps}>
                    {(item: DepartmentItem, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '16fr 1fr',
                          padding: '6px 10px',
                          alignItems: 'center',
                          borderBottom:
                            index === pairedDeps.length - 1
                              ? ''
                              : '1px solid #ccc',
                          // borderTopLeftRadius: '6px',
                          // borderTopRightRadius: '6px',
                          // borderBottomLeftRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                          // borderBottomRightRadius: index === formik.values.pairedDeps.length-1 ? '6px' : '0px',
                        }}
                        className="listItem"
                      >
                        <div className="contentText">{item.name}</div>
                        {isEdit && (
                          <FaTimes
                            className="icon-cancel"
                            size={16}
                            onClick={(e) => handleRemoveDepartment(item, e)}
                          />
                        )}
                      </div>
                    )}
                  </ViewportList>
                </div>
              </>
            )}

          {groups && groups.length > 0 && (
            <div>
              <label htmlFor="notifyType" className={`notification-css-title`}>
                Group
              </label>
              <div className="contentBorder" style={{ marginTop: '10px' }}>
                <ViewportList items={groups}>
                  {(item: GroupItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === groups.length - 1 ? '' : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">
                        {item.name}: {item.pairedUserIDs.length} Users added
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
          {autoGenGroup && autoGenGroup.length > 0 && (
            <div>
              <label htmlFor="notifyType" className={`notification-css-title`}>
                Users
              </label>
              <div className="contentBorder" style={{ marginTop: '10px' }}>
                <ViewportList items={autoGenGroup}>
                  {(item: GroupItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === autoGenGroup.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">
                        {item.pairedUserIDs.length} Users added
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
        <div className="KetamineGeneral">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <h5 className="ketmine-header-text">Information</h5>
          </div>
          <label htmlFor="Title" className="ketamine-general-label">
            General
          </label>

          <div className="input-container roundBorder">
            <div
              style={{ display: 'flex', marginTop: '20px' }}
              className="ketamine-general-label"
            >
              <div style={{ marginRight: '10px' }}>Viewed:</div>
              <div style={{ flex: 1, marginTop: '-20px' }}>
                <div
                  className="lightText"
                  style={{ justifyContent: 'center', color: '#000' }}
                >
                  {Math.round((viewList.length / totalUsers.length) * 100)}%
                </div>
                <ProgressBar
                  completed={Math.round(
                    (viewList.length / totalUsers.length) * 100
                  )}
                  height={'10px'}
                  width={'100%'}
                  bgColor={'#00534C'}
                  isLabelVisible={false}
                />
              </div>
              <div
                className="contentJustifyCenter"
                style={{ marginLeft: '10px', marginRight: '10px' }}
              >
                ({viewList.length} / {totalUsers.length})
              </div>
              <div
                onClick={() => {
                  if (notification.viewed.length > 0) {
                    setIsModalVisible(true);
                    setType('viewed');
                  }
                }}
                className={`'cursorPointer contentJustifyCenter' ${notification.viewed.length > 0 ? ' contentTextgreenColor' : ' contentTextInactive'}`}
                style={{ marginRight: '10px' }}
              >
                View
                <IoChevronForward style={{ marginRight: '5px' }} />
              </div>
            </div>

            {notification.type !== 'GENERAL' && (
              <div
                style={{ display: 'flex', marginTop: '20px', width: '100%' }}
                className="ketamine-general-label"
              >
                <div style={{ marginRight: '10px' }}>Acknowledge:</div>
                <div style={{ flex: 1, marginTop: '-20px' }}>
                  <div
                    className="lightText"
                    style={{ justifyContent: 'center', color: '#000' }}
                  >
                    {Math.round((ackList.length / totalUsers.length) * 100)}%
                  </div>
                  <ProgressBar
                    completed={Math.round(
                      (ackList.length / totalUsers.length) * 100
                    )}
                    height={'10px'}
                    width={'100%'}
                    bgColor={'#00534C'}
                    isLabelVisible={false}
                  />
                </div>
                <div
                  className="contentJustifyCenter"
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                >
                  ({ackList.length} / {totalUsers.length})
                </div>
                <div
                  onClick={() => {
                    if (ackList.length > 0) {
                      setIsModalVisible(true);
                      setType('acknowledged');
                    }
                  }}
                  className={`'cursorPointer contentJustifyCenter' ${notification.acknowledged.length > 0 ? ' contentTextgreenColor' : ' contentTextInactive'}`}
                  style={{ marginRight: '10px' }}
                >
                  View
                  <IoChevronForward style={{ marginRight: '5px' }} />
                </div>
              </div>
            )}
          </div>

          {notification.fileURLs.length > 0 && (
            <>
              <div>
                <label
                  htmlFor="Title"
                  className="ketamine-general-label"
                  style={{ marginTop: '10px' }}
                >
                  Attachments: ({notification.fileURLs.length})
                </label>
              </div>
              {loadingAttachments && (
                <div style={{ width: '100%', marginLeft: '45%' }}>
                  <ReactLoading
                    className="load"
                    type="bubbles"
                    height={60}
                    width={60}
                  />
                </div>
              )}
              <div className="image-gallery previewImage">
                {formik.values.attachments?.map(
                  (fileData: any, index: number) => {
                    const file = fileData.file;
                    const url = fileData.url;
                    return (
                      <div key={index}>
                        <>
                          {file == null ? (
                            <div
                              className="cursorPointer"
                              style={{
                                width: '150px',
                                marginTop: '10px',
                              }}
                              onClick={(e) => handleViewFile(e, index)}
                            >
                              <div
                                className="lightText"
                                style={{ textAlign: 'center' }}
                              >
                                Error: File could not be loaded
                              </div>
                            </div>
                          ) : (
                            <>
                              {file.type.startsWith('image') && (
                                <div
                                  className="cursorPointer"
                                  style={{ width: '150px', marginTop: '10px' }}
                                  onClick={(e) => handleViewFile(e, index)}
                                >
                                  {isEdit && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'end',
                                        marginBottom: '2px',
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          formik.setFieldValue('attachments', [
                                            ...formik.values.attachments.filter(
                                              (f: any) => f.file !== file
                                            ),
                                          ]);
                                        }}
                                        className="red_text cursorPointer icon-normal"
                                      />
                                    </div>
                                  )}
                                  <img
                                    src={objURLS[index] as string}
                                    alt={file.name}
                                    style={{ width: '150px' }}
                                  />
                                </div>
                              )}
                              {file.type.startsWith('video') && (
                                <>
                                  {isEdit && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'end',
                                        marginBottom: '2px',
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          formik.setFieldValue('attachments', [
                                            ...formik.values.attachments.filter(
                                              (f: any) => f.file !== file
                                            ),
                                          ]);
                                        }}
                                        className="red_text cursorPointer icon-normal"
                                      />
                                    </div>
                                  )}
                                  <GenerateThumbnail
                                    file={file}
                                    onClick={(e: any) =>
                                      handleViewFile(e, index)
                                    }
                                  />
                                </>
                                // <div className='cursorPointer' style={{ width: '133px', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
                                //   <video src={objURLS[index]} controls style={{ width: '133px' }} />
                                // </div>
                              )}
                              {file.type === 'application/pdf' && (
                                <>
                                  {isEdit && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'end',
                                        marginBottom: '2px',
                                      }}
                                    >
                                      <AiOutlineCloseCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          formik.setFieldValue('attachments', [
                                            ...formik.values.attachments.filter(
                                              (f: any) => f.file !== file
                                            ),
                                          ]);
                                        }}
                                        className="red_text cursorPointer icon-normal"
                                      />
                                    </div>
                                  )}
                                  <GenerateThumbnail
                                    file={file}
                                    onClick={(e: any) =>
                                      handleViewFile(e, index)
                                    }
                                  />
                                </>
                                // <div className='cursorPointer' style={{ width: '100', marginTop: '10px' }} onClick={(e) => handleViewFile(e, index)}>
                                //   <embed src={objURLS[index]} width="150" height="85" style={{zIndex: 1}} onClick={(e) => handleViewFile(e, index)}/>
                                //   {/*Create a clickable item over this one to register the touch */}
                                //   <div style={{ position: 'absolute', top: 0, left: 0, width: '150px', height: '85px', zIndex: 2}} onClick={(e) => handleViewFile(e, index)}></div>
                                // </div>
                              )}
                            </>
                          )}
                        </>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationViewPage;
