import React, { useMemo, useState } from 'react';
import ModelItem from '../../../../data/model/ModelInterface';
import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import HighlightChanges from '../HighlightChanges';
import InfusionFullView from '../fullView/InfusionFullView';
import {
  globals,
  jsonToTitleCase,
  toTitleCase,
} from '../../../_global/common/Utils';
import FullViewDialog from '../fullView/FullViewDialog';
import ComparisonView from '../comparisonView/ComparisonView';
import ReviewalItem from '../../../../data/model/ReviewalItem';
import { useNavigate } from 'react-router-dom';
import InfusionItem from '../../../../data/model/InfusionItem';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem;
  reviewal: ReviewalItem;
  onChangesCount?: (count: number) => void; // Add the callback prop
}

interface Change {
  key: string;
  currentValue: string;
  archiveValue: string;
}

const INFUSION_DATA = [
  {
    keys: ['name'],
    titles: ['Name'],
  },
  {
    keys: ['concentrations'],
    titles: ['Concentration'],
  },
  {
    keys: ['routes'],
    titles: ['Routes'],
  },
  {
    keys: ['rangeLow', 'rangeHigh'],
    titles: ['Range Low (kg)', 'Range High (kg)'],
  },
  {
    keys: ['minDose', 'maxDose'],
    titles: ['Min Dose', 'Max Dose'],
  },
  {
    keys: ['contraindication'],
    titles: ['Contraindication'],
  },
  {
    keys: ['warning'],
    titles: ['Warning'],
  },
  {
    keys: ['indication'],
    titles: ['Indication'],
  },
  {
    keys: ['interaction'],
    titles: ['Interaction'],
  },
  {
    keys: ['onset'],
    titles: ['Onset'],
  },
  {
    keys: ['duration'],
    titles: ['Duration'],
  },
  {
    keys: ['note'],
    titles: ['Note'],
  },
  {
    keys: ['medClass'],
    titles: ['Class'],
  },
  {
    keys: ['action'],
    titles: ['Action'],
  },
];

export const compareInfusionItems = (
  currentItem: InfusionItem,
  archiveItem: InfusionItem
): Change[] => {
  const changes: Change[] = [];

  // Define the specific fields to check
  const fieldsToCompare: (keyof InfusionItem)[] = [
    'name',
    'rangeLow',
    'rangeHigh',
    'contraindication',
    'warning',
    'indication',
    'interaction',
    'onset',
    'duration',
    'note',
    'medClass',
    'action',
    'routes',
    'concentrations',
    'minDose',
    'maxDose',
  ];

  // Function to compare values
  const compareValues = (value1: any, value2: any): boolean => {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return JSON.stringify(value1) !== JSON.stringify(value2);
    }
    return value1 !== value2;
  };

  function getConcentrationString(concentrations: any) {
    if (!concentrations) return '';
    let str = '';

    for (let i = 0; i < concentrations.length; i++) {
      let concen = concentrations[i];
      if (concen.firstAmnt <= 0) return 'N/A';
      str += concen.firstAmnt + ' ' + concen.firstUnit;
      if (!concen.secAmnt || concen.secAmnt <= 0) return str;
      if (concen ?? 0 > 0) {
        str += '/';
        if (concen.secAmnt != 1.0) str += concen.secAmnt + ' ' + concen.secUnit;
        else str += concen.secUnit;
      }
      if (i < concentrations.length - 1) str += ', ';
    }
    return str;
  }

  fieldsToCompare.forEach((key) => {
    let currentValue = currentItem[key];
    let archiveValue = archiveItem ? archiveItem[key] : '';

    if (key === 'rangeHigh') {
      currentValue =
        currentValue === globals.MAX_VALUE
          ? 'Max'
          : (currentValue as string) || '';
      archiveValue =
        archiveValue === globals.MAX_VALUE
          ? 'Max'
          : (archiveValue as string) || '';
    }

    if (key === 'rangeLow') {
      currentValue =
        currentValue === 0 ? 'Min' : (currentValue as string) || '';
      archiveValue =
        archiveValue === 0 ? 'Min' : (archiveValue as string) || '';
    }

    if (key === 'routes') {
      currentValue = (currentValue as string[])?.join(', ') || '';
      archiveValue =
        (archiveValue !== '' && (archiveValue as string[])?.join(', ')) || '';
    }

    if (key === 'concentrations') {
      currentValue = getConcentrationString(currentValue);
      archiveValue = getConcentrationString(archiveValue);
    }

    if (compareValues(currentValue, archiveValue)) {
      changes.push({
        key,
        currentValue: String(currentValue),
        archiveValue: String(archiveValue),
      });
    }
  });

  return changes;
};

const InfusionComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
  onChangesCount,
}) => {
  const navigate = useNavigate();
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem as InfusionItem,
      previousItem: currentDraft?.previousItem as InfusionItem | null,
    };
  }, [currentDraft]);
  const changes = useMemo(() => {
    const comparedChanges = compareInfusionItems(
      changeItem as InfusionItem,
      previousItem as InfusionItem
    );
    if (onChangesCount) {
      onChangesCount(comparedChanges.length); // Call the callback with the changes count
    }
    return comparedChanges;
  }, [changeItem, previousItem, onChangesCount]);

  const handleViewClick = (mode: 'before' | 'after') => {
    if (previousItem) {
      (previousItem as InfusionItem).subItems = (
        changeItem as InfusionItem
      ).subItems;
    }
    let inf = mode === 'before' && previousItem ? previousItem : changeItem;

    const state = {
      selectedProtocol: null,
      value: inf,
      subValue: null,
      type: 'Infusion',
      editType: 'edit',
      editMode: false,
      page: 'reviewPage',
      reviewal: reviewal,
      currentDraft: currentDraft,
    };
    //Replace everything but [A-Za-z0-9] with '-'
    let cleanedPath = inf.name.replace(/[^A-Za-z0-9]/g, '-');
    navigate(`/review/view/${cleanedPath}`, { state });
  };

  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">
                      Before
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('before')}
                      >
                        View
                      </button>
                    </div>{' '}
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      After
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <ComparisonView
                focusItem={changeItem as InfusionItem}
                changes={changes}
                data={INFUSION_DATA}
              />
            </>
          ) : (
            <>
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                      <button
                        className="secondary-button-small-border btn-rightMargin btn btn-primary viewButton"
                        onClick={() => handleViewClick('after')}
                      >
                        View
                      </button>
                    </div>
                  </th>
                </tr>
              </thead>
              <ComparisonView
                focusItem={changeItem as InfusionItem}
                changes={changes}
                data={INFUSION_DATA}
                isNewItem={true}
              />
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default InfusionComparison;
