import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../protocol/ProtocolHeader';

import { Button } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { ViewportList } from 'react-viewport-list';
import './Review.scss';

import { FaArrowDown, FaCircleCheck } from 'react-icons/fa6';
import {
  BatchQuery,
  DatabaseResponse,
  Response,
  ResponseType,
} from '../../../data/AmplifyDB';
import { getStatusStyles } from './styles';
import { LogEvent, User } from '../../../models';
import { FaTimes } from 'react-icons/fa';
import ReviewalItem, {
  cloneReviewalItem,
} from '../../../data/model/ReviewalItem';
import SearchableDropdown from '../../components/SearchableDropdown';
import {
  createDraftGroupForReviewal,
  createReviewalProcess,
  deleteAcknowledgeItem,
  deleteDraftChangeItem,
  deleteDraftGroupItem,
  DraftChangeJSON,
  DraftGroupJSON,
  getFullReviewal,
  ReviewalJSON,
} from '../../../data/functions/ReviewalDB';
import { Select, MenuItem } from '@mui/material';
import SearchBar from '../../components/Search/SearchBar';
import {
  convertDraftChangeItemToDraft,
  Draft,
  getDraftUpdates,
} from '../../../data/AmplifyVersion';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { HiPencil } from 'react-icons/hi';
import { InputText } from 'primereact/inputtext';
import { LogEventInput } from '../../../API';
import Loading from '../../components/Loading/Loading';
import CategoryItem from '../../../data/model/CategoryItem';
import MedicationItem from '../../../data/model/MedicationItem';
import ProtocolItem from '../../../data/model/ProtocolItem';
import ModelItem from '../../../data/model/ModelItem';
import MedicationSubItem from '../../../data/model/MedicationSubItem';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ElectricalItem from '../../../data/model/ElectricalItem';
import EquipmentItem from '../../../data/model/EquipmentItem';
import VitalItem from '../../../data/model/VitalItem';
import DraftGroupItem from '../../../data/model/DraftGroupItem';
import { clone } from 'lodash';
import DraftChangeItem from '../../../data/model/DraftChangeItem';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { globals } from '../../_global/common/Utils';

const ReviewEdit = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((reviewal: any) => reviewal.protocol.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const { data } = location.state;

  const [reviewal, setReviewal] = useState<ReviewalItem>(data);
  const [searchQuery, setSearchQuery] = useState('');

  const [allDrafts, setAllDrafts] = useState<Draft[]>([]);
  // const [allAvailableDrafts, setAllAvailableDrafts] = useState<Draft[]>([]);

  const prevDraftGroups = reviewal.draftGroups.map((group: DraftGroupItem) => {
    return {
      previousItem: group,
      title: group.title,
      description: group.description,
      drafts: convertDraftChangeItemToDraft(group.draftChanges),
    };
  });
  const [draftGroups, setDraftGroups] = useState<any>(
    reviewal.draftGroups.map((group: DraftGroupItem) => {
      return {
        previousItem: group,
        title: group.title,
        description: group.description,
        drafts: convertDraftChangeItemToDraft(group.draftChanges),
      };
    })
  );
  const [curIndex, setCurIndex] = useState(0); //Handles the current index of the draft group
  const [filteredUsers, setFilteredUsers] = useState<User[]>(
    database.users.filter(
      (u: User) =>
        u.id !== user.id && reviewal.reviewers.some((r: User) => r.id !== u.id)
    )
  );
  const [loading, setLoading] = useState<string | null>(null);

  const [titleError, setTitleError] = useState('');

  const [selectAll, setSelectAll] = useState(false);
  const [selectedDrafts, setSelectedDrafts] = useState<Draft[]>([]);
  const draftGroup = useMemo(
    () => draftGroups[curIndex],
    [draftGroups, curIndex]
  );

  const [hoveredDraft, setHoveredDraft] = useState<string | null>(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [filters, setFilters] = useState<any>({
    type: '',
    folder: '',
    modifiedBy: '',
    changed: '',
  });

  const [modalState, setModalState] = useState({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    isRedBtn: false,
  });

  //  formik form to handle if there is any change in the
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: reviewal.title,
      reviewers: reviewal.reviewers,
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      reviewers: Yup.array().min(1, 'Reviewers are required'),
    }),
    onSubmit: (values) => {},
  });

  /* Fetch all available Drafts */
  useEffect(() => {
    const fetchChanges = async () => {
      let resp: Response = await getDraftUpdates(database);
      if (resp.type === ResponseType.Success) setAllDrafts([...resp.data]);
    };
    fetchChanges();
  }, [reviewal, database]);

  const handleAddReviwer = (reviewer: User) => {
    formik.setFieldValue('reviewers', [...formik.values.reviewers, reviewer]);
    let filt = filteredUsers.filter((u: User) => u.id !== reviewer.id);
    setFilteredUsers(filt);
  };

  const handleRemoveReviewer = (reviewer: User) => {
    const newOptions = formik.values.reviewers.filter(
      (option: User) => option.id !== reviewer.id
    );
    formik.setFieldValue('reviewers', newOptions);
    setFilteredUsers([...filteredUsers, reviewer]);
  };

  const handleBack = () => {
    navigate('/reviewChanges', {
      state: {
        data: reviewal,
      },
    });
  };

  const handleSearchChange = (text: string) => {
    setSearchQuery(text);
    // filter(allReviewals, text, inputType);
  };

  const handleReset = () => {
    setSearchQuery('');
    formik.resetForm();
  };

  const getCategoryList = (draft: any, folderId: string) => {
    if (draft.model instanceof MedicationItem) {
      const filteredMedications = draft.model.subItems.filter(
        (medication: MedicationSubItem) => {
          // Check if the medication's parent protocol's parent UID matches the folderId
          return medication?.parentProtocol?.parent?.uid === folderId;
        }
      );

      return filteredMedications.length === 0 ? false : true;
    } else if (draft?.model instanceof ProtocolItem) {
      // Protocol Item
      if (draft?.model?.parent?.uid === folderId) {
        return draft?.model?.parent?.uid === folderId;
      }
    } else if (draft?.model instanceof CategoryItem) {
      // Category Item
      if (draft?.model?.uid === folderId) {
        return draft?.model?.uid === folderId;
      }
    } else if (draft?.model instanceof ElectricalItem) {
      // Electrical Item
      const filteredElectrical = draft.model.parents.filter(
        (equipment: ProtocolItem) => {
          // Check if the equipment's parent protocol's parent UID matches the folderId
          return equipment?.parent?.uid === folderId;
        }
      );
      return filteredElectrical.length === 0 ? false : true;
    } else if (draft?.model instanceof EquipmentItem) {
      // Equipment Item
      const filteredEquipment = draft.model.parents.filter(
        (equipment: ProtocolItem) => {
          // Check if the electrical's parent protocol's parent UID matches the folderId
          return equipment?.parent?.uid === folderId;
        }
      );
      return filteredEquipment.length === 0 ? false : true;
    }
  };

  const getDateDifferenceInDays = (date1: Date, date2: Date): number => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const filteredDrafts = useMemo(() => {
    const currentDate = new Date();
    console.log('ALL DRAFTS:', allDrafts);
    return allDrafts.filter((draft: any) => {
      const matchesSearchQuery =
        draft?.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        draft?.message.toLowerCase().includes(searchQuery.toLowerCase());

      const matchesType =
        !filters.type ||
        draft?.changeType.toLowerCase() === filters.type.toLowerCase();

      const matchesModifiedBy =
        !filters.modifiedBy ||
        draft?.model?.modifiedBy?.id === filters.modifiedBy;
      const draftDate = new Date(draft?.model?.modifiedBy?.createdAt);
      const matchesFolder =
        !filters.folder || getCategoryList(draft, filters.folder);
      let matchesChanged = true;

      if (filters.changed === 'date10') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 10;
      } else if (filters.changed === 'date20') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 20;
      } else if (filters.changed === 'date30') {
        matchesChanged = getDateDifferenceInDays(draftDate, currentDate) <= 30;
      }

      // console.log(
      //   'Matches:',
      //   matchesSearchQuery,
      //   matchesType,
      //   matchesFolder,
      //   matchesModifiedBy,
      //   matchesChanged
      // );
      return (
        matchesSearchQuery &&
        matchesType &&
        matchesFolder &&
        matchesModifiedBy &&
        matchesChanged
      );
    });
  }, [
    allDrafts,
    searchQuery,
    filters.type,
    filters.folder,
    filters.modifiedBy,
    filters.changed,
  ]);

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    const sourceArray =
      source.droppableId === 'drafts' ? allDrafts : draftGroup.drafts;
    const destinationArray =
      destination.droppableId === 'drafts' ? allDrafts : draftGroup.drafts;

    if (selectedDrafts.length > 0) {
      // Handle multi-select drag
      const itemsToMove = selectedDrafts.filter((draft: Draft) =>
        sourceArray.some(
          (item: Draft) =>
            item.model.uid === draft.model.uid &&
            item.changeType === draft.changeType
        )
      );

      // Remove selected items from the source array
      const newSourceArray = sourceArray.filter(
        (item: Draft) =>
          !itemsToMove.some(
            (draft: Draft) =>
              draft.model.uid === item.model.uid &&
              draft.changeType === item.changeType
          )
      );

      // Insert selected items into the destination array
      const newDestinationArray = [...destinationArray];
      newDestinationArray.splice(destination.index, 0, ...itemsToMove);

      // Update the state
      if (source.droppableId === 'drafts') {
        setAllDrafts(newSourceArray);
      } else {
        setDraftGroups((prevGroups: any) =>
          prevGroups.map((group: any, index: number) =>
            index === curIndex ? { ...group, drafts: newSourceArray } : group
          )
        );
      }

      if (destination.droppableId === 'drafts') {
        setAllDrafts(newDestinationArray);
      } else {
        setDraftGroups((prevGroups: any) =>
          prevGroups.map((group: any, index: number) =>
            index === curIndex
              ? { ...group, drafts: newDestinationArray }
              : group
          )
        );
      }
    } else {
      // Handle single item drag (your existing logic)
      const sourceIndex =
        source.droppableId === 'drafts'
          ? allDrafts.findIndex(
              (draft: any) =>
                draft.model.uid === filteredDrafts[source.index].model.uid &&
                draft.changeType === filteredDrafts[source.index].changeType
            )
          : source.index;

      const [movedItem] = sourceArray.splice(sourceIndex, 1);

      if (destination.droppableId === 'drafts') {
        const destinationIndex =
          destination.index < filteredDrafts.length
            ? allDrafts.findIndex(
                (draft: any) =>
                  draft.model.uid ===
                    filteredDrafts[destination.index].model.uid &&
                  draft.changeType ===
                    filteredDrafts[destination.index].changeType
              )
            : allDrafts.length;
        allDrafts.splice(destinationIndex, 0, movedItem);
      } else {
        draftGroup.drafts.splice(destination.index, 0, movedItem);
      }

      setAllDrafts([...allDrafts]);
      setDraftGroups((prevGroups: any) =>
        prevGroups.map((group: any, index: number) =>
          index === curIndex
            ? { ...group, drafts: [...draftGroup.drafts] }
            : group
        )
      );
    }

    // Clear selection after drag
    setIsDraggingOver(false);
    setSelectedDrafts([]);
    setSelectAll(false);
  };

  const handleSave = async () => {
    setLoading('Updating Review...');
    try {
      let review = reviewal;
      /* First Check if any Draft Groups have been removed */
      let promises: Promise<Response>[] = [];
      for (let i = 0; i < prevDraftGroups.length; i++) {
        if (
          !draftGroups.some(
            (group: any) =>
              group.previousItem.uid === prevDraftGroups[i].previousItem.uid
          )
        ) {
          if (globals.debug)
            console.log(
              'DELETING DRAFT GROUP:',
              prevDraftGroups[i].previousItem.uid
            );
          promises = [
            ...promises,
            deleteDraftGroupItem(prevDraftGroups[i].previousItem, true), //Delete all DraftChanges that belonged to the DraftGroup
          ];
        }
      }

      /* Loop through every DraftGroup and create the DraftChanges */
      let draftGroupsJSONs: DraftGroupJSON[] = [];
      for (let i = 0; i < draftGroups.length; i++) {
        let dg = draftGroups[i];
        /* The DraftGroup is getting updated */
        if (dg.previousItem) {
          /* Check if the DraftGroup is updated */
          let isUpdated = false;
          if (dg.title !== dg.previousItem.title) isUpdated = true;
          else if (dg.description !== dg.previousItem.description)
            isUpdated = true;
          else if (dg.drafts.length !== dg.previousItem.draftChanges.length)
            isUpdated = true;
          else if (
            dg.drafts.find((draft: Draft) => draft.draftChangeItem == null)
          )
            isUpdated = true;

          // else {
          //   for (let j = 0; j < dg.drafts.length; j++) {
          //     // let dc = dg.drafts[j];
          //     // let found = dg.previousItem.drafts.find(
          //     //   (draft: Draft) => draft.draftChangeItem.uid === dc.model.uid
          //     // );
          //   }
          // }

          if (isUpdated) {
            if (globals.debug) console.log('DraftGroup is Updated:', dg);
            /* Check for all DraftChanges to delete */
            for (let j = 0; j < dg.previousItem.draftChanges.length; j++) {
              let dc: DraftChangeItem = dg.previousItem.draftChanges[j];
              if (
                !dg.drafts.some(
                  (draft: Draft) =>
                    draft.draftChangeItem &&
                    draft.draftChangeItem.uid === dc.uid
                )
              ) {
                if (globals.debug)
                  console.log(
                    'Deleting DraftChange:',
                    dc.uid,
                    dc.changeItem?.name
                  );
                promises.push(deleteDraftChangeItem(dc, true));
              }
            }

            /* Check for the DraftChanges to add */
            let changes: DraftChangeJSON[] = [];
            for (let j = 0; j < dg.drafts.length; j++) {
              let draft: Draft = dg.drafts[j];
              if (draft.draftChangeItem == null) {
                /* Search through the already existing DraftChanges */
                let found = dg.previousItem.draftChanges.find(
                  (dc: DraftChangeItem) =>
                    dc.changeItem && dc.changeItem.uid === draft.model.uid
                );

                if (found) {
                  if (globals.debug)
                    console.log('Found ALREADY CREATED DraftChange:', found);
                  continue;
                }

                if (globals.debug) console.log('Createing DraftChange:', draft);
                let dc: DraftChangeJSON = {
                  changeType: draft.changeType,
                  changeItem: draft.model,
                  previousItem: draft.model.activeItem,
                };
                changes.push(dc);
              }
            }
            //Update the DraftGroup
            let dgJSON: DraftGroupJSON = {
              previousItem: dg.previousItem,
              title: dg.title,
              description: dg.description,
              createdBy: user,
              draftChanges: changes,
            };
            //Creating the DraftGroup
            if (globals.debug) console.log('Creating DraftGroup:', dgJSON);
            draftGroupsJSONs.push(dgJSON);
          }
        } else {
          /* Create a new DraftGroup */
          let dgJSON: DraftGroupJSON = {
            previousItem: dg.previousItem,
            title: dg.title,
            description: dg.description,
            createdBy: user,
            draftChanges: dg.drafts.map((draft: any) => {
              return {
                changeType: draft.changeType,
                changeItem: draft.model,
                previousItem: draft.model.activeItem,
              } as DraftChangeJSON;
            }),
          };

          draftGroupsJSONs.push(dgJSON);
        }
      }

      if (draftGroupsJSONs.length > 0) {
        promises.push(
          createDraftGroupForReviewal(database, review, draftGroupsJSONs)
        );
      }

      for (let i = 0; i < review.acknowledgements.length; i++) {
        let ack = review.acknowledgements[i];
        promises.push(deleteAcknowledgeItem(ack, true));
      }

      let results = await BatchQuery(promises);

      /* Need to iterate through the changes, DraftChange, DraftChangeGroup, then Reviewal */
      if ((formik.isValid && formik.dirty) || promises.length > 0) {
        let reviewJSON: ReviewalJSON = {
          previousItem: reviewal,
          title: formik.values.title,
          description: '',
          reviewers: formik.values.reviewers,
          user: user,
          events: [
            ...reviewal.events,
            {
              title:
                'Review Updated by ' + user.firstName + ' ' + user.lastName,
              description:
                'Review was Updated by ' +
                user.firstName +
                ' ' +
                user.lastName +
                ' on ' +
                new Date().toLocaleString(),
              timestamp: new Date().toISOString(),
              color: '#00534C',
            } as LogEvent,
          ],
        };
        const response: Response = await createReviewalProcess(
          database,
          reviewJSON,
          false
        );
        if (response.type === ResponseType.Success) {
          review = response.data;
        } else {
          if (globals.debug)
            console.error('Failed to Update Review:', response);
          setLoading(null);
          return;
        }
      }

      let reviewalItem = await getFullReviewal(database, review, user);
      if (reviewalItem) {
        setReviewal(reviewalItem);
        setLoading(null);
        setModalState({
          isVisible: true,
          title: 'Review Successfully Updated',
          description:
            'Review has been successfully updated. Would you like to edit more or continue to the changes page?',
          primaryBtnName: 'Edit',
          secondaryBtnName: 'Continue',
          isRedBtn: false,
        });
      } else {
        if (globals.debug) console.error('Failed to Update Review:', results);
        setLoading(null);
      }
    } catch (error) {
      if (globals.debug) console.error('Failed to Update Review:', error);
      setLoading(null);
    }
  };

  const handleTitleChange = (event: any) => {
    event.preventDefault();
    formik.setFieldValue('title', event.target.value);
    if (!event.target.value) setTitleError('Title is required');
    else if (event.target.value.length > 50) setTitleError('Title is too long');
    else setTitleError('');
  };

  const handleDraftTitleChange = (event: any) => {
    event.preventDefault();
    setDraftGroups(
      draftGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, title: event.target.value } : group
      )
    );
  };

  //  checks if any user changes are made on UI, apart from the initial data
  const isSaveValid = useMemo(() => {
    let isDraftGroupDirty = false;
    if (draftGroups.length !== prevDraftGroups.length) isDraftGroupDirty = true;
    else {
      //Sort the Previous and Current DraftGroups so the order is the same
      let compPrev = [...prevDraftGroups].sort((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );
      let compCur = [...draftGroups].sort((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );
      //  check if the draftGroups are dirty
      for (let i = 0; i < compCur.length; i++) {
        if (compCur[i].title !== compPrev[i].title) {
          isDraftGroupDirty = true;
          break;
        }
        if (compCur[i].description !== compPrev[i].description) {
          isDraftGroupDirty = true;
          break;
        }
        if (compCur[i].drafts.length !== compPrev[i].drafts.length) {
          isDraftGroupDirty = true;
          break;
        }
        let prevDrafts = compPrev[i].drafts.sort((a: Draft, b: Draft) => {
          if (a.changeType === b.changeType)
            return a.title.localeCompare(b.title);
          return a.changeType.localeCompare(b.changeType);
        });
        let curDrafts = compCur[i].drafts.sort((a: Draft, b: Draft) => {
          if (a.changeType === b.changeType)
            return a.title.localeCompare(b.title);
          return a.changeType.localeCompare(b.changeType);
        });
        for (let j = 0; j < curDrafts.length; j++) {
          //Check if a DraftChnageType is different or the title is different
          if (
            curDrafts[j].changeType !== prevDrafts[j].changeType ||
            curDrafts[j].title !== prevDrafts[j].title
          ) {
            isDraftGroupDirty = true;
            break;
          }
        }
      }
    }
    return formik.isValid && (formik.dirty || isDraftGroupDirty);
  }, [formik.isValid, formik.dirty, draftGroups, prevDraftGroups]);

  const handleCreateDraftGroup = () => {
    setDraftGroups([
      ...draftGroups,
      {
        title: 'Review Group ' + (draftGroups.length + 1),
        description: '',
        drafts: [],
      },
    ]);
    setCurIndex(draftGroups.length);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedDrafts(selectAll ? [] : filteredDrafts);
  };

  const handleRemoveFromDraftGroup = (draftToRemove: any) => {
    // Remove the draft from the draftGroup
    const updatedDrafts = draftGroup.drafts.filter(
      (draft: any) => draft.model.uid !== draftToRemove.model.uid
    );

    // Add the draft back to allDrafts
    setAllDrafts((prevDrafts: any) => [...prevDrafts, draftToRemove]);

    // Update the draftGroups
    setDraftGroups((prevGroups: any) =>
      prevGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, drafts: updatedDrafts } : group
      )
    );
  };

  const handleClearDraftGroup = () => {
    // Add all drafts from the current group back to allDrafts
    setAllDrafts((prevDrafts: any) => [...prevDrafts, ...draftGroup.drafts]);

    // Clear the drafts from the current group
    setDraftGroups((prevGroups: any) =>
      prevGroups.map((group: any, index: number) =>
        index === curIndex ? { ...group, drafts: [] } : group
      )
    );
  };

  const handleDragUpdate = (update: any) => {
    //  set isDraggingOver to true when dragging over the droppable area DraftGroup
    setIsDraggingOver(update.destination?.droppableId === 'draft-group');
  };

  const handleDeleteDraftGroup = (index: number) => {
    //  delete the draft group at the index
    const newDraftGroups = draftGroups.filter(
      (group: any, i: number) => i !== index
    );
    setDraftGroups(newDraftGroups);
    setCurIndex(0);
  };

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => setModalState({ ...modalState, isVisible: false })}
        handleSubmit={() => {
          if (modalState.secondaryBtnName === 'Continue') handleBack();
          setModalState({ ...modalState, isVisible: false });
        }}
        primaryBtnName={
          modalState.primaryBtnName === ''
            ? undefined
            : modalState.primaryBtnName
        }
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={modalState.description}
        secondaryDescription={''}
        isDeleteBtn={modalState.isRedBtn === true ? true : false}
      />
      <ProtocolHeader
        //  review + newDate (month-date)
        name={
          <div
            style={{
              paddingBottom: '10px',
            }}
          >
            <HiPencil
              className="icon-black rightIcon"
              style={{
                height: '2rem',
                width: '2rem',
                cursor: 'pointer',
                color: '#00534C',
              }}
            />
            <InputText
              value={formik.values.title}
              onChange={handleTitleChange}
              placeholder="Add Review Title..."
              className="title-input-text-box"
              style={{
                fontWeight: 'bold',
                width: `calc(1rem*${formik.values.title.length})`,
                minWidth: '350px',
              }}
            />
            {titleError && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '8px' }}>
                {titleError}
              </p>
            )}
          </div>
        }
        description={
          'Create a new review by dragging drafts into the group, adding reviewers, and setting a title'
        }
        rightSideBtn={'edit'}
        type={'protocol'}
        handleSave={handleSave}
        isBackButton={true}
        handleCancel={handleBack}
        page={'Review Change Page'}
        isEditButton={false}
        isReviewButton={false}
        isReviewActive={false}
        isCancelButton={false}
        isSaveButton={true}
        isSaveActive={isSaveValid}
        handleCancelEdit={handleBack}
      />
      {loading && <Loading loading={loading} />}
      <div className="review-create-container" style={{ padding: '2px 10px' }}>
        <div className="menu-bar" style={{ marginBottom: '10px' }}>
          <Row gutterWidth={16}>
            <Col sm={3}>
              <SearchBar
                containerStyle={{ width: '100%', height: '30px' }}
                value={searchQuery}
                onChange={(searchTerm: string) => {
                  handleSearchChange(searchTerm);
                }}
                onSubmit={(searchTerm: string) => {}}
                placeholder={'Search Reviews...'}
              />
            </Col>
            <Col sm={2}>
              <Select
                value={filters.type}
                onChange={(e) =>
                  setFilters({ ...filters, type: e.target.value })
                }
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Type';
                  }
                  return selected;
                }}
              >
                <MenuItem disabled value="" className="menuItemFontSize">
                  Type
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                <MenuItem value="Protocol" className="menuItemFontSize">
                  Protocol
                </MenuItem>
                <MenuItem value="Folder" className="menuItemFontSize">
                  Folder
                </MenuItem>
                <MenuItem value="Medication" className="menuItemFontSize">
                  Medication
                </MenuItem>
                <MenuItem value="Infusion" className="menuItemFontSize">
                  Infusion
                </MenuItem>
                <MenuItem value="Electrical" className="menuItemFontSize">
                  Electrical
                </MenuItem>
                <MenuItem value="Equipment" className="menuItemFontSize">
                  Equipment
                </MenuItem>
                <MenuItem value="Vital" className="menuItemFontSize">
                  Vital
                </MenuItem>
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={filters.folder}
                onChange={(e) =>
                  setFilters({ ...filters, folder: e.target.value })
                }
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove border initially
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 'none', // Add border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Folder';
                  }
                  let folder = database.categories.find(
                    (category: CategoryItem) => category.uid === selected
                  );
                  return folder?.name;
                }}
              >
                <MenuItem value="" disabled className="menuItemFontSize">
                  Folder
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                {database.categories.map((category: CategoryItem) => (
                  <MenuItem
                    key={category.uid}
                    value={category.uid}
                    className="menuItemFontSize"
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={filters.modifiedBy}
                onChange={(e) =>
                  setFilters({ ...filters, modifiedBy: e.target.value })
                }
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },
                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Modified By';
                  }
                  //  return first and last name of the user
                  let user = database.users.find(
                    (user: User) => user.id === selected
                  );
                  return user?.firstName + ' ' + user?.lastName;
                }}
              >
                <MenuItem value="" disabled className="menuItemFontSize">
                  Modified By
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>

                {database.users.map((user: User) => (
                  <MenuItem
                    key={user.id}
                    value={user.id}
                    className="menuItemFontSize"
                  >
                    {user.firstName} {user.lastName}
                  </MenuItem>
                ))}
              </Select>
            </Col>
            <Col sm={2}>
              <Select
                value={filters.changed}
                onChange={(e) =>
                  setFilters({ ...filters, changed: e.target.value })
                }
                displayEmpty
                sx={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: '1px solid #E0EADD',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove default border
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: 0, // Remove border when focused
                  },

                  backgroundColor: '#E0EADD',
                  fontSize: '14px',
                }}
                renderValue={(selected) => {
                  if (!selected) {
                    return 'Last Modified Date';
                  }
                  //  return Changed: Last 10 days, 20 days, 30 days
                  if (selected === 'date10') return 'Changed: Last 10 days';
                  if (selected === 'date20') return 'Changed: Last 20 days';
                  if (selected === 'date30') return 'Changed: Last 30 days';
                }}
              >
                <MenuItem disabled value="" className="menuItemFontSize">
                  Last Modified Date
                </MenuItem>
                <MenuItem value="" className="menuItemFontSize">
                  All
                </MenuItem>
                <MenuItem value="date10" className="menuItemFontSize">
                  Changed: Last 10 days
                </MenuItem>
                <MenuItem value="date20" className="menuItemFontSize">
                  Changed: Last 20 days
                </MenuItem>
                <MenuItem value="date30" className="menuItemFontSize">
                  Changed: Last 30 days
                </MenuItem>
              </Select>
            </Col>
            <Col sm={1}>
              <Button
                className="primary-button btn-rightMargin btn btn-primary"
                style={{
                  width: '100%',
                  height: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleReset} // Call reset function on click
              >
                Reset
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="review-box"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragUpdate={handleDragUpdate}
          >
            <Droppable droppableId="drafts">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`drafts ${selectAll ? 'select-all' : ''}`}
                  style={{
                    fontSize: '16px',
                    flex: '0 0 23%',
                    padding: '20px',
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                    border: '1px solid #e0e0e0',
                    height: 'calc(100vh - 327px)',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '20px',
                    }}
                  >
                    <label
                      style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: '#333',
                      }}
                    >
                      Drafts:
                      {selectedDrafts.length > 0 &&
                        ` ${selectedDrafts.length} /`}
                      {` ${filteredDrafts.length}`}
                    </label>
                    <div
                      className={`select-all-button ${selectAll ? 'selected' : ''}`}
                      onClick={handleSelectAll}
                      style={{
                        cursor: 'pointer',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        backgroundColor: selectAll ? '#00534c' : '#f0f0f0',
                        color: selectAll ? 'white' : '#333',
                        transition: 'all 0.3s ease',
                        fontSize: '12px',
                      }}
                    >
                      {selectAll ? 'Deselect All' : 'Select All'}
                    </div>
                  </div>
                  <div className="drafts-scroll" style={{ overflowY: 'auto' }}>
                    {filteredDrafts.map((draft: Draft, index: number) => (
                      <Draggable
                        key={draft.model.uid + '-' + draft.changeType}
                        draggableId={`draggable-${draft.model.uid + '-' + draft.changeType}`}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="draggable-item"
                          >
                            <div
                              className={`draftItemWrapper ${selectedDrafts.includes(draft) ? 'selected' : ''}`}
                              style={{
                                position: 'relative', // for positioning the tooltip
                                border: `2px solid ${selectedDrafts.includes(draft) ? '#1890ff' : '#e0e0e0'}`,
                                borderRadius: '4px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                transition: 'all 0.3s ease',
                                backgroundColor: 'white',
                                marginBottom: '10px',
                                overflow: 'hidden',
                                cursor: 'pointer',
                                borderTopLeftRadius: '6px',
                                borderTopRightRadius: '6px',
                              }}
                              onClick={() => {
                                const newSelectedDrafts =
                                  selectedDrafts.includes(draft)
                                    ? selectedDrafts.filter(
                                        (item: any) => item !== draft
                                      )
                                    : [...selectedDrafts, draft];
                                setSelectedDrafts(newSelectedDrafts);
                              }}
                            >
                              <div
                                className="draftItemsInput"
                                style={{
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                  backgroundColor: getStatusStyles(
                                    draft.changeType
                                  ).backgroundColor,
                                  color: getStatusStyles(draft.changeType)
                                    .color,
                                  padding: '6px',
                                  fontSize: '14px',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  borderTopRightRadius: '4px',
                                  borderTopLeftRadius: '4px',
                                }}
                              >
                                <span
                                  style={{
                                    display: 'flex',
                                    flex: '1',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {draft.title}
                                </span>
                                {selectedDrafts.includes(draft) && (
                                  <span
                                    style={{
                                      position: 'absolute',
                                      top: '5px',
                                      right: '5px',
                                      height: '22px',
                                      width: '22px',
                                      backgroundColor: `${getStatusStyles(draft.changeType).color}`,
                                      borderRadius: '50%',
                                    }}
                                  >
                                    <FaCircleCheck
                                      size={20}
                                      color={'#FFF'}
                                      style={{
                                        marginBottom: '2px',
                                      }}
                                    />
                                  </span>
                                )}
                              </div>
                              <div style={{ padding: '10px' }}>
                                <div
                                  style={{
                                    color: '#6a6a6a',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                  }}
                                >
                                  {draft.message}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>

            <Droppable droppableId="draft-group">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="draft-group"
                  style={{
                    flex: '0 0 56.5%',
                    padding: '10px',
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                    border: '1px solid rgb(224, 224, 224)',
                    height: 'calc(100vh - 327px)',
                    overflow: 'hidden',
                    backgroundColor: '#f8f9fa',
                    borderRadius: '8px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginBottom: '10px',
                    }}
                  >
                    <div
                      style={{
                        flex: 8,
                        display: 'flex',
                        // justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-start',
                        marginBottom: '10px',
                      }}
                    >
                      <HiPencil
                        className="icon-black rightIcon"
                        style={{
                          height: '2rem',
                          width: '2rem',
                          cursor: 'pointer',
                          color: '#00534C',
                        }}
                      />
                      <InputText
                        value={draftGroup.title}
                        onChange={handleDraftTitleChange}
                        placeholder="Add Title..."
                        className="title-input-text-box"
                        style={{
                          width: `calc(1rem*${draftGroup.title.length})`,
                        }}
                      />
                      <span
                        style={{
                          marginLeft: '10px',
                          fontSize: '14px',
                          color: '#6a6a6a',
                          fontWeight: '600',
                        }}
                      >
                        {draftGroup.drafts.length} items
                      </span>
                    </div>
                    {draftGroups.length > 1 && (
                      <button
                        className="secondary-button btn-rightMargin btn btn-primary"
                        onClick={() => handleDeleteDraftGroup(curIndex)}
                      >
                        Delete Group
                      </button>
                    )}
                    <button
                      className="red-background-button btn-rightMargin  btn btn-primary"
                      onClick={handleClearDraftGroup}
                    >
                      Clear All
                    </button>
                    <div
                      style={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <button
                        className="primary-button btn-rightMargin btn btn-primary"
                        style={{ padding: '6px' }}
                        onClick={handleCreateDraftGroup}
                      >
                        + Create Draft Group
                      </button>
                    </div>
                  </div>

                  <InputText
                    placeholder="Add Description..."
                    style={{
                      marginBottom: '20px',
                      background: 'transparent',
                      // border: 'none',
                      // borderBottom: '1px solid #636363',
                      // borderRadius: '0',
                    }}
                    value={draftGroup.description}
                    onChange={(e) => {
                      setDraftGroups(
                        draftGroups.map((group: any, index: number) =>
                          index === curIndex
                            ? { ...group, description: e.target.value }
                            : group
                        )
                      );
                    }}
                  />
                  {draftGroup && draftGroup.drafts.length === 0 && (
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '30px',
                        border: `2px dashed ${isDraggingOver ? '#4A90E2' : '#BEBEBE'}`,
                        backgroundColor: isDraggingOver ? '#E8F4FD' : '#F9F9F9',
                        borderRadius: '12px',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        boxShadow: isDraggingOver
                          ? '0 6px 12px rgba(0, 0, 0, 0.2)'
                          : '0 4px 8px rgba(0, 0, 0, 0.1)',
                        transition: 'all 0.3s ease',
                      }}
                    >
                      <FaArrowDown
                        size={48}
                        color={isDraggingOver ? '#4A90E2' : '#7D7D7D'}
                        style={{
                          marginBottom: '15px',
                          transition: 'color 0.3s ease',
                        }}
                      />
                      <p
                        style={{
                          fontSize: '16px',
                          color: isDraggingOver ? '#4A90E2' : '#7D7D7D',
                          transition: 'color 0.3s ease, font-weight 0.3s ease',
                        }}
                      >
                        <div
                          style={{
                            marginLeft: '5px',
                            fontSize: '30px',
                            fontWeight: 'bold',
                            color: isDraggingOver ? '#4A90E2' : '#7D7D7D',
                          }}
                        >
                          Drag and drop
                        </div>{' '}
                        Drafts here to initiate the review process
                      </p>
                    </div>
                  )}

                  <div
                    className="draft-drag-list"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '15px',
                      maxHeight: '100%',
                      overflowY: 'auto',
                    }}
                  >
                    {draftGroup.drafts.map(
                      (draft: Draft, itemIndex: number) => (
                        <Draggable
                          key={draft.model.uid}
                          draggableId={`draggable-${draft.model.uid}`}
                          index={itemIndex}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="draggable-item"
                              style={{
                                ...provided.draggableProps.style, // Ensure this is included
                                flex: '0 0 calc(33.333% - 10px)',
                                boxSizing: 'border-box',
                                minWidth: '200px', // Adjust as needed to fit your layout
                                marginBottom: '5px', // Vertical gap
                              }}
                            >
                              <div
                                style={{ position: 'relative' }}
                                onMouseEnter={() =>
                                  setHoveredDraft(draft.model.uid)
                                }
                                onMouseLeave={() => setHoveredDraft(null)}
                              >
                                <div
                                  className="draftItemWrapper"
                                  style={{
                                    display: 'inline-block',
                                    border: '1px solid rgb(224, 224, 224)',
                                    borderRadius: '4px',
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                    cursor: 'pointer',
                                    transition:
                                      'border-color 0.3s, background-color 0.3s, box-shadow 0.3s',
                                    backgroundColor: 'white',
                                    width: '100%',
                                  }}
                                >
                                  <div
                                    className="draftItemsInput"
                                    style={{
                                      fontWeight: 'bold',
                                      textAlign: 'center',
                                      backgroundColor: getStatusStyles(
                                        draft.changeType
                                      ).backgroundColor,
                                      color: getStatusStyles(draft.changeType)
                                        .color,
                                      padding: '6px',
                                      fontSize: '14px',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      borderTopRightRadius: '4px',
                                      borderTopLeftRadius: '4px',
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: 'flex',
                                        flex: '1',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {draft.title}
                                    </span>
                                    {hoveredDraft === draft.model.uid && (
                                      <span
                                        className="hoverable-button-container"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemoveFromDraftGroup(draft);
                                        }}
                                      >
                                        <FaTimes
                                          style={{
                                            cursor: 'pointer',
                                            // color: '#DC143C',
                                            fontSize: '16px',
                                          }}
                                        />
                                      </span>
                                    )}
                                  </div>
                                  <div style={{ padding: '4px 8px 4px 8px' }}>
                                    <div
                                      style={{
                                        color: '#6a6a6a',
                                        fontSize: '12px',
                                        padding: '5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {draft.message}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div
            className="reviewers"
            style={{
              flex: '0 0 19%',
              display: 'flex',
              marginTop: '5px',
              flexDirection: 'column',
              border: '1px solid rgb(224, 224, 224)',
              height: 'calc(100vh - 327px)',
              overflow: 'hidden',
              backgroundColor: '#f8f9fa',
              borderRadius: '8px',
              overflowY: 'auto',
            }}
          >
            <div
              style={{
                padding: '0px 15px',
                height: '100%',
                paddingTop: '15px',
              }}
            >
              <div
                className="contentText"
                style={{
                  fontSize: '20px',
                  fontWeight: '500',
                  marginBottom: '5%',
                }}
              >
                Reviewers
              </div>

              <SearchableDropdown
                id="searchDropdown"
                options={filteredUsers}
                labelField={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                valueField={(option) =>
                  option.firstName + ' ' + option.lastName
                }
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: User) => handleAddReviwer(option)}
                onClear={() => {}}
                placeholder="Search Reviewers..."
              />
              {formik.values.reviewers &&
                formik.values.reviewers.length > 0 && (
                  <div className="contentLabel" style={{ display: 'block' }}>
                    <div className="contentLabel" style={{ fontSize: '15px' }}>
                      Users
                    </div>
                  </div>
                )}
              <div className="contentBorder protocolCalculationPad reviewListScroll">
                <ViewportList items={formik.values.reviewers}>
                  {(item: any, index: any) => (
                    <div
                      key={item + index}
                      className={`listhover spaceBetween cursorPointer item contentHeading ${formik.values.reviewers.length !== index + 1 ? 'contentUnderline' : ''}`}
                    >
                      <div>
                        {item?.firstName} {item?.lastName}
                      </div>

                      <div>
                        <FaTimes
                          style={{ cursor: 'pointer', color: 'red' }}
                          onClick={() => handleRemoveReviewer(item)}
                        />
                      </div>
                    </div>
                  )}
                </ViewportList>
              </div>
              <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
                {draftGroups.length > 0 && (
                  <div
                    className="draftGroupsHeader"
                    style={{
                      fontSize: '20px',
                      fontWeight: '500',
                      marginTop: '5%',
                    }}
                  >
                    Draft Groups
                  </div>
                )}
                {draftGroups.length > 0 &&
                  draftGroups.map((group: DraftGroupJSON, index: number) => (
                    <div
                      key={index}
                      className={`draftGroup ${curIndex === index ? 'active' : ''}`}
                      onClick={() => setCurIndex(index)}
                    >
                      <div className="contentText">{group.title}</div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewEdit;
