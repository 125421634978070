import { useEffect, useState } from 'react';
import './css/Login.scss'; // Importing CSS;
import { useLocation, useNavigate } from 'react-router-dom';
import Loading from '../../components/Loading/Loading';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { decrypt } from '../../_global/common/Encrypt';

interface ModalState {
  title: string;
  primaryBtnName?: string;
  secondaryBtnName?: string;
  message: string;
  handleClose?: () => void;
  handleSubmit?: () => void;
}

const ShareCreateAccount = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState<ModalState>({
    title: '',
    message: '',
  });
  const [loadingText, setLoadingText] = useState<string | null>(null);

  useEffect(() => {
    const validateDirectURL = async () => {
      const urlParts = location.pathname.split('/');

      // If URL doesn't match expected format, redirect to login and reload
      if (
        urlParts[1] !== 'share-create-account' ||
        !location.search.includes('signature') ||
        !location.search.includes('expiration')
      ) {
        navigate('/login');
        window.location.reload();
        return;
      }

      // Get signature and expiration from URL
      const signature = decodeURIComponent(
        location.search.split('signature=')[1].split('&')[0]
      );
      const expiration = Number(location.search.split('expiration=')[1]);
      const currentTime = new Date().getTime();

      // If link is expired, show modal and then redirect to login and reload
      if (expiration < currentTime) {
        setModalState({
          title: 'Link Has Expired',
          message:
            'This invitation link is no longer active. Please reach out to your administrator to request a new invitation.',
          handleSubmit: () => {
            navigate('/login');
            window.location.reload();
          },
        });
        return;
      }

      try {
        setLoadingText('Fetching department code...');
        // Decrypt the signature to get the department code
        const decryptedData = decrypt(
          signature,
          process.env.REACT_APP_SHARE_KEY
        );
        const data = JSON.parse(decryptedData);

        // If expiration from URL and expiration from decrypted data don't match, show modal and then redirect to login and reload
        if (expiration !== data.expiration) {
          setLoadingText(null);
          setModalState({
            title: 'Invalid Invitation',
            message:
              "We couldn't verify this invitation link. Please contact your administrator for assistance.",
            handleSubmit: () => {
              navigate('/login');
              window.location.reload();
            },
          });
          return;
        }

        setLoadingText(null);
        // Navigate to create-account page with department code
        navigate('/create-account', {
          state: {
            shareAccountData: data,
          },
        });
      } catch (error) {
        setLoadingText(null);
        console.error('Error parsing decrypted data:', error);
        setModalState({
          title: 'Invalid Link',
          message:
            'This invitation link appears to be invalid. Please request a new invitation from your administrator.',
          handleSubmit: () => {
            navigate('/login');
            window.location.reload();
          },
        });
      }
    };

    validateDirectURL();
  }, [location.pathname, location.search]);

  return (
    <div className="login-container">
      {loadingText && <Loading text={loadingText} />}
      <ConfirmModal
        isVisible={modalState.title !== ''}
        title={modalState.title}
        handleClose={() => {
          modalState.handleClose?.();
          setModalState({
            title: '',
            message: '',
          });
          navigate('/login');
          window.location.reload();
        }}
        handleSubmit={() => {
          modalState.handleSubmit?.();
          setModalState({
            title: '',
            message: '',
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={!modalState.primaryBtnName || !modalState.secondaryBtnName}
        primaryBtnName={modalState.primaryBtnName ?? ''}
        secondaryBtnName={modalState.secondaryBtnName ?? 'Okay'}
        primaryDescription={modalState.message}
      />
    </div>
  );
};

export default ShareCreateAccount;
