import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { ViewportList } from 'react-viewport-list';
import { Col, Row } from 'react-grid-system';
import { DataStore, Storage } from 'aws-amplify';
import { Button } from 'react-bootstrap';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import { IoArrowBack } from 'react-icons/io5';
import Dropzone from 'react-dropzone';
import { FiUpload } from 'react-icons/fi';
import { InputText } from 'primereact/inputtext';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { Department, User } from '../../../../../models';
import WorkbookItem from '../../../../../data/model/WorkbookItem';
import {
  getFormattedDate,
  globals,
  handleCopy,
} from '../../../../_global/common/Utils';
import { getHashedPin } from '../../../../_global/common/Encrypt';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import Loading from '../../../../components/Loading/Loading';

const ViewProtocolSet = (props: any) => {
  const rootUrl = window.location.origin;
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const user: User = useSelector((state: any) => state.user);
  const parmWorkbook: WorkbookItem = state.item;
  const [selectedWorkbook, setSelectedWorkbook] =
    useState<WorkbookItem>(parmWorkbook);

  const [name, setName] = useState('');
  const [file, setFile] = useState<File | null>(null);

  const [isWarningModal, setIsWarningModal] = useState(false);

  const [isUploadMode, setIsUploadMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingText, setUploadingText] = useState('Loading...');
  const [workbookURL, setWorkbookURL] = useState('');
  const [isCopied, setIsCopied] = useState('');
  const [department, setDepartment] = useState<Department | undefined>(
    undefined
  );

  const objURL = useMemo(() => {
    if (file) return URL.createObjectURL(file);
    else return workbookURL;
  }, [file, workbookURL]);

  useEffect(() => {
    const fetchURL = async (url: string) => {
      return await Storage.get(url, {
        level: 'public',
      });
    };
    const fetchDepartment = async (id: string) => {
      return await DataStore.query(Department, id);
    };

    if (parmWorkbook) {
      setName(parmWorkbook.name);
      if (parmWorkbook.workbookUrl) {
        fetchURL(parmWorkbook.workbookUrl).then((url) => {
          setWorkbookURL(url);
        });
        fetchDepartment(parmWorkbook.departmentID).then((dep) => {
          setDepartment(dep);
        });
      }
    }
  }, [parmWorkbook]);

  const handleBack = () => {
    /* If the form is dirty, then show the warning modal */
    if ((file || name !== parmWorkbook.name) && !isWarningModal) {
      setIsWarningModal(true);
    } else {
      navigate(`/actions/models/protocol-sets`, {
        state: { search: { searchQuery: '' } },
      });
    }
  };

  const handleViewFullScreen = () => {
    if (workbookURL !== '') {
      localStorage.setItem('pdfURL', workbookURL);
      localStorage.setItem('pdfName', selectedWorkbook.name);
      let filterName = selectedWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_');
      const expiration = new Date().getTime() + 1000 * 60 * 60 * 6; //6 hours
      const signature = getHashedPin(
        filterName,
        workbookURL + expiration.toLocaleString(),
        'SHA512'
      );
      window.open(
        `${rootUrl}/fullscreen-pdf/${filterName}?signature=${signature}&expiration=${expiration}
      `,
        '_blank'
      );
    }
  };

  const downloadJsonFile = (json: any) => {
    try {
      if (!json || json.length === 0) {
        alert('Error parsing AI data Body is empty or undefined');
        return;
      }

      /* Save the results of the JSON to a text file and download it */
      const blob = new Blob([JSON.stringify(json)], {
        type: 'application/json',
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      let date = new Date();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      let year = String(date.getFullYear()).substring(2);
      let hours = String(date.getHours()).padStart(2, '0');
      let minutes = String(date.getMinutes()).padStart(2, '0');
      let seconds = String(date.getSeconds()).padStart(2, '0');
      let name =
        parmWorkbook.name.replace(/[^a-zA-Z0-9]/g, '_') +
        '_' +
        month +
        '_' +
        day +
        '_' +
        year +
        '_' +
        hours +
        '_' +
        minutes +
        '_' +
        seconds;
      //Add the timestamp to the file name
      a.download = name;
      a.click();
      URL.revokeObjectURL(url);
    } catch (e) {
      console.error('Error parsing AI data', e);
      return;
    }
  };

  return (
    <div className="screen-container">
      <ProtocolHeader
        isBackButton={true}
        handleCancel={handleBack}
        name={isEditMode ? 'Edit Protocol Set' : selectedWorkbook.name}
        page={'Protocol Sets'}
        rightSideBtn={'edit'}
        isEditButton={!isEditMode}
        isFullScreenButton={true}
        handleFullScreen={handleViewFullScreen}
        handleCancelEdit={() => {
          setFile(null);
          setName(parmWorkbook.name);
          setIsEditMode(false);
          setIsUploadMode(false);
        }}
        isUploadButton={!isUploadMode && isEditMode}
        handleUpload={() => {
          setIsUploadMode(true);
        }}
        type={'protocol'}
      />
      <div className="ketamineContent">
        {((isUploadMode && file) || !isUploadMode) && (
          <div className="workbook-upload-container">
            <embed
              src={objURL}
              title="Uploaded Workbook"
              style={{
                height: '100%',
                width: '100%',
                flex: 2,
                border: 'none',
                marginRight: '10px',
              }}
            />
            <div className="dragDropDotGridNewWorkbookDetails">
              <h5 className="ketmine-header-text">Protocol Set Details</h5>
              {/* <div className="contentText">
              {`Great, now name the workook${department.subDeps && department.subDeps.length > 0 ? ' and pair it with departments' : ''}.`}
              </div> */}
              <div className="input-container roundBorder">
                <div
                  style={{
                    display: 'flex',
                    marginTop: '5px',
                    flexDirection: 'column',
                  }}
                  className="ketamine-general-label"
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginRight: '10px' }}>Modified By:</div>
                    <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                      {selectedWorkbook.modifiedBy
                        ? selectedWorkbook.modifiedBy.firstName +
                          ' ' +
                          selectedWorkbook.modifiedBy.lastName
                        : 'Hinckley Medical'}
                    </div>
                  </div>
                  {department && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Department:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {department.name}
                      </div>
                    </div>
                  )}
                  {selectedWorkbook.model.updatedAt && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Last Updated:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {getFormattedDate(
                          selectedWorkbook.model.updatedAt,
                          false
                        )}
                      </div>
                    </div>
                  )}
                  {selectedWorkbook.model.createdAt && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Created At:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {getFormattedDate(
                          selectedWorkbook.model.createdAt,
                          false
                        )}
                      </div>
                    </div>
                  )}
                  {selectedWorkbook.version && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div style={{ marginRight: '10px' }}>Version:</div>
                      <div style={{ fontWeight: '500', paddingBottom: '10px' }}>
                        {selectedWorkbook.version}
                      </div>
                    </div>
                  )}
                  {user.type === 'ADMIN' && (
                    <>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>
                          Protocol Set ID:
                        </div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                          }}
                        >
                          {parmWorkbook.uid}
                          <span>
                            {isCopied && isCopied === parmWorkbook.uid ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(parmWorkbook.uid, e, setIsCopied)
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>
                          Protocol Set URL:
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            // alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              fontWeight: '500',
                              paddingBottom: '10px',
                              maxWidth: '20vw',
                              //Tail with ellipsis
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {parmWorkbook.workbookUrl}
                          </div>
                          <span>
                            {isCopied &&
                            isCopied === parmWorkbook.workbookUrl ? (
                              <BiSolidCopy
                                color={'#00534C'}
                                size="1rem"
                                className="copy_icon"
                              />
                            ) : (
                              <BiCopy
                                size="1rem"
                                className="copy_icon"
                                onClick={(e) =>
                                  handleCopy(
                                    parmWorkbook.workbookUrl,
                                    e,
                                    setIsCopied
                                  )
                                }
                              />
                            )}
                          </span>
                        </div>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginRight: '10px' }}>
                          AI Parsed Results:
                        </div>
                        <div
                          style={{
                            fontWeight: '500',
                            paddingBottom: '10px',
                            color: selectedWorkbook.aiPdfParserResults
                              ? '#00534C'
                              : '#636363',
                            textDecoration: selectedWorkbook.aiPdfParserResults
                              ? 'underline'
                              : 'none',
                            cursor: selectedWorkbook.aiPdfParserResults
                              ? 'pointer'
                              : '',
                          }}
                          onClick={() => {
                            if (selectedWorkbook.aiPdfParserResults)
                              downloadJsonFile(
                                selectedWorkbook.aiPdfParserResults
                              );
                          }}
                        >
                          Download
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <label
                htmlFor="name"
                className={`notification-css-title`}
                style={{ fontSize: '16px' }}
              >
                Name <span className="required-field">*</span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="notification-model"
                  id="name"
                  name="name"
                  disabled={!isEditMode}
                  autoFocus={true}
                  required={true}
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  style={{
                    fontSize: '16px',
                    cursor: 'auto',
                    padding: '20px 20px',
                  }}
                />
                <div className="input-border" />
              </div>
            </div>
          </div>
        )}
      </div>
      {isUploading && (
        <div className="loading">
          <Loading type="bubbles" message={uploadingText} />
        </div>
      )}
    </div>
  );
};

export default ViewProtocolSet;
