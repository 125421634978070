import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProtocolHeader from '../../../protocol/ProtocolHeader';
import { Button, Col, Row } from 'react-bootstrap';
import { Storage } from '@aws-amplify/storage';
import Loading from '../../../../components/Loading/Loading';
import { InputText } from 'primereact/inputtext';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import { useSelector } from 'react-redux';
import {
  getDepartmentsFromState,
  globals,
  toTitleCase,
} from '../../../../_global/common/Utils';
import { FaTimes } from 'react-icons/fa';
import { Category, LazyCategory, User } from '../../../../../models';
import { useFormik } from 'formik';
import { ProgressStatus } from '../../../../../models';

import * as Yup from 'yup';
import SearchableDropdown from '../../../../components/SearchableDropdown';
import {
  CategoryJSON,
  createCategory,
} from '../../../../../data/functions/CategoryDB';
import './AIPdfParser.scss';
import { ViewportList } from 'react-viewport-list';
import CategoryItem from '../../../../../data/model/CategoryItem';

import DepartmentItem from '../../../../../data/model/DepartmentItem';
import Fuse from 'fuse.js';
import MedicationItem from '../../../../../data/model/MedicationItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  createProtocol,
  ProtocolJSON,
  uploadInitialPDFToS3,
} from '../../../../../data/functions/ProtocolDB';
import {
  handleGetDepartment,
  handleLoadingFaviocon,
} from '../../../../../store/actions';
import { useDispatch } from 'react-redux';
import { InputSwitch } from 'primereact/inputswitch';
import { BiRename, BiSort, BiTrash } from 'react-icons/bi';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import AIPdfParserMedicationInput from './AIPdfParserProtocolInput';
import AiPdfParserProtocolInput from './AIPdfParserProtocolInput';
import { RadioButton } from 'primereact/radiobutton';
import { start } from 'repl';
import { max, set } from 'lodash';
import { PiPlus } from 'react-icons/pi';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import InfusionItem from '../../../../../data/model/InfusionItem';
import { AiOutlineClose, AiOutlineRight } from 'react-icons/ai';
import { IoArrowBack, IoSave } from 'react-icons/io5';
interface Dose {
  type: string;
  name: string;
  dose: string;
  administrationRoutes: string;
  maxSingleDose: string;
  minSingleDose: string;
  maxTotalDose: string;
  repeatTime: string;
  repeatAllowed: string;
  ageGroup: string;
  weightLow: string;
  weightHigh: string;
  ageLow: string;
  ageHigh: string;
  title: string;
  instruction: string;
  warning: string;
  source: string;
}

interface MedicationData {
  file: string;
  index: number;
  doses: Dose[];
}

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});

type ProtocolInput = {
  folder: CategoryItem | null | undefined;
  file: string;
  pattern?: string;
  name?: string;
  startPage?: string;
  endPage?: string;
  confirmed: boolean;
  numTreatmentItems?: number;
};

const AIPdfParserProtocolAutomatic = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log('location.state', location.state);
  const user: User = useSelector((state: any) => state?.user);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const [lowScoreThreshold, setLowScoreThreshold] = useState<string>('0.4');
  const [categories, setCategories] = useState<CategoryItem[]>(
    database.categories
  );
  const department = database.department;
  const type: string =
    location.state && location.state.type ? location.state.type : 'Full';

  const reducerState = useSelector((state: any) => state.department);
  const { subDeps, allSubDeps, userDepartments } = useMemo(() => {
    return getDepartmentsFromState(department, reducerState);
  }, [department, reducerState]);

  const [parsedProtocolData, setParsedProtocolData] = useState<ProtocolInput[]>(
    location.state && location.state.parsedProtocolData
      ? location.state.parsedProtocolData
      : []
  );
  const [aiParsedData, setAIParsedData] = useState([]);

  const [departmentList, setDepartmentList] = useState<DepartmentItem[]>([]);
  const [dbMeds, setDbMeds] = useState<MedicationItem[]>([]);
  const [dbInfusions, setDbInfusions] = useState<InfusionItem[]>([]);
  const [dbElectricals, setDbElectricals] = useState<ElectricalItem[]>([]);

  const [loading, setLoading] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [state, setState] = useState<any>({
    startPage:
      location.state && location.state.startPage
        ? location.state.startPage
        : '',
    endPage:
      location.state && location.state.endPage ? location.state.endPage : '',
    folder:
      location.state && location.state.folder ? location.state.folder : '',
    pairedDeps: [],
    pdfKey:
      location.state && location.state.pdfKey ? location.state.pdfKey : '',
    pattern:
      location.state && location.state.pattern
        ? location.state.pattern
        : {
            start: '',
            end: '',
            dynamic: '',
          },
    completedResults:
      location.state && location.state.completedResults
        ? location.state.completedResults
        : [],
  });
  const [modalState, setModalState] = useState<any>({
    isVisible: false,
    title: '',
    description: '',
    primaryBtnName: '',
    secondaryBtnName: '',
    primaryDescription: '',
    secondaryDescription: '',
  });

  useEffect(() => {
    dispatch<any>(handleLoadingFaviocon(false));
  }, [dispatch]);

  const groupProtocolNameData = (
    data: any[],
    aiParsedData: MedicationData[]
  ): ProtocolInput[] => {
    try {
      let cur: ProtocolInput | null = null;
      let protocolData: ProtocolInput[] = [];
      for (let i = 0; i < data.length; i++) {
        console.log('data[', i, ']', data[i]);
        let startPage: number = Number(data[i].page_num);
        if (isNaN(startPage)) {
          console.error('Invalid page number:', data[i].page_num);
          continue;
        }
        startPage = startPage + 1;
        let score = data[i].prediction;
        let threshold = Number(lowScoreThreshold) ?? 0.6;
        if (score < threshold) {
          //This belongs to hte last protocol unless it is the first one
          if (i !== 0 && cur) cur.endPage = startPage + '';
          console.log('LOW SCORE', data[i].prediction, data[i]);
          continue;
        }

        if (cur == null) {
          cur = {
            folder: state.folder,
            file: data[i].file,
            name: toTitleCase(data[i].text),
            startPage: startPage + '',
            endPage: '',
            confirmed: false,
            numTreatmentItems: 0,
          };
        } else if (
          cur.file === data[i].file &&
          cur.name &&
          isSimilar(toTitleCase(cur.name), toTitleCase(data[i].text))
        ) {
          cur.endPage = Number(startPage) + '';
        } else {
          let parsedData = removeDuplicateByProtocol(
            cur.startPage ? cur.startPage : '',
            cur.endPage ? cur.endPage : cur.startPage ? cur.startPage : '',
            aiParsedData
          );
          let numTreatmentItems = parsedData.reduce(
            (acc, item) => acc + item.doses.length,
            0
          );
          cur.numTreatmentItems = numTreatmentItems;
          protocolData.push(cur);

          cur = {
            folder: state.folder,
            file: data[i].file,
            name: toTitleCase(data[i].text),
            startPage: startPage + '',
            endPage: '',
            confirmed: false,
          };
        }
      }
      if (cur) {
        let parsedData = removeDuplicateByProtocol(
          cur.startPage ? cur.startPage : '',
          cur.endPage ? cur.endPage : cur.startPage ? cur.startPage : '',
          aiParsedData
        );
        let numTreatmentItems = parsedData.reduce(
          (acc, item) => acc + item.doses.length,
          0
        );
        cur.numTreatmentItems = numTreatmentItems;
        protocolData.push(cur);
      }
      return protocolData;
    } catch (error) {
      console.error('Error in groupProtocolNameData:', error);
      return [];
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    if (location.state) {
      setState({
        ...state,
        file: location.state.file,
        pdfKey: location.state.pdfKey,
        startPage: location.state.startPage,
        endPage: location.state.endPage,
      });
    }
    if (location.state && location.state.dbMeds) {
      setDbMeds(location.state.dbMeds);
    }
    if (location.state && location.state.dbInfusions) {
      setDbInfusions(location.state.dbInfusions);
    }
    if (location.state && location.state.dbElectricals) {
      setDbElectricals(location.state.dbElectricals);
    }
    if (location.state && location.state.aiParsedData) {
      console.log('location.state.aiParsedData', location.state.aiParsedData);
      setAIParsedData(location.state.aiParsedData);
    }

    if (location.state.protocolNames) {
      let groupedData = groupProtocolNameData(
        location.state.protocolNames,
        location.state.aiParsedData
      );
      setParsedProtocolData(groupedData);
    } else if (location.state.parsedProtocolData) {
      setParsedProtocolData(location.state.parsedProtocolData);
    }
  }, [
    location.state,
    location.state.pdfKey,
    location.state.file,
    location.state.dbMeds,
    location.state.dbInfusions,
    location.state.dbElectricals,
    location.state.startPage,
    location.state.endPage,
    location.state.aiParsedData,
    location.state.protocolNames,
    location.state.parsedProtocolData,
    lowScoreThreshold,
  ]);

  const objURL = useMemo(() => {
    if (state.file) return URL.createObjectURL(state.file);
    else return '';
  }, [state.file]);

  const selectedProtocols = useMemo(() => {
    return parsedProtocolData.filter((data) => data.confirmed);
  }, [parsedProtocolData]);

  // const filteredList: CategoryItem[] = useMemo(() => {
  //   if (!state.name) return [];
  //   return database.categories.filter((folder) =>
  //     folder.name.toLowerCase().includes(state.name.toLowerCase())
  //   );
  // }, [state.name, database]);
  // Clean up the object URL when the component unmounts
  useEffect(() => {
    return () => {
      if (objURL) {
        URL.revokeObjectURL(objURL);
      }
    };
  }, [objURL]);

  useEffect(() => {
    if (allSubDeps) {
      let l = [...allSubDeps];
      l = l.filter((dep) => {
        return !state.pairedDeps.some((d: DepartmentItem) => d.id === dep.id);
      });
      setDepartmentList(l);
    }
  }, [allSubDeps, state.pairedDeps]);

  /* Add all protcols within the range of the start and end page if they are not already added */
  useEffect(() => {
    let { startPage, endPage } = state;
    if (!startPage || !endPage) return;

    let startPageNum: number = Number(startPage);
    let endPageNum: number = Number(endPage);
    if (isNaN(startPageNum) || isNaN(endPageNum)) return;

    let newProtocolData: ProtocolInput[] = [];
    for (let i = 0; i < parsedProtocolData.length; i++) {
      let item = parsedProtocolData[i];
      if (item.startPage == null) continue;
      let start = Number(item.startPage);
      let end = item.endPage ? Number(item.endPage) : start;
      let isConfirmed = item.confirmed;
      if (start >= startPageNum && end <= endPageNum) isConfirmed = true;
      else isConfirmed = false;

      newProtocolData.push({
        folder: item.folder,
        file: item.file,
        name: item.name,
        startPage: item.startPage,
        endPage: item.endPage,
        confirmed: isConfirmed,
      });
    }
    setParsedProtocolData(newProtocolData);
  }, [state.startPage, state.endPage]);

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(
      database,
      undefined,
      undefined,
      true,
      true
    );
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  const handleStartPageChange = (e: any) => {
    const value = e.target.value;
    if (value === '') setState({ ...state, startPage: value });
    else {
      if (/^\d*$/.test(value)) {
        let startPage = Number(value);
        if (startPage < 1) setError('Please enter a valid start page number');
        else setState({ ...state, startPage: value });
      } else {
        setError('Please enter a valid  start page number');
      }
    }
  };

  const handleEndPageChange = (e: any) => {
    const value = e.target.value;
    if (value === '') setState({ ...state, endPage: value });
    else {
      if (/^\d*$/.test(value)) {
        let endPage = Number(value);
        if (endPage < 1) setError('Please enter a valid end page number');
        else setState({ ...state, endPage: value });
      } else {
        setError('Please enter a valid end page number');
      }
    }
  };

  const valid = (data: ProtocolInput) => {
    let { startPage, endPage, name } = data;

    const startPageNum = Number(startPage);
    const endPageNum = endPage ? Number(endPage) : Number(startPage);
    if (name === '') {
      setError('Protocol cannot be empty');
      console.log('Protocol cannot be empty');
      return false;
    }
    if (
      isNaN(startPageNum) ||
      startPageNum < 1 ||
      isNaN(endPageNum) ||
      (endPageNum < 1 && endPageNum !== 0)
    ) {
      if (isNaN(startPageNum) || startPageNum < 1) {
        console.log('Start Page must be a valid number greater than 0');
        setError('Start Page must be a valid number greater than 0');
      }
      return false;
    }

    if (endPageNum !== 0) {
      if (startPageNum > endPageNum) {
        setError('End Page must be greater than or equal to Start Page');
        return false;
      }
    }
    return true;
  };

  const handleSplittingPDF = async (data: ProtocolInput) => {
    try {
      let { startPage, endPage, name } = data;

      let id = state.pdfKey;
      if (globals.debug) console.log('id', id);
      if (!id) return;
      if (!valid(data)) {
        console.log('Invalid Protocol Input');
        return;
      }
      const url =
        process.env.REACT_APP_API_GATEWAY_URL ??
        (function () {
          throw new Error('API URL is not defined');
        })();
      const bucketName =
        process.env.REACT_APP_BUCKET_NAME ??
        (function () {
          throw new Error('BUCKET NAME is not defined');
        })();
      const apiUrl = url;

      const requestBody = {
        bucket: bucketName,
        key: `public/${id}`,
        startPage,
        endPage: endPage ? endPage : startPage,
        customName: `workBook-${startPage}-${endPage}`,
        depID: department.id,
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      if (globals.debug) console.log('result', result);
      if (result.statusCode !== 200) {
        const errorMessages = JSON.parse(result.body);
        if (errorMessages.error === 'sequence index out of range') {
          setError('Error: Invalid page range. Please try again.');
        } else {
          setError('Error: Unknown error occured');
          console.error('Error:', errorMessages);
        }
      }

      const parsedBody = JSON.parse(result.body);
      if (globals.debug) console.log('parsedBody', parsedBody);
      const processedPdfUrl = parsedBody.processedPdfUrl;
      if (!processedPdfUrl) {
        setError('Error: No processed PDF URL');
      } else {
        const key = processedPdfUrl.split('.com/')[1];
        const keywWithoutPublic = key.split('public/')[1];
        const extractedWorkbook: any = await Storage.get(keywWithoutPublic, {
          level: 'public',
          contentType: 'application/pdf',
          download: true,
        });
        if (extractedWorkbook == null || extractedWorkbook.Body == null) {
          throw new Error('Error extracting workbook');
        }

        const fileUploaded = new File(
          [extractedWorkbook.Body],
          name ? name : 'protocol_' + startPage + '-' + endPage + '.pdf',
          {
            type: 'application/pdf',
          }
        );
        setError(null);
        return {
          key,
          processedPdfUrl: processedPdfUrl,
          fileUploaded: fileUploaded,
        };
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Error splitting the PDF');
      return;
    }
  };

  const isSimilar = (str1: string, str2: string, threshold = 0.8): boolean => {
    if (!str1 || !str2) return false;

    const fuse = new Fuse([str1.trim()], {
      includeScore: true,
      threshold: 1 - threshold, // Inverse the threshold for matching
    });

    const result = fuse.search(str2.trim());
    return (
      result.length > 0 &&
      result[0].score !== undefined &&
      result[0].score <= 1 - threshold
    );
  };

  const isSimilarOrEmpty = (
    field1: string,
    field2: string,
    threshold = 0.8
  ): boolean => {
    if (!field1 || !field2) return true;

    if (field1.trim().toLowerCase() === field2.trim().toLowerCase())
      return true;

    return isSimilar(field1.trim(), field2.trim(), threshold);
  };

  const handleDuplicate = (
    doses: Dose[],
    uniqueRows: { [key: string]: Dose[] }
  ): Dose[] => {
    if (globals.debug) console.log('uniqueRows', uniqueRows);
    const timeUnits = ['hr', 'min', 'sec'];
    const pageUniqueRows: { [key: string]: Dose[] } = {};
    const criticalFields = ['type', 'name', 'dose'];
    const subCriticalFields = [
      'administrationRoutes',
      'maxSingleDose',
      'minSingleDose',
      'maxTotalDose',
      'repeatTime',
      'repeatAllowed',
    ];
    const nonCriticalFields = [
      'ageGroup',
      'weightLow',
      'weightHigh',
      'ageLow',
      'ageHigh',
      'title',
      'instruction',
      'warning',
    ];

    const standardizeDoseUnit = (dose: any): string => {
      try {
        const unitMap: { [key: string]: string } = {
          g: 'G',
          gms: 'G',
          gm: 'G',
          'g/kg': 'G/kg',
          'gm/kg': 'G/kg',
          'gms/kg': 'G/kg',
          gram: 'G',
          grams: 'G',
          'gram/kg': 'G/kg',
          'grams/kg': 'G/kg',
          iu: 'IU',
          l: 'L',
          mdi: 'MDI',
          meq: 'mEq',
          ml: 'mL',
          'ml/hr': 'mL/hr',
          lpm: 'LPM',
          puffs: 'Puffs',
          j: 'J',
          joules: 'J',
          joule: 'J',
          'joule/kg': 'J/kg',
          'j/kg': 'J/kg',
          'joules/kg': 'J/kg',
          et: 'ET',
          ett: 'ETT',
          'ml/kg': 'mL/kg',
          'meq/kg': 'mEq/kg',
          'ml/min': 'mL/min',
          'ml/kg/hr': 'mL/kg/hr',
          'ml/kg/min': 'mL/kg/min',
          'meq/kg/min': 'mEq/kg/min',
          'meq/kg/hr': 'mEq/kg/hr',
          units: 'Units',
          'units/kg': 'Units/kg',
          'units/kg/min': 'Units/kg/min',
          'units/kg/hr': 'Units/kg/hr',
          // Add other unit conversions here
        };

        const doseStr = dose.toString(); // Convert to string
        const rangeMatch = doseStr.match(
          /([\d\.]+)\s*-\s*([\d\.]+)\s*([a-zA-Z\/]+)/
        );

        if (rangeMatch) {
          const value1 = parseFloat(rangeMatch[1]).toFixed(2);
          const value2 = parseFloat(rangeMatch[2]).toFixed(2);
          const unit = rangeMatch[3].toLowerCase();
          const standardizedUnit = unitMap[unit] || unit;
          return `${value1}-${value2} ${standardizedUnit}`;
        }
        const singleMatch = doseStr.match(/([\d\.]+)\s*([a-zA-Z\/]+)/);
        if (singleMatch) {
          const value = parseFloat(singleMatch[1]).toFixed(2);
          const unit = singleMatch[2].toLowerCase();
          const standardizedUnit = unitMap[unit] || unit;
          return `${value} ${standardizedUnit}`;
        }
        // Return the original dose if no match is found
        return doseStr;
      } catch (e) {
        console.log('Error in standardizeDoseUnit', e);
        return dose.toString();
      }
    };

    doses.forEach((item) => {
      try {
        console.log('item', item);
        if (item.dose == null || item.dose === '') {
          return;
        }
        if (item.type == null || item.type === '') {
          //Check if the dose matches infusion or medication type and set the type accordingly
          const rangeMatch = item.dose.match(
            /([\d\.]+)\s*-\s*([\d\.]+)\s*([a-zA-Z\/]+)/
          );
          const singleMatch = item.dose.match(/([\d\.]+)\s*([a-zA-Z\/]+)/);
          if (rangeMatch || singleMatch) {
            //CHeck if the dose has time unit
            if (timeUnits.some((unit) => item.dose.includes(unit))) {
              item.type = 'Infusion';
            } else {
              item.type = 'Medication';
            }
          } else {
            return console.log('Invalid dose', item.dose);
          }
        }
        if (item.type === 'Infusion') {
          if (!timeUnits.some((unit) => item.dose.includes(unit))) {
            console.log('Infusion dose does not have time unit', item.dose);
            item.type = 'Medication';
          }
        } else if (item.type === 'Medication') {
          if (timeUnits.some((unit) => item.dose.includes(unit))) {
            console.log('Medication dose has time unit', item.dose);
            item.type = 'Infusion';
          }
        }

        console.log('BEFORE item.dose', item.dose);
        item.dose = standardizeDoseUnit(item.dose);
        console.log('AFTER item.dose', item.dose);
        item.maxSingleDose = standardizeDoseUnit(item.maxSingleDose);
        item.minSingleDose = standardizeDoseUnit(item.minSingleDose);
        item.maxTotalDose = standardizeDoseUnit(item.maxTotalDose);

        const criticalFieldValues = tupleToStringArray(item, criticalFields);
        const subCriticalFieldValues = tupleToStringArray(
          item,
          subCriticalFields
        );
        const nonCriticalFieldValues = tupleToStringArray(
          item,
          nonCriticalFields
        );
        const criticalFieldKey = criticalFieldValues.join('|'); // Join array into a single string using a separator

        if (!(criticalFieldKey in uniqueRows)) {
          uniqueRows[criticalFieldKey] = [item];
          pageUniqueRows[criticalFieldKey] = [item];
        } else {
          const existingItems: Dose[] = uniqueRows[criticalFieldKey];
          let isDuplicate = false;

          existingItems.forEach((existingItem) => {
            const existingSubCriticalFields = tupleToStringArray(
              existingItem,
              subCriticalFields
            );
            const existingNonCriticalFields = tupleToStringArray(
              existingItem,
              nonCriticalFields
            );

            // Check for partial match in administrationRoutes
            const adminRoutes = new Set([
              ...subCriticalFieldValues[0]
                .split(',')
                .map((route) => route.trim()),
              ...existingSubCriticalFields[0]
                .split(',')
                .map((route) => route.trim()),
            ]);
            const isAdminRoutesDuplicate = [...adminRoutes].some((route) =>
              existingSubCriticalFields[0].includes(route.trim())
            );

            if (
              isAdminRoutesDuplicate &&
              subCriticalFields
                .slice(1)
                .every((_, idx) =>
                  isSimilarOrEmpty(
                    existingSubCriticalFields[idx + 1].trim(),
                    subCriticalFieldValues[idx + 1].trim()
                  )
                )
            ) {
              isDuplicate = true;

              subCriticalFields.forEach((field, idx) => {
                if (!existingSubCriticalFields[idx]) {
                  (existingItem as any)[field] = subCriticalFieldValues[idx];
                } else if (!subCriticalFieldValues[idx]) {
                  (item as any)[field] = existingSubCriticalFields[idx];
                }
              });

              nonCriticalFields.forEach((field, idx) => {
                if (!existingNonCriticalFields[idx]) {
                  (existingItem as any)[field] = nonCriticalFieldValues[idx];
                } else if (!nonCriticalFieldValues[idx]) {
                  (item as any)[field] = existingNonCriticalFields[idx];
                }
              });

              // Combine administrationRoutes
              existingItem.administrationRoutes = [...adminRoutes].join(',');
              item.administrationRoutes = [...adminRoutes].join(',');

              existingItem.source = 'BOTH';
              item.source = 'BOTH';
            }
          });

          if (!isDuplicate) {
            uniqueRows[criticalFieldKey].push(item);
            pageUniqueRows[criticalFieldKey] = [item];
          }
        }
      } catch (error) {
        console.error('Error in handleDuplicate', error);
      }
    });

    let updatedData: Dose[] = [];

    // Directly iterate over the entries of pageUniqueRows
    Object.entries(pageUniqueRows).forEach(([, items]) => {
      items.forEach((item) => {
        updatedData.push(item);
      });
    });

    console.log('updatedData', updatedData);

    return updatedData;
  };

  // Helper function to convert tuple fields to string array
  const tupleToStringArray = (item: any, fields: string[]): string[] => {
    return fields.map((field) => String(item[field] ?? ''));
  };

  const removeDuplicateByProtocol = (
    startPage: string,
    endPage: string,
    _aiParsedData: any = aiParsedData
  ) => {
    const startPageNum: number = Number(startPage);
    const endPageNum: number = endPage ? Number(endPage) : Number(startPage);
    const filteredData: MedicationData[] = (
      _aiParsedData as MedicationData[]
    ).filter((item) => item.index >= startPageNum && item.index <= endPageNum);

    const finalJson: any[] = [];
    const uniqueRows: { [key: string]: Dose[] } = {};

    for (let i = 0; i < filteredData.length; i++) {
      const item = filteredData[i];
      const updatedData = handleDuplicate(item.doses, uniqueRows);

      finalJson.push({
        file: item.file,
        index: item.index,
        doses: updatedData,
      });
    }

    return finalJson;
  };

  const groupDataByType = (
    data: MedicationData[]
  ): { [key: string]: MedicationData[] } | undefined => {
    const groupedByType: { [key: string]: MedicationData[] } = {};

    data.forEach((item) => {
      const { file, index, doses } = item;
      doses.forEach((dose) => {
        const doseType = dose.type === 'Med' ? 'Medication' : dose.type;
        if (!groupedByType[doseType]) {
          groupedByType[doseType] = [];
        }
        // if (
        //   dose.ageGroup &&
        //   dose.ageGroup.toLocaleLowerCase().includes('adult')
        // ) {
        //   dose.weightLow = '40 kg';
        // } else if (
        //   dose.ageGroup &&
        //   dose.ageGroup.toLocaleLowerCase().includes('pediatric')
        // ) {
        //   dose.weightHigh = '40 kg';
        // }
        groupedByType[doseType].push({
          file,
          index,
          doses: [dose],
        });
      });
    });

    /* Make sure all the types are an empty array if it doesn't exist */
    const allTypes = ['Electrical', 'Infusion', 'Medication'];
    allTypes.forEach((type) => {
      if (!groupedByType[type]) {
        groupedByType[type] = [];
      }
    });

    let isDoses = false;
    Object.keys(groupedByType).forEach((key) => {
      if (groupedByType[key].length > 0) {
        isDoses = true;
      }
    });

    return isDoses ? groupedByType : undefined;
  };

  const handleBack = () => {
    navigate(`/actions/ai-pdf-parser`, {
      state: {
        file: state.file,
        pdfKey: state.pdfKey,
      },
    });
  };

  const handleAddDepartment = (option: DepartmentItem) => {
    let l = [...state.pairedDeps, option];
    l.sort((a, b) => a.name.localeCompare(b.name));
    // formik.setFieldValue('pairedDeps', l);
    setState({ ...state, pairedDeps: l });
  };

  const handleRemoveDepartment = (option: DepartmentItem, e: any) => {
    e.stopPropagation();
    let l = state.pairedDeps.filter((d: DepartmentItem) => d.id !== option.id);
    // formik.setFieldValue('pairedDeps', l);
    setState({ ...state, pairedDeps: l });
  };
  const handleClearDepartments = () => {
    // formik.setFieldValue('pairedDeps', []);
    setState({ ...state, pairedDeps: [] });
  };

  const handleContinue = async () => {
    try {
      let { file, folder, pdfKey } = state;
      setLoading('Creating Protocols for Folder ' + folder.name + '...');
      let results: any[] = [];
      let promises = [];
      for (let i = 0; i < selectedProtocols.length; i++) {
        promises.push(handleProcessProtocol(folder, selectedProtocols[i], i));
        if (promises.length > 20) {
          let res = await Promise.all(promises);
          results = results.concat(res);
          promises = [];
        }
      }
      if (promises.length > 0) {
        let res = await Promise.all(promises);
        results = results.concat(res);
      }
      console.log('results', results);
      setLoading(null);
      if (type === 'Full') {
        navigate('/actions/ai-pdf-parser/results-automatic', {
          state: {
            results: results,
            folder: folder,
            startPage: state.startPage,
            endPage: state.endPage,
            database: database,
            dbMeds: dbMeds,
            dbInfusions: dbInfusions,
            dbElectricals: dbElectricals,
            pdfKey: state.pdfKey,
            file: file,
            aiParsedData: aiParsedData,
            parsedProtocolData: parsedProtocolData,
            pattern: state.pattern,
          },
        });
      } else {
        setModalState({
          isVisible: true,
          title: 'Successfully Created ' + results.length + ' Protocols',
          description: 'Protocols have been added to the folder ' + folder.name,
          primaryBtnName: 'Continue',
          secondaryBtnName: 'Continue',
          primaryDescription: '',
          secondaryDescription: '',
        });
      }
    } catch (error) {
      console.error('ERROR PROCESSING PROTOCOL', error);
      setLoading(null);
    }
  };

  const handleProcessProtocol = (
    folder: CategoryItem,
    data: ProtocolInput,
    index: number
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let { file, pattern, name, startPage, endPage } = data;
        if (name == null || startPage == null) {
          console.error(
            'ERROR CREATING PROTOCOL: MISSING DATA',
            name,
            startPage
          );
          return reject('ERROR CREATING PROTOCOL: MISSING DATA');
        }

        /* 1. First split the PDF to get the pages */
        let result = await handleSplittingPDF(data);
        if (result == null) return reject('ERROR SPLITTING PDF');

        let { fileUploaded, key, processedPdfUrl } = result;

        if (fileUploaded == null) {
          console.error('ERROR SPLITTING PDF');
          return reject('ERROR SPLITTING PDF');
        }

        /* 2. Create the new protocol */
        let newProtocol: ProtocolItem | undefined = await handleCreateProtocol(
          folder,
          (pattern ? pattern + ' ' + name : name).trim(),
          fileUploaded,
          state.pairedDeps,
          index
        );

        if (newProtocol == null) {
          console.error('ERROR CREATING PROTOCOL');
          return reject('ERROR CREATING PROTOCOL');
        }
        /* 3. Save the AI Data */
        let aiParsedResult: MedicationData[] | undefined = undefined;
        let groupByType: { [key: string]: MedicationData[] } | undefined =
          undefined;
        if (type === 'Full') {
          aiParsedResult = removeDuplicateByProtocol(
            startPage,
            endPage ? endPage : startPage
          );
          groupByType = groupDataByType(aiParsedResult);
          console.log('aiParsedResult', aiParsedResult);
          console.log('groupByType', groupByType);
        }
        resolve({
          newProtocol,
          fileUploaded,
          key,
          processedPdfUrl,
          aiParsedResult,
          groupByType,
          startPage,
          endPage,
        });
        // if (groupByType == null) {
        //   console.log('NO DATA FOUND for protocol:', name);
        //   // setIsNoDataModal(true);
        //   // setLoading(null);
        // } else if (fileUploaded && aiParsedResult) {
        //   // setLoading(null);
        //   // navigate('/actions/ai-pdf-parser/results', {
        //   //   state: {
        //   //     file: file,
        //   //     pdfKey: pdfKey,
        //   //     fileUploaded: fileUploaded, // Pass the file path if needed
        //   //     folder: folder,
        //   //     protocol: newProtocol,
        //   //     startPage: startPage,
        //   //     endPage: endPage,
        //   //     database: database,
        //   //     dbMeds: dbMeds,
        //   //     dbInfusions: dbInfusions,
        //   //     aiParsedData: aiParsedData,
        //   //     allTypeData: {
        //   //       Electrical: groupByType.Electrical,
        //   //       Infusion: groupByType.Infusion,
        //   //       Medication: groupByType.Medication,
        //   //     },
        //   //     pdfKey: state.pdfKey,
        //   //   },
        //   // });
        // }
      } catch (error) {
        console.error('ERROR PROCESSING PROTOCOL', error);
        reject(error);
      }
    });
  };

  const handleCreateProtocol = async (
    parent: CategoryItem,
    name: string,
    pdf: File,
    pairedDeps: DepartmentItem[],
    index?: number
  ): Promise<ProtocolItem | undefined> => {
    try {
      // not adding else since its common for both cases
      setLoading('Saving the Protocol in DB.....');
      if (parent == null || name == null || pdf == null) {
        console.error(
          'ERROR CREATING PROTOCOL: MISSING DATA',
          parent,
          name,
          pdf
        );
        return;
      }

      let protocols = database.protocols;
      let find = protocols.find((p) => {
        /* Do a fuzzy search */
        let isSim = isSimilar(p.name, name, 0.8);
        if (isSim) {
          /* Make sure the parent is the same */
          if (p.parent.uid === parent.uid) return true;
        }
        return false;
      });
      if (find) {
        console.log('Protocol already exists', find);
        return find;
      }

      /* First check if the protocol already exists */
      /* Upload the PDF to the S3 bucket */
      let result: Response = await uploadInitialPDFToS3(
        database.department,
        parent,
        name,
        pdf,
        (error: any) => {
          console.error('ERROR UPLOADING PDF TO S3', error);
        }
      );
      if (result.type === ResponseType.Failure) {
        console.error('ERROR UPLOADING PDF TO S3', result.data);
        return;
      }

      let pdfID: string = result.data;

      let i = parent.protocols.length + (index != null ? index : 0);
      console.log('index', i, index);

      /* Create the new protocol item */
      let json: ProtocolJSON = {
        departmentID: database.department.id,
        name: name,
        nickname: name,
        pdfID: pdfID,
        rangeLow: 0,
        rangeHigh: globals.MAX_VALUE,
        parentCategory: parent,
        pairedDepsIDs: pairedDeps.map((dep) => dep.id),
        pairedProtocols: [],
        medications: [],
        infusions: [],
        equipment: [],
        electrical: [],
        forms: [],
        createdBy: user.id,
        status: ProgressStatus.DRAFT,
        activeID: null,
        version: 'v1.0.0',
        pdfVersion: 'v1.0.0',
        index: i,
        isPublic: parent.isPublic,
        isRestrictive: parent.isRestrictive,
        keychainID: null,
      };

      /* Create the protocol item */

      let results: Response = await createProtocol(json);
      if (results.type === ResponseType.Success) {
        let newProtocol: ProtocolItem = results.data;
        if (globals.debug) console.log('CREATED DRAFT PROTOCOL:', newProtocol);
        return newProtocol;

        /* Reload the database -- OR use the subscribe method */
        //reloadDatabase();
      } else {
        if (globals.debug)
          console.log('ERROR CREATING DRAFT PROTOCOL:', results.data);
      }

      setLoading(null);
    } catch (error) {
      console.error('ERROR CREATING PROTOCOL', error);
    }
  };

  // const handleSave = async () => {};

  const isSaveActive = useMemo(() => {
    return state.folder && selectedProtocols.length > 0;
  }, [state, selectedProtocols]);

  const handlePatternUpdate = (field: string, value: string) => {
    let { pattern } = state;
    if (pattern == null) pattern = { start: '', end: '', dynamic: '' };
    pattern[field] = value;
    setState({ ...state, pattern: pattern });

    if (pattern.dynamic === '' || pattern.dynamic === null) return;
    else {
      let newParsedProtocolData = [...parsedProtocolData];
      let index = 0;
      for (let i = 0; i < newParsedProtocolData.length; i++) {
        if (!newParsedProtocolData[i].confirmed) continue;
        let patternStr = calculatePattern(index, pattern);
        if (patternStr === null) continue;
        newParsedProtocolData[i].pattern = patternStr;
        index++;
      }
      setParsedProtocolData(newParsedProtocolData);
    }
  };

  const calculatePattern = (index: number, pattern: any) => {
    let { start, end, dynamic } = pattern;

    if (dynamic === '' || dynamic === null) return;

    let str = start ? start : '';

    // Check if it is a number (including decimals)
    if (dynamic.match(/^[0-9]+(\.[0-9]+)?$/)) {
      let num = parseFloat(dynamic); // Use parseFloat to handle decimals
      if (isNaN(num)) return '';
      // Determine the increment based on the number of decimal places
      let decimalPlaces = dynamic.includes('.')
        ? dynamic.split('.')[1].length
        : 0;
      let increment = 1 / Math.pow(10, decimalPlaces);
      str += (num + increment * index).toFixed(decimalPlaces); // Ensure the same number of decimal places
    }
    // Increase the character by the index
    else if (dynamic.match(/^[a-zA-Z]+$/)) {
      str += String.fromCharCode(dynamic.charCodeAt(0) + index);
    } else return null;

    return str + (end ? end : '');
  };

  function detectAllCapsPaste(
    lastValue: any,
    value: string,
    setParsedProtocolData: any,
    index: number
  ) {
    console.log(value, lastValue, value.length - lastValue.length);
    if (value.length - lastValue.length > 2) {
      let cleaned_string = value.replace(/[^A-Za-z]/g, '');
      console.log(cleaned_string);
      if (cleaned_string.length === 0) return false;
      if (cleaned_string === cleaned_string.toUpperCase()) {
        let temp = [...parsedProtocolData];
        temp[index].name = toTitleCase(value);
        setParsedProtocolData(temp);
      }
    }
  }

  return (
    <div className="screen-container">
      {loading !== null && <Loading type="bubbles" message={loading} />}

      {/* <ProtocolHeader
        page={'AI PDF Parser'}
        name={'Extract Protocols'}
        description={
          selectedProtocols.length > 0
            ? 'Adding ' +
              selectedProtocols.length +
              '/' +
              parsedProtocolData.length +
              ' protocols to folder: ' +
              state.folder.name
            : 'Select protocols to add to a folder - Type: ' + type
        }
        isBackButton={true}
        type={'protocol'}
        isCancelButton={true}
        isCustomButton={true}
        handleCancelEdit={() => {
          handleBack();
        }}
        isCustomActive={isSaveActive}
        customText="Continue"
        // rightSideBtn={'add'}
        handleCustom={() => {
          handleContinue();
        }}
        handleCancel={handleBack}
      /> */}

      <ConfirmModal
        isVisible={modalState.isVisible}
        title={modalState.title}
        handleClose={() => {
          setModalState({ ...modalState, isVisible: false });
        }}
        handleSubmit={() => {
          setModalState({ ...modalState, isVisible: false });
          setState({
            ...state,
            startPage:
              Number(state.endPage ? state.endPage : state.startPage) + 1 + '',
            endPage: '',
            protocolName: '',
            protocol: undefined,
            pdf: null,
            pairedDeps: [],
          });
        }}
        isDeleteBtn={false}
        isSingleBtn={modalState.primaryBtnName === modalState.secondaryBtnName}
        primaryBtnName={modalState.primaryBtnName}
        secondaryBtnName={modalState.secondaryBtnName}
        primaryDescription={
          modalState.description ? modalState.description : ''
        }
      />

      <div
        className="content-container"
        style={
          {
            // maxHeight: 'calc(100vh - 250px)',
            // overflowY: 'auto',
          }
        }
      >
        <div
          className="left-panel"
          style={
            {
              // maxHeight: 'calc(100vh - 250px)',
              // overflowY: 'auto',
            }
          }
        >
          <div
            className="fixedHeader fixedHeaderPad"
            data-testid="protocol-header"
          >
            <div
              style={{
                margin: '0px 10px',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px 0px',
                  flex: 1,
                  overflowX: 'auto',
                }}
              >
                <div className={`titleStyling`}>
                  <div
                    className=""
                    style={{ alignItems: 'center', justifyContent: 'center' }}
                  >
                    <h6
                      className={`backTextLight hoverText`}
                      onClick={handleBack}
                    >
                      <span>
                        <IoArrowBack
                          data-testid="isBackBttn"
                          size="1.15rem"
                          onClick={handleBack}
                          style={{ marginRight: '5px' }}
                        />
                      </span>
                      AI PDF Parser
                    </h6>
                  </div>

                  <div className="titleItemContainer">
                    <div className="titleTextPH">Extract Protocols</div>
                  </div>

                  <h6
                    className={`headerTextLight`}
                    style={{
                      fontWeight: '400',
                      color: '#616161',
                      marginRight: '2rem',
                    }}
                  >
                    {selectedProtocols.length > 0
                      ? 'Adding ' +
                        selectedProtocols.length +
                        '/' +
                        parsedProtocolData.length +
                        ' protocols to folder: ' +
                        state.folder.name
                      : 'Select protocols to add to a folder - Type: ' + type}
                  </h6>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  paddingRight: 0,
                }}
              >
                <div className="headerButtonContainer">
                  <Button
                    data-testid="isCancelButton"
                    className="secondary-button-small-border btn-rightMargin"
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    <span>
                      <AiOutlineClose className="icon-normal" size="1rem" />
                    </span>{' '}
                    Cancel
                  </Button>
                  <Button
                    data-testid="isSaveButton"
                    className={` ${
                      // rightSideBtn === "save"
                      'primary-button btn-rightMargin'
                      // : "secondary-button-small-border btn-rightMargin"
                    }`}
                    onClick={() => {
                      handleContinue();
                    }}
                    disabled={!isSaveActive}
                  >
                    Continue
                    <span>
                      <AiOutlineRight className="icon-normal" />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <label htmlFor="protocolName" className="ketamine-general-label">
            Find Folder<span className="required-field">*</span>
            {state.folder != null && (
              <span className="headerTextMargin">
                {' '}
                <span
                  className="boldText"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  {' '}
                  {state.folder?.protocols?.length} Protocols
                </span>
              </span>
            )}
          </label>
          <span
            className="contentText greyText"
            style={{ fontWeight: '500', marginLeft: '10px' }}
          >
            Find the folder where the protocol will be saved. Then select all
            the protocols that will be added to the folder.
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '1rem',
              padding: '1rem',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
              marginBottom: '1rem',
            }}
          >
            <SearchableDropdown<CategoryItem>
              id="searchDropdown"
              value={state.folder}
              onChange={(option) => {
                setState({ ...state, folder: option });
              }}
              onClear={() => {
                setState({ ...state, folder: '' });
              }}
              labelField={(option) => option.name}
              valueField={(option) => option.name}
              keyField={(option) => option.uid}
              options={database.categories}
              placeholder="Search Folder..."
              containerStyle={{ flex: 4 }}
              autoFocus={state.folder == null}
              onCreate={(e) => {}}
            />
            <InputText
              style={{
                flex: 1,
                fontSize: '14px',
                fontWeight: '500',
                color: '#000',
                marginRight: '2px',
                // borderColor: '#E0EADD',
                // borderLeft: 'none',
                // borderRight: 'none',
                // borderTop: 'none',
                // borderRadius: '0px',
              }}
              value={state.startPage}
              onChange={handleStartPageChange}
              onClick={(e) => {
                e.stopPropagation();
              }}
              placeholder="Start Page..."
            />
            <InputText
              style={{
                flex: 1,
                fontSize: '14px',
                fontWeight: '500',
                color: '#000',
                marginLeft: '2px',
                // borderColor: '#E0EADD',
                // borderLeft: 'none',
                // borderRight: 'none',
                // borderTop: 'none',
                // borderRadius: '0px',
              }}
              value={state.endPage}
              onChange={handleEndPageChange}
              onClick={(e) => {
                e.stopPropagation();
              }}
              placeholder="End Page..."
            />
          </div>
          {department && allSubDeps && allSubDeps.length > 0 && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                <span
                  className="headerTextMargin"
                  style={{ fontSize: '16px', marginTop: '10px' }}
                >
                  Subscribed Departments: {state.pairedDeps.length} /{' '}
                  {allSubDeps.length}
                  <span
                    onClick={() => {
                      if (allSubDeps)
                        setState({
                          ...state,
                          pairedDeps: [...allSubDeps],
                        });
                    }}
                  >
                    <div className="clickableText">Add All</div>
                  </span>
                </span>
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '10px' }}
              >
                This is a list of every department that will subscribe to the
                protocol.
              </span>
              <SearchableDropdown<DepartmentItem>
                id="searchDropdown"
                options={departmentList}
                labelField={(option) => option.name}
                valueField={(option) => option.name}
                keyField={(option) => option.id}
                multiSelect={true}
                onChange={(option: DepartmentItem) =>
                  handleAddDepartment(option)
                }
                onClear={handleClearDepartments}
                placeholder="Search department..."
              />
              {state.pairedDeps.length === 0 && (
                <h6 style={{ textAlign: 'center', marginTop: '10px' }}>
                  No paired departments...
                </h6>
              )}
              <div
                style={{
                  border:
                    state.pairedDeps.length === 0 ? '0px' : '1px solid #ccc',
                  borderRadius: '5px',
                  marginBottom: '20px',
                  marginTop: '10px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ViewportList items={state.pairedDeps}>
                  {(item: DepartmentItem, index) => (
                    <div
                      key={index}
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '16fr 1fr',
                        padding: '6px 10px',
                        alignItems: 'center',
                        borderBottom:
                          index === state.pairedDeps.length - 1
                            ? ''
                            : '1px solid #ccc',
                      }}
                      className="listItem"
                    >
                      <div className="contentText">{item.name}</div>
                      <FaTimes
                        className="icon-cancel"
                        size={16}
                        onClick={(e) => handleRemoveDepartment(item, e)}
                      />
                    </div>
                  )}
                </ViewportList>
              </div>
            </>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '.5rem',
              padding: '0.5rem 1rem',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
              // marginBottom: '1rem',
              alignContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <div
              style={{
                flex: 3,
              }}
            >
              <label htmlFor="protocolName" className="ketamine-general-label">
                Add Protocol Pattern
              </label>
              <span
                className="contentText greyText"
                style={{ fontWeight: '500', marginLeft: '10px' }}
              >
                Add a pattern to the start of every selected protocol.
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                flex: 1,
              }}
            >
              <label
                htmlFor="protocolName"
                className="ketamine-general-label"
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                Static Start
              </label>
              <InputText
                style={{
                  flex: 1,
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#000',
                  marginRight: '2px',
                  maxHeight: '30px',
                  // padding: '0 10px 0 10px',
                  // borderColor: '#E0EADD',
                  // borderLeft: 'none',
                  // borderRight: 'none',
                  // borderTop: 'none',
                  // borderRadius: '0px',
                }}
                value={state.pattern.start}
                onChange={(e) => {
                  handlePatternUpdate('start', e.target.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Start..."
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                flex: 1,
              }}
            >
              <label
                htmlFor="protocolName"
                className="ketamine-general-label"
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                Dynamic Pattern
              </label>
              <InputText
                style={{
                  flex: 1,
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#000',
                  marginRight: '2px',
                  maxHeight: '30px',
                  // padding: '0 10px 0 10px',
                  // borderColor: '#E0EADD',
                  // borderLeft: 'none',
                  // borderRight: 'none',
                  // borderTop: 'none',
                  // borderRadius: '0px',
                }}
                value={state.pattern.dynamic}
                onChange={(e) => {
                  handlePatternUpdate('dynamic', e.target.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder="Pattern..."
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                // justifyContent: 'space-between',
                alignItems: 'flex-start',
                flex: 1,
              }}
            >
              <label
                htmlFor="protocolName"
                className="ketamine-general-label"
                style={{
                  margin: 0,
                  padding: 0,
                }}
              >
                Static End
              </label>
              <InputText
                style={{
                  flex: 1,
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#000',
                  marginRight: '2px',
                  maxHeight: '30px',
                  // padding: '0 10px 0 10px',
                  // borderColor: '#E0EADD',
                  // borderLeft: 'none',
                  // borderRight: 'none',
                  // borderTop: 'none',
                  // borderRadius: '0px',
                }}
                value={state.pattern.end}
                onChange={(e) => {
                  handlePatternUpdate('end', e.target.value);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                placeholder="End..."
              />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '1rem',
              // padding: '0px 10px',
              // gap: '2rem',
            }}
          >
            <div
              style={{
                flex: 1,
              }}
            >
              <label
                htmlFor="protocolName"
                className="ketamine-general-label"
                style={{
                  marginTop: '10px',
                  fontSize: '16px',
                }}
              >
                Detected: {parsedProtocolData.length} Protocols
              </label>
              <label
                htmlFor="protocolName"
                className="ketamine-general-label"
                style={{
                  marginTop: '-8px',
                  fontSize: '10px',
                  color: '#616161',
                }}
              >
                Press "Shift + Enter" to add a new line
              </label>
            </div>

            <div
              style={{
                gap: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                margin: 0,
                padding: 0,
              }}
            >
              <label
                htmlFor=""
                className=""
                style={{
                  fontSize: '10px',
                  margin: '-8px 0 0 0',
                  padding: 0,
                }}
              >
                Threshold
              </label>
              <InputText
                style={{
                  // flex: 1,
                  maxWidth: '75px',
                  fontSize: '14px',
                  fontWeight: '500',
                  color: '#000',
                  marginRight: '8px',
                  marginTop: '0px',
                }}
                value={lowScoreThreshold + ''}
                onChange={(e) => {
                  // if (isNaN(Number(e.target.value))) return;
                  setLowScoreThreshold(e.target.value);
                }}
                placeholder="Threshold..."
              />
            </div>

            <Button
              className={` ${
                // rightSideBtn === "save"
                'secondary-button-small-border btn-rightMargin'
                // : "secondary-button-small-border btn-rightMargin"
              }`}
              onClick={() => {
                setParsedProtocolData([]);
              }}
            >
              <span>
                <BiTrash size="1rem" />
              </span>{' '}
              Clear
            </Button>
            <Button
              className={` ${
                // rightSideBtn === "save"
                'secondary-button-small-border btn-rightMargin'
                // : "secondary-button-small-border btn-rightMargin"
              }`}
              onClick={() => {
                /*Sort the parsed protocol data based on the start and end page*/
                let temp = [...parsedProtocolData];
                temp.sort((a, b) => {
                  let aStart = Number(a.startPage);
                  let bStart = Number(b.startPage);
                  if (aStart !== bStart) return aStart - bStart;
                  let aEnd = Number(a.endPage);
                  let bEnd = Number(b.endPage);
                  return aEnd - bEnd;
                });
                setParsedProtocolData(temp);
              }}
            >
              <span>
                <BiSort size="1rem" />
              </span>{' '}
              Sort
            </Button>
            <Button
              data-testid="isSaveButton"
              className={` ${
                // rightSideBtn === "save"
                'primary-button btn-rightMargin'
                // : "secondary-button-small-border btn-rightMargin"
              }`}
              onClick={() => {
                /* Add a protocol to the parsed protocol data */
                let start = '';
                if (parsedProtocolData.length > 0) {
                  let data = parsedProtocolData[0];
                  start =
                    (Number(data.endPage ? data.endPage : data.startPage) + 1 ??
                      '') + '';
                }
                let temp = [
                  {
                    folder: state.folder,
                    file: '',
                    name: '',
                    startPage: start,
                    endPage: '',
                    confirmed: false,
                    pattern: '',
                  },
                  ...parsedProtocolData,
                ];

                setParsedProtocolData(temp);
              }}
            >
              <span>
                <PiPlus size="1rem" />
              </span>{' '}
              Protocol
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              alignItems: 'flex-start',
              padding: '0px 10px',
              gap: '2rem',
            }}
          >
            <label
              className="clickableText"
              style={{
                fontSize: '12px',
                fontWeight: 'bold',
                margin: 0,
                padding: 0,
              }}
              onClick={() => {
                if (selectedProtocols.length === parsedProtocolData.length) {
                  setParsedProtocolData(
                    parsedProtocolData.map((item) => ({
                      ...item,
                      confirmed: false,
                    }))
                  );
                } else {
                  let temp = [...parsedProtocolData];
                  let allConfirmed = temp.every((item) => item.confirmed);
                  temp.forEach((item) => (item.confirmed = !allConfirmed));
                  setParsedProtocolData(temp);
                }
              }}
            >
              {selectedProtocols.length === parsedProtocolData.length
                ? 'Remove All'
                : 'Add All'}
            </label>
            {state.pattern.dynamic && (
              <label
                style={{
                  flex: 1,
                  fontSize: '12px',
                  fontWeight: 'bold',
                }}
              >
                Pattern
              </label>
            )}
            <label style={{ flex: 8, fontSize: '12px', fontWeight: 'bold' }}>
              Protocol Name
            </label>
            <label
              style={{
                flex: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'end',
              }}
            >
              Start
            </label>
            <label
              style={{
                flex: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                textAlign: 'end',
                // marginRight: '2.5rem',
              }}
            >
              End
            </label>
            <label
              style={{
                flex: 1,
                fontSize: '12px',
                fontWeight: 'bold',
                marginRight: '1.0rem',
              }}
            >
              Items
            </label>
          </div>
          <div
            style={{
              // maxHeight: '300px',
              // overflowY: 'auto',
              // overflowX: 'hidden',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
            }}
          >
            <ViewportList items={parsedProtocolData}>
              {(data: ProtocolInput, index: number) => {
                return (
                  <div
                    onClick={() => {
                      let temp = [...parsedProtocolData];
                      temp[index].confirmed = !data.confirmed;
                      setParsedProtocolData(temp);
                    }}
                    key={index}
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'row',
                      // justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '10px 10px',
                      backgroundColor: index % 2 === 0 ? '#F7FAF6' : '#fff',
                      // borderBottom:
                      //   parsedProtocolData.length - 1 === index
                      //     ? ''
                      //     : '1px solid #e3e3e3',
                    }}
                  >
                    <RadioButton
                      checked={data.confirmed}
                      onChange={(e) => {
                        let temp = [...parsedProtocolData];
                        temp[index].confirmed = !data.confirmed;
                        setParsedProtocolData(temp);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                    {data.confirmed && data.pattern && (
                      <InputText
                        style={{
                          flex: 0.9,
                          fontSize: '14px',
                          fontWeight: '500',
                          color: '#000',
                          margin: '0 0rem 0 3rem',
                          // borderLeft: 'none',
                          // borderRight: 'none',
                          // borderTop: 'none',
                          // borderRadius: '0px',
                          // borderColor: '#E0EADD',
                          // padding: '0 10px 0 10px',
                        }}
                        value={data.pattern}
                        onChange={(e) => {
                          let temp = [...parsedProtocolData];
                          temp[index].name = e.target.value;
                          setParsedProtocolData(temp);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    )}
                    <InputText
                      style={{
                        flex: 8,
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#000',
                        margin: '0 2rem',
                        // borderLeft: 'none',
                        // borderRight: 'none',
                        // borderTop: 'none',
                        // borderRadius: '0px',
                        // borderColor: '#E0EADD',
                        // padding: '0 10px 0 10px',
                      }}
                      value={data.name}
                      onChange={(e) => {
                        let temp = [...parsedProtocolData];
                        let lastVal = temp[index].name;
                        temp[index].name = e.target.value;
                        setParsedProtocolData(temp);
                        detectAllCapsPaste(
                          lastVal,
                          e.target.value,
                          setParsedProtocolData,
                          index
                        );
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onKeyDown={(e) => {
                        //Check for shift + enter to add a new line
                        if (e.key === 'Enter' && e.shiftKey) {
                          e.preventDefault();
                          let start = '';
                          if (parsedProtocolData.length > 0) {
                            let data = parsedProtocolData[0];
                            start =
                              Number(
                                data.endPage ? data.endPage : data.startPage
                              ) +
                              1 +
                              '';
                          }
                          let temp = [
                            {
                              folder: state.folder,
                              file: '',
                              name: '',
                              startPage: start,
                              endPage: '',
                              confirmed: false,
                              pattern: '',
                            },
                            ...parsedProtocolData,
                          ];
                          setParsedProtocolData(temp);
                        } else if (e.key === 'e' && e.ctrlKey) {
                          console.log('Incrementing end page');
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) + 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) + 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 's' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) + 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'w' && e.ctrlKey) {
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) - 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) - 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'a' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) - 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 't' && e.ctrlKey) {
                          //toTitleCase the name
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].name = toTitleCase(
                            temp[index].name ?? ''
                          );
                          setParsedProtocolData(temp);
                        }
                      }}
                    />
                    <InputText
                      style={{
                        flex: 1,
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#000',
                        marginRight: '2px',
                        // borderColor: '#E0EADD',
                        // borderLeft: 'none',
                        // borderRight: 'none',
                        // borderTop: 'none',
                        // borderRadius: '0px',
                      }}
                      value={data.startPage}
                      onChange={(e) => {
                        let temp = [...parsedProtocolData];
                        temp[index].startPage = e.target.value;
                        setParsedProtocolData(temp);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      onKeyDown={(e) => {
                        //Check for shift + enter to add a new line
                        if (e.key === 'Enter' && e.shiftKey) {
                          e.preventDefault();
                          let start = '';
                          if (parsedProtocolData.length > 0) {
                            let data = parsedProtocolData[0];
                            start =
                              Number(
                                data.endPage ? data.endPage : data.startPage
                              ) +
                              1 +
                              '';
                          }
                          let temp = [
                            {
                              folder: state.folder,
                              file: '',
                              name: '',
                              startPage: start,
                              endPage: '',
                              confirmed: false,
                              pattern: '',
                            },
                            ...parsedProtocolData,
                          ];
                          setParsedProtocolData(temp);
                        } else if (e.key === 'e' && e.ctrlKey) {
                          console.log('Incrementing end page');
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) + 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) + 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 's' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) + 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'w' && e.ctrlKey) {
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) - 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) - 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'a' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) - 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 't' && e.ctrlKey) {
                          //toTitleCase the name
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].name = toTitleCase(
                            temp[index].name ?? ''
                          );
                          setParsedProtocolData(temp);
                        }
                      }}
                    />
                    <InputText
                      style={{
                        flex: 1,
                        fontSize: '14px',
                        fontWeight: '500',
                        color: '#000',
                        marginLeft: '2px',
                        // borderColor: '#E0EADD',
                        // borderLeft: 'none',
                        // borderRight: 'none',
                        // borderTop: 'none',
                        // borderRadius: '0px',
                      }}
                      value={data.endPage}
                      onChange={(e) => {
                        let temp = [...parsedProtocolData];
                        temp[index].endPage = e.target.value;
                        setParsedProtocolData(temp);
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      placeholder="End Page..."
                      onKeyDown={(e) => {
                        //Check for shift + enter to add a new line
                        if (e.key === 'Enter' && e.shiftKey) {
                          e.preventDefault();
                          let start = '';
                          if (parsedProtocolData.length > 0) {
                            let data = parsedProtocolData[0];
                            start =
                              Number(
                                data.endPage ? data.endPage : data.startPage
                              ) +
                              1 +
                              '';
                          }
                          let temp = [
                            {
                              folder: state.folder,
                              file: '',
                              name: '',
                              startPage: start,
                              endPage: '',
                              confirmed: false,
                              pattern: '',
                            },
                            ...parsedProtocolData,
                          ];
                          setParsedProtocolData(temp);
                        } else if (e.key === 'e' && e.ctrlKey) {
                          console.log('Incrementing end page');
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) + 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) + 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 's' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) + 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'w' && e.ctrlKey) {
                          //Increment the end page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          if (
                            temp[index].endPage === '' ||
                            temp[index].endPage == null
                          )
                            temp[index].endPage = (
                              Number(temp[index].startPage) - 1
                            ).toString();
                          else
                            temp[index].endPage = (
                              Number(temp[index].endPage) - 1
                            ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 'a' && e.ctrlKey) {
                          //Increment the start page by 1
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].startPage = (
                            Number(temp[index].startPage) - 1
                          ).toString();
                          setParsedProtocolData(temp);
                        } else if (e.key === 't' && e.ctrlKey) {
                          //toTitleCase the name
                          e.preventDefault();
                          let temp = [...parsedProtocolData];
                          temp[index].name = toTitleCase(
                            temp[index].name ?? ''
                          );
                          setParsedProtocolData(temp);
                        }
                      }}
                    />
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginLeft: '0.1rem',
                        fontSize: '14px',
                        border: '1px solid #e0e0e0',
                        borderRadius: '5px',
                        padding: '0.5rem 1rem',
                        fontWeight: '500',
                        backgroundColor: '#FFF',
                      }}
                    >
                      {data.numTreatmentItems}
                    </div>
                    <FaTimes
                      className="icon-cancel"
                      style={{
                        marginLeft: '1rem',
                        cursor: 'pointer',
                        padding: '0px',
                        height: '1rem',
                        width: '1rem',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        let temp = [...parsedProtocolData];
                        temp.splice(index, 1);
                        setParsedProtocolData(temp);
                      }}
                    />
                  </div>
                  // <AiPdfParserProtocolInput
                  //   folder={state.folder}
                  //   data={data}
                  //   index={index}
                  //   db={database}
                  //   onChange={(data: any, index: number) => {
                  //     let temp = [...parsedProtocolData];
                  //     temp[index] = data;
                  //     setParsedProtocolData(temp);
                  //   }}
                  //   onDelete={(index: number) => {
                  //     let temp = [...parsedProtocolData];
                  //     temp.splice(index, 1);
                  //     setParsedProtocolData(temp);
                  //   }}
                  //   onCopy={(index: number) => {
                  //     let temp = [...parsedProtocolData];
                  //     temp.push({ ...parsedProtocolData[index] });
                  //     setParsedProtocolData(temp);
                  //   }}
                  // />
                );
              }}
            </ViewportList>
          </div>
        </div>
        <div
          className="right-panel fixedHeader"
          style={{
            maxHeight: 'calc(100vh - 90px)',
            top: '84px',
          }}
        >
          {objURL ? (
            <embed
              title="PDF Document"
              type="application/pdf"
              src={objURL}
              style={{
                height: '100%',
                width: '100%',
              }}
            />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AIPdfParserProtocolAutomatic;
