import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import EquipmentNewPage from '../ui/pages/protocol/edit/Equipment/EquipmentNewPage';
import InfusionProtocolCreatePage from '../ui/pages/protocol/edit/Infusion/InfusionProtocolCreatePage';
import InfusionProtocolEditPage from '../ui/pages/protocol/edit/Infusion/InfusionProtocolEditPage';
import MedicationProtocolCreatePage from '../ui/pages/protocol/edit/Medication/MedicationProtocolCreatePage';
import MedicationProtocolEditPage from '../ui/pages/protocol/edit/Medication/MedicationProtocolEditPage';
import VitalProtocolCreatePage from '../ui/pages/protocol/edit/Vital/VitalProtocolCreatePage';
import VitalProtocolEditPage from '../ui/pages/protocol/edit/Vital/VitalProtocolEditPage';
import ElectricalProtocolCreatePage from '../ui/pages/protocol/edit/Electrical/ElectricalProtocolCreatePage';
import ElectricalProtocolEditPage from '../ui/pages/protocol/edit/Electrical/ElectricalProtocolEditPage';
import CheckListNewPage from '../ui/pages/protocol/edit/CheckList/CheckListNewPage';
import CheckListEditPage from '../ui/pages/protocol/edit/CheckList/CheckListEditPage';
import CreateEditAmbulance from '../ui/pages/protocol/edit/Ambulance/AmbulanceCreateEditPage';
import MedicationProtocolCreateFromHMPage from '../ui/pages/protocol/edit/Medication/MedicationProtocolCreateFromHMPage';
import InfusionProtocolCreateFromHMPage from '../ui/pages/protocol/edit/Infusion/InfusionProtocolCreateFromHMPage';
import ElectricalProtocolCreateFromHM from '../ui/pages/protocol/edit/Electrical/ElectricalProtocolCreateFromHM';
import EquipmentNewHMPage from '../ui/pages/protocol/edit/Equipment/EquipmentFromHM';

/* 10-27-23 praveen : Created the common component protocol route navigation */
const ProtocolNavigator = () => {
  const location = useLocation();
  const protocolPage = useMemo(() => {
    if (location.state.type === 'Medication') {
      if (location.state.editType === 'new') {
        return <MedicationProtocolCreatePage stateData={location.state} />;
      } else if (location.state.editType === 'new_hm') {
        return (
          <MedicationProtocolCreateFromHMPage stateData={location.state} />
        );
      } else {
        return <MedicationProtocolEditPage stateData={location.state} />;
      }
    } else if (location.state.type === 'Infusion') {
      if (location.state.editType === 'new') {
        return <InfusionProtocolCreatePage stateData={location.state} />;
      } else if (location.state.editType === 'new_hm') {
        return <InfusionProtocolCreateFromHMPage stateData={location.state} />;
      } else {
        return <InfusionProtocolEditPage stateData={location.state} />;
      }
    } else if (location.state.type === 'Equipment') {
      if (location.state.editType === 'new_hm')
        return <EquipmentNewHMPage stateData={location.state} />;
      else return <EquipmentNewPage stateData={location.state} />;
    } else if (location.state.type === 'Vital') {
      if (location.state.editType === 'new') {
        return <VitalProtocolCreatePage stateData={location.state} />;
      } else {
        return <VitalProtocolEditPage stateData={location.state} />;
      }
    } else if (location.state.type === 'Electrical') {
      if (location.state.editType === 'new') {
        return <ElectricalProtocolCreatePage stateData={location.state} />;
      } else if (location.state.editType === 'new_hm') {
        return <ElectricalProtocolCreateFromHM stateData={location.state} />;
      } else {
        return <ElectricalProtocolEditPage stateData={location.state} />;
      }
    } else if (location.state.type === 'Checklist') {
      if (location.state.editType === 'new') {
        return <CheckListNewPage stateData={location.state} />;
      } else {
        return <CheckListEditPage stateData={location.state} />;
      }
    } else if (location.state.type === 'Ambulance') {
      return <CreateEditAmbulance stateData={location.state} />;
    }
  }, [location]);

  return <>{protocolPage}</>;
};

export default ProtocolNavigator;
