import React, { useMemo, useState } from 'react';

import ModelItem from '../../../../data/model/ModelInterface';

import DraftChangeItem from '../../../../data/model/DraftChangeItem';
import ReviewalItem from '../../../../data/model/ReviewalItem';

interface FolderComparisonProps {
  currentDraft: DraftChangeItem | null;
  reviewal: ReviewalItem;
}
const OtherComparison: React.FC<FolderComparisonProps> = ({
  currentDraft,
  reviewal,
}) => {
  const { changeItem, previousItem } = useMemo(() => {
    return {
      changeItem: currentDraft?.changeItem,
      previousItem: currentDraft?.previousItem,
    };
  }, [currentDraft]);
  const compareItems = (itemA: any, itemB: any, fieldName: string) => {
    if (itemA === itemB) {
      return <span>{itemA}</span>;
    }
    return (
      <span>
        <span style={{ color: 'red' }}>{itemA}</span> vs{' '}
        <span style={{ color: 'green' }}>{itemB}</span> ({fieldName})
      </span>
    );
  };

  return (
    <div>
      <h1>Folder Comparison</h1>
      <div>
        <h2>Comparison Details</h2>
      </div>
    </div>
  );
};

export default OtherComparison;
