import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { BiCopy } from 'react-icons/bi';
import { BiSolidCopy } from 'react-icons/bi';
import { FaFilePdf } from 'react-icons/fa6';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import { handleCopy } from '../_global/common/Utils';

interface CopyToClipboardProps {
  text: string;
  style?: React.CSSProperties;
}

/* 10-11-23 Arul: Created the CustomPdfUpload component for Protocol Modal */
const CopyToClipboard: React.FC<CopyToClipboardProps> = (props) => {
  const [text, setText] = useState(props.text);
  const [isCopied, setIsCopied] = useState<string | null>(null);
  useEffect(() => {
    setText(props.text);
  }, [props.text]);
  return (
    <span style={props.style}>
      {isCopied && isCopied === text ? (
        <BiSolidCopy color={'#00534C'} size="1rem" className="copy_icon" />
      ) : (
        <BiCopy
          size="1rem"
          className="copy_icon"
          onClick={(e) => handleCopy(text, e, setIsCopied)}
        />
      )}
    </span>
  );
};

export default CopyToClipboard;
