import React, { useMemo } from 'react';
import { diffWords, Change as DiffChange } from 'diff';

interface HighlightChangesProps {
  beforeText: string;
  afterText: string;
  greyOnSame?: boolean;
  onlyPrevious?: boolean; //Only show the previous text in red
  onlyCurrent?: boolean; //Only show the current text in green
  debug?: boolean;
  style?: React.CSSProperties;
}

// Type alias for the diff changes
type DiffPart = DiffChange;

const HighlightChanges: React.FC<HighlightChangesProps> = ({
  beforeText,
  afterText,
  greyOnSame = false,
  onlyPrevious = false,
  onlyCurrent = false,
  debug = false,
  style,
}) => {
  // Get the differences between the two texts
  const changes: DiffPart[] = diffWords(beforeText, afterText);

  /* Clean the changes array based on the onlyCurrent and onlyPrevious flags
   * 1. If onlyPrevious is true, remove all changes that are not added and if there are two removed items in a row merge them into one
   * 2. If onlyCurrent is true, remove all changes that are not removed and if there are two added items in a row merge them into one
   * 3. If both are true, remove all changes that are not added or removed
   * 4. If both are false, return the changes array
   */
  const cleanedChanges = useMemo(() => {
    if (onlyPrevious) {
      let _changes = changes.filter(
        (part) => part.added !== true && part.value.trim() !== ''
      );
      if (debug) console.log('ONLY PREVIOUS FILTERED Changes', _changes);
      let mergedChanges: DiffPart[] = [];
      for (let i = 0; i < _changes.length; i++) {
        if (
          i < _changes.length - 1 &&
          _changes[i].removed &&
          _changes[i + 1].removed
        ) {
          mergedChanges.push({
            value: `${_changes[i].value} ${_changes[i + 1].value}`,
            removed: true,
          });
          i++;
        } else {
          mergedChanges.push(_changes[i]);
        }
      }
      return mergedChanges;
    }
    if (onlyCurrent) {
      let _changes = changes.filter(
        (part) => part.removed !== true && part.value.trim() !== ''
      );
      if (debug) console.log('ONLY CURRENT FILTERED Changes', _changes);
      let mergedChanges: DiffPart[] = [];
      for (let i = 0; i < _changes.length; i++) {
        if (
          i < _changes.length - 1 &&
          _changes[i].added &&
          _changes[i + 1].added
        ) {
          mergedChanges.push({
            value: `${_changes[i].value} ${_changes[i + 1].value}`,
            added: true,
          });
          i++;
        } else {
          mergedChanges.push(_changes[i]);
        }
      }
      return mergedChanges;
    }
    return changes.filter((part) => part.value.trim() !== '');
  }, [changes, onlyCurrent, onlyPrevious]);

  if (debug) {
    console.log('beforeText', beforeText);
    console.log('afterText', afterText);
    console.log('changes', changes);
    console.log('cleanedChanges', cleanedChanges);
  }

  return (
    <div>
      {cleanedChanges.map((part, index) => {
        if (part.added && onlyPrevious) {
          return null;
        }
        if (part.removed && onlyCurrent) {
          return null;
        }
        // Determine background and text color based on the type of change
        const backgroundColor = part.added
          ? '#eaf2c2'
          : part.removed
            ? '#fadad7'
            : 'transparent';
        const color = part.added
          ? '#406619'
          : part.removed
            ? '#b30000'
            : greyOnSame
              ? '#616161'
              : '#000000';

        return (
          <span
            key={index}
            style={{
              backgroundColor,
              color,
              display: 'inline',
              padding: '0 1px',
              borderRadius: '3px',
              width: 'fit-content',
              ...style,
            }}
          >
            {part.value}
          </span>
        );
      })}
    </div>
  );
};

export default HighlightChanges;
