import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Io5 from 'react-icons/io5';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  checkSubDeps,
  loadDatabase,
} from '../../../data/AmplifyDB';
import { User } from '../../../models';
import ProtocolHeader from '../protocol/ProtocolHeader';
import { useDispatch } from 'react-redux';
import { FaBuilding } from 'react-icons/fa6';
import {
  getDepartmentsFromState,
  getSoftwarePackage,
  globals,
} from '../../_global/common/Utils';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { ViewportList } from 'react-viewport-list';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import DepartmentPage from '../actions/details/department/DepartmentPage';
import './Settings.scss';
import EditDepartmentPage from './details/EditDepartmentPage';
import { Button } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  DepartmentJSON,
  DepartmentSettingsJSON,
  updateDepartment,
  updateDepartmentSettings,
  uploadFileToS3,
} from '../../../data/functions/DepartmentDB';
import { SoftwareType } from '../../../API';
import { get } from 'lodash';
import DepartmentItem from '../../../data/model/DepartmentItem';
import Loading from '../../components/Loading/Loading';
import { handleGetDepartment, handleUpdateUser } from '../../../store/actions';
import KeychainItem from '../../../data/model/KeychainItem';
import EditProfilePage from './details/EditProfilePage';
import { editUserItem } from '../../../data/functions/UserDB';
import SelectDepartmentsModal from '../../components/Modal/SelectDepartmentsModal';
import { IoCopy } from 'react-icons/io5';
import { Alert, Snackbar } from '@mui/material';

const ICON_SIZE = '1.15rem';
const SETTINGS_TABS = [
  {
    name: 'Department',
    icon: (
      <FaBuilding
        style={{ height: ICON_SIZE, width: ICON_SIZE }}
        color="#636363"
      />
    ),
  },
  {
    name: 'Profile',
    icon: (
      <Io5.IoPersonCircle
        style={{ height: ICON_SIZE, width: ICON_SIZE }}
        color="#636363"
      />
    ),
  },
];

const SettingsPage = (props: any) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const dbState = useSelector((state: any) => state);

  const [database, setDatabase] = useState<DatabaseResponse>(
    dbState.protocol.departmentItem
  );
  const parentDepartment: DepartmentItem = useMemo(() => {
    return database.department;
  }, [database]);

  const [department, setDepartment] =
    useState<DepartmentItem>(parentDepartment);
  const user: User = useSelector((state: any) => state?.user);
  const reducerState = useSelector((state: any) => state.department);
  const { allSubDeps } = useMemo(() => {
    return getDepartmentsFromState(database.department, reducerState);
  }, [database.department, reducerState]);

  const [isCopied, setIsCopied] = useState<string | null>(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [tab, setTab] = useState('Department');
  const [logo, setLogo] = useState<File | null>(null);
  const [loading, setLoading] = useState<string | null>(null);
  const [selectDepartmentsModal, setSelectDepartmentsModal] =
    useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  const departmentFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: department.name,
      neonateCutoff: department.model.config.neonateCutoff
        ? department.model.config.neonateCutoff
        : 5,
      pediatricCutoff: department.model.config.pediatricCutoff
        ? department.model.config.pediatricCutoff
        : 40,
      softwarePlan: getSoftwarePackage(department.model.softwarePlan),
      activeStatus: department.model.activeStatus === false ? false : true,
      agencyNumEMS: department.model.agencyNumEMS
        ? department.model.agencyNumEMS
        : '',
      stateIDEMS: department.model.stateIdEMS
        ? department.model.stateIdEMS
        : '',
      gnisCode: department.model.gnisCodeEMS
        ? department.model.gnisCodeEMS
        : '',
      location: department.location,
      departmentCode: department.departmentCode
        ? department.departmentCode
        : '',

      pin: '',
      hashedPin: department.hashedPin ? department.hashedPin : '',
      saltedPin: department.salt ? department.salt : '',

      oneweightEnabled: department.isOneWeightEnabled,
      realTimeEnabled: department.isRealTimeEnabled,
      infusionCalculation: department.infusionCalculation,
      ageFilterEnabled: department.isAgeFilterEnabled,
      isPublic: department.isPublic,
      isPublicSignup: department.isPublicSignup,
      isTopEnabled: department.isTopEnabled,
      keychain: department.keychain as KeychainItem | null | undefined,
      renewalDate: department.renewalDate,
      infusionGTTS: department.gttsCalculations,
      uniquePublicURL: department.uniquePublicURL,
      isToggleAdvancedFiltersEnabled: department.isToggleAdvancedFiltersEnabled,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      neonateCutoff: Yup.number().required(),
      pediatricCutoff: Yup.number().required(),
      softwarePlan: Yup.string().nullable(),
      activeStatus: Yup.boolean().required(),
      agencyNumEMS: Yup.string(),
      stateIDEMS: Yup.string(),
      gnisCode: Yup.string(),
      location: Yup.string().required(),
      departmentCode: Yup.string().required(),
      pin: Yup.string(),
      hashedPin: Yup.string(),
      saltedPin: Yup.string(),
      oneweightEnabled: Yup.boolean().required(),
      realTimeEnabled: Yup.boolean().required(),
      infusionCalculation: Yup.boolean().required(),
      ageFilterEnabled: Yup.boolean().required(),
      isPublic: Yup.boolean().required(),
      keychain: Yup.object().nullable(),
      renewalDate: Yup.date().nullable(),
      infusionGTTS: Yup.array().nullable(),
      uniquePublicURL: Yup.string().nullable(),
      isToggleAdvancedFiltersEnabled: Yup.boolean().required(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  const userFormik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      cognitoID: user.cognitoID,
      type: user.type,
      depAdmins: user.depAdmins,
      departmentID: user.departmentID,
      indexedParentDepID: user.indexedParentDepID,
      hashedPin: user.hashedPin,
      saltPin: user.saltPin,
      status: user.status,
      // notificationTokens: user.notificationTokens,
      pairedDepIDs: user.pairedDepIDs,
      oneDoseVersion: user.oneDoseVersion,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(),
      lastName: Yup.string().required(),
      cognitoID: Yup.string().required(),
      type: Yup.string().required(),
      depAdmins: Yup.array(),
      departmentID: Yup.string().required(),
      indexedParentDepID: Yup.string().required(),
      hashedPin: Yup.string().required(),
      saltPin: Yup.string().required(),
      status: Yup.string(),
      // notificationTokens: Yup.array(),
      pairedDepIDs: Yup.array(),
      oneDoseVersion: Yup.string(),
    }),
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    if (tab !== 'Department') {
      departmentFormik.resetForm();
      setLogo(null);
    } else {
      userFormik.resetForm();
    }
  }, [tab]);

  const PageUI = useMemo(() => {
    switch (tab) {
      case 'Department':
        return (
          <EditDepartmentPage
            department={department}
            formik={departmentFormik}
            isEditMode={isEditMode}
            logo={logo}
            setLogo={setLogo}
            setDepartment={setDepartment}
            parentDepartment={parentDepartment}
          />
        );
      case 'Profile':
        return (
          <EditProfilePage
            user={user}
            formik={userFormik}
            isEditMode={isEditMode}
          />
        );
      default:
        return <div>Department</div>;
    }
  }, [
    tab,
    departmentFormik,
    departmentFormik.values,
    isEditMode,
    logo,
    setLogo,
    department,
    parentDepartment,
    allSubDeps,
  ]);

  const isSaveValid = useMemo(() => {
    if (tab === 'Department') {
      return (departmentFormik.isValid && departmentFormik.dirty) || logo;
    }
    return userFormik.isValid && userFormik.dirty;
  }, [
    departmentFormik.isValid,
    departmentFormik.dirty,
    departmentFormik,
    departmentFormik.values,
    logo,
    userFormik.isValid,
    userFormik.dirty,
    userFormik,
    tab,
  ]);

  const handleSave = () => {
    if (tab === 'Department') {
      const changedValues: any = getChangedValues();
      let subDeps: DepartmentItem[] = allSubDeps
        ? allSubDeps.filter((dep) => dep.id !== department.id)
        : [];
      if (subDeps.length > 0 && Object.keys(changedValues).length > 0)
        setSelectDepartmentsModal(true);
      else handleUpdateDepartment([department]);
    } else handleUpdateUserData();
  };

  const handleUpdateDepartment = async (deps: DepartmentItem[]) => {
    let updates: DepartmentItem[] = [];
    for (let dep of deps) {
      if (dep.id === department.id) {
        try {
          let logoURI = department.logoURL;
          /* First upload the new logo */
          if (logo) {
            let response: Response = await uploadFileToS3(
              department,
              logo,
              (error: any) => {
                if (error) {
                  console.error('error uploading logo: ', error);
                }
              }
            );
            if (response.type === ResponseType.Success) {
              logoURI = response.data;
              department.logoVerifiedUrl = URL.createObjectURL(logo);
              setLogo(null);
            }
            console.log('Successfully put logo into S3: ', logoURI);
          }

          let gtts: number[] | null = null;
          if (departmentFormik.values.infusionGTTS) {
            gtts = [];
            for (
              let i = 0;
              i < departmentFormik.values.infusionGTTS.length;
              i++
            ) {
              let n = Number(departmentFormik.values.infusionGTTS[i]);
              if (isNaN(n)) continue;
              gtts.push(n);
            }
          }

          /* Now update the department */
          let updatedDepartment: DepartmentJSON = {
            name: departmentFormik.values.name,
            location: departmentFormik.values.location,
            departmentCode: departmentFormik.values.departmentCode,
            neonateCutoff: departmentFormik.values.neonateCutoff,
            pediatricCutoff: departmentFormik.values.pediatricCutoff,
            softwarePlan: getSoftwarePackage(
              departmentFormik.values.softwarePlan
            ),
            activeStatus: departmentFormik.values.activeStatus,
            agencyNumEMS: departmentFormik.values.agencyNumEMS,
            stateIDEMS: departmentFormik.values.stateIDEMS,
            gnisCode: departmentFormik.values.gnisCode,
            hashedPin: departmentFormik.values.hashedPin,
            saltedPin: departmentFormik.values.saltedPin,
            logoURI: logoURI,
            oneweightEnabled: departmentFormik.values.oneweightEnabled,
            realtimeUpdating: departmentFormik.values.realTimeEnabled,
            infusionCalculation: departmentFormik.values.infusionCalculation,
            ageFilterEnabled: departmentFormik.values.ageFilterEnabled,
            isPublic: departmentFormik.values.isPublic,
            keychain: department.keychain,
            renewalDate: departmentFormik.values.renewalDate,
            isPublicSignup: departmentFormik.values.isPublicSignup,
            gttsCalculations: gtts,
            isTopEnabled: departmentFormik.values.isTopEnabled,
            uniquePublicURL: departmentFormik.values.uniquePublicURL,
            isToggleAdvancedFiltersEnabled:
              departmentFormik.values.isToggleAdvancedFiltersEnabled,
          };
          console.log('Updating department: ', updatedDepartment);
          console.log('Department: ', department);
          const respone: Response = await updateDepartment(
            updatedDepartment,
            department
          );
          if (respone.type === ResponseType.Success) {
            let newDep: DepartmentItem = respone.data;
            console.log('Successfully Updated: ', newDep);
            updates.push(newDep);
          } else {
            console.log('error updating department: ', respone.data);
          }
        } catch (error) {
          console.log('error updating department: ', error);
        }
      } else {
        const changedValues: DepartmentSettingsJSON = getChangedValues();
        console.log(
          'Updating sub-departmetn with settings: ',
          changedValues,
          'for department: ',
          dep
        );
        const response: Response = await updateDepartmentSettings(
          changedValues,
          dep
        );
        if (response.type === ResponseType.Success) {
          console.log('Successfully updated sub-department: ', response.data);
          updates.push(response.data);
        } else {
          console.log('error updating sub-department: ', response.data);
        }
      }
    }
    departmentFormik.resetForm();
    for (let dep of updates) {
      if (dep.id !== parentDepartment.id) replaceSubDeparment(dep);
      else reloadDatabase(dep);
    }
    setSnackbar({
      open: true,
      message: `Department Settings Successfully Updated${
        updates.length > 1 ? ` for ${updates.length} Departments` : '.'
      }`,
      severity: 'success',
    });
  };

  function getChangedValues() {
    const KEYS = [
      'neonateCutoff',
      'pediatricCutoff',
      'softwarePlan',
      'activeStatus',
      'location',
      'oneweightEnabled',
      'realTimeEnabled',
      'infusionCalculation',
      'ageFilterEnabled',
      'isPublic',
      'isPublicSignup',
      'infusionGTTS',
      'isToggleAdvancedFiltersEnabled',
    ];
    if (tab === 'Department') {
      //Get the values that are different from the original department
      let values: any = departmentFormik.values;
      let initialValues: any = departmentFormik.initialValues;
      let modifiedValues: any = {};
      for (let key of KEYS) {
        if (values[key] !== initialValues[key])
          modifiedValues[key] = values[key];
      }
      console.log('Modified values: ', modifiedValues);

      return modifiedValues;
    }
  }

  const handleUpdateUserData = async () => {
    console.log('Updating user: ', userFormik.values);
    let newUser: User | undefined = await editUserItem(user, userFormik.values);
    if (newUser) {
      console.log('Successfully updated user: ', newUser);
      dispatch<any>(handleUpdateUser(newUser));
    } else {
      console.error('Error updating user: ', newUser);
    }
  };

  const replaceSubDeparment = (newDep: DepartmentItem) => {
    if (!parentDepartment) return;
    checkSubDeps(
      parentDepartment,
      reducerState.subDepsMap ? reducerState.subDepsMap : new Map(),
      reducerState.allSubDepsMap ? reducerState.allSubDepsMap : new Map(),
      user,
      undefined,
      undefined,
      false,
      false
    );
    // const subDeps = getDepartmentsFromState(
    //   parentDepartment,
    //   reducerState
    // ).subDeps;
    // if (!subDeps) return;
    // let prevDeps: DepartmentItem[] = [...subDeps];
    // let index: number | undefined = prevDeps?.findIndex(
    //   (dep) => dep.id === newDep.id
    // );
    // if (index && index !== -1) {
    //   prevDeps?.splice(index, 1, newDep);
    //   // parentDepartment.subDeps = prevDeps;
    //   // parentDepartment.activeSubDep = newDep;
    // }
  };

  const reloadDatabase = async (
    department: DepartmentItem
  ): Promise<boolean> => {
    /* 1-22-24 Guru:  Updated the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  return (
    <div
      className="screen-container"
      style={{
        height: '100%',
        padding: '0 0rem',
      }}
    >
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isEditMessage && (
        <ConfirmModal
          isVisible={isEditMessage}
          title="Edit Department?"
          handleClose={() => {
            setIsEditMessage(false);
          }}
          handleSubmit={() => {
            setIsEditMessage(false);
            setIsEditMode(true);
          }}
          primaryBtnName="Cancel"
          secondaryBtnName="Edit"
          primaryDescription={`Are you sure you want to edit this will go live immediatelty?`}
        />
      )}
      <SelectDepartmentsModal
        isVisible={selectDepartmentsModal}
        title={`Copy Settings to Additional Departments?`}
        description={`Would you like to copy the settings to any departments under ${department.name}? Select the departments you would like to copy the settings to.`}
        buttonText={'Continue'}
        // buttonIcon={<IoCopy size="1rem" />}
        department={department}
        values={[department]}
        handleClose={() => setSelectDepartmentsModal(false)}
        handleSubmit={(deps) => {
          setSelectDepartmentsModal(false);
          if (deps.length === 1 && deps[0].id === department.id) {
            handleUpdateDepartment([department]);
          } else handleUpdateDepartment(deps);
        }}
      />
      <div className="fixedHeader fixedHeaderPad" data-testid="protocol-header">
        <div
          style={{ margin: '0px 10px', display: 'flex', flexDirection: 'row' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px 0px',
              flex: 1,
              overflowX: 'auto',
            }}
          >
            <div className={`titleStyling`}>
              <div
                className=""
                style={{ alignItems: 'center', justifyContent: 'center' }}
              >
                <h6
                  className={`backTextLight`}
                  onClick={() => {
                    //handleBackButton();
                  }}
                ></h6>
              </div>

              <div className="titleItemContainer">
                <div className="titleTextPH">Settings</div>
              </div>

              <h6
                className={`headerTextLight`}
                style={{
                  fontWeight: '400',
                  color: '#616161',
                  marginRight: '2rem',
                }}
              >
                Edit the {tab === 'Department' ? 'department' : 'profile'}{' '}
                settings.
              </h6>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: 0,
            }}
          >
            <div className="headerButtonContainer">
              <Button
                data-testid="isCancelButton"
                className="secondary-button-small-border btn-rightMargin"
                onClick={() => {
                  setIsEditMode(false);
                  if (tab === 'Department') {
                    departmentFormik.resetForm();
                    setLogo(null);
                  } else {
                    userFormik.resetForm();
                  }
                }}
                disabled={!isSaveValid}
              >
                <span>
                  <AiOutlineClose className="icon-normal" size="1rem" />
                </span>{' '}
                Cancel
              </Button>
              <Button
                data-testid="isSaveButton"
                className={` ${
                  // rightSideBtn === "save"
                  'primary-button btn-rightMargin'
                  // : "secondary-button-small-border btn-rightMargin"
                }`}
                onClick={() => {
                  // formik.handleSubmit();
                  handleSave();
                }}
                disabled={!isSaveValid}
              >
                <span>
                  <Io5.IoSave size="1rem" />
                </span>{' '}
                Save
              </Button>
            </div>
          </div>
        </div>
        <hr />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          //   justifyContent: 'space-between',
          //   alignItems: 'center',
          height: '100%',
          padding: '1rem',
          gap: '2rem',
        }}
      >
        <div
          style={{
            height: '100%',
            width: '15%',
            minWidth: '100px',
            flex: 1,
            // borderRight: '1px solid #ccc',
            //Create this a sticky div
            position: 'sticky',
            top: '20vh',
            // backgroundColor: 'white',
            // zIndex: 1,
            // overflowY: 'auto',
          }}
        >
          <ViewportList items={SETTINGS_TABS}>
            {(item: any, index: any) => {
              return (
                <div
                  key={index}
                  style={
                    {
                      // borderBottom:
                      //   index === SETTINGS_TABS.length - 1
                      //     ? undefined
                      //     : '1px solid #ccc',
                    }
                  }
                  onClick={() => {
                    setTab(item.name);
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                    className={`settings-tab-container ${tab === item.name ? 'active' : ''}`}
                  >
                    {item.icon}

                    <h6
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        //   gap: '0.5rem',
                        fontSize: '1rem',
                        margin: '0 0 0 12px',
                        padding: 0,
                        fontWeight: '500',
                      }}
                    >
                      {item.name}
                    </h6>
                  </div>
                </div>
              );
            }}
          </ViewportList>
        </div>
        <div
          style={{
            flex: 7,
            borderLeft: '1px solid #ccc',
          }}
        >
          {PageUI}
          {/* <DepartmentPage /> */}
        </div>
      </div>
      {loading && <Loading type="bubbles" message={loading} />}
    </div>
  );
};

export default SettingsPage;
