import React, { useMemo } from 'react';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import HighlightChanges from '../HighlightChanges';
import { globals } from '../../../_global/common/Utils';
import HighlightChangesRow from '../HighlightChangesRow';
import FormItem from '../../../../data/model/FormItem';
interface FolderComparisonProps {
  focusItem: FormItem;
  changes?: any;
}

const ChecklistComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
}) => {
  const checklistItems = useMemo(() => {
    return {
      currentOptions: focusItem.items,
      beforeOptions: focusItem.activeItem?.items || [],
    };
  }, [focusItem]);

  const findBeforeChecklistValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const findCurrentChecklistValue = (changes: any, key: string) => {
    const change = changes.find((change: any) => change.key === key);
    return change ? change.changes : null;
  };

  const optionHasValues = (change: any, isBefore: boolean) => {
    if (isBefore) {
      return (
        (change.archiveValue?.firstItem != null &&
          change.archiveValue?.firstItem !== '') ||
        (change.archiveValue?.secItem != null &&
          change.archiveValue?.secItem !== '')
      );
    } else {
      return (
        (change.currentValue?.firstItem != null &&
          change.currentValue?.firstItem !== '') ||
        (change.currentValue?.secItem != null &&
          change.currentValue?.secItem !== '')
      );
    }
  };

  const formatGroups = (groups: any[], isBefore: boolean) => {
    return groups.map((change: any, index: number) => {
      const hasValues = optionHasValues(change, isBefore);

      if (!hasValues) {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px',
              padding: '10px 0px',
              minHeight: '53.5px',
              // backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
              borderTopLeftRadius: index === 0 ? '8px' : '',
              borderTopRightRadius: index === 0 ? '8px' : '',
              borderBottomLeftRadius: index === groups.length - 1 ? '8px' : '',
              borderBottomRightRadius: index === groups.length - 1 ? '8px' : '',
              borderBottom:
                index !== groups.length - 1 ? '1px solid #eaeded' : '',
            }}
          ></div>
        );
      }

      const isDouble = isBefore
        ? change.archiveValue?.isDouble
        : change.currentValue?.isDouble;

      return (
        <div
          key={index}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '15px',
            padding: '10px 0px',
            minHeight: '50px',
            // backgroundColor: index % 2 === 0 ? '#fafbfb' : 'white',
            borderTopLeftRadius: index === 0 ? '8px' : '',
            borderTopRightRadius: index === 0 ? '8px' : '',
            borderBottomLeftRadius: index === groups.length - 1 ? '8px' : '',
            borderBottomRightRadius: index === groups.length - 1 ? '8px' : '',
            borderBottom:
              index !== groups.length - 1 ? '1px solid #eaeded' : '',
          }}
        >
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <div
              style={{
                fontWeight: 'bold',
                fontSize: '14px',
                color: '#gray',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '8px',
              }}
            >
              {index + 1}:
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <div
              style={{
                border: '1px solid #e0e0e0',
                color: change.archiveValue?.rangeLow ? '#000' : '#616161',
                fontWeight: '500',
                fontSize: '15px',
                padding: '4px',
                borderRadius: '5px',
                minHeight: '30px',
                backgroundColor: 'white',
              }}
            >
              <HighlightChanges
                beforeText={change.archiveValue?.firstItem || ''}
                afterText={change.currentValue?.firstItem || ''}
                onlyPrevious={isBefore}
                onlyCurrent={!isBefore}
                style={{
                  fontSize: '14px',
                }}
              />
            </div>
          </div>

          {isDouble && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: change.archiveValue?.secItem ? '#000' : '#616161',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={change.archiveValue?.secItem || ''}
                  afterText={change.currentValue?.secItem || ''}
                  onlyPrevious={isBefore}
                  onlyCurrent={!isBefore}
                  style={{
                    fontSize: '14px',
                  }}
                />
              </div>
            </div>
          )}
        </div>
      );
    });
  };

  const renderGroups = (groupChanges: any[]) => {
    return groupChanges.map((change: any, index: number) => {
      if (change.currentValue == null && change.archiveValue == null)
        return null;
      const currentOptions = change.currentValue?.options || [];
      const archiveOptions = change.archiveValue?.options || [];
      return (
        <tr key={index}>
          <td>
            <label htmlFor={''} className="ketamine-general-label">
              Group {index + 1}:
            </label>

            {change.archiveValue && (
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  fontWeight: '500',
                  fontSize: '15px',
                  borderRadius: '8px',
                  // paddingRight: '10px',
                  minHeight: '100px',
                  padding: '10px',
                  backgroundColor: 'white',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: '8px',
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 5,
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Title
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.title || ''}
                        afterText={change.currentValue?.title || ''}
                        onlyPrevious={true}
                      />
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Structure
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.isUrgent || ''}
                        afterText={change.currentValue?.isUrgent || ''}
                        onlyPrevious={true}
                      />
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      // marginRight: '10px',
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Type
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.isDouble || ''}
                        afterText={change.currentValue?.isDouble || ''}
                        onlyPrevious={true}
                      />
                    </div>
                  </div>
                </div>

                {(currentOptions.length > 0 || archiveOptions.length > 0) && (
                  <div
                    style={{
                      // border: '1px solid #e0e0e0',
                      fontWeight: '500',
                      fontSize: '15px',
                      // borderRadius: '8px',
                      // paddingRight: '10px',
                      marginTop: '8px',
                      // padding: '10px',
                      backgroundColor: 'white',
                    }}
                  >
                    {formatGroups(change.archiveValue?.options || [], true)}
                  </div>
                )}
              </div>
            )}
          </td>
          <td>
            <label htmlFor={''} className="ketamine-general-label">
              Group {index + 1}:
            </label>

            {change.currentValue && (
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  fontWeight: '500',
                  fontSize: '15px',
                  borderRadius: '8px',
                  // paddingRight: '10px',
                  padding: '10px',
                  backgroundColor: 'white',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    flex: 1,
                    gap: '8px',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 5,
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Title
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.title || ''}
                        afterText={change.currentValue?.title || ''}
                        onlyCurrent={true}
                      />
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                    }}
                  >
                    <label htmlFor={''} className={` input-label`}>
                      Structure
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.isUrgent || ''}
                        afterText={change.currentValue?.isUrgent || ''}
                        onlyCurrent={true}
                      />
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: 1,
                      marginRight: '10px',
                    }}
                  >
                    <label htmlFor={''} className={`input-label`}>
                      Type
                    </label>
                    <div
                      style={{
                        border: '1px solid #e0e0e0',
                        color: '#000',
                        fontWeight: '500',
                        fontSize: '15px',
                        padding: '4px',
                        borderRadius: '5px',
                        minHeight: '30px',
                        backgroundColor: 'white',
                      }}
                    >
                      <HighlightChanges
                        beforeText={change.archiveValue?.isDouble || ''}
                        afterText={change.currentValue?.isDouble || ''}
                        onlyCurrent={true}
                      />
                    </div>
                  </div>
                </div>
                {(currentOptions.length > 0 || archiveOptions.length > 0) && (
                  <div
                    style={{
                      // border: '1px solid #e0e0e0',
                      fontWeight: '500',
                      fontSize: '15px',
                      // borderRadius: '8px',
                      // paddingRight: '10px',
                      marginTop: '8px',
                      // padding: '10px',
                      backgroundColor: 'white',
                    }}
                  >
                    {formatGroups(change.currentValue?.options || [], false)}
                  </div>
                )}
              </div>
            )}
          </td>
        </tr>
      );
    });
  };

  const findOptionsKey = (changes: any[]) => {
    return changes.find((change) => change.key === 'groups');
  };

  const isChange = (keys: string[]) => {
    return keys.some((key) => {
      return findBeforeValue(changes, key) || findCurrentValue(changes, key);
    });
  };

  return (
    <tbody className="comparison-rows">
      {(findBeforeValue(changes, 'name') ||
        findCurrentValue(changes, 'name')) && (
        <HighlightChangesRow
          changes={changes}
          titles={['Name']}
          keys={['name']}
        />
      )}
      {findOptionsKey(changes) != null &&
        findOptionsKey(changes).changes.length > 0 &&
        renderGroups(findOptionsKey(changes).changes)}
    </tbody>
  );
};

export default ChecklistComparisonView;
