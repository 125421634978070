import React, { useMemo } from 'react';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import ElectricalSubItem from '../../../../data/model/ElectricalSubItem';
import DraftChangeItem, {
  DraftChangeType,
} from '../../../../data/model/DraftChangeItem';
import FullViewDialog from '../fullView/FullViewDialog';
import ProtocolAccordionSecondLayer from '../../protocol/details/ProtocolAccordionSecondLayer';
import ProtocolItem from '../../../../data/model/ProtocolItem';
import { useSelector } from 'react-redux';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { toTitleCase } from '../../../_global/common/Utils';
import InfusionSubItem from '../../../../data/model/InfusionSubItem';
import DepartmentItem from '../../../../data/model/DepartmentItem';

interface FolderComparisonProps {
  changeType: DraftChangeType;
  currentDraft: DraftChangeItem;
  department: DepartmentItem;
  changeItem?:
    | MedicationSubItem[]
    | InfusionSubItem[]
    | ElectricalSubItem[]
    | null;
  previousItem?:
    | MedicationSubItem[]
    | InfusionSubItem[]
    | ElectricalSubItem[]
    | null;
  onChangesCount?: (count: number) => void;
}

const compareSubIndexItems = (
  currentItems: MedicationSubItem[] | ElectricalSubItem[],
  previousItems: MedicationSubItem[] | ElectricalSubItem[]
): number => {
  let changeCount = 0;

  for (let i = 0; i < currentItems.length; i++) {
    const currentItem = currentItems[i];
    const previousItem = previousItems[i];
    if (currentItem.index !== previousItem.index) {
      changeCount++;
    }
  }
  changeCount = 1;
  return changeCount;
};

export function findBeforeIndexAndName(
  item: MedicationSubItem | ElectricalSubItem | ProtocolItem,
  previousItems: (MedicationSubItem | ElectricalSubItem | ProtocolItem)[]
): string {
  if (previousItems == null || previousItems.length === 0) return '';

  const index = previousItems.findIndex(
    (prevItem) => prevItem.index === item.index
  );
  return `${index + 1}: ${item.name}`;
}

export function findAfterIndexAndName(
  item: MedicationSubItem | InfusionSubItem | ElectricalSubItem | ProtocolItem,
  changeItems: (
    | MedicationSubItem
    | InfusionSubItem
    | ElectricalSubItem
    | ProtocolItem
  )[]
): string {
  if (changeItems == null || changeItems.length === 0) return '';
  const index = changeItems.findIndex(
    (changeItem) => changeItem.index === item.index
  );
  return `${index + 1}: ${item.name}`;
}

const DoseIndexComparison: React.FC<FolderComparisonProps> = ({
  changeItem = [],
  previousItem = [],
  onChangesCount,
  currentDraft,
  changeType,
  department,
}) => {
  const changes = useMemo(() => {
    const count = compareSubIndexItems(
      changeItem as MedicationSubItem[],
      previousItem as MedicationSubItem[]
    );
    if (onChangesCount) {
      onChangesCount(count);
    }
    return count;
  }, [changeItem, previousItem, onChangesCount]);
  const headerTitle =
    changeType === DraftChangeType.MEDICATION_INDEX
      ? 'Medication'
      : changeType === DraftChangeType.INFUSION_INDEX
        ? 'Infusion'
        : 'Electrical';

  return (
    <div>
      <div className="doseChangesWrapper">
        <table className="changesTable">
          {previousItem ? (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader beforeChange">
                    <div className="headerContent">Before</div>{' '}
                  </th>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">After</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {previousItem?.length >= 1 && (
                      <div>
                        <Accordion activeIndex={0}>
                          <AccordionTab
                            data-testid={headerTitle}
                            header={headerTitle}
                            disabled
                          >
                            <ProtocolAccordionSecondLayer
                              data={previousItem}
                              protocol={
                                currentDraft?.previousItem as ProtocolItem
                              }
                              type={headerTitle}
                              accordionType={headerTitle}
                              isEditMode={false}
                              onReorder={() => {}}
                              viewOnlyMode={true}
                              keepBefore={true}
                              previousItems={previousItem} // Pass previousItems here
                              changeItems={changeItem} // Pass changeItems here
                              department={department}
                            />
                          </AccordionTab>
                        </Accordion>
                      </div>
                    )}
                  </td>
                  <td>
                    {changeItem && changeItem?.length >= 1 && (
                      <Accordion activeIndex={0}>
                        <AccordionTab
                          data-testid={headerTitle}
                          header={headerTitle}
                          disabled
                        >
                          <ProtocolAccordionSecondLayer
                            data={changeItem}
                            protocol={currentDraft?.changeItem as ProtocolItem}
                            type={headerTitle}
                            accordionType={headerTitle}
                            isEditMode={false}
                            onReorder={() => {}}
                            viewOnlyMode={true}
                            previousItems={previousItem} // Pass previousItems here
                            changeItems={changeItem} // Pass changeItems here
                            department={department}
                          />
                        </AccordionTab>
                      </Accordion>
                    )}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <>
              {' '}
              <thead>
                <tr>
                  <th className="changeHeader afterChange">
                    <div className="headerContent">
                      New {toTitleCase(currentDraft.changeType)}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  {changeItem && changeItem?.length >= 1 && (
                    <Accordion activeIndex={0}>
                      <AccordionTab
                        data-testid={headerTitle}
                        header={headerTitle}
                        disabled
                      >
                        <div>
                          <ProtocolAccordionSecondLayer
                            data={changeItem}
                            protocol={currentDraft?.changeItem as ProtocolItem}
                            type={headerTitle}
                            accordionType={headerTitle}
                            isEditMode={false}
                            onReorder={() => {}}
                            viewOnlyMode={true}
                            previousItems={previousItem} // Pass previousItems here
                            changeItems={changeItem} // Pass changeItems here
                            department={department}
                          />
                        </div>
                      </AccordionTab>
                    </Accordion>
                  )}
                </tr>
              </tbody>
            </>
          )}
        </table>
      </div>
    </div>
  );
};

export default DoseIndexComparison;
