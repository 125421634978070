import PatientWeight from '../../ui/_global/common/PatientWeight';
import ModelItem from './ModelItem';
import { getObjectDifference, globals } from '../../ui/_global/common/Utils';
import { LogEvent, Acknowledge, Reviewal, UserComment } from '../../API';
import { ACKStatus } from '../../API';
import { DatabaseResponse } from '../AmplifyDB';
import DraftGroupItem from './DraftGroupItem';
import AcknowledgeItem from './AcknowledgeItem';
import UserCommentItem from './UserCommentItem';
import { User } from '../../models';
import { ReviewalJSON } from '../functions/ReviewalDB';
import DraftChangeItem from './DraftChangeItem';

export const cloneReviewalItem = (
  item: ReviewalItem,
  db: DatabaseResponse
): ReviewalItem => {
  let newItem = new ReviewalItem(item.any_model, db, true) as ReviewalItem;
  newItem.isFullyLoaded = item.isFullyLoaded;
  newItem.draftGroups = item.draftGroups;
  newItem.acknowledgements = item.acknowledgements;
  newItem.events = item.events;
  newItem.comments = item.comments;
  return newItem;
};
export const updateReviewalItem = (
  item: ReviewalItem,
  reviewal: Reviewal,
  db: DatabaseResponse
) => {
  let newItem = new ReviewalItem(reviewal, db, true) as ReviewalItem;
  newItem.isFullyLoaded = item.isFullyLoaded;
  newItem.draftGroups = item.draftGroups;
  newItem.acknowledgements = item.acknowledgements;
  newItem.events = item.events;
  newItem.comments = item.comments;
  return newItem;
};

class ReviewalItem extends ModelItem<Reviewal> {
  any_model: any;
  title: string;
  description: string | null | undefined;
  draftGroups: DraftGroupItem[] = [];
  reviewers: User[];
  state: ACKStatus;
  acknowledgements: AcknowledgeItem[] = [];
  events: LogEvent[] = [];
  comments: UserCommentItem[] = [];
  createdAt: Date;
  endedAt: Date | null | undefined;
  user: User;
  isFullyLoaded: boolean = false;

  constructor(reviewal: any, db: DatabaseResponse, isClone: boolean = false) {
    super(reviewal, reviewal.title, db.department.id);
    this.TAG = 'ReviewalItem';
    this.any_model = reviewal;
    this.title = reviewal.title;
    this.description = reviewal.description;
    this.state = this.getReviewStatus(reviewal.status);
    this.createdAt = new Date(reviewal.createdAt);
    this.user = reviewal.User as User;

    if (reviewal.endedAt) this.endedAt = new Date(reviewal.endedAt);
    else this.endedAt = null;

    this.reviewers = [];
    for (let reviewer of reviewal.reviewers) {
      let user = db.users.find((u) => u.id === reviewer);
      if (user && !this.reviewers.includes(user)) this.reviewers.push(user);
    }
    if (!isClone) {
      let items = reviewal.DraftGroups.items;
      items.forEach((item: any) => {
        if (!item || item._deleted === true) return;
        let draftGroup = new DraftGroupItem(item, db);
        this.draftGroups.push(draftGroup);
      });
      this.acknowledgements = [];
      this.events = this.model.events;
      this.comments = []; //this.model.comments;

      if (reviewal.Acknowledges) {
        items = reviewal.Acknowledges.items;
        items.forEach((item: Acknowledge) => {
          if (!item || item._deleted === true) return;
          let user = db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for Acknowledgement', item);
            return;
          }
          this.acknowledgements.push(
            new AcknowledgeItem(item, this, user, this)
          );
        });
      }

      if (reviewal.UserComments) {
        let items = reviewal.UserComments.items;
        items.forEach((item: UserComment) => {
          if (!item || item._deleted === true) return;
          let user = db.users.find((u) => u.id === item.userID);
          if (!user) {
            console.error('Owner not found for UserComment', item);
            return;
          }
          this.comments.push(new UserCommentItem(item, this, user, []));
        });
      }
    }
  }

  findDraftChange(uid: string): DraftChangeItem | undefined {
    return this.draftGroups
      .flatMap((dg) => dg.draftChanges)
      .find((dc) => dc.uid === uid);
  }

  updateDraftGroup(draftGroup: DraftGroupItem, isDeleted: boolean) {
    let index = this.draftGroups.findIndex((dg) => dg.uid === draftGroup.uid);
    if (index !== -1) {
      if (isDeleted) this.draftGroups.splice(index, 1);
      else this.draftGroups[index] = draftGroup;
    }
  }

  updateDraftChange(draftChange: DraftChangeItem, isDeleted: boolean) {
    let dgIndex = this.draftGroups.findIndex(
      (dg) => dg.uid === draftChange.draftGroup?.uid
    );
    if (dgIndex !== -1) {
      let dg = this.draftGroups[dgIndex];
      let dcIndex = dg.draftChanges.findIndex(
        (dc) => dc.uid === draftChange.uid
      );
      if (dcIndex !== -1) {
        if (isDeleted) dg.draftChanges.splice(dcIndex, 1);
        else dg.draftChanges[dcIndex] = draftChange;
      }
    }
  }

  getReviewStatus(status: ACKStatus | string): ACKStatus {
    switch (status) {
      case 'PENDING':
        return ACKStatus.PENDING;
      case 'APPROVED':
        return ACKStatus.APPROVED;
      case 'REJECTED':
        return ACKStatus.REJECTED;
      case 'CLOSED':
        return ACKStatus.CLOSED;
      case 'PUBLISHED':
        return ACKStatus.PUBLISHED;
      default:
        return ACKStatus.DRAFT;
    }
  }

  getDescription(): string {
    return this.description ? this.description : '';
  }

  getOwner(): User {
    return this.user;
  }

  getReviewers(): User[] {
    return this.reviewers;
  }

  getAcknowledgements(): AcknowledgeItem[] {
    return this.acknowledgements;
  }

  getEvents(): LogEvent[] {
    return this.events;
  }

  getComments(): UserCommentItem[] {
    return this.comments;
  }

  getCreatedAt(): Date {
    return this.createdAt;
  }

  getEndedAt(): Date | null | undefined {
    return this.endedAt;
  }

  equals(obj: any): boolean {
    if (obj == null) return false;
    if (!(obj instanceof ReviewalItem)) return false;
    return getObjectDifference(this, obj).length === 0;
  }

  toString(): string {
    return `ReviewalItem -> {
            uid=${this.uid},
            title=${this.title},
            description=${this.description},
            status=${this.status},
            User=${this.user.firstName + ' ' + this.user.lastName},
            reviewers=${this.reviewers.length},
            acknowledgements=${this.acknowledgements.length},
            events=${this.events.length},
            comments=${this.comments.length},
            createdAt=${this.createdAt},
            endedAt=${this.endedAt},
        }`;
  }
}

export default ReviewalItem;
