import { Dialog } from 'primereact/dialog';
import { Button } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import { FaPaperPlane } from 'react-icons/fa';
import { InputText } from 'primereact/inputtext';
import React, { useState, useRef, useEffect, useMemo } from 'react';
import { FiUserPlus } from 'react-icons/fi';
import { MdAddLink } from 'react-icons/md';
import {
  generateEncryptedShareURL,
  globals,
  handleCopy,
} from '../../_global/common/Utils';
import { BiCopy } from 'react-icons/bi';
import { BiSolidCopy } from 'react-icons/bi';
import AWS from 'aws-sdk';
import { Alert, Snackbar } from '@mui/material';
import { useSelector } from 'react-redux';
import { User } from '../../../models';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
  region: 'us-east-2',
});
const lambda = new AWS.Lambda();

interface EmailTag {
  id: number;
  email: string;
  isEditing: boolean;
}

interface ShareURLModalProps {
  isVisible: boolean;
  handleClose: () => void;
  department: any;
}

const ShareURLModal: React.FC<ShareURLModalProps> = ({
  isVisible,
  handleClose,
  department,
}) => {
  const user: User = useSelector((state: any) => state.user);
  const [currentInput, setCurrentInput] = useState('');
  const [emailTags, setEmailTags] = useState<EmailTag[]>([]);
  const [nextId, setNextId] = useState(0);
  const [error, setError] = useState<string>('');
  const [isCopied, setIsCopied] = useState<string | null>(null);
  const editInputRef = useRef<HTMLInputElement>(null);

  const shareURL = useMemo(
    () =>
      generateEncryptedShareURL(
        department,
        user,
        globals.SHARE_URL_EXPIRATION_TIME
      ),
    [department, user]
  );

  const [snackbar, setSnackbar] = useState<any>({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    // Focus the edit input when a tag enters edit mode
    if (editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [emailTags]);

  /* Email validation function */
  const validateEmail = (email: string): boolean => {
    const emailRegex = /\S+@\S+\.\S+/;
    return emailRegex.test(email);
  };

  /* To send email to users */
  const handleSendEmail = async (
    type: string,
    email: string,
    share_url: string
  ): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      if (email) {
        let payload = {};
        if (type === 'shareUserCreation') {
          payload = {
            email: email,
            actionType: 'SHARE_USER_CREATION',
            share_url: share_url,
            departmentName: department.name,
          };
        }

        const params = {
          FunctionName: 'sendEmailToUsers', // Ensure this matches your Lambda's actual name
          InvocationType: 'RequestResponse',
          Payload: JSON.stringify(payload),
        };

        try {
          const result = await lambda.invoke(params).promise();
          const parsedResult = JSON.parse(result.Payload as string);
          if (parsedResult && parsedResult.statusCode === 200) {
            if (globals.debug)
              console.log('Email sent successfully:', parsedResult);
            resolve(parsedResult);
          } else {
            if (globals.debug)
              console.error('Error in Lambda response:', parsedResult);
            reject(new Error('Error invoking Lambda function'));
          }
        } catch (error) {
          if (globals.debug)
            console.error('Error invoking Lambda function:', error);
          reject(new Error('Error invoking Lambda function'));
        }
      }
    });
  };

  /* To add email tags */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    setError(''); // Clear error when user starts typing
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      addEmailTag();
    } else if (
      e.key === 'Backspace' &&
      currentInput === '' &&
      emailTags.length > 0
    ) {
      setEmailTags((prev) => prev.slice(0, -1));
    }
  };

  /* To handle edit key down */
  const handleEditKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    tag: EmailTag
  ) => {
    setError(''); // Clear error when user starts typing
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      finishEditing(tag.id, (e.target as HTMLInputElement).value);
    } else if (e.key === 'Escape') {
      cancelEditing(tag.id);
    }
  };

  /* To add email tags */
  const addEmailTag = () => {
    const trimmedInput = currentInput.trim();
    if (trimmedInput) {
      if (!validateEmail(trimmedInput)) {
        setError('Email is invalid');
        return;
      }
      if (emailTags.some((tag) => tag.email === trimmedInput)) {
        setError('This email has already been added');
        return;
      }
      setEmailTags((prev) => [
        ...prev,
        { id: nextId, email: trimmedInput, isEditing: false },
      ]);
      setNextId((prev) => prev + 1);
      setCurrentInput('');
      setError(''); // Clear error when a new tag is added
    }
  };

  /* To remove email tags */
  const removeEmailTag = (idToRemove: number) => {
    setEmailTags((prev) => prev.filter((tag) => tag.id !== idToRemove));
    setError('');
  };

  /* To start editing */
  const startEditing = (id: number) => {
    setEmailTags((prev) =>
      prev.map((tag) => ({
        ...tag,
        isEditing: tag.id === id,
      }))
    );
    setError('');
  };

  /* To finish editing */
  const finishEditing = (id: number, newValue: string) => {
    const trimmedValue = newValue.trim();
    if (trimmedValue) {
      if (!validateEmail(trimmedValue)) {
        setError('Email is invalid');
        return;
      }
      if (
        emailTags.some((tag) => tag.email === trimmedValue && tag.id !== id)
      ) {
        setError('This email has already been added');
        return;
      }
      setEmailTags((prev) =>
        prev.map((tag) =>
          tag.id === id
            ? { ...tag, email: trimmedValue, isEditing: false }
            : tag
        )
      );
      setError('');
    }
  };

  /* To cancel editing */
  const cancelEditing = (id: number) => {
    setEmailTags((prev) =>
      prev.map((tag) => (tag.id === id ? { ...tag, isEditing: false } : tag))
    );
    setError('');
  };

  /* To handle Send Invitations button */
  const handleSend = () => {
    let promises: Promise<any>[] = [];
    if (emailTags.length > 0) {
      emailTags.map((tag) =>
        promises.push(handleSendEmail('shareUserCreation', tag.email, shareURL))
      );
      setEmailTags([]);
      setError('');
      Promise.all(promises)
        .then((results: any) => {
          let n = results.length;
          setSnackbar({
            open: true,
            message: `Invitations Sent Successfully to ${n} emails`,
            severity: 'success',
          });
        })
        .catch((error) => {
          setSnackbar({
            open: true,
            message: 'Error Sending Invitations',
            severity: 'error',
          });
        });
    }
  };

  /* Helper function to get button text */
  const getButtonText = () => {
    if (emailTags.length <= 1) {
      return 'Send Invitation';
    }
    return 'Send Invitations';
  };

  return (
    <Dialog
      visible={isVisible}
      style={{
        flex: 1,
        backgroundColor: 'white',
        padding: '20px',
        margin: 0,
        display: 'flex',
        borderRadius: '12px',
        maxWidth: '600px',
      }}
      onHide={handleClose}
      showHeader={false}
      className="parseDialog"
    >
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <div style={{ background: 'white', padding: 0, margin: 0, gap: '10px' }}>
        <h4 className="keychain-title">
          <span
            style={{
              marginLeft: '20px',
              fontSize: '1.25rem',
              fontWeight: '600',
              color: 'black',
              marginRight: '6rem',
              flex: 1,
            }}
          >
            Share {department.name} Access
          </span>
          <Button
            className="iconButton"
            variant=""
            style={{ marginRight: '10px' }}
            onClick={handleClose}
          >
            <IoClose size="1.5rem" />
          </Button>
        </h4>

        <form
          className="keychain-form"
          style={{
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            background: 'transparent',
            border: 'none',
          }}
        >
          <div
            className="input-container"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              marginBottom: '10px',
            }}
          >
            {department && department.logoVerifiedUrl && (
              <img
                className="department-logo-public"
                style={{
                  height: '4rem',
                  width: '4rem',
                  marginRight: '10px',
                }}
                src={department.logoVerifiedUrl}
                alt="Agency Logo"
              />
            )}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <label
                className="settings-general-label"
                style={{
                  fontWeight: '600',
                  color: 'black',
                  fontSize: '1rem',
                }}
              >
                {department.name}
              </label>
            </div>
          </div>

          <p
            className="sidebarText"
            style={{ marginLeft: '10px', marginBottom: '20px' }}
          >
            This link will expire in {globals.SHARE_URL_EXPIRATION_TIME} hours.
            Anyone with this link can create an account with access to this
            department.
          </p>

          {/* Email Input Section */}
          <label
            className="settings-general-label"
            style={{ marginBottom: '4px' }}
          >
            Share via Email
          </label>
          <p
            className="sidebarText"
            style={{ marginLeft: '10px', marginTop: '0px' }}
          >
            Press "Enter" to add the email.
          </p>
          {/* Error message display */}
          {error && (
            <div
              style={{
                color: '#dc3545',
                fontSize: '14px',
                marginBottom: '2px',
                paddingLeft: '35px',
              }}
            >
              {error}
            </div>
          )}
          <div
            className="public-input-container"
            style={{ marginBottom: '10px', position: 'relative' }}
          >
            <FiUserPlus
              size={16}
              style={{
                position: 'absolute',
                left: '12px',
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
                color: '#666',
              }}
            />
            <div
              className="form-control"
              style={{
                minHeight: '38px',
                height: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '5px',
                padding: '8px 8px 8px 35px',
                alignItems: 'center',
                border: '1px solid #ced4da',
                borderRadius: '4px',
                backgroundColor: '#fff',
              }}
            >
              {emailTags.map((tag) => (
                <div
                  key={tag.id}
                  style={{
                    background: '#f0f0f0',
                    color: 'black',
                    padding: '1px 6px',
                    borderRadius: '3px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px',
                    fontSize: '14px',
                    cursor: 'pointer',
                  }}
                  onDoubleClick={() => startEditing(tag.id)}
                >
                  {tag.isEditing ? (
                    <input
                      ref={editInputRef}
                      type="text"
                      defaultValue={tag.email}
                      onKeyDown={(e) => handleEditKeyDown(e, tag)}
                      onBlur={(e) => finishEditing(tag.id, e.target.value)}
                      style={{
                        background: 'transparent',
                        border: 'none',
                        outline: 'none',
                        color: 'black',
                        width: `${tag.email.length + 2}ch`,
                        padding: '0',
                        margin: '0',
                        fontSize: '14px',
                      }}
                    />
                  ) : (
                    tag.email
                  )}
                  {!tag.isEditing && (
                    <IoClose
                      size="0.8rem"
                      style={{ cursor: 'pointer', color: '#888888' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeEmailTag(tag.id);
                      }}
                      aria-label="remove email"
                      role="button"
                    />
                  )}
                </div>
              ))}
              <InputText
                type="text"
                value={currentInput}
                onChange={(e) => setCurrentInput(e.target.value)}
                onKeyDown={handleKeyDown}
                onBlur={addEmailTag}
                placeholder={emailTags.length === 0 ? 'Enter Email(s)' : ''}
                style={{
                  border: 'none',
                  outline: 'none',
                  flex: 1,
                  minWidth: '100px',
                  background: 'transparent',
                  padding: '0',
                  fontSize: '14px',
                  color: '#495057',
                }}
              />
            </div>
          </div>

          {/* Send Invitation Button */}
          <Button
            className="secondary-button-small"
            onClick={handleSend}
            disabled={emailTags.length === 0}
            style={{
              marginBottom: '20px',
              backgroundColor: '#00534C',
              color: '#FFF',
              border: 'none',
              height: '32px',
              padding: '6px 12px',
              fontSize: '14px',
              borderRadius: '4px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '6px',
              width: '100%',
            }}
          >
            <FaPaperPlane size={12} />
            {getButtonText()}
          </Button>

          {/* Share Link Section */}
          <label
            className="settings-general-label"
            style={{ marginBottom: '8px' }}
          >
            Share via Link
          </label>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '2px',
              marginBottom: '10px',
            }}
          >
            <div
              className="public-input-container"
              style={{
                position: 'relative',
                flex: 1,
              }}
            >
              <MdAddLink
                size={16}
                style={{
                  position: 'absolute',
                  left: '12px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 1,
                  color: '#666',
                }}
              />
              <InputText
                value={shareURL}
                readOnly
                className="form-control"
                style={{
                  width: '100%',
                  height: '38px',
                  paddingLeft: '35px',
                  paddingRight: '12px',
                  border: '1px solid #ced4da',
                  borderRadius: '4px',
                  fontSize: '14px',
                  color: '#495057',
                }}
              />
            </div>
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginRight: '2px',
              }}
            >
              {isCopied && isCopied === shareURL ? (
                <BiSolidCopy
                  color={'#00534C'}
                  size="1rem"
                  className="copy_icon"
                  aria-label="link copied"
                />
              ) : (
                <BiCopy
                  size="1rem"
                  className="copy_icon"
                  aria-label="copy link"
                  onClick={(e) => handleCopy(shareURL, e, setIsCopied)}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ShareURLModal;
