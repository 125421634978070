import React from 'react';
import HighlightChanges from '../HighlightChanges';
import {
  findBeforeValue,
  findCurrentValue,
} from '../reviewComparsion/MedicationComparison';
import MedicationSubItem from '../../../../data/model/MedicationSubItem';
import { FaArrowsLeftRightToLine, FaSyringe } from 'react-icons/fa6';
import DepartmentItem from '../../../../data/model/DepartmentItem';

interface FolderComparisonProps {
  focusItem: MedicationSubItem;
  changes: any[];
  isPrevious?: boolean;
  department: DepartmentItem;
}

const MedicationDoseComparisonView: React.FC<FolderComparisonProps> = ({
  focusItem,
  changes,
  isPrevious,
  department,
}) => {
  return (
    <div className={`model-input-container  ${''}`} style={{ padding: '6px' }}>
      <div className={`${'doseGridPad'}`}>
        <div className="ketamine-container-pad">
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px',
              margin: '10px 0px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: '5px',
                // minWidth: '70px',
              }}
            >
              <FaArrowsLeftRightToLine
                style={{
                  fontSize: '1.5rem',
                  color: 'black',
                }}
              />
            </div>
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={`input-label`}>
                Weight Low (kg)
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'rangeLow')}
                  afterText={findCurrentValue(changes, 'rangeLow')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>
            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label`}>
                Weight High (kg)
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'rangeHigh')}
                  afterText={findCurrentValue(changes, 'rangeHigh')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>
            {department.isAgeFilterEnabled && (
              <>
                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age Low
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'ageLow')}
                      afterText={findCurrentValue(changes, 'ageLow')}
                      onlyPrevious={isPrevious === true}
                      onlyCurrent={isPrevious !== true}
                    />
                  </div>
                </div>

                <div
                  className=""
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                  }}
                >
                  <label htmlFor={''} className={` input-label`}>
                    Age High
                  </label>
                  <div
                    style={{
                      border: '1px solid #e0e0e0',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '15px',
                      padding: '4px',
                      borderRadius: '5px',
                      minHeight: '30px',
                      backgroundColor: 'white',
                    }}
                  >
                    <HighlightChanges
                      beforeText={findBeforeValue(changes, 'ageHigh')}
                      afterText={findCurrentValue(changes, 'ageHigh')}
                      onlyPrevious={isPrevious === true}
                      onlyCurrent={isPrevious !== true}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            className=""
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '15px',
              margin: '10px 0px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                // minWidth: '70px',
              }}
            >
              <FaSyringe
                style={{
                  fontSize: '1.5rem',
                  color: 'black',
                }}
              />
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 2,
              }}
            >
              <label htmlFor={''} className={` input-label `}>
                Calculation Basis
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'fullBasis')}
                  afterText={findCurrentValue(changes, 'fullBasis')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={` input-label `}>
                Min Dose
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'fullMinDose')}
                  afterText={findCurrentValue(changes, 'fullMinDose')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={`input-label `}>
                Max Dose
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'fullMaxDose')}
                  afterText={findCurrentValue(changes, 'fullMaxDose')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>

            <div
              className=""
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
              }}
            >
              <label htmlFor={''} className={`input-label `}>
                Max Total
              </label>
              <div
                style={{
                  border: '1px solid #e0e0e0',
                  color: '#000',
                  fontWeight: '500',
                  fontSize: '15px',
                  padding: '4px',
                  borderRadius: '5px',
                  minHeight: '30px',
                  backgroundColor: 'white',
                }}
              >
                <HighlightChanges
                  beforeText={findBeforeValue(changes, 'fullMaxTotalDose')}
                  afterText={findCurrentValue(changes, 'fullMaxTotalDose')}
                  onlyPrevious={isPrevious === true}
                  onlyCurrent={isPrevious !== true}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="sidebarInputContent"
          style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
        >
          <label
            htmlFor={''}
            className={` rowLabelWidth sidebarLabel`}
            style={{ minWidth: '70px' }}
          >
            Route:
          </label>
          <div
            style={{
              border: '1px solid #e0e0e0',
              color: '#000',
              fontWeight: '500',
              fontSize: '15px',
              padding: '4px',
              borderRadius: '5px',
              minHeight: '30px',
              backgroundColor: 'white',
            }}
          >
            <HighlightChanges
              beforeText={findBeforeValue(changes, 'routes')}
              afterText={findCurrentValue(changes, 'routes')}
              onlyPrevious={isPrevious === true}
              onlyCurrent={isPrevious !== true}
            />
          </div>
        </div>
      </div>

      <div className={` ${'sideoutFieldPad'}`}>
        <div
          className="sidebarInputContent"
          style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
        >
          <label
            htmlFor="Title"
            className={`sidebarLabel `}
            style={{ minWidth: '70px' }}
          >
            Title:{' '}
          </label>
          <div
            style={{
              border: '1px solid #e0e0e0',
              color: '#000',
              fontWeight: '500',
              fontSize: '15px',
              padding: '4px',
              borderRadius: '5px',
              minHeight: '30px',
              backgroundColor: 'white',
            }}
          >
            <HighlightChanges
              beforeText={findBeforeValue(changes, 'title')}
              afterText={findCurrentValue(changes, 'title')}
              onlyPrevious={isPrevious === true}
              onlyCurrent={isPrevious !== true}
            />
          </div>
        </div>

        <div
          className="sidebarInputContent"
          style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
        >
          <label
            htmlFor="Warning"
            className={`sidebarLabel`}
            style={{ minWidth: '70px' }}
          >
            Warning:{' '}
          </label>
          <div
            style={{
              border: '1px solid #e0e0e0',
              color: '#000',
              fontWeight: '500',
              fontSize: '15px',
              padding: '4px',
              borderRadius: '5px',
              minHeight: '30px',
              backgroundColor: 'white',
            }}
          >
            <HighlightChanges
              beforeText={findBeforeValue(changes, 'warning')}
              afterText={findCurrentValue(changes, 'warning')}
              onlyPrevious={isPrevious === true}
              onlyCurrent={isPrevious !== true}
            />
          </div>
        </div>

        <div
          className="sidebarInputContent"
          style={{ marginBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
        >
          <label
            htmlFor="instructions"
            className={`sidebarLabel`}
            style={{ minWidth: '70px' }}
          >
            Instructions:{' '}
          </label>
          <div
            style={{
              border: '1px solid #e0e0e0',
              color: '#000',
              fontWeight: '500',
              fontSize: '15px',
              padding: '4px',
              borderRadius: '5px',
              minHeight: '30px',
              backgroundColor: 'white',
            }}
          >
            <HighlightChanges
              beforeText={findBeforeValue(changes, 'instruction')}
              afterText={findCurrentValue(changes, 'instruction')}
              onlyPrevious={isPrevious === true}
              onlyCurrent={isPrevious !== true}
            />
          </div>
        </div>

        <div
          className="sidebarInputContent"
          style={{ paddingBottom: '10px', gridTemplateColumns: '0fr 4fr' }}
        >
          <label
            htmlFor="Note"
            className={`sidebarLabel`}
            style={{ minWidth: '70px' }}
          >
            Note:{' '}
          </label>
          <div
            style={{
              border: '1px solid #e0e0e0',
              color: '#000',
              fontWeight: '500',
              fontSize: '15px',
              padding: '4px',
              borderRadius: '5px',
              minHeight: '30px',
              backgroundColor: 'white',
            }}
          >
            <HighlightChanges
              beforeText={findBeforeValue(changes, 'note')}
              afterText={findCurrentValue(changes, 'note')}
              onlyPrevious={isPrevious === true}
              onlyCurrent={isPrevious !== true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MedicationDoseComparisonView;
