import { Sidebar } from 'primereact/sidebar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { HiPlus } from 'react-icons/hi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import { Button } from 'react-bootstrap';

import {
  DatabaseResponse,
  ResponseType,
  Response,
} from '../../../data/AmplifyDB';
import SearchBar from '../Search/SearchBar';
import { fetchItemFromAPI } from '../../../data/GraphQL_API';
import { handleHinckleyCache } from '../../../store/actions';
import { toTitleCase } from '../../_global/common/Utils';
import { Contact } from '../../../models';
import ContactItem from '../../../data/model/ContactItem';
import DepartmentItem from '../../../data/model/DepartmentItem';
import { fetchContacts } from '../../../data/functions/ContactDB';
import { RadioButton, RadioButtonChangeEvent } from 'primereact/radiobutton';
import { AiOutlinePlus } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { IoSave } from 'react-icons/io5';

interface AddContactSideoutProps {
  handleClose: () => void;
  handleAddContacts: (contacts: ContactItem[]) => void;
  isVisible: boolean;
  contacts: ContactItem[];
  department: DepartmentItem;
}

/* 10-13-23 Praveen: Created the side out component for Protocol creation flow */
const AddContactSideout: React.FC<AddContactSideoutProps> = (props) => {
  const { handleClose, isVisible, handleAddContacts, contacts, department } =
    props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const database: DatabaseResponse = useSelector(
    (state: any) => state.protocol.departmentItem
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);

  const topLevelDepartment = useMemo(() => {
    return department.getTopLevelDep();
  }, [department]);

  const [allContacts, setAllContacts] = useState<ContactItem[]>([]);
  const [value, setValue] = useState<any>('');
  const [selectedContacts, setSelectedContacts] =
    useState<ContactItem[]>(contacts);

  const loadParentContacts = async () => {
    const resp = await fetchContacts(topLevelDepartment);
    if (resp.type === ResponseType.Success) {
      setAllContacts(resp.data);
    }
  };

  const options = useMemo(() => {
    return allContacts.filter((c) =>
      c.name.toLowerCase().includes(value.toLowerCase())
    );
  }, [allContacts, value]);

  useEffect(() => {
    setSelectedContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    loadParentContacts();
  }, [topLevelDepartment, isVisible]);

  const handleNavigateToNewPage = () => {
    const state = {
      selectedProtocol: null,
      value: null,
      subValue: null,
      type: 'Contacts',
      editType: 'new',
      editMode: false,
      page: 'listContactPage',
    };
    navigate(`/database/new/contact`, { state });
  };

  const handleCloseSideouts = () => {
    setSelectedContacts(contacts);
    handleClose();
  };

  const isAddActive = useMemo(() => {
    if (selectedContacts.length !== contacts.length) {
      return true;
    }
    for (let i = 0; i < selectedContacts.length; i++) {
      if (contacts.find((c) => c.uid === selectedContacts[i].uid) === undefined)
        return true;
    }
    return false;
  }, [selectedContacts, contacts]);

  const handleToggleContact = (contact: ContactItem) => {
    if (selectedContacts.find((c) => c.uid === contact.uid)) {
      setSelectedContacts(
        selectedContacts.filter((c) => c.uid !== contact.uid)
      );
    } else {
      setSelectedContacts([...selectedContacts, contact]);
    }
  };

  return (
    <Sidebar
      visible={isVisible}
      position="right"
      onHide={handleCloseSideouts}
      style={{
        width: '25%',
        minWidth: '400px',
      }}
      className={'protocolSidebar sidebarWidth'}
    >
      <div style={{ height: '100%', padding: '10px' }}>
        <div
          className="buttonContainer contentTitleLarge hoverText"
          onClick={() => {
            setAllContacts(contacts);
            handleClose();
          }}
        >
          <span className="headerTilte">
            <BiArrowBack
              className="header-icon cursorPointer"
              style={{ paddingLeft: '4px' }}
            />
            <HiPlus className="header-icon " style={{ marginLeft: '8px' }} />{' '}
            Add Contact
          </span>
        </div>
        <div className="contentText">
          Control the available contacts for your agency. This is a list of all
          available contacts.
          {adminLevel <= 2
            ? "You can only add/remove contact's created by " +
              department.parentDep?.name
            : ''}
        </div>
        {adminLevel > 2 && (
          <div>
            <div className="contentLabelBold">Create New Contact</div>
            <div
              onClick={() => handleNavigateToNewPage()}
              className="createBtnhover cursorPointer contentBorder protocolCalculationPad contentHeadingBold newProtocolBorder "
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <span>
                <HiPlus className="text-icon " /> Create New Contact
              </span>
            </div>
          </div>
        )}

        <div className="contentLabelBold">
          {topLevelDepartment.name} Contacts: {options.length}
        </div>
        <SearchBar
          value={value}
          onChange={(value: string) => setValue(value)}
          placeholder="Search"
          containerStyle={{ width: '100%' }}
        />
        <div
          style={{ maxHeight: '60%' }}
          className="contentBorder protocolCalculationPad secondaryListScroll"
        >
          <div>
            {options && options.length === 0 && (
              <div
                className="contentHeading"
                style={{
                  color: '#636363',
                }}
              >
                No contacts found...
              </div>
            )}
            <ViewportList items={options}>
              {(item: any, index: any) => {
                return (
                  <div
                    key={item + index}
                    onClick={() => handleToggleContact(item)}
                    className={`listhover cursorPointer item contentHeading no-select ${
                      options.length !== index + 1 ? 'contentUnderline' : ''
                    }`}
                  >
                    <RadioButton
                      inputId="pairedProtocol"
                      name="pairedProtocol"
                      className=""
                      style={{ marginRight: '10px' }}
                      value={item.name}
                      checked={
                        selectedContacts?.find(
                          (option: any) => option.uid === item.uid
                        ) !== undefined
                      }
                      onChange={(e: RadioButtonChangeEvent) => {
                        handleToggleContact(item);
                      }}
                    />
                    {item?.name}
                  </div>
                );
              }}
            </ViewportList>
          </div>
        </div>
      </div>
      <div className="btn_Bottom">
        <Button
          className="secondary-button btn-rightMargin"
          data-testid="cancelBtn"
          onClick={handleCloseSideouts}
        >
          <span>
            <AiOutlineClose className="icon-normal" />
          </span>{' '}
          Cancel
        </Button>
        <Button
          className="primary-button"
          data-testid="saveBtn"
          onClick={() => handleAddContacts(selectedContacts)}
          disabled={!isAddActive}
        >
          <span>
            <IoSave className="icon-normal" />
          </span>{' '}
          Save
        </Button>
      </div>
    </Sidebar>
  );
};

export default AddContactSideout;
