import React, { useContext, useEffect, useState, useRef } from 'react';
import './GeneralSelection.scss';

interface SelectionProps<T> {
  selectedIndex: number;
  items: T[];
  onPress: (item: T, index: number) => void;
  labelField: (option: T) => string;
  valueField: (option: T) => string;
  badgeField?: (option: T) => string;
  containerStyle?: React.CSSProperties;
}

/* 9-26-23 Hazlett: Created concentration selection item */
function GeneralSelection<T>(props: SelectionProps<T>) {
  const { onPress, selectedIndex, items, containerStyle, badgeField } = props;

  const [selIndex, setSelectedIndex] = useState<number>(selectedIndex || -1);
  const [data, setData] = useState<any[]>(items || []);
  const [itemWidth, setItemWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  // Create ref for the first item
  const firstItemRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Update measurements when needed
  useEffect(() => {
    const updateMeasurements = () => {
      if (firstItemRef.current) {
        setItemWidth(firstItemRef.current.offsetWidth);
      }
      if (containerRef.current) {
        setContainerHeight(containerRef.current.offsetHeight);
      }
    };

    updateMeasurements();
    window.addEventListener('resize', updateMeasurements);
    return () => window.removeEventListener('resize', updateMeasurements);
  }, [data.length, selectedIndex]);

  useEffect(() => {
    setSelectedIndex(selectedIndex);
    setData(items);
  }, [selectedIndex, items]);

  const handleItemSelection = (item: T, index: number) => {
    if (index === selIndex) return;
    setSelectedIndex(index);
    onPress(item, index);
    // dispatch<any>(toggleSelectedWeight({ selectedWeight: index }))
  };

  return (
    <div style={containerStyle}>
      <div className="gs-container" ref={containerRef}>
        <div
          className="gs-background-slider"
          style={{
            transform: `translateX(${selIndex * itemWidth}px)`,
            width: `${itemWidth}px`,
            height: `${containerHeight}px`,
          }}
        />
        {data.map((item, index) => (
          <div
            ref={index === 0 ? firstItemRef : null}
            className={`gs-item${index === selIndex ? ' selected' : ''}`}
            key={index}
            onClick={() => handleItemSelection(item, index)}
          >
            <h4 className="gs-item-text no-select">{item}</h4>
            {badgeField &&
              badgeField(item) !== '' &&
              badgeField(item) !== '0' && (
                <span
                  className={`gs-item-badge no-select${index === selIndex ? ' selected' : ''}`}
                >
                  {badgeField(item)}
                </span>
              )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default GeneralSelection;
