/**
 * @file Utils.tsx
 * @author Colton Hazlett
 * @brief This file contains the global variables and functions used throughout the application.
 * @date 7-18-23 Hazlett
 * @version 1.0.0
 * @details This file contains the global variables and functions used throughout the application.
 */
import _ from 'lodash';
import { PatientAgeGroup } from '../../../API';
import { SoftwareType, User } from '../../../models';
import ModelItem from '../../../data/model/ModelItem';
import DepartmentItem from '../../../data/model/DepartmentItem';
import ModelSubItem from '../../../data/model/ModelSubItem';
import { ProgressStatus } from '../../../models';
import { UserType } from '../../../models';
import { encrypt } from './Encrypt';

export const globals = {
  VERSION: 'v1.6.7',
  MAX_VALUE: 2147483647, //7-18-23 Hazlett: This is the maximum value for a 32 bit integer and I have used it in the DB
  MAX_VALUE_STR: '2147483647',
  debug: false,
  oldDBDoseStyle: true,
  outputDataStoreEvents: false,
  maxVersionValue: 60,
  maxDatabaseDelayMS: 10000,
  QUERY_EXPIRATION_TIME: 1000 * 60 * 10, // 10 min
  QUERY_BATCH_SIZE: 20,
  MAX_SUB_DEPARTMENTS: 30,
  SHARE_URL_EXPIRATION_TIME: 6, //6 hours
  config: {
    availSensors: [15, 18],
  },
  uuid: {
    FORCE_MAIN_SERVICE: '00002723-0000-1000-8000-00805F9B34FB', //Cyanokit (hydroxocobalamin)
    FORCE_MAIN_WGHT: '000027A5-0011-1000-8000-00805F9B34FB',
    FORCE_MAIN_STATE: '000027A5-0033-1000-8000-00805F9B34FB',
    BATT_SERVICE: '0000180F-0000-1000-8000-00805F9B34FB',
    BATTERY_DATA: '00002A19-0000-1000-8000-00805F9B34FB',
    DEVICE_SERVICE: '0000180A-0000-1000-8000-00805F9B34FB',
    DEVICE_TX: '00002A00-0011-1000-8000-00805F9B34FB',
    UUID_NOTIFICATION: '00002902-0000-1000-8000-00805f9b34fb',
  },
  msg: {
    MSG_SENSOR_WEIGHT: 100,
    MSG_CONNECT_DEVICE: 101,
    MSG_FRAGMENT_MANAGER: 102,
    MSG_REGISTER_CLIENT: 103,
    MSG_UNREGISTER_CLIENT: 104,
    MSG_REMOVE_CALLBACKS: 105,
    MSG_DELAY: 106,
    MSG_BLE_STATUS: 107,
    MSG_DEVICES: 108,
    MSG_SENSOR_DATA: 109,
    MSG_BATTERY: 110,
    MSG_PROGRESS: 111,
    MSG_DEVICE_DATA: 112,
    MSG_CLEAR: 113,
    MSG_STATUS: 114,
    MSG_SCAN: 115,
    MSG_DISCONNECT: 116,
    MSG_READ_BATTERY: 117,
    MSG_AUTO_TARE: 118,
    MSG_READ_CALIBRATION: 119,
    MSG_WRITE_CALIBRATION: 120,
    MSG_STATE_RQST_RESULT: 121,
    MSG_WRITE_SLEEP_BUFFER: 122,
    MSG_FOUND_DEVICE: 123,
    MSG_WRITE_DEVICE_NAME: 124,
    MSG_INITIALIZE_ACTIVITY: 125,
    MSG_NUM_SENSORS: 126,
    MSG_SLEEP_BUFFER: 127,
    MSG_WRITE_NUM_SENSORS: 128,
    MSG_INITIALIZE_LOBBY: 129,
    MSG_STOP_SCAN: 130,
    MSG_CLEAR_SCAN_LIST: 131,
    MSG_WRITE_ALL_CALIBRATION: 132,
    MSG_WRITE_SERIAL_NUM: 133,
    MSG_READ_WEIGHT: 135,
    MSG_LCD_DEC: 136,
    MSG_LCD_LED: 137,
    MSG_LCD_UNITS: 138,
    MSG_ENBL_FLAG: 139,
    MSG_STATE_CHANGE: 140,
    MSG_FINISHED_CNCT: 141,
    MSG_SENSOR_STDDEV: 142,
    MSG_DEBUG_AUTO_TARE: 143,
    MSG_SENSOR_DATA_RAW: 144,
    MSG_SENSOR_DATA_CAL: 145,
    MSG_HW_FW_VERSION: 146,
    MSG_OTA_FILE: 147,
    MSG_RQST_TX_DATA: 148,
    MSG_EXPAND_SHEET: 149,
    MSG_TARE_SCALE: 150,
  },
  ble: {
    /* BLE states */
    BLE_WEIGH: 1,
    IDLE_WEIGH: 2,
    ZERO_WEIGH: 3,
    LVLD_WEIGH: 4,
    MVMNT_WEIGH: 5,
    PTNT_WEIGH: 6,
    PTNT_SVD: 7,
    PTNT_IDLE: 8,
    PTNT_SLEEP: 9,
    PTNT_RLSD: 10,
    DEEP_SLP: 11,
    DSCNT_MODE: 12,
    SYNC_OW: 13,
    OTA_UPDT: 14,
    ERR_STATE: 15,

    /* BLE types for the bytes in the data packet */
    TYPE_FMWR: 0,
    TYPE_DEV_SN: 1,
    TYPE_CAL: 2,
    TYPE_CUR_NAME: 3,
    TYPE_DEF_NAME: 4,
    TYPE_FS_RW: 5,
    TYPE_FS_CAL: 6,
  },
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const medicationRangeKeys = [
  'basis',
  'rangeLow',
  'rangeHigh',
  'route',
  'nemsisRoutes',
  'title',
  'warning',
  'instruction',
  'note',
  'index',
  'maxDose',
  'minDose',
  'calcMax',
  'calcMin',
];

export const electricalRangeKeys = [
  'basis',
  'rangeLow',
  'rangeHigh',
  'title',
  'warning',
  'instruction',
  'note',
  'index',
  'calcMax',
  'fixedMax',
];

export function removeTypename(obj: any): any {
  // Recursively remove __typename
  const cleanedObj = (object: any): any => {
    // Check if the object is an array
    if (Array.isArray(object)) {
      return object.map((item) => cleanedObj(item));
    }
    // Check if the object is an actual object
    else if (object !== null && typeof object === 'object') {
      for (const key in object) {
        if (key !== '__typename') continue;
        if (key === '__typename') {
          delete object[key];
        } else if (typeof object[key] === 'object') {
          object[key] = cleanedObj(object[key]);
        }
      }
    }
    return object;
  };

  return cleanedObj(obj);
}

/**
 * Generate new IDs for the options
 * @param options - The options to generate new IDs for
 * @returns The options with new IDs
 */
export const generateNewIDs = (options: any[]) => {
  /* Validate all items have an id  otherwise return the original array */
  if (options.some((option) => !option.id)) return options;
  let newOptions = [...options];
  newOptions.forEach((option) => {
    option.id = generateID();
  });
  return newOptions;
};

export const hasAdminUserAccess = (
  department: DepartmentItem,
  reducerState: any,
  user: User,
  model?: ModelItem<any> | ModelSubItem<any>
): boolean => {
  if (!user || !department) return false;
  if (user.type === 'USER' || user.type === 'DEPT') return false;
  if (model == null) return user.depAdmins?.includes(department.id) || false;
  const departmentOwner = findDepartmentOwner(department, reducerState, model);
  return (
    (department.id === departmentOwner?.id &&
      user.depAdmins?.includes(model.departmentID)) ||
    false
  );
};

export function findDepartmentOwner(
  department: DepartmentItem,
  reducerState: any,
  item: ModelItem<any> | ModelSubItem<any>
): DepartmentItem | null {
  const allSubDeps = getDepartmentsFromState(
    department,
    reducerState
  ).allSubDeps;
  if (item.status === ProgressStatus.DEACTIVATED && item.overrideItem)
    return findDepartmentOwner(department, reducerState, item.overrideItem);
  if (department.id === item.departmentID) {
    return department;
  } else if (
    department.parentDep &&
    department.parentDep.id === item.departmentID
  ) {
    return department.parentDep;
  } else if (
    department.parentDep &&
    department.parentDep.parentDep &&
    department.parentDep.parentDep.id === item.departmentID
  ) {
    return department.parentDep.parentDep;
  }

  return allSubDeps?.find((dep) => dep.id === item.departmentID) || null;
}

export function findPairedDepartments(
  department: DepartmentItem,
  reducerState: any,
  item: ModelItem<any>
): DepartmentItem[] | null {
  const allSubDeps = getDepartmentsFromState(
    department,
    reducerState
  ).allSubDeps;
  if (
    !department ||
    allSubDeps == null ||
    item.pairedDepIDs == null ||
    item.pairedDepIDs.length === 0
  )
    return null;

  let deps = allSubDeps.filter((dep) => item.pairedDepIDs?.includes(dep.id));

  return deps;
}

export function isOwnerDepartment(
  department: DepartmentItem,
  item: ModelItem<any> | ModelSubItem<any>
): boolean {
  if (department.id === item.departmentID) return true;
  return false;
}

export function findStartingIndex(
  department: DepartmentItem,
  reducerState: any,
  item: ModelItem<any> | ModelSubItem<any>
): number {
  if (department.parentDep == null) return 0;
  let deps: DepartmentItem[] = [department];
  if (department.parentDep) deps = [department.parentDep, ...deps];
  if (department.parentDep?.parentDep)
    deps = [department.parentDep.parentDep, ...deps];

  let owner = findDepartmentOwner(department, reducerState, item);
  if (!owner) return 0;

  if (deps.length > 1 && owner.id === deps[1].id) return 1000;
  if (deps.length > 2 && owner.id === deps[2].id) return 10000;
  return 0;
}

export function getProgressStatus(status: any): ProgressStatus {
  switch (status) {
    case 'DRAFT':
      return ProgressStatus.DRAFT;
    case 'ACTIVE':
      return ProgressStatus.ACTIVE;
    case 'ARCHIVE':
      return ProgressStatus.ARCHIVE;
    case 'DELETED':
      return ProgressStatus.DELETED;
    case 'DRAFT_DELETE':
      return ProgressStatus.DRAFT_DELETE;
    case 'DEACTIVATED':
      return ProgressStatus.DEACTIVATED;
    default:
      return ProgressStatus.ACTIVE;
  }
}

const placeString = (place: number): string => {
  if (place > 3 && place < 21) return `${place}th`;

  switch (place % 10) {
    case 1:
      return `${place}st`;
    case 2:
      return `${place}nd`;
    case 3:
      return `${place}rd`;
    default:
      return `${place}th`;
  }
};
export const toNameCase = (str: string) => {
  if (!str) return str;
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};

// Author: Guruprasad Venkatraman (01-23-2024)
// Checking if both the Dose object and the object inside
// of the UpdatedOptionItems array are equal.
export const isObjectEqual = (obj1: any, obj2: any) => {
  const entries1 = Object.entries(obj1).sort();
  const entries2 = Object.entries(obj2).sort();

  if (entries1.length !== entries2.length) {
    return false;
  }

  return entries1.every(([key, val], index) => {
    return entries2[index][0] === key && entries2[index][1] === val;
  });
};

/*  Author: Abhishek Verma
 This method is used to compare two objects deeply and return true if they are equal, works for non-primite types as well

 */
export const deepCompareObjects = (obj1: any, obj2: any): boolean => {
  const entries1 = Object.entries(obj1).sort();
  const entries2 = Object.entries(obj2).sort();

  if (entries1.length !== entries2.length) {
    return false;
  }

  return entries1.every(([key, val], index) => {
    const [key2, val2] = entries2[index];

    if (key !== key2) {
      return false;
    }

    if (Array.isArray(val) && Array.isArray(val2)) {
      if (val.length !== val2.length) {
        return false;
      }

      // Sort arrays before comparison to handle order-agnostic comparison
      const sortedVal = [...val].sort();
      const sortedVal2 = [...val2].sort();

      return sortedVal.every((item, i) => {
        if (typeof item === 'object' && typeof sortedVal2[i] === 'object') {
          return deepCompareObjects(item, sortedVal2[i]);
        }
        return item === sortedVal2[i];
      });
    }

    if (
      typeof val === 'object' &&
      typeof val2 === 'object' &&
      val !== null &&
      val2 !== null
    ) {
      return deepCompareObjects(val, val2);
    }

    return val === val2;
  });
};

/* Output hte highest time unit */
export const convertToHighestTime = (timeInSec: number): string => {
  let time = timeInSec;
  const hours = Math.floor(time / 3600);
  time %= 3600;
  const minutes = Math.floor(time / 60);
  time %= 60;
  const seconds = time;

  let str: string = '';
  if (hours > 0) str += `${hours} hr `;
  if (minutes > 0) str += `${minutes} min `;
  if (seconds > 0) str += `${seconds} sec`;
  return str.trim();
};

// Define a type for the keys of the rangeMap
type RangeKey =
  | '1 Mo'
  | '2 Mo'
  | '3 Mo'
  | '4 Mo'
  | '5 Mo'
  | '6 Mo'
  | '7 Mo'
  | '8 Mo'
  | '9 Mo'
  | '10 Mo'
  | '11 Mo'
  | '1 Yr'
  | '2 Yr'
  | '3 Yr'
  | '4 Yr'
  | '5 Yr'
  | '6 Yr'
  | '7 Yr'
  | '8 Yr'
  | '9 Yr'
  | '10 Yr'
  | '11 Yr'
  | '12 Yr'
  | '13 Yr'
  | '14 Yr'
  | 'Sm. Adult'
  | 'Adult'
  | 'Lg. Adult'
  | 'MIN'
  | 'MAX';

export const numberToRangeString = (numStr: string): RangeKey | string => {
  const num = parseInt(numStr, 10);
  if (num >= 1 && num <= 11) {
    return `${num} Mo`;
  } else if (num >= 12 && num <= 25) {
    return `${num - 11} Yr`;
  } else if (num === 26) {
    return 'Sm. Adult';
  } else if (num === 27) {
    return 'Adult';
  } else if (num === 28) {
    return 'Lg. Adult';
  } else if (num === 29) {
    return 'MAX';
  } else {
    return 'MIN';
  }
};

export const mapRangeToIndex = (rangeValue: RangeKey | string) => {
  const rangeMap: { [key in RangeKey]: number } = {
    MIN: 0,
    '1 Mo': 1,
    '2 Mo': 2,
    '3 Mo': 3,
    '4 Mo': 4,
    '5 Mo': 5,
    '6 Mo': 6,
    '7 Mo': 7,
    '8 Mo': 8,
    '9 Mo': 9,
    '10 Mo': 10,
    '11 Mo': 11,
    '1 Yr': 12,
    '2 Yr': 13,
    '3 Yr': 14,
    '4 Yr': 15,
    '5 Yr': 16,
    '6 Yr': 17,
    '7 Yr': 18,
    '8 Yr': 19,
    '9 Yr': 20,
    '10 Yr': 21,
    '11 Yr': 22,
    '12 Yr': 23,
    '13 Yr': 24,
    '14 Yr': 25,
    'Sm. Adult': 26,
    Adult: 27,
    'Lg. Adult': 28,
    MAX: 29,
  };
  if (!isNaN(Number(rangeValue))) rangeValue = numberToRangeString(rangeValue);
  return rangeMap[rangeValue as RangeKey];
};

export function getSoftwarePackage(
  softwarePlan: string | undefined | null
): SoftwareType | null {
  switch (softwarePlan) {
    case 'STARTER':
      return SoftwareType.STARTER;
    case 'PROFESSIONAL':
      return SoftwareType.PROFESSIONAL;
    case 'ENTERPRISE':
      return SoftwareType.ENTERPRISE;
    case 'PLUS':
    case 'SOFTWARE_ONLY':
      return SoftwareType.PLUS;
    case 'ESSENTIALS':
      return SoftwareType.ESSENTIALS;
    case 'VIEW_ONLY':
      return SoftwareType.VIEW_ONLY;
    case 'PREMIUM':
      return SoftwareType.PREMIUM;
    default:
      return null;
  }
}

export const mapIndexToRange = (index: number): string => {
  const indexMap = [
    'Min',
    '1 Mo',
    '2 Mo',
    '3 Mo',
    '4 Mo',
    '5 Mo',
    '6 Mo',
    '7 Mo',
    '8 Mo',
    '9 Mo',
    '10 Mo',
    '11 Mo',
    '1 Yr',
    '2 Yr',
    '3 Yr',
    '4 Yr',
    '5 Yr',
    '6 Yr',
    '7 Yr',
    '8 Yr',
    '9 Yr',
    '10 Yr',
    '11 Yr',
    '12 Yr',
    '13 Yr',
    '14 Yr',
    'Sm. Adult',
    'Adult',
    'Lg. Adult',
    'Max',
  ];
  if (index === 2147483647) {
    return 'Max';
  }
  return indexMap[index] || 'Unknown';
};
export const toTitleCase = (str: string): string => {
  if (!str) return str;
  const exclude = [
    'or',
    'and',
    'the',
    'of',
    'in',
    'to',
    'a',
    'an',
    'but',
    'for',
    'at',
    'by',
    'on',
    'with',
  ]; // Add or remove words as needed

  const include = [
    'EMS',
    'ALS',
    'BLS',
    'CPAP',
    'ETT',
    'IV',
    'IO',
    'PDF',
    'DNR',
    'MCI',
    'COPD',
    'EKG',
    'FD',
    'FDA',
    'S.W.A.T',
    'EMR',
  ];

  // Replace all '_' with ' '
  str = str.replace(/_/g, ' ');

  const removeSpecialChars = (word: string) =>
    word.replace(/[^a-zA-Z0-9]/g, '');

  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      if (include.includes(removeSpecialChars(word).toUpperCase()))
        return word.toUpperCase();
      if (exclude.includes(word)) return word;

      let capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);

      // Check for opening parenthesis
      if (word.startsWith('(') && word.length > 1) {
        capitalizedWord = '(' + word.charAt(1).toUpperCase() + word.slice(2);
      }

      // Check for opening quote
      if (word.startsWith('"') && word.length > 1) {
        capitalizedWord = '"' + word.charAt(1).toUpperCase() + word.slice(2);
      }

      //Check for '/' and capitalize the next word
      if (word.includes('/')) {
        const splitWord = word.split('/');
        capitalizedWord = '';
        splitWord.forEach((split, index) => {
          if (index === 0) {
            capitalizedWord += split.charAt(0).toUpperCase() + split.slice(1);
          } else {
            capitalizedWord +=
              '/' + split.charAt(0).toUpperCase() + split.slice(1);
          }
        });
      }

      // Add more checks for other punctuation if needed
      return capitalizedWord;
    })
    .join(' ')
    .trim();
};

export const isMedicationItem = (item: any): boolean => {
  if (
    item &&
    item.hasOwnProperty('TAG') &&
    item.TAG === 'MedicationItem' &&
    item.dbMedication
  )
    return true;
  return false;
};

export const isElectricalItem = (item: any): boolean => {
  if (item && item.hasOwnProperty('TAG') && item.TAG === 'ElectricalItem')
    return true;
  return false;
};

export const isEquipmentItem = (item: any): boolean => {
  if (item && item.hasOwnProperty('TAG') && item.TAG === 'EquipmentItem')
    return true;
  return false;
};

export const isInfusionItem = (item: any): boolean => {
  if (
    item &&
    item.hasOwnProperty('TAG') &&
    item.TAG === 'MedicationItem' &&
    item.dbDrip
  )
    return true;
  return false;
};

export const isVitalItem = (item: any): boolean => {
  if (item && item.hasOwnProperty('TAG') && item.TAG === 'VitalItem')
    return true;
  return false;
};

export const isForm = (item: any): boolean => {
  if (item && item.hasOwnProperty('TAG') && item.TAG === 'Form') return true;
  return false;
};

/* 10-12-23 Arul: This function will return the formatted date  */

export const getFormattedDate = (
  date: Date | string | null | undefined,
  isDateOnly: boolean = false
): string => {
  if (!date) return '';
  if (typeof date === 'string') date = new Date(date);

  let month = months[date.getMonth()];
  let day = String(date.getDate()).padStart(2, '0');
  let year = String(date.getFullYear());
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');

  if (isDateOnly) return month + ' ' + placeString(Number(day)) + ', ' + year;
  else
    return (
      month +
      ' ' +
      placeString(Number(day)) +
      ', ' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds
    );
};

/* 8-17-23 Hazlett: This function will return the formatted date string in military timestamp format */
export const getFormattedDateTime = (
  date: Date | string,
  isTimeOnly: boolean,
  isDateOnly?: boolean
): string => {
  if (!date) return '';
  if (typeof date === 'string') date = new Date(date);

  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  let year = String(date.getFullYear()).substring(2);
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');

  if (isTimeOnly) return hours + ':' + minutes + ':' + seconds;
  if (isDateOnly) return month + '/' + day + '/' + year;
  else
    return (
      month +
      '/' +
      day +
      '/' +
      year +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds
    );
};

export function CalculateByteSting(bytes: number): string {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return Number((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/* 8-17-23 Hazlett: This function will return the formatted duration between two DateTime items */
export const getFormattedDuration = (
  start: Date | string,
  end: Date | string
): string => {
  if (!start || !end) return '';
  let startDate: Date;
  let endDate: Date;

  if (typeof start === 'string') {
    const parsedStart = new Date(start);
    startDate = new Date(
      Date.UTC(
        parsedStart.getUTCFullYear(),
        parsedStart.getUTCMonth(),
        parsedStart.getUTCDate(),
        parsedStart.getUTCHours(),
        parsedStart.getUTCMinutes(),
        parsedStart.getUTCSeconds()
      )
    );
  } else {
    startDate = start;
  }

  if (typeof end === 'string') {
    const parsedEnd = new Date(end);
    endDate = new Date(
      Date.UTC(
        parsedEnd.getUTCFullYear(),
        parsedEnd.getUTCMonth(),
        parsedEnd.getUTCDate(),
        parsedEnd.getUTCHours(),
        parsedEnd.getUTCMinutes(),
        parsedEnd.getUTCSeconds()
      )
    );
  } else {
    endDate = end;
  }

  const diff = (endDate.getTime() - startDate.getTime()) / 1000.0;
  return getDurationString(diff);
};

/* 8-17-23 Hazlett: This function will return the formatted duration between two DateTime items */
export const getDurationString = (durationMilliSeconds: number): string => {
  const durationInSeconds = Math.floor(durationMilliSeconds / 1000);
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  let formattedDuration = '';
  if (hours > 0) formattedDuration += `${hours} hr `;
  if (minutes > 0) formattedDuration += `${minutes} min `;
  if (seconds > 0) formattedDuration += `${seconds} sec`;

  return formattedDuration.trim();
};

/* 7-8-23 Hazlett: This function will return a number rounded to the decimal places */
export const roundToDec = (val: number, decPlaces: number): number => {
  if (decPlaces < 0) return -1;
  let scale = Math.pow(10.0, decPlaces);
  return Math.round(val * scale) / scale;
};

/* 9-20-23 Hazlett: Converts a byte array to a number */
export const byteArrayToNumber = (
  byteArray: number[],
  offset: number
): number => {
  if (byteArray.length < offset + 4) {
    console.error('Insufficient bytes to extract float from given offset');
    return -1.0;
  }

  const buffer = new ArrayBuffer(4); // 4 bytes for 32-bit float
  const view = new DataView(buffer);

  for (let i = 0; i < 4; i++) {
    view.setUint8(i, byteArray[offset + i]);
  }

  return view.getFloat32(0, true); // true for little-endian
};

export const generateDepartmentURL = (department: DepartmentItem): string => {
  let url = 'https://www.onedose-admin.com/sites/';
  url += department.name.replaceAll(' ', '');
  return url;
};

export const handleCopy = (text: string, event: any, setIsCopied: any) => {
  event.stopPropagation(); // Prevents the click event from bubbling up
  navigator.clipboard.writeText(text);

  setIsCopied(text);
  setTimeout(() => setIsCopied(null), 2000); // show a "copied!" message for 2 seconds
};

const activeLogInitialData = {
  log: {
    id: '1234-567-890',
    ambulanceID: '1234-567-890',
    oneweightID: '1234-567-890',
    protocolID: ['1234-567-890'],
    userIDs: ['1234-567-890'],
    startTime: '2023-08-09T20:30:23.613Z',
    endTime: '2023-08-09T21:30:23.613Z',
    estimatedTimeStamp: '2023-08-09T20:30:23.613Z',
    actualTimestamp: '2023-08-09T20:30:23.613Z',
    medications: [],
    equipments: [],
    electricals: [],
    forms: [],
    infusions: [],
  },
  actual: {
    weight: 0,
    _isEstimate: false,
    _isSelected: false,
    theme: {
      title: 'Grey',
      primary: '#787878',
      primarySelected: '#787878',
      secondary: '#C8C8C8',
      secondarySelected: '#C8C8C8',
      bgPrimary: '#CCCCCC',
      bgSecondary: '#F5F5F5',
      contrast: '#FFD699',
    },
    age: -1,
  },
  estimate: {
    weight: 0,
    _isEstimate: false,
    _isSelected: false,
    theme: {
      title: 'Grey',
      primary: '#787878',
      primarySelected: '#787878',
      secondary: '#C8C8C8',
      secondarySelected: '#C8C8C8',
      bgPrimary: '#CCCCCC',
      bgSecondary: '#F5F5F5',
      contrast: '#FFD699',
    },
    age: -1,
  },
  taggedAmbulance: {
    id: '',
    name: '',
  },
  taggedOneWeight: {
    id: '1234-567-890',
    name: 'Adult',
  },
  taggedProtocols: [],
  taggedUsers: [],
  uid: '1234-567-890',
};

export const getLogsInitialData = () => {
  return activeLogInitialData;
};

export function getPatientAgeGroup(str: any): PatientAgeGroup | null {
  switch (str) {
    case 'ADULT':
      return PatientAgeGroup.ADULT;
    case 'NEONATE':
      return PatientAgeGroup.NEONATE;
    case 'PEDIATRIC':
      return PatientAgeGroup.PEDIATRIC;
    case 'GERIATRIC':
      return PatientAgeGroup.GERIATRIC;
    default:
      return null;
  }
}

/* 7-18-23 Hazlett: This function will return a string of the BLE TX Type name */
export const getBleTXType = (type: number): string => {
  switch (type) {
    case globals.ble.TYPE_FMWR:
      return 'Firmware Version';
    case globals.ble.TYPE_DEV_SN:
      return 'Serial Number';
    case globals.ble.TYPE_FS_RW:
      return 'Sensor Raw Values';
    case globals.ble.TYPE_FS_CAL:
      return 'Sensor Calibrated Values';
    case globals.ble.TYPE_CAL:
      return 'Calibration Information';
    case globals.ble.TYPE_CUR_NAME:
      return 'Current Device Name';
    case globals.ble.TYPE_DEF_NAME:
      return 'Default Device Name';
    default:
      return 'Unknown';
  }
};

/*7-18-23 Hazlett: This function will return a string of the message name */
export const getMsgName = (msg: number): string => {
  switch (msg) {
    case globals.msg.MSG_SENSOR_WEIGHT:
      return 'MSG_SENSOR_WEIGHT';
    case globals.msg.MSG_CONNECT_DEVICE:
      return 'MSG_CONNECT_DEVICE';
    case globals.msg.MSG_FRAGMENT_MANAGER:
      return 'MSG_FRAGMENT_MANAGER';
    case globals.msg.MSG_REGISTER_CLIENT:
      return 'MSG_REGISTER_CLIENT';
    case globals.msg.MSG_UNREGISTER_CLIENT:
      return 'MSG_UNREGISTER_CLIENT';
    case globals.msg.MSG_REMOVE_CALLBACKS:
      return 'MSG_REMOVE_CALLBACKS';
    case globals.msg.MSG_DELAY:
      return 'MSG_DELAY';
    case globals.msg.MSG_BLE_STATUS:
      return 'MSG_BLE_STATUS';
    case globals.msg.MSG_DEVICES:
      return 'MSG_DEVICES';
    case globals.msg.MSG_SENSOR_DATA:
      return 'MSG_SENSOR_DATA';
    case globals.msg.MSG_BATTERY:
      return 'MSG_BATTERY';
    case globals.msg.MSG_PROGRESS:
      return 'MSG_PROGRESS';
    case globals.msg.MSG_DEVICE_DATA:
      return 'MSG_DEVICE_DATA';
    case globals.msg.MSG_CLEAR:
      return 'MSG_CLEAR';
    case globals.msg.MSG_STATUS:
      return 'MSG_STATUS';
    case globals.msg.MSG_SCAN:
      return 'MSG_SCAN';
    case globals.msg.MSG_DISCONNECT:
      return 'MSG_DISCONNECT';
    case globals.msg.MSG_READ_BATTERY:
      return 'MSG_READ_BATTERY';
    case globals.msg.MSG_AUTO_TARE:
      return 'MSG_AUTO_TARE';
    case globals.msg.MSG_READ_CALIBRATION:
      return 'MSG_READ_CALIBRATION';
    case globals.msg.MSG_WRITE_CALIBRATION:
      return 'MSG_WRITE_CALIBRATION';
    case globals.msg.MSG_STATE_RQST_RESULT:
      return 'MSG_STATE_RQST_RESULT';
    case globals.msg.MSG_WRITE_SLEEP_BUFFER:
      return 'MSG_WRITE_SLEEP_BUFFER';
    case globals.msg.MSG_FOUND_DEVICE:
      return 'MSG_FOUND_DEVICE';
    case globals.msg.MSG_WRITE_DEVICE_NAME:
      return 'MSG_WRITE_DEVICE_NAME';
    case globals.msg.MSG_INITIALIZE_ACTIVITY:
      return 'MSG_INITIALIZE_ACTIVITY';
    case globals.msg.MSG_NUM_SENSORS:
      return 'MSG_NUM_SENSORS';
    case globals.msg.MSG_SLEEP_BUFFER:
      return 'MSG_SLEEP_BUFFER';
    case globals.msg.MSG_WRITE_NUM_SENSORS:
      return 'MSG_WRITE_NUM_SENSORS';
    case globals.msg.MSG_INITIALIZE_LOBBY:
      return 'MSG_INITIALIZE_LOBBY';
    case globals.msg.MSG_STOP_SCAN:
      return 'MSG_STOP_SCAN';
    case globals.msg.MSG_CLEAR_SCAN_LIST:
      return 'MSG_CLEAR_SCAN_LIST';
    case globals.msg.MSG_WRITE_ALL_CALIBRATION:
      return 'MSG_WRITE_ALL_CALIBRATION';
    case globals.msg.MSG_WRITE_SERIAL_NUM:
      return 'MSG_WRITE_SERIAL_NUM';
    case globals.msg.MSG_READ_WEIGHT:
      return 'MSG_READ_WEIGHT';
    case globals.msg.MSG_LCD_DEC:
      return 'MSG_LCD_DEC';
    case globals.msg.MSG_LCD_LED:
      return 'MSG_LCD_LED';
    case globals.msg.MSG_LCD_UNITS:
      return 'MSG_LCD_UNITS';
    case globals.msg.MSG_ENBL_FLAG:
      return 'MSG_ENBL_FLAG';
    case globals.msg.MSG_STATE_CHANGE:
      return 'MSG_STATE_CHANGE';
    case globals.msg.MSG_FINISHED_CNCT:
      return 'MSG_FINISHED_CNCT';
    case globals.msg.MSG_SENSOR_STDDEV:
      return 'MSG_SENSOR_STDDEV';
    case globals.msg.MSG_DEBUG_AUTO_TARE:
      return 'MSG_DEBUG_AUTO_TARE';
    case globals.msg.MSG_SENSOR_DATA_RAW:
      return 'MSG_SENSOR_DATA_RAW';
    case globals.msg.MSG_SENSOR_DATA_CAL:
      return 'MSG_SENSOR_DATA_CAL';
    case globals.msg.MSG_HW_FW_VERSION:
      return 'MSG_HW_FW_VERSION';
    case globals.msg.MSG_OTA_FILE:
      return 'MSG_OTA_FILE';
    case globals.msg.MSG_RQST_TX_DATA:
      return 'MSG_RQST_TX_DATA';
    default:
      return 'MSG_UNKNOWN';
  }
};

export const getChecklistLogData = (): any => {
  const data = {
    completed: '',
    formID: '1234-567-890',
    name: 'Form',
    protocolID: '1234-567-890',
    startedTime: '',
  };

  return data;
};

export const isObjectInArray = (arr: any[], obj: any): boolean => {
  for (let i = 0; i < arr.length; i++)
    if (getObjectDifference(arr[i], obj).length === 0) return true;
  return false;
};

export const getObjectDifference = (obj1: any, obj2: any) => {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
};

/**
 * This function will upgrade the version string to the next version
 *      - Ugrade major or minor version if the value has reached the max value (20)
 * @param version The version string to upgrade in the format "v(Major).(Minor).(Patch)"
 * @returns The upgraded version string in the same format
 */
export const upgradeVersion = (version: string): string => {
  const versionArray = version.split('.');
  let major = Number(versionArray[0].substring(1)); // Remove the "v" from the string
  let minor = Number(versionArray[1]);
  let patch = Number(versionArray[2]);

  if (patch < globals.maxVersionValue) {
    patch++;
  } else if (minor < globals.maxVersionValue) {
    minor++;
    patch = 0;
  } else if (major < globals.maxVersionValue) {
    major++;
    minor = 0;
    patch = 0;
  }

  return `v${major}.${minor}.${patch}`;
};

export const compareVersions = (a: string, b: string) => {
  if (a === b) return 0;
  else if (a == null) return -1;
  else if (b == null) return 1;
  const aVersion = a.split('.');
  const bVersion = b.split('.');
  for (let i = 0; i < aVersion.length; i++) {
    if (parseInt(aVersion[i]) > parseInt(bVersion[i])) return 1;
    if (parseInt(aVersion[i]) < parseInt(bVersion[i])) return -1;
  }
  return 0;
};

export const getActiveID = (
  item: ModelItem<any> | ModelSubItem<any>
): string | null => {
  if (item.status === ProgressStatus.DEACTIVATED && item.overrideID != null)
    return item.overrideID;
  else if (item.status.includes('DRAFT') && item.activeID) return item.activeID;
  else return item.uid;
};

export const SOFTWARE_PLAN_OPTIONS = [
  { label: 'Strater', value: 'STARTER' },
  { label: 'Professional', value: 'PROFESSIONAL' },
  { label: 'Enterprise', value: 'ENTERPRISE' },
  { label: 'Essentials', value: 'ESSENTIALS' },
  { label: 'Plus', value: 'PLUS' },
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'View Only', value: 'VIEW_ONLY' },
  { label: 'Premium Null', value: null },
];

export const USAStatesAbrev = [
  { label: 'AL', value: 'AL' },
  { label: 'AK', value: 'AK' },
  { label: 'AS', value: 'AS' },
  { label: 'AZ', value: 'AZ' },
  { label: 'AR', value: 'AR' },
  { label: 'CA', value: 'CA' },
  { label: 'CO', value: 'CO' },
  { label: 'CT', value: 'CT' },
  { label: 'DE', value: 'DE' },
  { label: 'DC', value: 'DC' },
  { label: 'FM', value: 'FM' },
  { label: 'FL', value: 'FL' },
  { label: 'GA', value: 'GA' },
  { label: 'GU', value: 'GU' },
  { label: 'HI', value: 'HI' },
  { label: 'ID', value: 'ID' },
  { label: 'IL', value: 'IL' },
  { label: 'IN', value: 'IN' },
  { label: 'IA', value: 'IA' },
  { label: 'KS', value: 'KS' },
  { label: 'KY', value: 'KY' },
  { label: 'LA', value: 'LA' },
  { label: 'ME', value: 'ME' },
  { label: 'MH', value: 'MH' },
  { label: 'MD', value: 'MD' },
  { label: 'MA', value: 'MA' },
  { label: 'MI', value: 'MI' },
  { label: 'MN', value: 'MN' },
  { label: 'MS', value: 'MS' },
  { label: 'MO', value: 'MO' },
  { label: 'MT', value: 'MT' },
  { label: 'NE', value: 'NE' },
  { label: 'NV', value: 'NV' },
  { label: 'NH', value: 'NH' },
  { label: 'NJ', value: 'NJ' },
  { label: 'NM', value: 'NM' },
  { label: 'NY', value: 'NY' },
  { label: 'NC', value: 'NC' },
  { label: 'ND', value: 'ND' },
  { label: 'MP', value: 'MP' },
  { label: 'OH', value: 'OH' },
  { label: 'OK', value: 'OK' },
  { label: 'OR', value: 'OR' },
  { label: 'PW', value: 'PW' },
  { label: 'PA', value: 'PA' },
  { label: 'PR', value: 'PR' },
  { label: 'RI', value: 'RI' },
  { label: 'SC', value: 'SC' },
  { label: 'SD', value: 'SD' },
  { label: 'TN', value: 'TN' },
  { label: 'TX', value: 'TX' },
  { label: 'UT', value: 'UT' },
  { label: 'VT', value: 'VT' },
  { label: 'VI', value: 'VI' },
  { label: 'VA', value: 'VA' },
  { label: 'WA', value: 'WA' },
  { label: 'WI', value: 'WI' },
  { label: 'WV', value: 'WV' },
  { label: 'WY', value: 'WY' },
];

export function jsonToTitleCase(input: string): string {
  const exceptions: { [key: string]: string } = {
    medClass: 'Medication Class',
  };

  if (exceptions[input]) {
    return exceptions[input];
  }

  const result = input
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Handle camelCase to split words
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Handle edge cases with all caps
    .replace(/_/g, ' ') // Replace underscores with spaces
    .toLowerCase() // Convert entire string to lower case
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    }); // Capitalize first letter of each word

  return result;
}

export function generateTemporaryPassword(): string {
  const length = 8;
  const numericCharset = '0123456789';
  const specialCharset = '!@#$%^&*(),.?":{}|<>';
  const alphaCharset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  // Ensure at least one character from each required set
  let password = '';
  password += numericCharset.charAt(
    Math.floor(Math.random() * numericCharset.length)
  );
  password += specialCharset.charAt(
    Math.floor(Math.random() * specialCharset.length)
  );

  // Fill remaining characters with random selection from all required sets
  const allCharset = numericCharset + specialCharset + alphaCharset;
  for (let i = password.length; i < length; i++) {
    const at = Math.floor(Math.random() * allCharset.length);
    password += allCharset.charAt(at);
  }

  // Shuffle the password to randomize character positions
  return password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
}

export function validatePassword(new_password: string) {
  const minLength = /.{6,}/;
  const hasNumber = /\d/;
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
  return (
    minLength.test(new_password) &&
    hasNumber.test(new_password) &&
    hasSpecialChar.test(new_password)
  );
}

export function validateEmail(email: string) {
  const emailRegex = /\S+@\S+\.\S+/;
  return emailRegex.test(email);
}

export const getGroupCode = (type: string) => {
  switch (type) {
    case UserType.ADMIN:
      return 0;
    case UserType.DEPT_ADMIN:
      return 1;
    case UserType.DEPT:
      return 2;
    case UserType.USER:
      return 3;
    default:
      return 4;
  }
};

/**
 * Get the sub-departments and user's departments for a given department
 * @param department The department to get the sub-departments and user's departments for
 * @param state The state to get the sub-departments and user's departments from
 * @returns An object containing the sub-departments, all sub-departments, and user's departments
 */
export const getDepartmentsFromState = (
  department: DepartmentItem,
  state: any
): {
  subDeps: DepartmentItem[];
  allSubDeps: DepartmentItem[];
  userDepartments: DepartmentItem[];
} => {
  try {
    if (
      department == null ||
      state == null ||
      state.subDeps == null ||
      state.allSubDeps == null
    )
      return { subDeps: [], allSubDeps: [], userDepartments: [] };

    const subDeps = (state.subDeps as Map<string, DepartmentItem[]>).get(
      department.id
    );
    const allSubDeps = (state.allSubDeps as Map<string, DepartmentItem[]>).get(
      department.id
    );
    const userDepartments = state.userDepartments;

    return {
      subDeps: subDeps ?? [],
      allSubDeps: allSubDeps ?? [],
      userDepartments: userDepartments ?? [],
    };
  } catch (error) {
    console.error('Error getting departments from state', error);
    throw error;
  }
};

/**
 * Generate an encrypted share URL for a given department
 * @param department The department to generate the share URL for
 * @param hours The number of hours the share URL will be valid for
 * @returns The encrypted share URL
 */
export const generateEncryptedShareURL = (
  department: DepartmentItem,
  user: User,
  hours: number = 6,
  domain: string = 'https://www.onedose-admin.com'
) => {
  const expiration = new Date().getTime() + 1000 * 60 * 60 * hours; // Convert hours to milliseconds

  const data = {
    departmentCode: department.departmentCode,
    user: {
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
    },
    expiration: expiration,
  };

  const signature = encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_SHARE_KEY
  );

  const encryptedUrl = `${domain}/share-create-account?signature=${signature}&expiration=${expiration}`;

  return encryptedUrl;
};

/**
 * This function will confirm that all items have an ID
 * If an item does not have an ID, it will generate a new ID for it
 * @param items The items to confirm have an ID
 * @returns The items with the new IDs
 */
export const confirmAllItemsHaveID = (items: any[]) => {
  try {
    let newItems = [];
    for (let i = 0; i < items.length; i++) {
      if (!items[i].id) {
        newItems.push({ ...items[i], id: generateID() });
      } else {
        newItems.push(items[i]);
      }
    }
    return newItems;
  } catch (error) {
    console.error('Error confirming all items have an ID', error);
    throw error;
  }
};

/**
 * Generate a random ID
 * @returns A random ID, Ex. "bd67fb03-0100-4460-a73f-6deebe1b472f"
 */
export const generateID = () => {
  return crypto.randomUUID();
};

export const findItemByID = (
  id: string,
  items: (ModelItem<any> | ModelSubItem<any>)[]
): ModelItem<any> | ModelSubItem<any> | null => {
  let item = items.find((item: ModelItem<any> | ModelSubItem<any>) => {
    if (item.uid === id) return true;
    else if (item.activeItem && item.activeItem.uid === id) return true;
    else if (item.overrideItem && item.overrideItem.uid === id) return true;
    else return false;
  });

  //Now return the correct item, activeItem or overrideItem
  if (item == null) return null;
  else if (item.activeItem && item.activeItem.uid === id)
    return item.activeItem;
  else if (item.overrideItem && item.overrideItem.uid === id)
    return item.overrideItem;
  else return item;
};
