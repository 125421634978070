import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaChevronDown, FaChevronRight, FaFilePdf } from 'react-icons/fa6';
import { RiDownload2Line, RiUpload2Line } from 'react-icons/ri';
import SearchableDropdown from './SearchableDropdown';
import DepartmentItem from '../../data/model/DepartmentItem';
import SelectDepartmentsModal from './Modal/SelectDepartmentsModal';
import { User } from '../../models';
import { useSelector } from 'react-redux';
import { InputText } from 'primereact/inputtext';
import { BiCopy, BiSolidCopy } from 'react-icons/bi';
import { handleCopy } from '../_global/common/Utils';
import { set } from 'lodash';

interface FolderProtocolAdvancedSettingsProps {
  type: 'Folder' | 'Protocol';
  department: DepartmentItem;
  availableDepartments: DepartmentItem[];
  owner: DepartmentItem | undefined;
  pairedDeps: DepartmentItem[];
  isRestrictive: boolean;
  setOwner: any;
  setIsRestrictive: any;
  setPairedDeps: any;
  isEditMode?: boolean;
  activeID?: string | undefined | null;
  setActiveID?: any;
}

/* 10-11-23 Arul: Created the CustomPdfUpload component for Protocol Modal */
const FolderProtocolAdvancedSettings: React.FC<
  FolderProtocolAdvancedSettingsProps
> = (props) => {
  const { type, setOwner, setIsRestrictive, setPairedDeps, setActiveID } =
    props;
  const isEditMode = useMemo(
    () => (props.isEditMode == null ? true : props.isEditMode),
    [props.isEditMode]
  );
  const user: User = useSelector((state: any) => state?.user);
  const [isCopied, setIsCopied] = useState<string | undefined | null>(
    undefined
  );

  const availableDepartments = useMemo(() => {
    return props.availableDepartments;
  }, [props.availableDepartments]);
  const department = useMemo(() => {
    return props.department;
  }, [props.department]);
  const owner = useMemo(() => {
    return props.owner;
  }, [props.owner]);
  const pairedDeps = useMemo(() => {
    return props.pairedDeps;
  }, [props.pairedDeps]);
  const isRestrictive = useMemo(() => {
    return props.isRestrictive;
  }, [props.isRestrictive]);
  const activeID = useMemo(() => {
    return props.activeID;
  }, [props.activeID]);
  const [isSelectingDepartments, setIsSelectingDepartments] =
    useState<boolean>(false);

  return (
    <>
      {owner && (
        <SelectDepartmentsModal
          department={owner}
          values={pairedDeps}
          isVisible={isSelectingDepartments}
          handleClose={() => setIsSelectingDepartments(false)}
          handleSubmit={(deps) => {
            setPairedDeps(deps);
            setIsSelectingDepartments(false);
          }}
        />
      )}
      <Accordion
        style={{
          borderRadius: '5px',
          boxShadow: 'none',
          alignContent: 'center',
          justifyContent: 'center',

          fontSize: '16px',
          marginTop: '20px',
          backgroundColor: 'transparent',
          border: '1px solid #ccc',
        }}
        onChange={(e, expanded) => {
          // if (!expanded && formik.values.owner == null)
          //   setTimeout(() => {
          //     formik.setFieldValue('owner', department);
          //   }, 100);
        }}
      >
        <AccordionSummary
          expandIcon={<FaChevronDown />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Advanced Settings
        </AccordionSummary>
        <AccordionDetails>
          <label htmlFor="" className={`notification-css-title`}>
            {type} Owner
          </label>
          <span
            className="contentText greyText"
            style={{ fontSize: '13px', marginLeft: '6px' }}
          >
            This is the department that will own the folder.
          </span>
          <SearchableDropdown<DepartmentItem>
            id="searchDropdown"
            options={[department, ...availableDepartments]}
            value={owner}
            labelField={(option) => option.name}
            valueField={(option) => option.name}
            keyField={(option) => option.id}
            onChange={(option: DepartmentItem) =>
              setOwner(option as DepartmentItem)
            }
            onClear={() => setOwner(undefined)}
            placeholder="Search department..."
            disabled={!isEditMode}
          />
          {availableDepartments.length === 0 && (
            <div
              style={{
                marginTop: '20px',
              }}
            >
              <label htmlFor="" className={`notification-css-title`}>
                {type} Subscription Note:
              </label>
              <span
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '6px' }}
              >
                This {type.toLocaleLowerCase()} cannot add any other departments
                because the {type.toLocaleLowerCase()} is restricted to{' '}
                {owner?.name} only. Update the folder's access to allow other
                departments to subscribe to it.
              </span>
            </div>
          )}
          {availableDepartments.length > 0 && owner && (
            <>
              <label htmlFor="" className={`notification-css-title`}>
                {type} Type
              </label>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  height: 'auto',
                  marginTop: '10px',
                  justifyItems: 'center',
                }}
              >
                <div
                  className="tab-container"
                  style={{
                    margin: 0,
                  }}
                >
                  <div
                    className={`tab ${!isRestrictive ? 'tab-active' : ''}`}
                    style={{
                      cursor: isEditMode ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (isEditMode) {
                        setIsRestrictive(false);
                        setPairedDeps([]);
                      }
                    }}
                  >
                    <div style={{ marginTop: '4px' }}>Inclusive</div>
                  </div>
                  <div
                    className={`tab ${isRestrictive ? 'tab-active' : ''}`}
                    style={{
                      cursor: isEditMode ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (isEditMode) setIsRestrictive(true);
                    }}
                  >
                    <div style={{ marginTop: '4px' }}>Restrictive</div>
                  </div>
                </div>
              </div>
              {isRestrictive ? (
                <span className={`notification-css`}>
                  Restrictive: Restricts this {type.toLocaleLowerCase()} to only
                  departments that are paired to it. Automatically adds the
                  folder owner.
                </span>
              ) : (
                <span className={`notification-css`}>
                  Inclusive: Allows the {type.toLocaleLowerCase()} to propagate
                  to all {availableDepartments.length + ' '}
                  department(s) under the owner.
                </span>
              )}
              {isRestrictive && (
                <div
                  className={`hoverable-button-with-text ${isEditMode ? '' : 'disabled'}`}
                  onClick={() => {
                    if (isEditMode) setIsSelectingDepartments(true);
                  }}
                >
                  <label className="hoverable-button-text">
                    {pairedDeps.length + ' Departments Selected'}
                  </label>
                  <FaChevronRight style={{ marginLeft: '5px' }} size="14px" />
                </div>
              )}
            </>
          )}
          {user.type === 'ADMIN' && setActiveID && (
            <>
              <label
                htmlFor="Name"
                className="ketamine-general-label"
                style={{
                  marginTop: '2rem',
                  marginLeft: '0px',
                  fontSize: '16px',
                }}
              >
                Developer Controls
              </label>
              <p
                className="contentText greyText"
                style={{ fontSize: '13px', marginLeft: '6px' }}
              >
                These is controls that should ONLY be used by developers.
              </p>
              <label htmlFor="Name" className="ketamine-general-label">
                Active ID:{' '}
                <span>
                  {isCopied && isCopied === activeID ? (
                    <BiSolidCopy
                      color={'#00534C'}
                      size="1rem"
                      className="copy_icon"
                    />
                  ) : (
                    <BiCopy
                      size="1rem"
                      className="copy_icon"
                      onClick={(e) =>
                        handleCopy(activeID || '', e, setIsCopied)
                      }
                    />
                  )}
                </span>
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="activeID"
                  name="activeID"
                  data-testid="activeID"
                  value={activeID ? activeID : ''}
                  onChange={(e: any) => {
                    setActiveID(e.target.value);
                  }}
                  disabled={!isEditMode}
                />
                <div className="input-border"></div>
              </div>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FolderProtocolAdvancedSettings;
