import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Accordion } from 'react-bootstrap';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ViewportList } from 'react-viewport-list';
import {
  DatabaseResponse,
  ResponseType,
  Response,
  loadDatabase,
} from '../../../../data/AmplifyDB';
import ProtocolHeader from '../../protocol/ProtocolHeader';
import {
  findDepartmentOwner,
  getFormattedDate,
  globals,
  hasAdminUserAccess,
} from '../../../_global/common/Utils';
import {
  FaCaretDown,
  FaCaretUp,
  FaChevronRight,
  FaLock,
} from 'react-icons/fa6';
import MedicationItem, {
  getConcentrationsArrayString,
  getConcentrationString,
} from '../../../../data/model/MedicationItem';
import SearchBar from '../../../components/Search/SearchBar';
import Status from '../../../components/ProgressStatus/ProgressStatus';
import { handleGetDepartment } from '../../../../store/actions';
import { useDispatch } from 'react-redux';
import NewMedicationSideout from '../../../components/SideOut/NewModelItemSideout';
import {
  deleteMedication,
  fetchMedications,
} from '../../../../data/functions/MedicationDB';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import ConfirmModal from '../../../components/Modal/ConfirmModal';
import { ProgressStatus, User } from '../../../../models';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import OwnerImage from '../../../components/OwnerImage/OwnerImage';
import HMSwitch from '../../../components/general/HMSwitch';
import HMCheckbox from '../../../components/general/HMCheckbox';
import { ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import DepartmentItem from '../../../../data/model/DepartmentItem';
import {
  isDraftCreated,
  removeCurrentDraftUpdates,
} from '../../../../data/AmplifyVersion';
import { handleToggleEnabled } from '../../../../data/functions/ModelDB';
import DraftSaveModal from '../../../components/Modal/DraftSaveModal';
import { set } from 'lodash';
import ReactLoading from 'react-loading';

/* 09-27-23 Arul: Created Component for Protocol Screen*/
const ListMedicaions = (props: any) => {
  const isLoggedIn = useSelector((state: any) => state.isLoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reducerState = useSelector((state: any) => state.department);
  const { state } = location;
  const [totalDoses, setTotalDoses] = useState(-1);
  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const user: User = useSelector((state: any) => state?.user);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);

  const [sort, setSort] = useState('name_asc');
  const department = database.department;
  const [medications, setMedications] = useState<MedicationItem[]>(
    database.medications
  );
  const [isCreateMedication, setIsCreateMedication] = useState(false);
  const scrollPosition = useRef(0);

  const [searchQuery, setSearchQuery] = useState(
    state?.search?.searchQuery ?? ''
  );
  const [filters, setFilters] = useState<any[]>(state?.search?.filters ?? []);
  const [categoriesFilter, setCategoriesFilter] = useState<any[]>(
    state?.search?.categoriesFilter ?? []
  );
  const adminLevel = useMemo(() => {
    return department?.adminLevel ?? 4;
  }, [department]);
  const adminAccess = useMemo(() => {
    return hasAdminUserAccess(department, reducerState, user);
  }, [department, reducerState, user]);
  const [list, setList] = useState<any[]>(medications);
  const [selectedItems, setSelectedItems] = useState<MedicationItem[]>([]);
  const paginationModel = { page: 0, pageSize: 50 };
  const [isDelete, setIsDelete] = useState(false);
  // allCheckboxBtn is to set the state for "delete all" button
  const [allCheckedBtn, setAllCheckedBtn] = useState(false);
  //  mainCheckbox is to set the state for the main checkbox
  const [mainCheckbox, setMainCheckbox] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [itemDeactivationStatus, setItemDeactivationStatus] = useState<
    MedicationItem[]
  >([]);
  const [viewing, setViewing] = useState<string | null>('all');

  const allDepartmentOwners = useMemo(() => {
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < medications.length; i++) {
      let owner = findDepartmentOwner(department, reducerState, medications[i]);
      if (owner && !deps.find((dep) => dep.id === owner?.id)) deps.push(owner);
    }
    setViewing('all');
    setFilters([]);
    return deps.sort((a, b) => a.name.localeCompare(b.name));
  }, [medications, department, reducerState]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === 'n') {
        event.preventDefault(); // Prevent the default browser action (e.g., saving the webpage)
        handleCreateItem();
        // Add your custom logic here
      } else if (event.ctrlKey && event.key === 'b') handleBack();
    };

    // Add event listener
    document.addEventListener('keydown', handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  /* TODO Need to redo the mappings */
  // const reloadDatabase = async () => {
  //   const response = await loadDatabase(department, undefined, true);

  //   if (response.type === ResponseType.Success) {
  //     let medications = response.data.medications;
  //     setMedications(medications);
  //     handleFilterChange(medications);
  //     dispatch<any>(
  //       handleGetDepartment({
  //         ...database,
  //         medications: medications,
  //       } as DatabaseResponse)
  //     );
  //   }
  // };

  const reloadDatabase = async () => {
    const response = await fetchMedications(
      department,
      database,
      true,
      true,
      true
    );
    if (response.type === ResponseType.Success) {
      setMedications(response.data);
      handleFilterChange(response.data);
      dispatch<any>(
        handleGetDepartment({
          ...database,
          medications: response.data,
        } as DatabaseResponse)
      );
    }
  };

  useEffect(() => {
    reloadDatabase();
  }, []);

  useEffect(() => {
    let count = 0;
    medications.forEach((item) => {
      count += item.subItems.length;
    });
    setTotalDoses(count !== 0 ? count : -1);
  }, [database]);

  /* 09-28-23 Arul: handle function for filter search*/
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
  };

  const handleFilterChange = (pList: any[]) => {
    let filteredList = [...(pList ? pList : medications)];
    if (searchQuery !== '' || filters.length > 0) {
      filteredList = medications.filter((item) => {
        return item.name.toLowerCase().includes(searchQuery.toLowerCase());
      });
      if (filters.length > 0) {
        filteredList = filteredList.filter((item) => {
          //Check if the Filters (Department ID)
          return filters.some((filter) => {
            return item.departmentID === filter.id;
          });
        });
      }
    }
    //Group by the parent first then sort by index
    filteredList = filteredList.sort((a: MedicationItem, b: MedicationItem) => {
      return a.name.localeCompare(b.name);
    });
    filteredList = handleSort(filteredList);
    setList(filteredList);
  };

  const handleSave = async () => {
    if (itemDeactivationStatus && itemDeactivationStatus.length > 0) {
      try {
        let promises: any[] = [];
        for (let i = 0; i < itemDeactivationStatus.length; i++) {
          promises.push(
            handleToggleEnabled(
              user,
              database.department,
              reducerState,
              itemDeactivationStatus[i]
            )
          );
        }
        let results = await Promise.all(promises);
        for (let i = 0; i < results.length; i++) {
          if (results[i].type === ResponseType.Success) {
            if (globals.debug) console.log('SAVED ITEM', results[i].data);
          } else {
            console.error('ERROR SAVING ITEM', results[i].data);
          }
        }

        reloadDatabase().then(() => {
          setItemDeactivationStatus([]);
        });
      } catch (error) {
        console.error('ERROR SAVING DEACTIVATE STATE', error);
      }
    }
  };

  useEffect(() => {
    handleFilterChange(medications);
  }, [searchQuery, filters, categoriesFilter, sort, medications]);

  const handleSort = (list: MedicationItem[]): MedicationItem[] => {
    const sortedList = list.sort((a, b) => {
      if (sort === 'name_desc') return b.name.localeCompare(a.name);
      else if (sort === 'name_asc') return a.name.localeCompare(b.name);
      else if (sort === 'doses_desc') {
        if (a.subItems.length > b.subItems.length) return -1;
        else if (a.subItems.length < b.subItems.length) return 1;
        else return 0;
      } else if (sort === 'doses_asc') {
        if (a.subItems.length < b.subItems.length) return -1;
        else if (a.subItems.length > b.subItems.length) return 1;
        else return 0;
      } else if (!a.model || !b.model) return 0;
      else if (sort === 'modified_asc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return aDate.getTime() - bDate.getTime();
      } else if (sort === 'modified_desc') {
        if (!a.model.updatedAt || !b.model.updatedAt) return 0;
        const aDate = new Date(a.model.updatedAt);
        const bDate = new Date(b.model.updatedAt);
        return bDate.getTime() - aDate.getTime();
      }
      return 0;
    });
    return sortedList;
  };

  /* 09-29-23 Arul: handle function to Navigate protocol detail page*/
  const handleItemClick = (medication: MedicationItem) => {
    const state = {
      selectedProtocol: null,
      value: medication,
      subValue: null,
      type: 'Medication',
      editType: 'edit',
      editMode: false,
      page: 'listMedicationsPage',
    };
    navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  const handleCreateItem = () => {
    setIsCreateMedication(true);
    // const state = {
    //   selectedProtocol: null,
    //   value: null,
    //   subValue: null,
    //   type: 'Medication',
    //   editType: 'new',
    //   editMode: false,
    //   page: 'listMedicationsPage',
    // };
    // navigate(`/protocol/edit/edit-protocol`, { state });
    // const state = { selectedProtocol: protocol, editMode: false };
    // navigate(`/${protocol.nickname}/protocol-detail`, { state });
  };

  /* 09-29-23 Arul: Render the Protocol Accordion*/
  const rowStyle = {
    display: 'grid',
    gridTemplateColumns: '3fr 2fr 2fr 2fr 1fr', // + (department.subDeps ? ' 1fr' : ''),
    // backgroundColor: isHovered ? '#e0e0e0' : '#fff'
  };

  const handleBack = () => {
    navigate(`/database`, { state: department });
  };

  const handleMakeNew = async () => {
    let response = await removeCurrentDraftUpdates(database);
    if (response.type === ResponseType.Success) {
      if (response.data.length > 0) {
        if (globals.debug)
          console.log('Successfully removed current draft updates', response);
        setIsConfirmModal(false);
        setIsOpen(false);
      }
    } else {
      if (globals.debug)
        console.log('Failed to remove current draft updates', response);
    }
  };

  const handleCheckIsDraft = async () => {
    let response: Response = await isDraftCreated(database.department);
    if (response.type === ResponseType.Success) {
      let isDraft = response.data;
      if (isDraft) setIsOpen(true);
    } else {
      if (globals.debug)
        console.log('Failed to check if draft exists', response);
    }
  };

  const columns = useMemo<GridColDef[]>(() => {
    let cols: GridColDef[] = [
      {
        field: 'input',
        flex: 0.25,
        disableColumnMenu: true,
        headerAlign: 'center',
        renderHeader: () => (
          <div style={{ marginRight: '20px' }}>
            <HMCheckbox
              checked={mainCheckbox}
              onChange={(
                event: React.ChangeEvent<HTMLInputElement>,
                checked: boolean
              ) => {
                setMainCheckbox(checked);
                if (checked) {
                  setSelectedItems([
                    ...medications.filter(
                      (item) =>
                        item.status !== 'DEACTIVATED' &&
                        department.id === item.departmentID
                    ),
                  ]);
                } else {
                  setSelectedItems([]);
                }
              }}
            />
          </div>
        ),
        renderCell: (params: any) => {
          let item = list.find((item) => item.uid === params.value.uid);
          if (!item) return <div></div>;
          const isChecked = selectedItems.find(
            (selectedItem) => selectedItem.uid === item.uid
          );

          const isOwner = item.departmentID === department.id ?? false;
          const adminAccess = hasAdminUserAccess(
            department,
            reducerState,
            user
          );

          let isSwitchChecked = item.status !== ProgressStatus.DEACTIVATED;
          if (
            itemDeactivationStatus.find((newItem) => newItem.uid === item.uid)
          )
            isSwitchChecked = !isSwitchChecked;

          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flex: 1,
                alignItems: 'center',
                height: '100%',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {adminAccess && (
                <>
                  {isOwner && item.status !== ProgressStatus.DEACTIVATED ? (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <HMCheckbox
                        checked={isChecked != null}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ) => {
                          handleSelectionChange(item);
                        }}
                      />
                      <div
                        style={{
                          marginLeft: '2px',
                          border: '1px solid #E0E0E0',
                          height: '50%',
                          width: '1px',
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        flexDirection: 'row',
                        marginLeft: '-22px',
                      }}
                    >
                      <HMSwitch
                        checked={isSwitchChecked}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>,
                          checked: boolean
                        ) => handleToggleEnabledUI(item as MedicationItem)}
                      />
                      <div
                        style={{
                          marginLeft: '-3px',
                          border: '1px solid #E0E0E0',
                          height: '50%',
                          width: '1px',
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          );
        },
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        filterOperators: [],
      },

      {
        field: 'name',
        flex: 2,
        renderHeader: () => <div style={{ fontWeight: 'bold' }}>Name</div>,
        renderCell: (params) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!(
              params.value.isOwner && params.value.status !== 'DEACTIVATED'
            ) && (
              <div style={{ marginRight: '8px' }}>
                <FaLock className="table-icon" style={{}} color="#A3A3A3" />
              </div>
            )}
            {params.value.name}
            {params.value.status !== 'ACTIVE' && (
              <Status
                status={params.value.status}
                style={{ marginLeft: '8px' }}
              />
            )}
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.name.localeCompare(v2.name),
        filterable: false,
      },
      {
        field: 'concentration',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Concentration</div>
        ),
        filterable: false,
        renderCell: (params) => {
          return params.value === undefined ? (
            <div>
              <ReactLoading
                className="load"
                type="spokes"
                height={18}
                width={18}
              />
            </div>
          ) : (
            <div>{params.value}</div>
          );
        },
      },
      {
        field: 'modified_date',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Modified Date</div>
        ),
        filterable: false,
      },
      {
        field: 'modified_by',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>Modified By</div>
        ),
        filterable: false,
      },
      {
        field: 'owner',
        flex: 1,
        renderHeader: () => <div style={{ fontWeight: 'bold' }}>Owner</div>,
        renderCell: (params) => {
          if (department) {
            const departmentOwner = findDepartmentOwner(
              department,
              reducerState,
              params.value
            );
            return (
              <div
                style={{
                  display: 'flex',
                  // justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {departmentOwner && (
                  <OwnerImage
                    owner={departmentOwner}
                    // isLocked={!adminAccess}
                    size={32}
                  />
                )}
              </div>
            );
          } else {
            return <div></div>;
          }
        },
        sortable: true,
        sortComparator: (v1, v2) => {
          const owner1 = findDepartmentOwner(department, reducerState, v1);
          const owner2 = findDepartmentOwner(department, reducerState, v2);
          return owner1?.name.localeCompare(owner2?.name || '') || 0;
        },
        filterable: false,
      },
      {
        field: 'doses',
        flex: 1,
        renderHeader: () => (
          <div style={{ fontWeight: 'bold' }}>
            Doses{totalDoses !== -1 ? ' (' + totalDoses + ')' : ''}
          </div>
        ),
        renderCell: (params) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>{params.value.count}</span>
            <span>
              <FaChevronRight
                className="icon-normal "
                style={{ margin: '4px' }}
              />
            </span>
          </div>
        ),
        sortable: true,
        sortComparator: (v1, v2) => v1.count - v2.count,
        filterable: false,
      },
    ];

    return cols;
  }, [
    department,
    database.users,
    list,
    itemDeactivationStatus,
    selectedItems,
    mainCheckbox,
  ]);

  const rows = useMemo(() => {
    return list.map((item: MedicationItem) => {
      let username = item.modifiedBy
        ? item.modifiedBy.firstName + ' ' + item.modifiedBy.lastName
        : 'Hinckley Medical';

      const isOwner = item.departmentID === department.id ?? false;

      return {
        id: item.uid,
        input: item,
        name: {
          name: item.name,
          status: item.status,
          isOwner: isOwner,
        },
        concentration: item.loadingConcentrations
          ? undefined
          : getConcentrationsArrayString(item.concentrations),
        modified_date: getFormattedDate(
          item?.model?.updatedAt ? item?.model?.updatedAt : new Date(),
          true
        ),
        owner: item,
        modified_by: username,
        doses: {
          count: item.subItems.length,
        },
      };
    });
  }, [list, department]);

  const handleSelectionChange = (protocol: MedicationItem) => {
    if (selectedItems.find((item) => item.uid === protocol.uid)) {
      setSelectedItems((prevItems: MedicationItem[]) => {
        const updatedItems = prevItems.filter(
          (item: MedicationItem) => item.uid !== protocol.uid
        );

        if (updatedItems.length === 0) {
          setMainCheckbox(false);
        }
        return updatedItems;
      });
    } else {
      let items = [...selectedItems, protocol];
      let available = medications.filter(
        (item) =>
          item.status !== 'DEACTIVATED' && department.id === item.departmentID
      );
      setSelectedItems(items);
      if (items.length === available.length) {
        setMainCheckbox(true);
      } else if (mainCheckbox) {
        setMainCheckbox(false);
      }
    }
  };

  const deleteNextMedication = async () => {
    try {
      // If there are no items left, exit the function
      if (selectedItems.length === 0) {
        setIsDelete(false);
        return;
      }

      // Get the current medication to delete (the first item in the array)
      const currentMedication = selectedItems[0];
      const isDraft =
        currentMedication.status === ProgressStatus.DRAFT &&
        currentMedication.activeID == null;

      // Delete the medication
      let response = await deleteMedication(currentMedication, !isDraft);
      if (response.type === ResponseType.Success) {
        setSnackbarMessage(
          `Successfully deleted medication: ${currentMedication.name}`
        );
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        // Remove the successfully deleted medication from the selectedItems array
        setSelectedItems((prevItems: MedicationItem[]) => {
          const updatedItems = prevItems.filter(
            (item: MedicationItem) => item.uid !== currentMedication.uid
          );
          // If no items are left after deletion, close the modal
          if (updatedItems.length === 0) {
            setIsDelete(false);
            setMainCheckbox(false);
          }
          return updatedItems;
        });
      } else {
        setSnackbarMessage('Failed to delete medication');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }

      // Reload the database and check if there are any items left
      reloadDatabase();
    } catch (error) {
      setSnackbarMessage('Failed to delete medication');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleConfirmDeleteMedications = async () => {
    if (allCheckedBtn) {
      try {
        // Initialize counters for success and failure
        let successCount = 0;
        let failureCount = 0;

        for (const parmMedication of selectedItems) {
          const isDraft =
            parmMedication.status === ProgressStatus.DRAFT &&
            parmMedication.activeID == null;
          let response = await deleteMedication(parmMedication, !isDraft);
          if (response.type === ResponseType.Success) {
            successCount++;
          } else {
            failureCount++;
          }
        }

        // Set snackbar message based on the results
        if (successCount > 0) {
          setSnackbarMessage(
            `Successfully deleted ${successCount} medications.`
          );
          setSnackbarSeverity('success');
        }
        if (failureCount > 0) {
          setSnackbarMessage(`Failed to delete ${failureCount} medications.`);
          setSnackbarSeverity('error');
        }
        if (successCount === selectedItems.length) {
          setMainCheckbox(false);
        }

        setSnackbarOpen(true); // Open snackbar after all deletions
        // Clear selectedItems after all deletions
        setSelectedItems([]);
        reloadDatabase();
      } catch (error) {
        setSnackbarMessage('Failed to delete medication');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
      setIsDelete(false); // Close modal after all deletions
    } else {
      deleteNextMedication(); // Call the refactored function
    }
  };
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleToggleEnabledUI = async (item: MedicationItem) => {
    let find = itemDeactivationStatus.find(
      (newItem) => newItem.uid === item.uid
    );
    if (find)
      setItemDeactivationStatus(
        itemDeactivationStatus.filter((newItem) => newItem.uid !== item.uid)
      );
    else setItemDeactivationStatus([...itemDeactivationStatus, item]);
  };

  const getRowClassName = useCallback(
    (params: any) => {
      const rowItem = list.find((item) => item.uid === params.id);
      const isSelected = selectedItems.some((item) => item.uid === params.id);
      const adminAccess = hasAdminUserAccess(
        department,
        reducerState,
        user,
        rowItem
      );

      // If the row is selected, give it the selected class.
      if (isSelected) {
        return 'selectedRow';
      }
      // Apply background color when adminAccess is false or the item status is not DEACTIVATED.
      if (!(adminAccess && rowItem?.status !== 'DEACTIVATED')) {
        return 'inactiveRow';
      }

      // Default return for other cases
      return 'white';
    },
    [department, list, selectedItems, user, reducerState]
  );

  return (
    <div className="screen-container">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isDelete && (
        <ConfirmModal
          isVisible={isDelete}
          title={'Delete Medication?'}
          handleClose={() => {
            setIsDelete(false);
            setAllCheckedBtn(false);
          }}
          handleSubmit={handleConfirmDeleteMedications}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Delete"
          primaryDescription={
            allCheckedBtn
              ? `Are you sure you would like to delete ${selectedItems.length} medications?`
              : `Are you sure you would like to delete ${selectedItems[0].name}?`
          }
          isSelectAllBtn={selectedItems.length > 1}
          handleCheckAll={(check: any) => {
            setAllCheckedBtn(check);
          }}
        />
      )}
      {isOpen && (
        <DraftSaveModal
          database={database}
          isVisible={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
          handleContinue={() => {
            setIsOpen(false);
          }}
          handleNew={() => {
            setIsConfirmModal(true);
          }}
        />
      )}
      {isConfirmModal && (
        <ConfirmModal
          isVisible={isConfirmModal}
          title="Make New Draft?"
          handleClose={() => {
            setIsConfirmModal(false);
          }}
          handleSubmit={handleMakeNew}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Make New"
          primaryDescription="Are you sure you would like to remove the previous draft and override it and create a new one? THIS IS NOT REVERSIBLE."
        />
      )}

      <NewMedicationSideout
        type="medication"
        isVisible={isCreateMedication}
        handleClose={() => setIsCreateMedication(false)}
      />
      <ProtocolHeader
        // homeScreen={true}
        isBackButton={true}
        handleCancel={handleBack}
        name={
          selectedItems.length === 0
            ? 'Medications: ' + list.length + ' items'
            : 'Medications: ' +
              selectedItems.length +
              ' / ' +
              list.length +
              ' items'
        }
        isSaveButton={itemDeactivationStatus.length > 0}
        isSaveActive={itemDeactivationStatus.length > 0}
        page={department.name}
        rightSideBtn={'edit'}
        isEditIcon={false}
        handleSave={() => handleSave()}
        handleCancelEdit={() => {
          console.log('Cancel Edit');
          setItemDeactivationStatus([]);
          setMedications([...medications]);
          handleFilterChange([...medications]);
        }}
        isCreateButton={adminLevel > 2 && adminAccess}
        isCreateActive={true}
        handleCreate={handleCreateItem}
        handleEdit={() => {
          handleCheckIsDraft();
        }}
        isDeleteButton={selectedItems.length > 0}
        isDeleteDisabled={selectedItems.length === 0}
        handleDelete={() => setIsDelete(true)}
        type={'protocol'}
      />
      <Row
        style={{
          marginBottom: '10px',
        }}
      >
        <Col sm={6}>
          <SearchBar
            containerStyle={{ width: '60%' }}
            value={searchQuery}
            onChange={(searchTerm: string) => {
              setSearchQuery(searchTerm);
            }}
            onSubmit={(searchTerm: string) => {}}
            placeholder={'Search Medications...'}
          />
        </Col>
        <Col sm={6}>
          {allDepartmentOwners.length > 1 && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                margin: '0px 10px',
              }}
            >
              <ToggleButtonGroup
                value={viewing}
                exclusive
                onChange={(
                  event: React.MouseEvent<HTMLElement>,
                  newAlignment: string | null
                ) => setViewing(newAlignment)}
                aria-label="owner"
                sx={{
                  padding: '0px',
                }}
              >
                <Tooltip title={'View All Protocols'} placement="bottom" arrow>
                  <ToggleButton
                    value="all"
                    aria-label="all"
                    autoCapitalize="none"
                    sx={{
                      padding: '0px 8px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                    onChange={() => {
                      setViewing('all');
                      setFilters([]);
                    }}
                  >
                    All
                  </ToggleButton>
                </Tooltip>
                {allDepartmentOwners.map((owner: DepartmentItem) => (
                  <ToggleButton
                    value={owner.id}
                    aria-label={owner.name}
                    autoCapitalize="none"
                    onChange={() => {
                      setViewing(owner.id);
                      setFilters([owner]);
                    }}
                    sx={{
                      padding: '4px', // Adjust padding as needed for the "All" button
                      minWidth: 'auto', // Ensures the width is only as wide as the content
                    }}
                  >
                    <Tooltip title={owner.name} placement="bottom" arrow>
                      <img
                        className="no-select owner-logo"
                        src={owner.logoVerifiedUrl ?? ''}
                        alt="Agency Logo"
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </Tooltip>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </div>
          )}

          {/* <MultiSelectDropdown<CategoryItem>
            title={'Categories'}
            options={categories}
            initialSelectedItems={categoriesFilter}
            labelField={(option: CategoryItem) => option.name}
            keyField={(option: CategoryItem) => option.uid}
            onSelected={(selected: CategoryItem[]) => {
              setCategoriesFilter(selected);
            }}
          />
          <MultiSelectDropdown<string>
            style={{ margin: '0px 10px' }}
            title={'Filters'}
            options={filterOptions}
            initialSelectedItems={filters}
            labelField={(option: string) => option}
            keyField={(option: string) => option}
            onSelected={(selected: string[]) => {
              setFilters(selected);
            }}
          /> */}

          {/* <Col sm={1}>
                    <MultiSelectDropdown<string>
                        title={'Filters'}
                        options={filterOptions}
                        initialSelectedItems={filters}
                        labelField={(option: string) => option}
                        keyField={(option: string) => option}
                        onSelected={(selected: string[]) => {
                            setFilters(selected);
                        }}
                    />
                </Col>
                <Col sm={1}>
                    <MultiSelectDropdown<CategoryItem>
                        title={'Categories'}
                        options={categories}
                        initialSelectedItems={categoriesFilter}
                        labelField={(option: CategoryItem) => option.name}
                        keyField={(option: CategoryItem) => option.uid}
                        onSelected={(selected: CategoryItem[]) => {
                            setCategoriesFilter(selected);
                        }}
                    />
                </Col> */}
        </Col>
      </Row>

      <Paper>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          pageSizeOptions={[50, 100, 200]}
          // checkboxSelection
          onRowClick={(params) =>
            handleItemClick(list.find((item) => item.uid === params.id))
          }
          // onRowSelectionModelChange={handleSelectionChange}
          // rowSelectionModel={selectedItems.map(
          //   (item: MedicationItem) => item.uid
          // )}
          getRowClassName={getRowClassName}
          sx={{
            '& .MuiDataGrid-footerContainer p': {
              margin: 0,
            },
            '& .MuiCheckbox-root': {
              '&.Mui-checked': {
                color: '#00534C',
              },
              '&.MuiCheckbox-indeterminate': {
                color: '#00534C',
              },
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
              backgroundColor: '#E0EADD',
            },
            '& .MuiDataGrid-cell': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },

            '& .selectedRow': {
              backgroundColor: '#E0EADD !important',
            },

            '& .inactiveRow': {
              backgroundColor: '#f2f2f2',
            },
          }}
        />
      </Paper>
    </div>
  );
};

export default ListMedicaions;
