import { DataStore } from '@aws-amplify/datastore';
import { API, graphqlOperation, Hub, syncExpression } from 'aws-amplify';
import {
  Ambulance,
  Category,
  Department,
  Drip,
  Equipment,
  Medication,
  Protocol,
  Vitals,
  Form,
  User,
  Contact,
  Workbook,
  WeightObject,
  Keychain,
  MedicationDose,
  InfusionDose,
  ElectricalDose,
  Concentration,
  FormLog,
  InputForm,
  CPRAssist,
  ElectricalShock,
  UserStatus,
} from '../models';
import MedicationItem from './model/MedicationItem';
import CategoryItem from './model/CategoryItem';
import ProtocolItem from './model/ProtocolItem';
import EquipmentItem from './model/EquipmentItem';
import ElectricalItem from './model/ElectricalItem';
import ContactItem from './model/ContactItem';
import FormItem from './model/FormItem';
import VitalItem from './model/VitalItem';
import ElectricalSubItem from './model/ElectricalSubItem';
import MedicationSubItem from './model/MedicationSubItem';
import LogItem from './model/LogItem';
import { Storage } from '@aws-amplify/storage';
import DepartmentItem from './model/DepartmentItem';
import NotificationItem from './model/NotificationItem';
import {
  getActiveID,
  getProgressStatus,
  globals,
} from '../ui/_global/common/Utils';
import KeychainItem from './model/KeychainItem';
import { fetchUsers, getUserByCognitoID } from './functions/UserDB';
import { getDepartmentByID } from './functions/DepartmentDB';
import {
  handleGetDepartment,
  handlePairedDepartments,
  handleUserDepartments,
} from '../store/actions';
import { Dispatch } from 'react';
import InfusionItem from './model/InfusionItem';
import InfusionSubItem from './model/InfusionSubItem';
import ModelItem from './model/ModelItem';
import GroupItem from './model/GroupItem';
import { SoftwareType } from '../models';
import * as queries from '../graphql/queries';

import AWS, { CognitoIdentityServiceProvider } from 'aws-sdk';
import { fetchVitals } from './functions/VitalDB';
import { fetchKeychains } from './functions/KeychainDB';
import { fetchContacts } from './functions/ContactDB';
import { fetchWeightObjects } from './functions/WeightObjectDB';
import {
  fetchElectrical,
  fetchElectricalDoses,
} from './functions/ElectricalDB';
import { fetchDrips, fetchInfusionDoses } from './functions/InfusionDB';
import {
  fetchMedicationDoses,
  fetchMedications,
} from './functions/MedicationDB';
import { fetchEquipment } from './functions/EquipmentDB';
import CPRItem from './model/CPRItem';
import { fetchCPRAssists } from './functions/CprDB';
import { fetchChecklists } from './functions/CheckListDB';
import ModelSubItem from './model/ModelSubItem';
import { fetchAmbulances } from './functions/AmbulanceDB';
import {
  CreateDepartmentInput,
  CreateRequireSyncInput,
  OneWeight,
  RequireSync,
} from '../API';
import { departmentsByParentDepID, getDepartment } from './QueryTypes';
import { createDepartment, createRequireSync } from '../graphql/mutations';
import {
  findUsersForDatabase,
  findUsersForModelItems,
} from './functions/ModelDB';

AWS.config.update({
  region: 'us-east-2',
  accessKeyId: process.env.REACT_APP_AI_PARSER_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AI_PARSER_SECRET_KEY,
}); // Replace 'us-west-2' with your Cognito region
const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider();

const UserPoolId =
  process.env.REACT_APP_USER_POOL_ID ??
  (function () {
    throw new Error('API URL is not defined');
  })();

let topParentDepID: string | null | undefined = undefined;
let curDepartmentID: string | null | undefined = undefined;
let parentDepIDs: string[] = [''];

DataStore.configure({
  syncExpressions: [
    syncExpression(Department, async () => {
      if (topParentDepID != null) {
        return (d) =>
          d.or((d) => [
            d.indexedParentDepID.eq(topParentDepID as string),
            d.id.eq(topParentDepID as string),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Keychain, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          // d.or((d) => [
          d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id)));
        // d.parentDepID.eq(departmentID as string),
        // ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Contact, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          // d.or((d) => [
          d.or((d) => [
            d.departmentID.eq(curDepartmentID as string),
            ...parentDepIDs.map((id) => d.departmentID.eq(id)),
          ]);
        // d.parentDepID.eq(departmentID as string),
        // ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(InputForm, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id)));
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Drip, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(InfusionDose, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Equipment, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Medication, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        // let arr =
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(MedicationDose, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Protocol, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Ambulance, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.or((d) => [
            d.departmentID.eq(curDepartmentID as string),
            ...parentDepIDs.map((id) => d.departmentID.eq(id)),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Category, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(ElectricalShock, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(ElectricalDose, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Vitals, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Form, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Workbook, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(WeightObject, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id)));
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(Concentration, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(CPRAssist, async () => {
      // if (departmentID === undefined) await getLocalDepID();
      if (curDepartmentID) {
        return (d) =>
          d.and((d) => [
            d.or((d) => parentDepIDs.map((id) => d.departmentID.eq(id))),
            d.status.ne('ARCHIVE'),
          ]);
      }
      return (d) => d.id.ne('');
    }),
    syncExpression(User, async () => {
      // const department = getLocalDepartment();
      if (globals.debug)
        console.log('SYNCING USERS topParentDepID', topParentDepID);
      if (topParentDepID != null && topParentDepID !== '')
        return (f) =>
          f.or((f) => [
            f.pairedDepIDs.contains(curDepartmentID),
            f.departmentID.eq(curDepartmentID as string),
            f.indexedParentDepID.eq(topParentDepID as string),
          ]);
      return (d) => d.id.eq('');
    }),
  ],
});

function getLocalDepartment() {
  let result = localStorage.getItem('loggedInDepartment');
  if (result) {
    let dep = JSON.parse(result);
    curDepartmentID = dep.id;
    parentDepIDs = [dep.id];
    if (dep.parentDepID) parentDepIDs.push(dep.parentDepID);
    return {
      department: dep,
      parentDepIDs: parentDepIDs,
    };
  }
}

export enum ResponseType {
  Success,
  Failure,
  Warning,
  Info,
}

export type Response = {
  type: ResponseType;
  data: any;
};

export type DatabaseResponse = {
  department: DepartmentItem;
  subDepsMap: Map<string, DepartmentItem[]>;
  allSubDepsMap: Map<string, DepartmentItem[]>;
  cprModel: CPRItem | undefined;
  users: User[];
  categories: CategoryItem[];
  protocols: ProtocolItem[];
  ambulances: Ambulance[];
  oneWeights: OneWeight[];
  medications: MedicationItem[];
  medicationDoses: MedicationSubItem[];
  infusions: InfusionItem[];
  infusionDoses: InfusionSubItem[];
  equipment: EquipmentItem[];
  electrical: ElectricalItem[];
  electricalDoses: ElectricalSubItem[];
  checklists: FormItem[];
  vitals: VitalItem[];
  logs: LogItem[];
  notifications: NotificationItem[];
  contacts: ContactItem[];
  weightObjects: WeightObject[];
  keychains: KeychainItem[];
  groups: GroupItem[];
};

/**
 * This function returns the users information from the subID from AWS Cognito.
 * @param subID The subID of the user to fetch information for.
 * @returns @type Response data -> 0 - user information, 1 - department information
 */
export const getUserInformation = async (
  subID: string,
  username: string,
  isInitialSignIn: boolean = false,
  dispatch?: Dispatch<any>
): Promise<Response> => {
  try {
    let user: User | null = null;
    user = await executeSingleQuery(
      queries.usersByCognitoID,
      {
        cognitoID: username,
      },
      globals.maxDatabaseDelayMS
    );

    if (user == null) {
      console.error(
        'Error fetching user information: No user found for subID:',
        subID,
        'or username:',
        username
      );
      return { type: ResponseType.Failure, data: 'No user found' };
    }

    let depID: string | null = null;
    let depCache: any = localStorage.getItem('loggedInDepartment');
    if (depCache) {
      depCache = JSON.parse(depCache);
      depID = depCache.id;
    } else if (user.pairedDepIDs && user.pairedDepIDs.length > 0)
      depID = user.pairedDepIDs[0];
    else depID = user.departmentID;

    if (depID == null) {
      console.error('Error fetching user information: No department found');
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }

    /* Validate the department ID is in the pairedDepIDs array */
    if (user.pairedDepIDs && !user.pairedDepIDs.includes(depID)) {
      depID = user.pairedDepIDs.length > 0 ? user.pairedDepIDs[0] : depID;
    }

    let departmentModel: Department | null = null;
    departmentModel = await executeSingleQuery(
      getDepartment,
      {
        id: depID as string,
      },
      globals.maxDatabaseDelayMS
    );

    if (departmentModel == null) {
      console.error('Error fetching user information: No department found');
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }

    let department = new DepartmentItem(departmentModel);
    await department.checkParentDep(false);
    department.calculateAdminLevel(user);

    parentDepIDs = [department.id];
    if (department.parentDepID) parentDepIDs.push(department.parentDepID);
    curDepartmentID = department.id;
    topParentDepID = department.getTopLevelDep().id;
    localStorage.setItem(
      'loggedInDepartment',
      JSON.stringify({
        id: department.id,
        parentDepID: department.parentDepID,
      })
    );

    if (isInitialSignIn) {
      await timeoutPromise(DataStore.start(), 2000).catch((e) => {
        console.error('Error starting DataStore:', e);
      });
      const isSynced = await waitForSyncQueriesReady().catch((e) => {
        console.error('Error waiting for sync queries:', e);
        console.warn(
          'DataStore syncing issue detected. Proceeding to fetch user information and will resolve syncing in background.'
        );
        handleSyncingInBackground();
      });
      if (!isSynced) {
        console.warn(
          'DataStore syncing issue detected. Proceeding to fetch user information and will resolve syncing in background.'
        );
        handleSyncingInBackground();
      }
    }
    await department.checkParentDep();

    let subDepsMap = new Map<string, DepartmentItem[]>();
    let allSubDepsMap = new Map<string, DepartmentItem[]>();
    await checkSubDeps(
      department.getTopLevelDep(),
      subDepsMap,
      allSubDepsMap,
      user,
      dispatch,
      undefined,
      true,
      true
    ).then((resp) => {
      return resp.allSubDeps;
    });

    let logoResult = await findDepartmentLogo(department);
    if (logoResult.type === ResponseType.Success)
      department.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    return {
      type: ResponseType.Success,
      data: [user, department, subDepsMap, allSubDepsMap],
    };
  } catch (error) {
    console.error('Error fetching user information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const checkSubDeps = async (
  department: DepartmentItem,
  subDepsMap: Map<string, DepartmentItem[]>,
  allSubDepsMap: Map<string, DepartmentItem[]>,
  user?: User,
  dispatch?: Dispatch<any>,
  departments?: Department[],
  useDataStore: boolean = false,
  waitForLogos: boolean = false
): Promise<{
  subDeps: DepartmentItem[];
  allSubDeps: DepartmentItem[];
}> => {
  return new Promise(async (resolve, reject) => {
    if (departments != null && departments.length === 0) {
      allSubDepsMap.set(department.id, []);
      subDepsMap.set(department.id, []);
      return resolve({ subDeps: [], allSubDeps: [] });
    }
    const loadAllDepLogos = async (
      deps: DepartmentItem[]
    ): Promise<DepartmentItem[]> => {
      return new Promise(async (resolve, reject) => {
        try {
          let logoPromises: Promise<void>[] = [];
          for (let i = 0; i < deps.length; i++) {
            logoPromises.push(
              findDepartmentLogo(deps[i]).then((resp) => {
                if (resp.type === ResponseType.Success)
                  deps[i].logoVerifiedUrl = resp.data;
              })
            );
            if (logoPromises.length > globals.QUERY_BATCH_SIZE) {
              await Promise.all(logoPromises);
              logoPromises = [];
            }
          }
          await Promise.all(logoPromises);
          resolve(deps);
        } catch (error) {
          console.log('Error loading department logos:', error);
          reject(error);
        }
      });
    };

    let id = department.getTopLevelDep().id;

    let allDeps: DepartmentItem[] = [];
    let subDeps: DepartmentItem[] = [];
    let respDeps: Department[] = departments ?? [];
    try {
      if (departments == null) {
        if (useDataStore) {
          respDeps = await DataStore.query(Department, (d) =>
            d.and((d) => [d.indexedParentDepID.eq(id), d.activeStatus.eq(true)])
          );
        } else {
          respDeps = await executeQuery(
            departmentsByParentDepID,
            {
              indexedParentDepID: id,
              filter: {
                and: [
                  {
                    activeStatus: {
                      eq: true,
                    },
                  },
                  {
                    _deleted: {
                      ne: true,
                    },
                  },
                ],
              },
            },
            15000,
            undefined,
            undefined,
            undefined,
            true,
            undefined,
            50
          );
        }
      }

      if (department.parentDep) department.isMultiDep = true;

      /* 12-17-24 Hazlett:
       *   - Now we need to map the departments in hierarchy
       *   - This now returns all the departments in the hierarchy at once
       *   - We need to map the departments to the correct parent department using parentDepID
       *   - Assume that this is the top level department
       */
      let childDeps = respDeps.filter((d) => d.parentDepID === department.id);
      let remainingDeps = respDeps.filter(
        (d) => d.parentDepID !== department.id
      );

      let childPromises: Promise<DepartmentItem[]>[] = [];
      for (let i = 0; i < childDeps.length; i++) {
        let subDep = childDeps[i];
        let subDepItem = new DepartmentItem(subDep);
        subDeps.push(subDepItem);
        allDeps.push(subDepItem);
        subDepItem.parentDep = department;
        childPromises.push(
          checkSubDeps(
            subDepItem,
            subDepsMap,
            allSubDepsMap,
            user,
            dispatch,
            remainingDeps,
            useDataStore,
            waitForLogos
          ).then((resp) => {
            return resp.subDeps;
          })
        );
        if (childPromises.length > globals.QUERY_BATCH_SIZE) {
          let responses = await Promise.all(childPromises);
          let deps = responses.flat();
          allDeps = allDeps.concat(deps);
          childPromises = [];
        }
        // if (deps) allDeps = allDeps.concat(deps);
        // let deps = await subDepItem.checkSubDeps(remainingDeps, useDataStore);
        // if (deps) allDeps = allDeps.concat(deps);
      }
      let responses = await Promise.all(childPromises);
      let deps = responses.flat();
      allDeps = allDeps.concat(deps);

      if (waitForLogos) await loadAllDepLogos(subDeps);
      else loadAllDepLogos(subDeps);

      // department.subDeps = subDeps
      //   .filter(
      //     (dep, index, self) =>
      //       self.findIndex((t) => t.id === dep.id) === index &&
      //       dep.id !== department.id
      //   )
      //   .sort((a, b) => a.name.localeCompare(b.name));

      // department.allSubDeps = allDeps
      //   .filter(
      //     (dep, index, self) =>
      //       self.findIndex((t) => t.id === dep.id) === index &&
      //       dep.id !== department.id
      //   )
      //   .sort((a, b) => a.name.localeCompare(b.name));

      if (departments == null) {
        let userDepartments: DepartmentItem[] | undefined;
        if (user != null)
          userDepartments = fetchUserDepartments(department, allDeps, user);

        subDepsMap.set(department.id, subDeps);
        allSubDepsMap.set(department.id, allDeps);
        dispatch &&
          dispatch(
            handlePairedDepartments(
              department,
              subDeps,
              allDeps,
              userDepartments
            )
          );
      } else {
        dispatch &&
          dispatch(handlePairedDepartments(department, subDeps, allDeps));
        subDepsMap.set(department.id, subDeps);
        allSubDepsMap.set(department.id, allDeps);
      }

      if (subDeps && subDeps.length > 0) {
        department.isMultiDep = true;
        if (globals.debug)
          console.log(
            department.name,
            'SubDeps:',
            subDeps,
            'isMultiDep:',
            department.isMultiDep
          );
      }
      resolve({ subDeps, allSubDeps: allDeps });
    } catch (error) {
      console.log('Error checking sub-departments ( checkSubDeps ) : ', error);
      reject(error);
    }
  });
};

/**
 * Fetch the user's departments
 * @param department The department to fetch the user's departments for
 * @param departments The list of all departments
 * @param user The user to fetch the departments for
 * @returns The list of user's departments
 */
const fetchUserDepartments = (
  department: DepartmentItem,
  departments: DepartmentItem[],
  user: User
): DepartmentItem[] => {
  // if (department.parentDep) {
  //   return fetchUserDepartments(department.parentDep, departments, user);
  // }

  let ids = [...new Set(user.pairedDepIDs ?? [])];

  let userDepartments: DepartmentItem[] = [];
  for (let id of ids) {
    if (id === department.id) {
      userDepartments.push(department);
    } else {
      let find = departments.find((d) => d.id === id);
      if (find) userDepartments.push(find);
    }
  }
  userDepartments.sort((a, b) => a.name.localeCompare(b.name));
  return userDepartments;
};

export const getDepartmentByCode = async (code: string): Promise<Response> => {
  try {
    let department = await DataStore.query(Department, (d) =>
      d.uniqueCode.eq(code)
    );
    if (department.length === 0) {
      console.error(
        'Error fetching department information: No department found'
      );
      return {
        type: ResponseType.Failure,
        data: 'No department found',
      };
    }
    let dep = new DepartmentItem(department[0]);
    let logoResult = await findDepartmentLogo(dep);
    if (logoResult.type === ResponseType.Success)
      dep.logoVerifiedUrl = logoResult.data;
    else console.error('Error fetching department logo:', logoResult.data);

    if (dep.subDepIDs.length > 0) {
      /* Get the sub departments & the last selected department */
      await getSubDepartments(dep, 'force');

      let id = localStorage.getItem('lastSelectedDepID');
      // if (id) {
      //   let lastSelectedDep = dep.subDeps?.find((d) => d.id === id);
      //   if (lastSelectedDep) dep.activeSubDep = lastSelectedDep;
      //   else dep.activeSubDep = dep.subDeps?.[0];
      // } else dep.activeSubDep = dep.subDeps?.[0];
    }

    return {
      type: ResponseType.Success,
      data: dep,
    };
  } catch (error) {
    console.error('Error fetching department information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

const waitForSyncQueriesReady = () => {
  return new Promise((resolve, reject) => {
    let timeoutId: any;
    const listener = Hub.listen('datastore', (hubData) => {
      const { event, data } = hubData.payload;
      if (event === 'syncQueriesReady') {
        clearTimeout(timeoutId);
        listener();
        return resolve('Success');
      } else if (event === 'subscriptionError') {
        clearTimeout(timeoutId);
        listener();
        return reject(data);
      }
    });
    timeoutId = setTimeout(() => {
      listener();
      reject(new Error('Timeout waiting for syncQueriesReady'));
    }, 10000);
  });
};

const handleSyncingInBackground = async () => {
  return new Promise((resolve, reject) => {
    if (globals.debug)
      if (globals.debug) console.log('Handling syncing in background');
    let attempt = 0;
    const maxAttempts = 5;

    const trySync = async () => {
      try {
        await DataStore.start();
        const isSynced = await waitForSyncQueriesReady();
        if (isSynced) {
          if (globals.debug)
            console.log('DataStore successfully synced in background');
          return resolve(true);
        } else {
          reject('DataStore failed to sync in background');
        }
      } catch (error) {
        console.error('Background syncing attempt failed:', error);
        if (attempt < maxAttempts) {
          attempt++;
          if (globals.debug)
            console.log(`Retrying sync in background, attempt ${attempt}`);
          setTimeout(trySync, 5000 * attempt);
        }
      }
    };
    trySync();
  });
};

export const findDepartmentLogo = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    if (department.logoURL == null) {
      return {
        type: ResponseType.Failure,
        data: 'Department URL is null',
      };
    }

    /* Check if the logo is stored in local storage */
    let result: any = localStorage.getItem(department.logoURL);
    if (result)
      return {
        type: ResponseType.Success,
        data: result,
      };

    /* If not, fetch it from S3 */
    result = await Storage.get(department.logoURL, {
      level: 'public',
      download: true,
    });
    const base64String = await convertBlobToBase64(result.Body);
    localStorage.setItem(department.logoURL, base64String);

    department.logoVerifiedUrl = base64String;

    return {
      type: ResponseType.Success,
      data: base64String,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Convert a blob to a base64 string
 * @param blob The blob to convert to base64
 * @returns A promise with the base64 string or ArrayBuffer
 */
const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      if (reader.result) resolve(reader.result as string);
      else reject('Error converting blob to base64');
    };
    reader.readAsDataURL(blob);
  });
};

/**
 * Load the sub departments for the department
 * @param department The department to fetch the sub departments for
 * @returns Response.Success -> DepartmentModel with sub departments, Response.Error -> Error message
 */
export const getSubDepartments = async (
  department: DepartmentItem,
  fetchLogo?: 'force' | 'lazy'
): Promise<Response> => {
  try {
    /* Stops the DataStore Sync Process */
    // await DataStore.stop();
    for (let i = 0; i < department.subDepIDs.length; i++) {
      let id = department.subDepIDs[i];
      const depModel = await DataStore.query(Department, id);
      if (depModel == null) {
        if (globals.debug)
          if (globals.debug)
            console.log(
              'Error fetching sub departments: No sub department found -> id:',
              id
            );
        continue;
      }
      // else if (depModel.activeStatus == null || depModel.activeStatus) {
      let dep = new DepartmentItem(depModel);
      if (fetchLogo === 'force') {
        let logoResult = await findDepartmentLogo(dep);
        if (logoResult.type === ResponseType.Success)
          dep.logoVerifiedUrl = logoResult.data;
      } else if (fetchLogo === 'lazy') {
        findDepartmentLogo(dep).then((logoResult: Response) => {
          if (logoResult.type === ResponseType.Success)
            dep.logoVerifiedUrl = logoResult.data;
        });
      }
      // department.addSubDep(dep);
      if (globals.debug) {
        console.log('Sub Department:', dep.name, dep.id);
      }
      // }
    }

    return {
      type: ResponseType.Success,
      data: department,
    };
  } catch (error) {
    console.error('Error fetching user information:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

/**
 * Create a department in the database
 * @param name The name of the department
 * @param location The location of the department
 * @param versions The protocol versions of the department
 * @param cognitoID The cognito ID of the user creating the department
 * @returns A response object with the type and data
 *      - SUCCESS - The department was created and returned in data
 *      - FAILURE - The department was not created and the error is returned in data
 */
export const createDepartmentItem = async (
  department: any
): Promise<Response> => {
  try {
    // let sync = new RequireSync({
    //   sync: false,
    // });
    // const sync: RequireSync | null = await executeSingleQuery(
    //   createRequireSync,
    //   {
    //     input: {
    //       sync: false,
    //     } as CreateRequireSyncInput,
    //   }
    // );
    // console.log('SYNC', sync);
    let json = {
      name: department.name,
      location: department.location,
      protocolVersions: 'v1.0.0',
      shiftTypes: [],
      cognitoID: 'n' /* TODO Remove this */,
      logoID:
        department.logoID != null
          ? department.logoID
          : department.parentDep?.logoURL || 'n',
      userID: 'n' /* TODO Remove this */,
      neonateCutoff: 5 /* TODO Remove this */,
      pediatricCutoff: 40 /* TODO Remove this */,
      calculators: department.parentDep?.calculators ?? [],
      adultRanges: [],
      softwarePlan: department.parentDep?.softwarePlan ?? SoftwareType.PREMIUM, //PREMIUM is the default
      subDepIDs: undefined /* TODO Remove this */,
      parentDepID:
        department.parentDep != null ? department.parentDep.id : undefined,
      indexedParentDepID:
        department.indexedParentDepID != null
          ? department.indexedParentDepID
          : department.parentDep != null
            ? department.parentDep.id
            : 'ROOT',
      activeStatus: true,

      agencyNumEMS: new Date().getTime().toString(),
      stateIdEMS: undefined,
      gnisCodeEMS: undefined,

      uniqueCode: department.uniqueCode,
      uniquePublicURL:
        department.uniquePublicURL != null
          ? department.uniquePublicURL
          : 'URL_NOT_SET',
      hashedPin: department.hashedPin,
      saltedPin: department.saltedPin,

      keychainID: undefined,

      isPublic:
        department.isPublic === true
          ? department.isPublic
          : (department.parentDep?.isPublic ?? false),
      infusionCalculation: department.parentDep?.infusionCalculation ?? false, //Default is false
      isPublicSignup:
        department.isPublicSignup === true
          ? department.isPublicSignup
          : (department.parentDep?.isPublicSignup ?? false),

      config: {
        isTopEnabled: true,
        neonateCutoff: 5,
        pediatricCutoff: 40,
        calculators: department.parentDep?.calculators ?? [],
        adultRanges: [],
        softwarePlan:
          department.parentDep?.softwarePlan ?? SoftwareType.PREMIUM,
        infusionCalculation: department.parentDep?.infusionCalculation ?? false,
        isPublic:
          department.isPublic === true
            ? department.isPublic
            : (department.parentDep?.isPublic ?? false),
        realTimeUpdating: department.parentDep?.realTimeUpdating ?? false,
        oneweightEnabled: department.parentDep?.oneweightEnabled ?? true,
        ageFilterEnabled: department.parentDep?.ageFilterEnabled ?? false,
        ageGroupFilterEnabled:
          department.parentDep?.ageGroupFilterEnabled ?? false,
        renewalDate: undefined,
        infusionGTTS: department.parentDep?.infusionGTTS ?? null,
        signedAgreement: false,
      },
    } as CreateDepartmentInput;
    //     let dep = new Department(
    // );
    // let rs = await DataStore.save(sync);
    const dep: Department | null = await executeSingleQuery(createDepartment, {
      input: json,
    });

    if (dep == null)
      return {
        type: ResponseType.Failure,
        data: 'Department not created',
      };

    return {
      type: ResponseType.Success,
      data: dep,
    };
  } catch (error) {
    console.error('Error creating department:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const addIDToDepartment = async (
  departmentID: string,
  subDepIDs: string[]
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    try {
      let dep = await DataStore.query(Department, departmentID);
      if (dep == null) {
        reject('Department not found');
        return;
      }
      let ids = [
        ...new Set(
          dep.subDepIDs ? [...dep.subDepIDs, ...subDepIDs] : subDepIDs
        ),
      ];
      let d = await DataStore.save(
        Department.copyOf(dep, (updated) => {
          updated.subDepIDs = ids;
        })
      );
      resolve({
        type: ResponseType.Success,
        data: d,
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const formatTimestamp = (timestamp: any) => {
  const date = new Date(timestamp);
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'short',
  }).format(date);
};

export const deleteDepartment = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const toDelete = await DataStore.query(Department, department.id);
    if (toDelete) {
      await DataStore.delete(toDelete);
      return {
        type: ResponseType.Success,
        data: null,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Department not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const editDepartment = async (department: any): Promise<Response> => {
  try {
    const toUpdate = await DataStore.query(Department, department.id);
    if (toUpdate) {
      let dep = await DataStore.save(
        Department.copyOf(toUpdate, (updated) => {
          updated.name = department.name;
          updated.location = department.location;
          updated.protocolVersions = department.protocolVersions;
          updated.shiftTypes = department.shiftTypes;
          // updated.cognitoID = department.cognitoID;
          updated.logoID = department.logoID;
        })
      );
      return {
        type: ResponseType.Success,
        data: dep,
      };
    }
    return {
      type: ResponseType.Failure,
      data: 'Department not found',
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const createNotification = async (
  notification: any
): Promise<Response> => {
  return {
    type: ResponseType.Success,
    data: null,
  };
  // try {
  // 	let n = await DataStore.save(
  // 		new Notification({
  // 			type: notification.type,
  // 			title: notification.title,
  // 			message: notification.message,
  // 			timestamp: new Date().toISOString(),
  // 			isReadIDs: [],
  // 			isAckIDs: [],
  // 			fileURLs: notification.fileURLs ? notification.fileURLs : [],
  // 			taggedProtocols: [],
  // 			questions: [],
  // 			departmentID: notification.departmentID,
  // 			createdBy: notification.createdBy,
  // 			modifiedBy: notification.createdBy,
  // 		})
  // 	);
  // 	if(globals.debug) console.log("Created Notification:", n);
  // 	let newNotification = new NotificationItem(n, []);
  // 	return {
  // 		type: ResponseType.Success,
  // 		data: newNotification,
  // 	};
  // } catch (e) {
  // 	return {
  // 		type: ResponseType.Failure,
  // 		data: e,
  // 	};
  // }
};

export const editNotification = async (
  notification: any
): Promise<Response> => {
  try {
    // let n = await DataStore.query(Notification, notification.id);
    // if (!n)
    //   return {
    //     type: ResponseType.Failure,
    //     data: 'Notification not found',
    //   };
    // let res = await DataStore.save(
    //   Notification.copyOf(n, (updated) => {
    //     updated.title = notification.title;
    //     updated.message = notification.message;
    //     updated.type = notification.type;
    //     updated.timestamp = new Date().toISOString();
    //     updated.modifiedBy = notification.modifiedBy;
    //   })
    // );
    // let newNotification = new NotificationItem(res, []);
    return {
      type: ResponseType.Success,
      data: notification,
    };
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * Get all departments in the database
 * @returns A response object with the type and data
 */
export const getDepartments = async (
  getLogos?: boolean,
  mapPairedDeps: boolean = true
): Promise<Response> => {
  try {
    let departments = await DataStore.query(Department);
    let deps: DepartmentItem[] = [];
    for (let i = 0; i < departments.length; i++) {
      let dep = new DepartmentItem(departments[i]);
      deps.push(dep);
      if (getLogos) {
        findDepartmentLogo(dep).then((logoResult) => {
          if (logoResult.type === ResponseType.Success)
            dep.logoVerifiedUrl = logoResult.data;
          else
            console.error('Error fetching department logo:', logoResult.data);
        });
      }
    }
    if (mapPairedDeps) mapPairedDepartments(deps);

    return {
      type: ResponseType.Success,
      data: deps,
    };
  } catch (error) {
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

// function mapPairedDepartments(departments: DepartmentItem[]): void {
//   let newDepartments: DepartmentItem[] = [];
//   for (let i = 0; i < departments.length; i++) {
//     let dep = departments[i];

//   }
// }
function mapPairedDepartments(departments: DepartmentItem[]): void {
  // // First pass: Create a map of department IDs to their objects
  // const depMap = new Map<string, DepartmentItem>();
  // departments.forEach((dep) => {
  //   depMap.set(dep.id, dep);
  // });
  // // Second pass: Link children to parents and remove children from root level
  // departments.forEach((dep) => {
  //   if (dep.parentDepID && depMap.has(dep.parentDepID)) {
  //     const parentDep = depMap.get(dep.parentDepID);
  //     if (parentDep) {
  //       // Initialize allSubDeps array if it doesn't exist
  //       if (!parentDep.allSubDeps) parentDep.allSubDeps = [];
  //       if (!parentDep.subDeps) parentDep.subDeps = [];
  //       // Add child to parent's allSubDeps array
  //       parentDep.allSubDeps.push(dep);
  //       parentDep.subDeps.push(dep);
  //       // // Mark this department for removal from root level
  //       // dep.isSubDepartment = true;
  //     }
  //   }
  // });
  // // Remove sub-departments from the root level array
  // for (let i = departments.length - 1; i >= 0; i--) {
  //   if (departments[i].parentDepID) departments.splice(i, 1);
  // }
  // // Sort allSubDeps arrays by name
  // departments.forEach((dep) => {
  //   if (dep.allSubDeps) {
  //     dep.allSubDeps.sort((a, b) => a.name.localeCompare(b.name));
  //   }
  // });
}

// export const fetchDepartmentUser = async (
//   department: Department
// ): Promise<Response> => {
//   try {
//     let user = await DataStore.query(User, department.userID);
//     return {
//       type: ResponseType.Success,
//       data: user,
//     };
//   } catch (error) {
//     return {
//       type: ResponseType.Failure,
//       data: error,
//     };
//   }
// };

// export const   = async (department: Department): Promise<Response> => {
//     try {
//         let categories = await DataStore.query(Category, c => c.departmentID.eq(department.id));
//         let protocolIDs: string[] = []
//         for(let i = 0; i < categories.length; i++){
//             let c = categories[i];
//             if(c.pairedProtocols){
//                 for(let j = 0; j < c.pairedProtocols.length; j++)
//                     if(c.pairedProtocols[j]) protocolIDs.push(c.pairedProtocols[j]);
//             }
//         }
//         let response = await getProtocolsByIDs(protocolIDs);
//         let protocols = (response.type === ResponseType.Success) ? response.data : [];
//         let resp = await fetchMedications(department.id);
//         let medications = (resp.type === ResponseType.Success) ? resp.data : [];

//         let infusions = await DataStore.query(Drip, i => i.departmentID.eq(department.id));
//         let equipment = await DataStore.query(Equipment, e => e.departmentID.eq(department.id));
//         let electricals = await DataStore.query(Electrical, e => e.departmentID.eq(department.id));
//         let vitals = await DataStore.query(Vitals, v => v.departmentID.eq(department.id));
//         let forms = await DataStore.query(Form, f => f.departmentID.eq(department.id));
//         let ambulances = await DataStore.query(Ambulance, a => a.departmentID.eq(department.id));
//         return {
//             type: ResponseType.Success,
//             data: {
//                 categories: categories,
//                 protocols: protocols,
//                 medications: medications,
//                 infusions: infusions,
//                 equipment: equipment,
//                 electricals: electricals,
//                 vitals: vitals,
//                 logs: logs,
//                 forms: forms,
//                 ambulances: ambulances,
//             },
//         };
//     } catch (error) {
//         return {
//             type: ResponseType.Failure,
//             data: error,
//         };
//     }
// }

export const fetchPDFURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/', {
      level: 'public',
    });

    let urls = result.results;

    // Filter to get only .pdf files
    const pdfFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.pdf')
    );

    let pdfURLs: string[] = [];
    for (let i = 0; i < pdfFiles.length; i++) {
      let file = pdfFiles[i];
      pdfURLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: pdfURLs,
    };
  } catch (e) {
    console.error('Error fetching PDF URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchPngURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/', {
      level: 'public',
    });

    let urls = result.results;

    const pngFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.png')
    );

    let pngURLs: string[] = [];
    for (let i = 0; i < pngFiles.length; i++) {
      let file = pngFiles[i];
      pngURLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: pngURLs,
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchNotificationUrls = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/notifications/', {
      level: 'public',
    });

    let urls = result.results;

    if (globals.debug) if (globals.debug) console.log('URLS:', urls);

    const pngFiles = urls.filter(
      (file: any) =>
        typeof file.key === 'string' &&
        (file.key.toLowerCase().endsWith('.png') ||
          file.key.toLowerCase().endsWith('.jpg') ||
          file.key.toLowerCase().endsWith('.jpeg'))
    );

    const mp4Files = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.mp4')
    );

    if (globals.debug) if (globals.debug) console.log('PNG FILES:', pngFiles);
    if (globals.debug) if (globals.debug) console.log('MP4 FILES:', mp4Files);

    let pngURLs: string[] = [];
    for (let i = 0; i < pngFiles.length; i++) {
      let file = pngFiles[i];
      pngURLs.push(file.key);
    }

    let mp4URLs: string[] = [];
    for (let i = 0; i < mp4Files.length; i++) {
      let file = mp4Files[i];
      mp4URLs.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: {
        png: pngURLs,
        mp4: mp4URLs,
      },
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchNotificationVideoURLs = async (
  department: DepartmentItem
): Promise<Response> => {
  try {
    const result: any = await Storage.list(department.id + '/notifications/', {
      level: 'public',
    });

    let urls = result.results;

    const mp4Files = urls.filter(
      (file: any) =>
        typeof file.key === 'string' && file.key.toLowerCase().endsWith('.mp4')
    );

    let mp4URLs: string[] = [];
    for (let i = 0; i < mp4Files.length; i++) {
      let file = mp4Files[i];
      mp4Files.push(file.key);
    }

    return {
      type: ResponseType.Success,
      data: mp4URLs,
    };
  } catch (e) {
    console.error('Error fetching MP4 URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchPdfFile = async (fileURL: string): Promise<Response> => {
  try {
    const result: any = await Storage.get(fileURL, { level: 'public' });

    return {
      type: ResponseType.Success,
      data: result,
    };
  } catch (e) {
    console.error('Error fetching PNG URLs:', e);
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

export const fetchOneWeights = async (depID: string): Promise<Response> => {
  return {
    type: ResponseType.Success,
    data: [],
  };
  // try {
  //   const oneWeightsList = await DataStore.query(OneWeight, (o) =>
  //     o.departmentID.eq(depID)
  //   );
  //   return {
  //     type: ResponseType.Success,
  //     data: oneWeightsList,
  //   };
  // } catch (error) {
  //   console.error('Error fetching one weights:', error);
  //   return {
  //     type: ResponseType.Failure,
  //     data: error,
  //   };
  // }
};

export const fetchCategoriesWithProtocols = async (
  dep: DepartmentItem,
  protocolCallback?: (protocols: ProtocolItem[]) => void,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    let start = new Date();
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);
    if (globals.debug) console.log('Department IDs:', depIDs);

    const categoriesList = await DataStore.query(Category, (c) =>
      c.and((c) => [
        c.or((c) => [
          c.and((c) => [
            c.isRestrictive.eq(false),
            c.or((c) => depIDs.map((id) => c.departmentID.contains(id))),
          ]),
          c.and((c) => [
            c.isRestrictive.eq(true),
            c.pairedDepIDs.contains(dep.id),
          ]),
        ]),
        c.and((c) => [c.status.ne('ARCHIVE'), c.status.ne('DELETED')]),
      ])
    );

    let categories: CategoryItem[] = [];
    // let userPromises = [];
    for (let i = 0; i < categoriesList.length; i++) {
      let category = new CategoryItem(categoriesList[i]);
      // userPromises.push(category.findUser());
      /* Take out the active version if there is one */
      mapModelItems(category, categories, category.status, dep);
    }
    // if (waitForUsers) await Promise.all(userPromises);
    if (waitForUsers) findUsersForModelItems(categories);

    categories.sort((a, b) => {
      if (a.index === b.index) return a.name.localeCompare(b.name);
      return a.index - b.index;
    });

    // let promises = [];
    // for (let i = 0; i < categories.length; i++){
    //   promises.push(fetchProtocols(categories[i], dep, waitForUsers));

    // }
    return {
      type: ResponseType.Success,
      data: [categories, []],
    };

    // if (protocolCallback) {
    //   Promise.all(promises).then((results) => {
    //     protocols = results.map((r) => r.data).flat();
    //     protocols.sort((a, b) => a.name.localeCompare(b.name));
    //     protocolCallback(protocols);
    //   });

    //   return {
    //     type: ResponseType.Success,
    //     data: categories,
    //     // data: [categories, protocols],
    //   };
    // } else {
    //   let results = await Promise.all(promises);
    //   protocols = results.map((r) => r.data).flat();
    //   protocols.sort((a, b) => a.name.localeCompare(b.name));
    //   return {
    //     type: ResponseType.Success,
    //     data: [categories, protocols],
    //   };
    // }

    //Sort the categories by their order
    // categories.sort((a, b) => {
    //   if (a.index === b.index) return a.name.localeCompare(b.name);
    //   return a.index - b.index;
    // });
    // return {
    //   type: ResponseType.Success,
    //   data: [categories, protocols],
    // };
  } catch (error) {
    console.error('Error fetching categories:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export const fetchProtocols = async (
  // cat: CategoryItem,
  dep: DepartmentItem,
  waitForUsers: boolean = false
): Promise<Response> => {
  try {
    /* Fetch the protocols for this category and then sort by index */
    // let id: string = cat.overrideID
    //   ? cat.overrideID
    //   : cat.status.includes('DRAFT') && cat.activeID
    //     ? cat.activeID
    //     : cat.uid;
    let depIDs = [dep.id];
    if (dep.parentDep) depIDs.push(dep.parentDep.id);
    if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);
    let start = new Date();
    let protocolList = await DataStore.query(Protocol, (p) =>
      // p.and((p) => [
      // p.categoryID.eq(id),
      p.or((p) => [
        p.and((p) => [
          p.isRestrictive.eq(false),
          p.or((p) => depIDs.map((id) => p.departmentID.contains(id))),
        ]),
        p.and((p) => [
          p.isRestrictive.eq(true),
          p.pairedDepIDs.contains(dep.id),
        ]),
        p.status.ne('DELETED'),
      ])
    );

    protocolList = protocolList.filter(
      (p) => p.status !== 'DELETED' && p.status !== 'ARCHIVE'
    );

    /* Create the ProtocolItem objects and add them to the category */
    // let protocols: ProtocolItem[] = [];
    // let userPromises: any = [];
    // for (let i = 0; i < protocolList.length; i++) {
    //   let protocol = new ProtocolItem(protocolList[i], cat);
    //   userPromises.push(protocol.findUser());
    //   /* Take out the active version if there is one */
    //   mapModelItems(protocol, protocols, protocol.status, dep);
    // }
    // if (waitForUsers) await Promise.all(userPromises);
    // cat.setProtocols(protocols);

    // protocols.sort((a: ProtocolItem, b: ProtocolItem) => {
    //   if (a.index === b.index) return a.name.localeCompare(b.name);
    //   return a.index - b.index;
    // });

    return {
      type: ResponseType.Success,
      data: protocolList,
    };
  } catch (error) {
    console.error('Error fetching protocols:', error);
    return {
      type: ResponseType.Failure,
      data: error,
    };
  }
};

export function mapModelItems(
  item: ModelItem<any>,
  items: ModelItem<any>[],
  str_status: string,
  dep: DepartmentItem,
  output: boolean = false
): ModelItem<any>[] | null {
  try {
    let status = getProgressStatus(str_status);
    if (status.includes('DRAFT') && item.departmentID !== dep.id) {
      return null;
      // return true;
    } else if (status.includes('DRAFT') || status === 'DEACTIVATED') {
      /* Take out the overridden version if there is one */
      if (item.overrideID != null) {
        let findIndex = items.findIndex(
          (m: ModelItem<any>) => m.uid === item.overrideID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED OVERRIDE', item.name, 'STATUS', status);
          let over = items[findIndex];
          items.splice(findIndex, 1);
          item.overrideItem = over;
        }
      }

      /* Take out the active version if there is one */
      if (item.activeID != null) {
        let findIndex = items.findIndex(
          (m: ModelItem<any>) => m.uid === item.activeID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED ACTIVE', item.name, 'STATUS', status);
          let actv = items[findIndex];
          items.splice(findIndex, 1);
          item.activeItem = actv;
        }
      }

      if (output)
        console.log('Pushing Draft Item:', item.name, 'Status:', status);
      items.push(item);
    } else if (status === 'ACTIVE') {
      /* Make sure the draft version is not in the list */
      let found = false;

      /* First check if the active version is in the list */
      let findIndex = items.findIndex(
        (m: ModelItem<any>) => m.activeID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND ACTIVE REMOVING', item.name, 'STATUS', status);
        items[findIndex].activeItem = item;
        found = true;
      }

      /* Next check if the item in the list is overridden by this item */
      findIndex = items.findIndex(
        (m: ModelItem<any>) => m.overrideID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND OVERRIDE REMOVING', item.name, 'STATUS', status);
        items[findIndex].overrideItem = item;
        found = true;
      }

      if (output)
        console.log('Pushing Active Item:', item.name, 'Status:', status);
      /* If the item is not overridden or an active item then add it */
      if (!found) items.push(item);
    }
    return items;
  } catch (error) {
    console.error('Error mapping item:', error);
    return null;
  }
}

export function mapModelSubItems(
  item: ModelSubItem<any>,
  items: ModelSubItem<any>[],
  str_status: string,
  dep: DepartmentItem,
  output: boolean = false
): ModelSubItem<any>[] | null {
  try {
    const parent = item.parent;
    const protocol: ProtocolItem = item.parentProtocol;
    const removeItem = (item: ModelSubItem<any>) => {
      if (item.TAG === 'ElectricalSubItem') {
        let elec = item as ElectricalSubItem;
        (parent as ElectricalItem).removeElectricalSubItem(elec);
        protocol.removeElectrical(elec);
      } else if (item.TAG === 'MedicationSubItem') {
        let med = item as MedicationSubItem;
        (parent as MedicationItem).removeMedicationSubItem(med);
        protocol.removeMedication(med);
      } else if (item.TAG === 'InfusionSubItem') {
        let inf = item as InfusionSubItem;
        (parent as InfusionItem).removeInfusionSubItem(inf);
        protocol.removeInfusion(inf);
      }
    };

    const addItem = (item: ModelSubItem<any>) => {
      if (item.TAG === 'ElectricalSubItem') {
        let elec = item as ElectricalSubItem;
        (parent as ElectricalItem).addElectricalSubItem(elec);
        protocol.addElectrical(elec);
      } else if (item.TAG === 'MedicationSubItem') {
        let med = item as MedicationSubItem;
        (parent as MedicationItem).addMedicationSubItem(med);
        protocol.addMedication(med);
      } else if (item.TAG === 'InfusionSubItem') {
        let inf = item as InfusionSubItem;
        (parent as InfusionItem).addInfusionSubItem(inf);
        protocol.addInfusion(inf);
      }
    };

    let status = getProgressStatus(str_status);
    if (status.includes('DRAFT') && item.departmentID !== dep.id) {
      return null;
      // return true;
    } else if (status.includes('DRAFT') || status === 'DEACTIVATED') {
      /* Take out the overridden version if there is one */
      if (item.overrideID != null) {
        let findIndex = items.findIndex(
          (m: ModelSubItem<any>) => m.uid === item.overrideID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED OVERRIDE', item.name, 'STATUS', status);
          let over = items[findIndex];
          items.splice(findIndex, 1);
          item.overrideItem = over;
          removeItem(over);
        }
      }

      /* Take out the active version if there is one */
      if (item.activeID != null) {
        let findIndex = items.findIndex(
          (m: ModelSubItem<any>) => m.uid === item.activeID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED ACTIVE', item.name, 'STATUS', status);
          let actv = items[findIndex];
          items.splice(findIndex, 1);
          item.activeItem = actv;
          removeItem(actv);
        }
      }

      if (output)
        console.log('Pushing Draft Item:', item.name, 'Status:', status);
      items.push(item);
      addItem(item);
    } else if (status === 'ACTIVE') {
      /* Make sure the draft version is not in the list */
      let found = false;

      /* First check if the active version is in the list */
      let findIndex = items.findIndex(
        (m: ModelSubItem<any>) => m.activeID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND ACTIVE REMOVING', item.name, 'STATUS', status);
        items[findIndex].activeItem = item;
        found = true;
      }

      /* Next check if the item in the list is overridden by this item */
      findIndex = items.findIndex(
        (m: ModelSubItem<any>) => m.overrideID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND OVERRIDE REMOVING', item.name, 'STATUS', status);
        items[findIndex].overrideItem = item;
        found = true;
      }

      if (output)
        console.log('Pushing Active Item:', item.name, 'Status:', status);
      /* If the item is not overridden or an active item then add it */
      if (!found) {
        items.push(item);
        addItem(item);
      }
    }
    return items;
  } catch (error) {
    console.error('Error mapping item:', error);
    return null;
  }
}

export function mapModelSubItemsCPR(
  item: MedicationSubItem | ElectricalSubItem,
  items: MedicationSubItem[] | ElectricalSubItem[],
  str_status: string,
  dep: DepartmentItem,
  output: boolean = false
): ModelSubItem<any>[] | null {
  try {
    const parent = item.parent;
    const cpr = item.parentCPR;
    if (cpr == null) return null;

    const removeItem = (item: ModelSubItem<any>) => {
      if (item.TAG === 'ElectricalSubItem') {
        let elec = item as ElectricalSubItem;
        // (parent as ElectricalItem).removeElectricalSubItem(elec);
        cpr.removeElectrical(elec);
      } else if (item.TAG === 'MedicationSubItem') {
        let med = item as MedicationSubItem;
        // (parent as MedicationItem).removeMedicationSubItem(med);
        cpr.removeMedication(med);
      }
    };

    const addItem = (item: ModelSubItem<any>) => {
      if (item.TAG === 'ElectricalSubItem') {
        let elec = item as ElectricalSubItem;
        // (parent as ElectricalItem).addElectricalSubItem(elec);
        cpr.addElectrical(elec);
      } else if (item.TAG === 'MedicationSubItem') {
        let med = item as MedicationSubItem;
        // (parent as MedicationItem).addMedicationSubItem(med);
        cpr.addMedication(med);
      }
    };

    let status = getProgressStatus(str_status);
    if (status.includes('DRAFT') && item.departmentID !== dep.id) {
      return null;
      // return true;
    } else if (status.includes('DRAFT') || status === 'DEACTIVATED') {
      /* Take out the overridden version if there is one */
      if (item.overrideID != null) {
        let findIndex = items.findIndex(
          (m: ModelSubItem<any>) => m.uid === item.overrideID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED OVERRIDE', item.name, 'STATUS', status);
          let over = items[findIndex];
          items.splice(findIndex, 1);
          item.overrideItem = over;
          removeItem(over);
        }
      }

      /* Take out the active version if there is one */
      if (item.activeID != null) {
        let findIndex = items.findIndex(
          (m: ModelSubItem<any>) => m.uid === item.activeID
        );
        if (findIndex !== -1) {
          if (output)
            console.log('REMOVED ACTIVE', item.name, 'STATUS', status);
          let actv = items[findIndex];
          items.splice(findIndex, 1);
          item.activeItem = actv;
          removeItem(actv);
        }
      }

      if (output)
        console.log('Pushing Draft Item:', item.name, 'Status:', status);
      items.push(item as any);
      addItem(item);
    } else if (status === 'ACTIVE') {
      /* Make sure the draft version is not in the list */
      let found = false;

      /* First check if the active version is in the list */
      let findIndex = items.findIndex(
        (m: ModelSubItem<any>) => m.activeID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND ACTIVE REMOVING', item.name, 'STATUS', status);
        items[findIndex].activeItem = item;
        found = true;
      }

      /* Next check if the item in the list is overridden by this item */
      findIndex = items.findIndex(
        (m: ModelSubItem<any>) => m.overrideID === item.uid
      );
      if (findIndex !== -1) {
        if (output)
          console.log('FOUND OVERRIDE REMOVING', item.name, 'STATUS', status);
        items[findIndex].overrideItem = item;
        found = true;
      }

      if (output)
        console.log('Pushing Active Item:', item.name, 'Status:', status);
      /* If the item is not overridden or an active item then add it */
      if (!found) {
        items.push(item as any);
        addItem(item);
      }
    }
    return items;
  } catch (error) {
    console.error('Error mapping item:', error);
    return null;
  }
}

// export const fetchVitals = async (dep: DepartmentItem): Promise<Response> => {
//   try {
//     let depIDs = [dep.id];
//     if (dep.parentDep) depIDs.push(dep.parentDep.id);
//     if (dep.parentDep?.parentDep) depIDs.push(dep.parentDep.parentDep.id);

//     const vitalsList = await DataStore.query(Vitals, (m) =>
//       m.and((m) => [
//         m.or((m) => depIDs.map((id) => m.departmentID.eq(id))),
//         m.and((m) => [m.status.ne('ARCHIVE'), m.status.ne('DELETED')]),
//       ])
//     );
//     let vitals: VitalItem[] = [];
//     for (let i = 0; i < vitalsList.length; i++) {
//       let vital = new VitalItem(vitalsList[i]);
//       // if (vital.status.includes('DRAFT') || vital.status === 'ACTIVE') {
//       /* Take out the active version if there is one */
//       mapModelItems(vital, vitals, vital.status, dep);
//       // }
//     }
//     vitals.sort((a, b) => a.getName().localeCompare(b.getName()));
//     return {
//       type: ResponseType.Success,
//       data: vitals,
//     };
//   } catch (error) {
//     console.error('Error fetching vitals:', error);
//     return {
//       type: ResponseType.Failure,
//       data: error,
//     };
//   }
// };

// export const fetchGroups = async (depID: string): Promise<Response> => {
//   try {
//     const groupsList: any[] = await DataStore.query(Group, (m) =>
//       m.departmentID.eq(depID)
//     );
//     let groups: GroupItem[] = [];
//     for (let i = 0; i < groupsList.length; i++) {
//       let group = new GroupItem(groupsList[i]);
//       groups.push(group);
//     }
//     return {
//       type: ResponseType.Success,
//       data: groups,
//     };
//   } catch (error) {
//     console.error('Error fetching groups:', error);
//     return {
//       type: ResponseType.Failure,
//       data: error,
//     };
//   }
// };

// export const fetchNotifications = async (depID: string): Promise<Response> => {
//   try {
//     const notificationsList = await DataStore.query(Notification, (m) =>
//       m.departmentID.eq(depID)
//     );
//     let nots: NotificationItem[] = [];
//     for (let i = 0; i < notificationsList.length; i++) {
//       let n = new NotificationItem(notificationsList[i], []);
//       let groups: GroupItem[] = [];
//       const groupNotifications = await DataStore.query(
//         GroupNotification,
//         (gn) => gn.notificationId.eq(n.uid)
//       );

//       for (let groupNotification of groupNotifications) {
//         const groupId = groupNotification.groupId;

//         if (groupId) {
//           const group = await DataStore.query(Group, groupId);

//           if (group) {
//             groups.push(new GroupItem(group));
//           }
//         }
//       }
//       n.addGroups(groups);
//       nots.push(n);
//     }
//     nots.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
//     return {
//       type: ResponseType.Success,
//       data: nots,
//     };
//   } catch (error) {
//     console.error('Error fetching notifications:', error);
//     return {
//       type: ResponseType.Failure,
//       data: error,
//     };
//   }
// };

export const fetchLogs = async (depID: string): Promise<Response> => {
  return {
    type: ResponseType.Success,
    data: [],
  };
};

export const fetchUserLogs = async (userID: string): Promise<Response> => {
  return {
    type: ResponseType.Success,
    data: [],
  };
};

export const loadDatabase = (
  item: DatabaseResponse | DepartmentItem,
  dispatch?: Dispatch<any>,
  wait: boolean = false,
  forceResync: boolean = false,
  waitForUsers: boolean = false,
  subDepsMapParam?: Map<string, DepartmentItem[]>,
  allSubDepsMapParam?: Map<string, DepartmentItem[]>,
  reloadAfterSync: boolean = false
): Promise<Response> => {
  return new Promise(async (resolve, reject) => {
    let department: DepartmentItem;
    let subDepsMap: Map<string, DepartmentItem[]>;
    let allSubDepsMap: Map<string, DepartmentItem[]>;
    if (item.hasOwnProperty('department')) {
      let db = item as DatabaseResponse;
      department = db.department;
      subDepsMap = db.subDepsMap;
      allSubDepsMap = db.allSubDepsMap;
    } else {
      department = item as DepartmentItem;
      subDepsMap = subDepsMapParam || new Map();
      allSubDepsMap = allSubDepsMapParam || new Map();
    }
    let start = new Date();
    if (globals.debug)
      console.log('Loading database for department:', department.name);
    try {
      let data: DatabaseResponse = {
        department: department,
        subDepsMap: subDepsMap || new Map(),
        allSubDepsMap: allSubDepsMap || new Map(),
        cprModel: undefined,
        categories: [],
        protocols: [],
        ambulances: [],
        users: [],
        oneWeights: [],
        medications: [],
        medicationDoses: [],
        infusions: [],
        infusionDoses: [],
        equipment: [],
        electrical: [],
        electricalDoses: [],
        checklists: [],
        vitals: [],
        logs: [],
        notifications: [],
        contacts: [],
        weightObjects: [],
        keychains: [],
        groups: [],
      };
      let medDoses: MedicationDose[] = [];
      let infusDoses: InfusionDose[] = [];
      let elecDoses: ElectricalDose[] = [];
      let protocols: Protocol[] = [];

      if (forceResync) {
        if (globals.debug) console.log('Force Resyncing the database');
        parentDepIDs = [department.id];
        if (department.parentDepID) parentDepIDs.push(department.parentDepID);
        curDepartmentID = department.id;
        localStorage.setItem(
          'loggedInDepartment',
          JSON.stringify({
            id: department.id,
            parentDepID: department.parentDepID,
          })
        );

        await timeoutPromise(DataStore.stop(), 1500).catch((e) => {
          console.error('Error stopping the database:', e);
        });
        if (globals.debug) console.log('Stopped the database');
        await timeoutPromise(DataStore.start(), 1000).catch((e) => {
          console.error('Error starting the database:', e);
        });
        if (globals.debug) console.log('Started the database');
        await waitForSyncQueriesReady().catch((e) => {
          console.error('Error waiting for sync queries:', e);
        });
        if (globals.debug)
          console.log('Database is ready  for department:', department.name);
      }

      if (reloadAfterSync) {
        waitForSyncQueriesReady().catch((e) => {
          loadDatabase(
            department,
            dispatch,
            wait,
            false,
            false,
            subDepsMap,
            allSubDepsMap,
            false
          );
        });
      }

      const createSupplementalPromises = (
        data: DatabaseResponse
      ): Promise<any>[] => {
        let promises: Promise<any>[] = [];
        promises.push(
          fetchMedicationDoses(department).then((medDoseResp) => {
            if (medDoseResp.type === ResponseType.Success) {
              medDoses = medDoseResp.data;
              if (globals.debug)
                console.log('Found ' + medDoses.length + ' medication doses');
            } else throw new Error('Error fetching medication doses.');
          })
        );

        promises.push(
          fetchInfusionDoses(department).then((infDoseResp) => {
            if (infDoseResp.type === ResponseType.Success) {
              infusDoses = infDoseResp.data;
              if (globals.debug)
                console.log('Found ' + infusDoses.length + ' infusion doses');
            } else throw new Error('Error fetching infusion doses.');
          })
        );

        promises.push(
          fetchElectricalDoses(department).then((elecDoseResp) => {
            if (elecDoseResp.type === ResponseType.Success) {
              elecDoses = elecDoseResp.data;
              if (globals.debug)
                console.log('Found ' + elecDoses.length + ' electrical doses');
            } else throw new Error('Error fetching electrical doses.');
          })
        );

        promises.push(
          fetchVitals(department, undefined, waitForUsers).then((vitalResp) => {
            if (vitalResp.type === ResponseType.Success) {
              data.vitals = vitalResp.data;
              if (globals.debug)
                console.log('Found ' + data.vitals.length + ' vitals');
            } else throw new Error('Error fetching vitals.');
          })
        );

        promises.push(
          fetchAmbulances(department).then((ambResp) => {
            if (ambResp.type === ResponseType.Success) {
              data.ambulances = ambResp.data;
              if (globals.debug)
                console.log('Found ' + data.ambulances.length + ' ambulances');
            } else throw new Error('Error fetching ambulances.');
          })
        );

        promises.push(
          fetchContacts(department).then((contactsResp) => {
            if (contactsResp.type === ResponseType.Success) {
              data.contacts = contactsResp.data;
              if (globals.debug)
                console.log('Found ' + data.contacts.length + ' contacts');
            } else throw new Error('Error fetching contacts.');
          })
        );

        promises.push(
          fetchKeychains(department).then((keychainResp) => {
            if (keychainResp.type === ResponseType.Success) {
              data.keychains = keychainResp.data;
              if (globals.debug)
                console.log('Found ' + data.keychains.length + ' keychains');
            } else throw new Error('Error fetching keychains.');
          })
        );

        promises.push(
          fetchWeightObjects(department).then((weightObjResp) => {
            if (weightObjResp.type === ResponseType.Success) {
              data.weightObjects = weightObjResp.data;
              if (globals.debug)
                console.log(
                  'Found ' + data.weightObjects.length + ' weight objects'
                );
            } else throw new Error('Error fetching weight objects.');
          })
        );

        promises.push(
          fetchChecklists(department, undefined, waitForUsers).then(
            (checkResp) => {
              if (checkResp.type === ResponseType.Success) {
                data.checklists = checkResp.data;
                if (globals.debug)
                  console.log(
                    'Found ' + data.checklists.length + ' checklists'
                  );
              } else throw new Error('Error fetching checklists.');
            }
          )
        );

        // promises.push(
        // fetchUsers(department).then((usrResp) => {
        //   if (usrResp.type === ResponseType.Success) {
        //     data.users = usrResp.data;
        //     if (globals.debug)
        //       console.log('Found ' + data.users.length + ' users');
        //   } else throw new Error('Error fetching users.');
        // });
        // );

        promises.push(
          fetchCPRAssists(department).then((cprResp) => {
            if (cprResp.type === ResponseType.Success) {
              data.cprModel = cprResp.data;
              if (globals.debug) console.log('Found CPR Assist');
            } else throw new Error('Error fetching CPR Assist.');
          })
        );

        return promises;
      };

      let essentialPromises: Promise<any>[] = [];
      let supplementalPromises: Promise<any>[] = [];

      let catPromise = fetchCategoriesWithProtocols(
        department,
        undefined,
        waitForUsers
      ).then((catResp) => {
        // Check response of fetchCategoriesWithProtocols and proceed
        if (catResp.type === ResponseType.Success) {
          data.categories = catResp.data[0]; // : catResp.data;
          // if (wait) data.protocols = catResp.data[1];
          // data.protocols = catResp.data[1];
          if (globals.debug) {
            console.log('Found ' + data.categories.length + ' categories');
            // if (wait)
            //   console.log('Found ' + data.protocols.length + ' protocols');
          }
          return catResp;
        } else throw new Error('Error fetching categories.');
      });

      let protocolPromise = fetchProtocols(department, waitForUsers).then(
        (protResp) => {
          if (protResp.type === ResponseType.Success) {
            protocols = protResp.data;
            if (globals.debug)
              console.log(
                'Found ' + protocols.length + ' PRE MAPPED protocols'
              );
          } else throw new Error('Error fetching protocols.');
        }
      );

      essentialPromises.push(
        fetchMedications(department, undefined, undefined, waitForUsers).then(
          (medResp) => {
            if (medResp.type === ResponseType.Success) {
              data.medications = medResp.data;
              if (globals.debug)
                console.log(
                  'Found ' + data.medications.length + ' medications'
                );
            } else throw new Error('Error fetching medications.');
          }
        )
      );

      essentialPromises.push(
        fetchDrips(department, undefined, undefined, waitForUsers).then(
          (dripResp) => {
            if (dripResp.type === ResponseType.Success) {
              data.infusions = dripResp.data;
              if (globals.debug)
                console.log('Found ' + data.infusions.length + ' infusions');
            } else throw new Error('Error fetching infusions.');
          }
        )
      );

      essentialPromises.push(
        fetchEquipment(department, undefined, undefined, waitForUsers).then(
          (equipResp) => {
            if (equipResp.type === ResponseType.Success) {
              data.equipment = equipResp.data;
              if (globals.debug)
                console.log('Found ' + data.equipment.length + ' equipment');
            } else throw new Error('Error fetching equipment.');
          }
        )
      );

      essentialPromises.push(
        fetchElectrical(department, undefined, undefined, waitForUsers).then(
          (elecResp) => {
            if (elecResp.type === ResponseType.Success) {
              data.electrical = elecResp.data;
              if (globals.debug)
                console.log('Found ' + data.electrical?.length + ' electrical');
            } else throw new Error('Error fetching electrical.');
          }
        )
      );

      if (wait) {
        essentialPromises = [catPromise, protocolPromise, ...essentialPromises];
        await Promise.all(essentialPromises);
        let protocolItems = await mapProtocolsToCategories(
          department,
          data.categories,
          allSubDepsMap?.get(department.id) || [],
          protocols,
          waitForUsers
        );
        data.protocols = protocolItems;
        supplementalPromises = createSupplementalPromises(data);
        await Promise.all(supplementalPromises);
        let { allMedicationDoses, allInfusionDoses, allElectricalDoses } =
          mapItems(data, medDoses, infusDoses, elecDoses);
        data.medicationDoses = allMedicationDoses;
        data.infusionDoses = allInfusionDoses;
        data.electricalDoses = allElectricalDoses;
        if (dispatch) dispatch(handleGetDepartment(data));

        await fetchUsers(department, true, UserStatus.ACTIVE).then(
          async (userResp) => {
            if (userResp.type === ResponseType.Success) {
              data.users = userResp.data;
              await findUsersForDatabase(data);
              if (globals.debug)
                console.log('Found ' + data.users.length + ' users');
            } else throw new Error('Error fetching users.');
          }
        );
        resolve({
          type: ResponseType.Success,
          data: data,
        });
      } else {
        await Promise.all([catPromise, protocolPromise]);
        let protocolItems = await mapProtocolsToCategories(
          department,
          data.categories,
          allSubDepsMap?.get(department.id) || [],
          protocols,
          waitForUsers
        );
        data.protocols = protocolItems;
        if (globals.debug)
          console.log('Found ' + data.protocols.length + ' protocols');
        resolve({
          type: ResponseType.Success,
          data: data,
        });
        Promise.all(essentialPromises).then(() => {
          resolve({
            type: ResponseType.Success,
            data: data,
          });
          supplementalPromises = createSupplementalPromises(data);

          Promise.all(supplementalPromises).then(() => {
            let { allMedicationDoses, allInfusionDoses, allElectricalDoses } =
              mapItems(data, medDoses, infusDoses, elecDoses);
            data.medicationDoses = allMedicationDoses;
            data.infusionDoses = allInfusionDoses;
            data.electricalDoses = allElectricalDoses;
            if (dispatch) dispatch(handleGetDepartment(data));
            resolve({
              type: ResponseType.Success,
              data: data,
            });
            fetchUsers(department, true, UserStatus.ACTIVE).then((userResp) => {
              if (userResp.type === ResponseType.Success) {
                data.users = userResp.data;
                findUsersForDatabase(data);
                if (globals.debug)
                  console.log('Found ' + data.users.length + ' users');
              } else throw new Error('Error fetching users.');
            });
          });
        });
      }
    } catch (error) {
      console.error('Error syncing to database:', error);
      return reject({
        type: ResponseType.Failure,
        data: error,
      });
    }
  });
};

/**
 * Maps the items to the protocols
 * @param data The data to map the items to
 */
export function mapItems(
  data: DatabaseResponse,
  medDoses: MedicationDose[],
  infusDoses: InfusionDose[],
  elecDoses: ElectricalDose[]
): {
  allMedicationDoses: MedicationSubItem[];
  allInfusionDoses: InfusionSubItem[];
  allElectricalDoses: ElectricalSubItem[];
} {
  let cprResp: {
    medItems: MedicationSubItem[];
    elecItems: ElectricalSubItem[];
  } | null = null;
  if (data.cprModel) cprResp = mapCPR(data, medDoses, elecDoses);

  let allMedicationDoses = mapMeds(
    data.protocols,
    data.medications,
    medDoses,
    data.department
  );
  if (cprResp) allMedicationDoses.push(...cprResp.medItems);
  let allInfusionDoses = mapInfusions(
    data.protocols,
    data.infusions,
    infusDoses,
    data.department
  );
  let allElectricalDoses = mapElectrical(
    data.protocols,
    data.electrical,
    elecDoses,
    data.department
  );
  if (cprResp) allElectricalDoses.push(...cprResp.elecItems);
  data.protocols.forEach((protocol) => {
    mapEquip(protocol, data.equipment);
    mapChecklists(protocol, data.checklists);
    mapProtocols(protocol, data.protocols);
    // const subDeps = getDepartmentsFromState(data.department, reducerState).subDeps;
    // if (data.department.subDeps && data.department.subDeps.length > 1)
    //   mapProtocolDepartments(protocol, data.department.subDeps);
  });
  // data.users.forEach((user: User) => {
  //   // mapNotificationToUser(data.notifications, user);
  //   mapProtocolsToUser(data.protocols, user);
  //   mapCategoriesToUser(data.categories, user);
  // });
  // data.notifications.forEach((notification) => {
  // 	mapUsersToNotification(notification, data.users);
  // });
  mapActiveItems(data);
  return {
    allMedicationDoses,
    allInfusionDoses,
    allElectricalDoses,
  };
}

function mapCPR(
  db: DatabaseResponse,
  medDoses: MedicationDose[],
  elecDoses: ElectricalDose[]
): {
  medItems: MedicationSubItem[];
  elecItems: ElectricalSubItem[];
} {
  try {
    const cpr = db.cprModel;
    if (cpr == null) return { medItems: [], elecItems: [] };
    let model: CPRAssist = cpr.getModel();
    let medItems = mapMedsToCPR(cpr, db, medDoses);
    let elecItems = mapElecsToCPR(cpr, db, elecDoses);
    model.protocolIDs.forEach((protID: string) => {
      db.protocols.forEach((prot) => {
        if (prot.getUid() === protID) {
          cpr.addProtocol(prot);
          prot.addCPR(cpr);
        }
      });
    });
    if (model.equipmentID) {
      let find = db.equipment.find(
        (equip) => equip.getUid() === model.equipmentID
      );
      if (find) cpr.addEquipment(find);
    }
    return {
      medItems,
      elecItems,
    };
  } catch (error) {
    console.error('Error mapping CPR:', error);
    return {
      medItems: [],
      elecItems: [],
    };
  }
}

function mapMedsToCPR(
  cpr: CPRItem,
  db: DatabaseResponse,
  doses: MedicationDose[]
): MedicationSubItem[] {
  let allDoses: MedicationSubItem[] = [];
  const cprID = getActiveID(cpr);
  for (let i = 0; i < doses.length; i++) {
    if (doses[i].cprAssistID != null)
      if (doses[i].cprAssistID === cprID) {
        let dose = doses[i];
        let parent = db.medications.find((m) => {
          const medID = getActiveID(m);
          return (
            m.getUid() === dose.medicationID || medID === dose.medicationID
          );
        });
        //  TODO: Need to fetch protocolItem from this id and pass this as second argument to MedicationSubItem
        if (parent) {
          let medication = new MedicationSubItem(parent, cpr, dose);
          medication.parentCPR = cpr;
          mapModelSubItemsCPR(medication, allDoses, dose.status, db.department);
          // doses = doses.filter((d) => d.id !== dose.id);
        }
      }
  }
  return allDoses;
}

function mapElecsToCPR(
  cpr: CPRItem,
  db: DatabaseResponse,
  doses: ElectricalDose[]
): ElectricalSubItem[] {
  let allDoses: ElectricalSubItem[] = [];
  const cprID = getActiveID(cpr);

  for (let i = 0; i < doses.length; i++) {
    if (doses[i].cprAssistID === cprID) {
      let dose = doses[i];
      let parent = db.electrical.find((m) => {
        const medID = getActiveID(m);
        return m.getUid() === dose.electricalID || medID === dose.electricalID;
      });
      if (parent) {
        let medication = new ElectricalSubItem(parent, cpr, dose);
        medication.parentCPR = cpr;
        mapModelSubItemsCPR(medication, allDoses, dose.status, db.department);
      }
    }
  }
  return allDoses;
}

function mapActiveItems(
  db: DatabaseResponse,
  outputDebug: boolean = false
): void {
  let protocols: ProtocolItem[] = [];
  const dep: DepartmentItem = db.department;
  for (let i = 0; i < db.protocols.length; i++) {
    let protocol = db.protocols[i];
    let cat = protocol.parent;
    try {
      let status = getProgressStatus(protocol.status);
      if (status.includes('DRAFT') && protocol.departmentID !== dep.id) {
        continue;
      } else if (status.includes('DRAFT') || status === 'DEACTIVATED') {
        /* Take out the overridden version if there is one */
        if (protocol.overrideID != null) {
          let findIndex = protocols.findIndex(
            (m: ModelItem<any>) => m.uid === protocol.overrideID
          );
          if (findIndex !== -1) {
            if (globals.debug && outputDebug)
              console.log('REMOVED OVERRIDE', protocol.name, 'STATUS', status);
            let over = protocols[findIndex];
            //Remove the protocol from the category
            let overCat = over.parent;
            overCat.protocols = overCat.protocols.filter(
              (p: ProtocolItem) => p.uid !== over.uid
            );
            protocols.splice(findIndex, 1);
            protocol.overrideItem = over;
          }
        }

        /* Take out the active version if there is one */
        if (protocol.activeID != null) {
          let findIndex = protocols.findIndex(
            (m: ModelItem<any>) => m.uid === protocol.activeID
          );
          if (findIndex !== -1) {
            if (globals.debug && outputDebug)
              console.log('REMOVED ACTIVE', protocol.name, 'STATUS', status);
            let actv = protocols[findIndex];
            let actvCat = actv.parent;
            actvCat.protocols = actvCat.protocols.filter(
              (p: ProtocolItem) => p.uid !== actv.uid
            );
            protocols.splice(findIndex, 1);
            protocol.activeItem = actv;
          }
        }
        protocols.push(protocol);
      } else if (status === 'ACTIVE') {
        /* Make sure the draft version is not in the list */
        let found = false;

        /* First check if the active version is in the list */
        let findIndex = protocols.findIndex(
          (m: ModelItem<any>) => m.activeID === protocol.uid
        );
        if (findIndex !== -1) {
          if (globals.debug && outputDebug)
            console.log(
              'FOUND ACTIVE REMOVING',
              protocol.name,
              'STATUS',
              status
            );
          cat.protocols = cat.protocols.filter(
            (p: ProtocolItem) => p.uid !== protocol.uid
          );
          protocols[findIndex].activeItem = protocol;
          found = true;
        }

        /* Next check if the item in the list is overridden by this item */
        findIndex = protocols.findIndex(
          (m: ModelItem<any>) => m.overrideID === protocol.uid
        );
        if (findIndex !== -1) {
          if (globals.debug && outputDebug)
            console.log(
              'FOUND OVERRIDE REMOVING',
              protocol.name,
              'STATUS',
              status
            );
          protocols[findIndex].overrideItem = protocol;
          found = true;
        }
        /* If the item is not overridden or an active item then add it */
        if (!found) protocols.push(protocol);
      }
    } catch (error) {
      console.error('Error mapping item:', error);
    }
  }
  db.protocols = protocols;
}

/* ---------------------- MAPPIN MEDICATION FUNCTIONS ---------------------- */
function mapMeds(
  prots: ProtocolItem[],
  meds: MedicationItem[],
  doses: MedicationDose[],
  dep: DepartmentItem,
  waitForUsers: boolean = false
): MedicationSubItem[] {
  let allDoses: MedicationSubItem[] = [];
  let promises: Promise<User | null>[] = [];
  for (let i = 0; i < doses.length; i++) {
    let dose = doses[i];
    if (dose.cprAssistID != null) continue;
    let parent = meds.find(
      (m) =>
        m.uid === dose.medicationID ||
        m.activeID === dose.medicationID ||
        m.overrideID === dose.medicationID
    );
    let protocol = prots.find(
      (p) =>
        p.uid === dose.protocolID ||
        p.activeID === dose.protocolID ||
        p.overrideID === dose.protocolID
    );

    if (parent && protocol) {
      let medication = new MedicationSubItem(parent, protocol, dose);
      promises.push(medication.findUser());

      mapModelSubItems(medication, allDoses, dose.status, dep);
    }
  }
  return allDoses;
}

/* ---------------------- MAPPIN DRIP (INFUSIONS) FUNCTIONS ---------------------- */
function mapInfusions(
  prots: ProtocolItem[],
  infus: InfusionItem[],
  doses: InfusionDose[],
  dep: DepartmentItem
): InfusionSubItem[] {
  let allDoses: InfusionSubItem[] = [];
  for (let i = 0; i < doses.length; i++) {
    let dose = doses[i];
    let parent = infus.find(
      (m) =>
        m.uid === dose.dripID ||
        m.activeID === dose.dripID ||
        m.overrideID === dose.dripID
    );
    let protocol = prots.find(
      (p) =>
        p.uid === dose.protocolID ||
        p.activeID === dose.protocolID ||
        p.overrideID === dose.protocolID
    );
    if (parent && protocol) {
      let infusion = new InfusionSubItem(parent, protocol, dose);
      mapModelSubItems(infusion, allDoses, dose.status, dep);
    }
  }
  return allDoses;
}

/* ---------------------- MAPPING EQUIPMENT FUNCTIONS ---------------------- */
function mapEquip(prot: ProtocolItem, equips: EquipmentItem[]): void {
  let model: Protocol = prot.getModel();
  model.equipmentIDs?.forEach((equipID: string) => {
    equips.forEach((equipment) => {
      if (equipment.getUid() === equipID) {
        prot.addEquipment(equipment);
        equipment.addParentProtocol(prot);
      }
    });
  });
}

/* ---------------------- MAPPING ELECTRICAL FUNCTIONS ---------------------- */
function mapElectrical(
  prots: ProtocolItem[],
  elecs: ElectricalItem[],
  doses: ElectricalDose[],
  dep: DepartmentItem
): ElectricalSubItem[] {
  let allDoses: ElectricalSubItem[] = [];
  for (let i = 0; i < doses.length; i++) {
    if (doses[i].cprAssistID != null) continue;
    let dose = doses[i];
    let parent = elecs.find(
      (m) =>
        m.uid === dose.electricalID ||
        m.activeID === dose.electricalID ||
        m.overrideID === dose.electricalID
    );
    let protocol = prots.find(
      (p) =>
        p.uid === dose.protocolID ||
        p.activeID === dose.protocolID ||
        p.overrideID === dose.protocolID
    );
    if (parent && protocol) {
      let infusion = new ElectricalSubItem(parent, protocol, dose);
      mapModelSubItems(infusion, allDoses, dose.status, dep);
    }
  }
  return allDoses;
}

/* ---------------------- MAPPING FORM FUNCTIONS ---------------------- */
function mapChecklists(prot: ProtocolItem, forms: FormItem[]): void {
  let model: Protocol = prot.getModel();
  model.formIDs?.forEach((formID: string) => {
    forms.forEach((form) => {
      let ID =
        form.status === 'DRAFT' && form.activeID ? form.activeID : form.uid;
      if (ID === formID) prot.addForm(form);
    });
  });
}

/* ---------------------- MAPPING PROTOCOL FUNCTIONS ---------------------- */
function mapProtocols(prot: ProtocolItem, protocols: ProtocolItem[]): void {
  let model: Protocol = prot.getModel();
  model.pairedProtocols?.forEach((protocolID: string | null) => {
    if (protocolID == null) return;
    protocols.forEach((protocol) => {
      let ID =
        protocol.status === 'DRAFT' && protocol.activeID
          ? protocol.activeID
          : protocol.uid;
      if (ID === protocolID) prot.addPairedProtocol(protocol);
    });
  });
}

/**
 * This function maps protocols to categories.
 * @param cats - The categories to map protocols to.
 * @param protocols - The protocols to map to categories.
 * @param waitForUsers - Whether to wait for the protocols to be fetched and find all users.
 * @returns - The mapped protocols.
 */
export async function mapProtocolsToCategories(
  dep: DepartmentItem,
  cats: CategoryItem[],
  allSubDeps: DepartmentItem[],
  protocols: Protocol[],
  waitForUsers: boolean = false
): Promise<ProtocolItem[]> {
  let protocolItems: ProtocolItem[] = [];
  for (let i = 0; i < cats.length; i++) {
    const cat = cats[i];
    /* Fetch the protocols for this category and then sort by index */
    let id: string = cat.overrideID
      ? cat.overrideID
      : cat.status.includes('DRAFT') && cat.activeID
        ? cat.activeID
        : cat.uid;
    let filtered = protocols.filter(
      (p) => p.categoryID === id
      // p.categoryID === cat.activeID ||
      // p.categoryID === cat.overrideID
    );
    let catProtocols: ProtocolItem[] = [];
    for (let i = 0; i < filtered.length; i++) {
      let protocol = new ProtocolItem(filtered[i], cat);
      // catProtocols.push(protocol);

      mapModelItems(protocol, catProtocols, protocol.status, dep);
      // mapProtocolDepartments(protocol, allSubDeps);
    }
    cat.setProtocols(catProtocols);
    protocolItems.push(...catProtocols);
    // let list = filtered.map((p) => new ProtocolItem(p, cat));
    // list.forEach((p) => mapProtocolDepartments(p, allSubDeps));
    // cat.setProtocols(list);
    // protocolItems.push(...list);
  }
  if (waitForUsers) await findUsersForModelItems(protocolItems);
  else findUsersForModelItems(protocolItems);

  return protocolItems;
}

function mapProtocolDepartments(
  prot: ProtocolItem,
  departments: DepartmentItem[]
): void {
  let model: Protocol = prot.getModel();
  model.pairedDepIDs?.forEach((depID: string) => {
    departments.forEach((department) => {
      if (department.id === depID) {
        prot.pairedDeps = [...prot.pairedDeps, department];
        prot.pairedDeps.sort((a, b) => a.name.localeCompare(b.name));
      }
    });
  });
}

/* ---------------------- Fetch S3 PDF ---------------------- */
export const fetchPDF = async (
  fileURL?: string,
  protocol?: ProtocolItem,
  isDownload: boolean = false
): Promise<Response> => {
  try {
    if (fileURL == null && protocol == null)
      return {
        type: ResponseType.Failure,
        data: 'File URL and Protocol not found',
      };

    let url: string | null | undefined = fileURL ? fileURL : protocol?.pdfUrl;
    if (!url)
      return {
        type: ResponseType.Failure,
        data: 'File URL not found',
      };

    const result: any = await Storage.get(url, {
      level: 'public',
      download: isDownload,
    });

    if (!isDownload) {
      return {
        type: ResponseType.Success,
        data: result,
      };
    } else {
      return {
        type: ResponseType.Success,
        data: result.Body as Blob,
      };
    }
  } catch (e) {
    return {
      type: ResponseType.Failure,
      data: e,
    };
  }
};

/**
 * This function fetches the email of a user from Cognito using their username
 * @param username The username of the user
 * @returns @type Response -> containing user's email or Error
 */
export const fetchUserEmail = async (username: string): Promise<string> => {
  try {
    const params = {
      UserPoolId: UserPoolId, // Replace with your actual Cognito User Pool ID
      Username: username,
    };

    const response = await cognitoIdentityServiceProvider
      .adminGetUser(params)
      .promise();

    // Extract email from the user's attributes
    const emailAttribute = response.UserAttributes?.find(
      (attr) => attr.Name === 'email'
    );

    if (!emailAttribute) {
      throw new Error('Email attribute not found');
    }

    return emailAttribute.Value as string;
  } catch (error: any) {
    console.error('Error fetching user email:', error);
    return 'ERROR: ' + error.message;
  }
};

async function fetchRecordsForMonthAndYear(
  model: any,
  monthAndYear: Date,
  departmentIDs: string[],
  day: any
) {
  const year = monthAndYear.getFullYear();
  const month = monthAndYear.getMonth();
  let startDate = new Date(Date.UTC(year, month, 1));
  let endDate: any;
  if (day) {
    endDate = new Date(Date.UTC(year, month, day, 23, 59, 59));
  } else {
    endDate = new Date(Date.UTC(year, month + 1, 0, 23, 59, 59));
  }

  try {
    const records = await DataStore.query(model);
    const filteredRecords = records.filter((record) => {
      const isStatusMatch =
        record.status === 'ACTIVE' || record.status === 'ARCHIVE';
      const isWithinDateRange =
        (record.createdAt >= startDate.toISOString() &&
          record.createdAt <= endDate.toISOString()) ||
        (record.updatedAt >= startDate.toISOString() &&
          record.updatedAt <= endDate.toISOString());

      let isDepartmentMatch = false;

      if (model === Protocol) {
        isDepartmentMatch = record.pairedDepIDs
          ? departmentIDs.some((id) => record.pairedDepIDs.includes(id))
          : false;
      } else {
        isDepartmentMatch = departmentIDs.includes(record.departmentID);
      }

      return isStatusMatch && isDepartmentMatch && isWithinDateRange;
    });

    const groupedByName = filteredRecords.reduce((acc: any, record: any) => {
      const key = record.model === 'Vitals' ? record.title : record.name;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(record);
      return acc;
    }, {});

    const matchedRecords = Object.values(groupedByName)
      .filter((group: any) => {
        const statuses = group.map((record: any) => record.status);
        return statuses.includes('ACTIVE') && statuses.includes('ARCHIVE');
      })
      .flat();

    return matchedRecords;
  } catch (error) {
    console.error(`Error fetching records for ${model.name}:`, error);
    return [];
  }
}

export async function fetchAllRecordsByMonthYear(
  monthAndYear: Date,
  departmentIDs: any,
  day?: any
) {
  const models = [
    Protocol,
    Medication,
    Equipment,
    ElectricalShock,
    Drip,
    Form,
    Vitals,
  ];
  const results: any = {};

  for (const model of models) {
    const data = await fetchRecordsForMonthAndYear(
      model,
      monthAndYear,
      departmentIDs,
      day
    );
    results[model.name] = data;
    if (globals.debug)
      console.log(`Fetched ${data.length} records for ${model.name}`);
  }
  if (globals.debug) console.log('results', results);
  return results;
}

export const outputAllDataStoreValues = async () => {
  try {
    let list = [
      DataStore.query(Department),
      DataStore.query(User),
      DataStore.query(Ambulance),
      DataStore.query(Category),
      DataStore.query(Medication),
      DataStore.query(Protocol),
      DataStore.query(Equipment),
      DataStore.query(Drip),
      DataStore.query(Form),
      DataStore.query(Vitals),
      DataStore.query(Contact),
      DataStore.query(InputForm),
      DataStore.query(FormLog),
      DataStore.query(ElectricalShock),
      DataStore.query(WeightObject),
      DataStore.query(MedicationDose),
      DataStore.query(InfusionDose),
      DataStore.query(ElectricalDose),
      DataStore.query(Concentration),
      DataStore.query(CPRAssist),
    ];
    let titles = [
      'Department',
      'User',
      'Ambulance',
      'Category',
      'Medication',
      'Protocol',
      'Equipment',
      'Drip',
      'Form',
      'Vitals',
      'Contact',
      'InputForm',
      'FormLog',
      'ElectricalShock',
      'WeightObject',
      'MedicationDose',
      'InfusionDose',
      'ElectricalDose',
      'Concentration',
      'CPRAssist',
    ];

    const data = await Promise.all(list);
    // let allAsyncStorageKeys = await AsyncStorage.getAllKeys();
    // console.log(
    //   'AsyncStorage -> All AsyncStorage Keys:',
    //   allAsyncStorageKeys.length
    // );
    console.log('DataStore -> All DataStore Values:', data.flat().length);
    for (let i = 0; i < data.length; i++) {
      console.log('DataStore -> ' + titles[i] + ':', data[i].length, data[i]);
    }
  } catch (error) {
    console.log('Error in outputAllDataStoreValues', error);
  }
};

const queryCache = new Map<string, any>();
let singleQueryCount = 0;
let executeQueryCount = 0;

export function getQueryCount() {
  return {
    singleQueryCount,
    executeQueryCount,
    total: singleQueryCount + executeQueryCount,
  };
}

// Improved executeSingleQuery function
export async function executeSingleQuery(
  query: any,
  params: any,
  timeout = 5000,
  cacheQuery = false,
  debugQuery = false
): Promise<any> {
  singleQueryCount++;
  return new Promise(async (resolve, reject) => {
    try {
      if (
        cacheQuery &&
        queryCache.has(JSON.stringify(query) + JSON.stringify(params))
      ) {
        const resp = queryCache.get(
          JSON.stringify(query) + JSON.stringify(params)
        );
        if (
          resp.expiration > new Date(Date.now() + globals.QUERY_EXPIRATION_TIME)
        ) {
          return resolve(resp.data);
        }
      }

      // Execute the GraphQL query and setup a timeout promise
      const promises = [
        API.graphql(graphqlOperation(query, params)),
        new Promise((_, timeoutReject) =>
          setTimeout(() => timeoutReject('Timeout'), timeout)
        ),
      ];

      // Wait for the first promise to resolve or reject
      const result: any = await Promise.race(promises);

      if (debugQuery) console.log('Single Query Result:', result);

      // If we hit the timeout
      if (result === 'Timeout') {
        return reject('The query took too long and was timed out.');
      }

      // Extract data from the result
      const responseData = result.data;
      const queryKey = Object.keys(responseData)[0];
      const responseItem = responseData[queryKey];

      // Check if the response contains an item or a list of items
      if (responseItem) {
        // If the response contains items (array), return the first one
        if (Array.isArray(responseItem.items)) {
          const items = responseItem.items;
          if (items.length === 0) {
            return resolve(undefined);
          }
          //Add the query function name and params to the cache
          if (cacheQuery)
            queryCache.set(JSON.stringify(query) + JSON.stringify(params), {
              data: items[0],
              expiration: new Date(Date.now() + globals.QUERY_EXPIRATION_TIME),
            });
          return resolve(items[0]);
        }
        //Add the query function name and params to the cache
        if (cacheQuery)
          queryCache.set(JSON.stringify(query) + JSON.stringify(params), {
            data: responseItem,
            expiration: new Date(Date.now() + globals.QUERY_EXPIRATION_TIME),
          });
        // If the response contains a single item, return it directly
        return resolve(responseItem);
      }

      // If no data was found, return undefined
      resolve(undefined);
    } catch (error) {
      console.error('Error executing single query:', error);
      reject(error);
    }
  });
}

export async function executeQuery(
  query: any,
  params: any,
  timeout = 5000,
  fullScan = true, // If true, perform a full scan to retrieve all pages of data
  limit: number = 1000, // Set the maximum number of items to retrieve
  overrideDeletedCheck = false, // Optional flag to override the _deleted check
  cacheQuery = false, // Optional flag to cache the query
  lazyLoadCallback?: (items: any[], isCompleted: boolean) => void,
  loadLimit: number = 100
): Promise<any> {
  executeQueryCount++;

  return new Promise(async (resolve, reject) => {
    try {
      // Modify the params to include the _deleted filter condition
      const updatedParams = addDeletedCheckToParams(
        params,
        overrideDeletedCheck
      );
      let allItems: any[] = [];
      let nextToken: string | null = null;

      // Adjust the initial query params to include the limit
      const initialParams = {
        ...updatedParams,
        limit: loadLimit < 0 ? 100 : Math.min(loadLimit, 100), // Set initial limit, or max 1000 if higher
        // limit < 0 ? 1000 : Math.min(limit, 1000), // Set initial limit, or max 1000 if higher
      };
      //Add the query function name and params to the cache
      if (
        cacheQuery &&
        queryCache.has(JSON.stringify(query) + JSON.stringify(initialParams))
      ) {
        let resp = queryCache.get(
          JSON.stringify(query) + JSON.stringify(initialParams)
        );
        const expiration = new Date(Date.now() + globals.QUERY_EXPIRATION_TIME);

        //If the cached data is still valid, return it
        if (resp.expiration < expiration) return resp.data;
      }

      do {
        // Set the nextToken if available, to retrieve the next page
        const queryParams = { ...initialParams, nextToken };

        const promises = [
          API.graphql(graphqlOperation(query, queryParams)),
          new Promise((resolve) =>
            setTimeout(() => resolve('timeout'), timeout)
          ),
        ];

        // Wait for the query to resolve or timeout
        const result: any = await Promise.race(promises);

        if (result === 'timeout') {
          throw new Error(
            'The query took too long and was timed out.\n' +
              'Query: ' +
              JSON.stringify(query) +
              '\nParams: ' +
              JSON.stringify(queryParams)
          );
        }

        // Extract data from the result
        const data = result?.data;
        const queryKey = Object.keys(data)[0]; // Get the first key in the data object, which is usually the query name
        const response = data[queryKey];

        let isFirstLoad = allItems.length === 0;

        // Append the items to allItems array if present
        if (response && response.items) {
          allItems = [...allItems, ...response.items];
        } else if (Array.isArray(response)) {
          allItems = [...allItems, ...response];
        }

        if (isFirstLoad && lazyLoadCallback) resolve(allItems);
        else if (lazyLoadCallback) lazyLoadCallback(allItems, false);

        // Get the nextToken for pagination
        nextToken = response?.nextToken || null;

        // If a limit is specified, break out of the loop if we have enough items
        if (limit > 0 && allItems.length >= limit) {
          allItems = allItems.slice(0, limit); // Trim to the limit if we exceed it
          break;
        }
      } while (fullScan && nextToken);

      //Add the query function name and params to the cache
      if (cacheQuery)
        queryCache.set(JSON.stringify(query) + JSON.stringify(initialParams), {
          data: allItems,
          expiration: new Date(Date.now() + globals.QUERY_EXPIRATION_TIME),
        });

      // Return the collected items
      if (lazyLoadCallback) lazyLoadCallback(allItems, true);
      else resolve(allItems);
    } catch (error) {
      // Handle any errors that occur during execution
      reject(error);
    }
  });
}

export async function timeoutPromise<T>(
  promise: Promise<T>,
  timeout: number
): Promise<T> {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error('Timeout'));
    }, timeout);

    promise
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((error) => {
        clearTimeout(timer);
        reject(error);
      });
  });
}

export async function repeatTimeoutPromise<T>(
  promise: Promise<T>,
  timeout: number,
  maxAttempts: number
): Promise<T> {
  return new Promise(async (resolve, reject) => {
    let attempt = 0;
    let result: T;
    const tryPromise = async () => {
      try {
        result = await timeoutPromise(promise, timeout * (1 + attempt));
        resolve(result);
      } catch (error) {
        if (attempt < maxAttempts) {
          attempt++;
          setTimeout(tryPromise, timeout);
        } else {
          reject(error);
        }
      }
    };
    tryPromise();
  });
}

// Helper function to add the _deleted filter condition to the params
function addDeletedCheckToParams(params: any, overrideDeletedCheck: boolean) {
  if (overrideDeletedCheck || !params?.filter) {
    // If override is true or no filter is provided, return the params as is
    return params;
  }

  // Extract the existing filter from params
  const existingFilter = params.filter;

  // Check if the _deleted condition is already present in the filter
  const isDeletedConditionPresent = checkDeletedCondition(existingFilter);

  if (isDeletedConditionPresent) {
    // If _deleted condition is already present, return the params as is
    return params;
  }

  // Create the _deleted condition
  const deletedCondition = { _deleted: { ne: true } };

  // Check if the existing filter is an array or an object
  let newFilter;

  if (existingFilter.and && Array.isArray(existingFilter.and)) {
    // If it's an array of conditions, add the _deleted condition
    newFilter = { and: [...existingFilter.and, deletedCondition] };
  } else {
    // If it's a single condition, wrap it in an array and add the _deleted condition
    newFilter = { and: [existingFilter, deletedCondition] };
  }

  // Return the updated params with the new filter
  return { ...params, filter: newFilter };
}

// Helper function to check if the _deleted condition is already present in the filter
function checkDeletedCondition(filter: any): boolean {
  if (!filter) return false;

  // Check if the _deleted condition is present at the top level
  if (filter._deleted) return true;

  // Recursively check nested conditions (e.g., within `and`/`or` arrays)
  if (filter.and && Array.isArray(filter.and)) {
    return filter.and.some((condition: any) =>
      checkDeletedCondition(condition)
    );
  }

  if (filter.or && Array.isArray(filter.or)) {
    return filter.or.some((condition: any) => checkDeletedCondition(condition));
  }

  return false;
}

export async function BatchQuery(
  promises: Promise<any>[],
  querySize: number = globals.QUERY_BATCH_SIZE,
  onProgress?: (percent: number, results: any[], i: number, n: number) => void
) {
  try {
    let i = 0;
    let n = Math.ceil(promises.length / querySize);
    let results: any[] = [];

    while (promises.length > 0) {
      i++;
      let batch: Promise<any>[] = promises.splice(0, querySize);
      let innerResults = await Promise.all(batch);
      if (onProgress) onProgress(i / n, innerResults, i, n);
      results.push(...innerResults);
    }
    return results;
  } catch (error) {
    console.error('Error batching queries:', error);
    throw error;
  }
}
