import { useFormik } from 'formik';
import * as _ from 'lodash';
import { InputText } from 'primereact/inputtext';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import {
  handleGetDepartment,
  handleSetProtocolData,
  handleSetSelectedDoseValue,
} from '../../../../../store/actions';
import ConfirmModal from '../../../../components/Modal/ConfirmModal';
import ProtocolEditHeader from '../../ProtocolHeader';
import {
  DatabaseResponse,
  Response,
  ResponseType,
  loadDatabase,
} from '../../../../../data/AmplifyDB';
import DepartmentItem from '../../../../../data/model/DepartmentItem';
import ProtocolItem from '../../../../../data/model/ProtocolItem';
import {
  ElectricalJSON,
  createElectrical,
} from '../../../../../data/functions/ElectricalDB';
import { globals, toTitleCase } from '../../../../_global/common/Utils';
import { ProgressStatus, User } from '../../../../../models';
import ElectricalItem from '../../../../../data/model/ElectricalItem';
import { Col, Row } from 'react-bootstrap';
import { BiRename } from 'react-icons/bi';

interface ElectricalProtocolCreatePageProps {
  stateData?: any;
}

/* 11-07-23 Praveen Created Electrical New Page component for Electrical layout */
const ElectricalProtocolCreatePage: React.FC<
  ElectricalProtocolCreatePageProps
> = ({ stateData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [database, setDatabase] = useState<DatabaseResponse>(
    useSelector((state: any) => state?.protocol?.departmentItem)
  );
  const department: DepartmentItem = database.department;
  const user: User = useSelector((state: any) => state?.user);
  const protocol: ProtocolItem = stateData.selectedProtocol;

  const [isWarningModal, setIsWarningModal] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      rangeLow: '',
      rangeHigh: '',
      warning: '',
      instruction: '',
      note: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required(),
      rangeLow: Yup.string(),
      rangeHigh: Yup.string(),
      title: Yup.string(),
      warning: Yup.string(),
      instruction: Yup.string(),
      note: Yup.string(),
    }),
    onSubmit: (values) => {},
  });

  const reloadDatabase = async (): Promise<boolean> => {
    /* 1-10-24 Hazlett:  Update the current data to the database change and keep the current state */
    const resp: Response = await loadDatabase(database);
    if (resp.type === ResponseType.Success) {
      const newDB: DatabaseResponse = resp.data;
      setDatabase(newDB);
      dispatch<any>(handleGetDepartment(newDB));
      return true;
    } else {
      console.error('ERROR LOADING DATABASE', resp.data);
      return false;
    }
  };

  /**
   * Create the draft version of the first electrical
   */
  const handleCreateElectrical = async () => {
    try {
      /* If the form is valid, then create the medication */
      if (formik.isValid) {
        if (globals.debug)
          console.log('Form is valid, creating electrical -> ', formik.values);

        const newElectrical: ElectricalJSON = {
          title: formik.values.name,
          rangeLow: !isNaN(Number(formik.values.rangeLow))
            ? Number(formik.values.rangeLow)
            : 0,
          rangeHigh: !isNaN(Number(formik.values.rangeHigh))
            ? Number(formik.values.rangeHigh)
            : globals.MAX_VALUE,
          departmentID: department.id,
          options: [],

          instruction: formik.values.instruction,
          note: formik.values.note,
          warning: formik.values.warning,
          createdBy: user.id,

          status: ProgressStatus.DRAFT,
          activeID: null,
          version: 'v1.0.0',
        };

        let results: Response = await createElectrical(newElectrical);
        if (results.type === ResponseType.Success) {
          if (globals.debug)
            console.log('Successfully created electrical', results.data);
          let isLoaded: boolean = await reloadDatabase();
          if (isLoaded) {
            if (globals.debug) console.log('Successfully reloaded database');
            const electrical: ElectricalItem = results.data;
            if (protocol) {
              navigate(`/${protocol.nickname}/protocol-detail`, {
                state: { selectedProtocol: protocol, editMode: true },
              });
            } else
              navigate(`/database/list-electricals`, {
                state: { department: department, database: database },
              });
          }
        }
      }
    } catch (error) {
      if (globals.debug) console.log('Error creating medication -> ', error);
    }
  };

  /*11/07/2023 Function to handle cancel in header*/
  const handleCancel = () => {
    /* If the form is dirty, then show the warning modal */
    if (formik.dirty && !isWarningModal) {
      setIsWarningModal(true);
    } else if (protocol) {
      navigate(`/${protocol.nickname}/protocol-detail`, {
        state: { selectedProtocol: protocol, editMode: true },
      });
    } else {
      navigate(`/database/list-electricals`, {
        state: { department: department, database: database },
      });
    }
  };

  return (
    <div className="screen-container">
      {isWarningModal && (
        <ConfirmModal
          isVisible={isWarningModal}
          title="Abandon Changes?"
          handleClose={() => {
            setIsWarningModal(false);
          }}
          handleSubmit={handleCancel}
          isDeleteBtn={true}
          primaryBtnName="Cancel"
          secondaryBtnName="Abandon"
          primaryDescription={`Changes were made to this Electrical.  Click cancel to return to Electrical details.  To continue without saving changes, select Abandon Changes.`}
        />
      )}
      <div className="fixedHeader">
        <ProtocolEditHeader
          name={'New Electrical Shock'}
          page={protocol ? protocol.name : 'Electricals'}
          protocolDetail={protocol}
          type={'protocol'}
          isCreateButton={true}
          isBackButton={true}
          isDotButton={true}
          isCreateActive={formik.dirty && formik.isValid}
          handleCancel={handleCancel}
          handleCreate={handleCreateElectrical}
        />
      </div>

      <div style={{ minHeight: '65vh' }} className="ketamineContent">
        <div className="KetamineGeneral">
          <div className="container overflow-info-first">
            <h5 className="ketmine-header-text">Required Information</h5>
            <div>
              <div>
                <label
                  htmlFor="name"
                  className={`notification-css-title`}
                  style={{ fontSize: '16px' }}
                >
                  Name
                  <span className="required-field ">
                    *
                    <BiRename
                      className="refresh-icon"
                      onClick={() => {
                        formik.setFieldValue(
                          'name',
                          toTitleCase(formik.values.name)
                        );
                      }}
                      size="1.25rem"
                      style={{ marginRight: '5px', cursor: 'pointer' }}
                    />
                  </span>
                </label>
                <div className="input-container">
                  <InputText
                    type="text"
                    className="form-control-general"
                    id="name"
                    name="name"
                    required={true}
                    data-testid="name"
                    value={formik.values.name}
                    onChange={(e: any) => {
                      formik.setFieldValue('name', e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <div className="input-border"></div>
                </div>
                {formik.touched.name && formik.errors.name ? (
                  <span className="errorText">{formik.errors.name}</span>
                ) : null}
                <Row>
                  <Col sm={6}>
                    <label
                      htmlFor="rangeLow"
                      className="ketamine-general-label"
                    >
                      Range Low (kg)
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="name"
                        name="rangeLow"
                        data-testid="rangeLow"
                        value={formik.values.rangeLow}
                        // style={{color: Number(formik.values.rangeLow) ? '#9e9e9e' : '#000000'}}
                        placeholder="Min"
                        onChange={(e: any) => {
                          /* Check for "Min" */
                          let n = Number(e.target.value);
                          if (isNaN(n))
                            formik.setFieldError('rangeLow', 'Invalid input');
                          else formik.setFieldValue('rangeLow', e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <div className="input-border"></div>
                    </div>
                    {formik.touched.rangeLow && formik.errors.rangeLow ? (
                      <span className="errorText">
                        {formik.errors.rangeLow}
                      </span>
                    ) : null}
                  </Col>
                  <Col sm={6}>
                    <label
                      htmlFor="rangeHigh"
                      className="ketamine-general-label"
                    >
                      Range High (kg)
                    </label>
                    <div className="input-container">
                      <InputText
                        type="text"
                        className="form-control-general"
                        id="rangeHigh"
                        name="rangeHigh"
                        data-testid="rangeHigh"
                        value={formik.values.rangeHigh}
                        placeholder="Max"
                        onChange={(e: any) => {
                          let n = Number(e.target.value);
                          if (isNaN(n))
                            formik.setFieldError('rangeHigh', 'Invalid input');
                          else
                            formik.setFieldValue('rangeHigh', e.target.value);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <div className="input-border"></div>
                    </div>
                    {formik.touched.rangeHigh && formik.errors.rangeHigh ? (
                      <span className="errorText">
                        {formik.errors.rangeHigh}
                      </span>
                    ) : null}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        <div className="KetamineGeneral">
          <div className="container overflow-info-second">
            <div>
              <h5 className="ketmine-header-text">Additional Information</h5>
              <label htmlFor="Warning" className="ketamine-general-label">
                Warning
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="warning"
                  name="warning"
                  required={true}
                  data-testid="warning"
                  value={formik.values.warning}
                  onChange={(e: any) => {
                    formik.setFieldValue('warning', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Instruction" className="ketamine-general-label">
                Instruction
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="instruction"
                  name="instruction"
                  required={true}
                  data-testid="instruction"
                  value={formik.values.instruction}
                  onChange={(e: any) => {
                    formik.setFieldValue('instruction', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
              <label htmlFor="Note" className="ketamine-general-label">
                Note
              </label>
              <div className="input-container">
                <InputText
                  type="text"
                  className="form-control-general"
                  id="note"
                  name="note"
                  required={true}
                  data-testid="note"
                  value={formik.values.note}
                  onChange={(e: any) => {
                    formik.setFieldValue('note', e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <div className="input-border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElectricalProtocolCreatePage;
